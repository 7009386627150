import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroller'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actions as contactsActions } from 'modules/contacts'
import {
  getTeamContactUuids,
  getTeamContactsHasMore,
  getTeamContactsLoading,
  getSearchContactsUuids,
} from 'selectors/contactsSelector'

import { Contact } from './Contact'
import { EndText } from './EndText'

export const ContactList = styled(
  ({ openModal = () => {}, loadSearchResults = false }) => {
    const dispatch = useDispatch()
    const loading = useRef(false)

    const teamContactsHasMore = useSelector(getTeamContactsHasMore)
    const teamContactsLoading = useSelector(getTeamContactsLoading)
    const teamContactsUuids = useSelector(getTeamContactUuids)

    const searchContactsUuids = useSelector(getSearchContactsUuids)

    const contacts = loadSearchResults ? searchContactsUuids : teamContactsUuids

    const hasMore = loadSearchResults
      ? false
      : teamContactsHasMore && !teamContactsLoading

    const handleInfiniteLoad = () => {
      if (!loading.current) {
        loading.current = true
        dispatch(contactsActions.queryContacts())
      }
    }

    useEffect(() => {
      if (loading.current && !teamContactsLoading) {
        setTimeout(() => {
          loading.current = false
        }, 1)
      }
    }, [teamContactsLoading])

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={_.debounce(handleInfiniteLoad, 1)}
        hasMore={hasMore}
      >
        {contacts.map(uuid => (
          <Contact key={uuid} uuid={uuid} openModal={openModal} />
        ))}

        {!loadSearchResults && <EndText />}
      </InfiniteScroll>
    )
  }
)`
  & > div:last-child {
    border-bottom: 1px solid #e3e3e3;
  }
`
