import styled, { css } from 'styled-components'
import BaseButton from 'components/Buttons'

const Span = styled.span`
  vertical-align: middle;
`

const CircleSpan = styled(Span)`
  width: 20px;
  display: inline-block;
  background-color: #C4412B;
  border-radius: 50%;
  margin-right: 10px;
`

const Button = styled(({ circle, children, ...props }) => (
  <BaseButton red {...props}>
    {!!circle && <CircleSpan>{circle}</CircleSpan>}
    <Span>{children}</Span>
  </BaseButton>
))`
  line-height: 20px;
`

export const Label = styled(Button).attrs(() => ({
  forwardedAs: 'label',
}))`
  cursor: pointer;
  margin-right: 20px;
  ${({ completed }) => completed && css`
    background-color: #BDBDBD;
    border-color: #BDBDBD;
    ${CircleSpan} {
      background-color: #417505;
    }
  `}
`

export default Button
