import styled from 'styled-components'
import _ from 'lodash'
import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { useDispatch } from 'react-redux'
import useModal from 'use-react-modal'

import { actions as contactsActions } from 'modules/contacts'

import Button from 'components/Buttons'

const DeleteContactModal = styled(
  forwardRef(({ className, ...props }, ref) => {
    const [formProps, setFormProps] = useState({
      contactUuid: null,
      preDelete: () => {},
      postDelete: () => {},
    })

    const { openModal, isOpen, Modal, closeModal } = useModal()

    const dispatch = useDispatch()
    const deleteContact = () => {
      dispatch(contactsActions.deleteContact(formProps.contactUuid))
      closeModal()
    }

    const summon = (e, _props) => {
      setFormProps(prevState => ({ ...prevState, ..._props }))
      _.result(_props, 'preDelete')
      openModal(e)
    }

    useImperativeHandle(ref, () => ({
      summon,
    }))

    if (!isOpen) return null

    return (
      <Modal {...props}>
        <Col>
          Are you sure you want to delete this contact?
          <Row style={{ marginTop: 10 }}>
            <Button secondary onClick={closeModal} type="button">
              Cancel
            </Button>

            <Button
              style={{ marginLeft: 15 }}
              primary
              onClick={deleteContact}
              type="button"
            >
              Delete
            </Button>
          </Row>
        </Col>
      </Modal>
    )
  })
)``

const Col = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 16px;
  width: 282px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
`

export default DeleteContactModal
