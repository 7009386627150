import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import useModal from 'use-react-modal'

import { actions as contactsActions } from 'modules/contacts'
import { getUpdateContactId } from 'selectors/contactsSelector'
import {
  currentTeamSelector,
  currentTeamIdSelector,
} from 'selectors/teamsSelector'

import Button from 'components/Buttons'
import { AddButton as _AddButton } from 'components/AddButton'
import { ContactModalToggle } from 'components/Modals'

import { media } from 'utility/styles'

import ContactForm from 'components/ContactForm/Form'
import { ContactsDropdown } from './ContactsDropdown'
import { ContactSearchInput } from './ContactSearchInput'
import { ContactList } from './ContactList'

const AddButton = styled(_AddButton)`
  ${media.tablet`
    & > button {
      width: 56px;
      height: 56px;
      margin: 0 24px 24px 0;
      & > i {
        font-size: 24px;
      }
    }
  `}
`

const ContactsPage = () => {
  const dispatch = useDispatch()
  const currentTeamUuid = useSelector(currentTeamIdSelector)
  let team = useSelector(currentTeamSelector)
  if (team) team = team.toJS()
  const mounted = useRef(false)

  const [openModal, closeModal, contactFormModalIsOpen, Modal] = useModal()

  const [
    openDeleteContactModal,
    closeDeleteContactModal,
    deleteContactConfirmModalIsOpen,
    ConfirmDeleteModal,
  ] = useModal({
    onClose: () => dispatch(contactsActions.setUpdateContactId(null)),
  })

  const updateContactId = useSelector(getUpdateContactId)
  const deleteContact = e => {
    dispatch(contactsActions.deleteContact(updateContactId))
    closeDeleteContactModal(e)
  }

  const [searchText, setSearchText] = useState('')
  const onChange = ({ target: { value } }) => {
    setSearchText(value)
  }

  const searchForContacts = value => {
    dispatch(contactsActions.searchContactsBig(value))
  }
  const searchForContactsDebounced = useRef(_.debounce(searchForContacts, 500))
    .current

  useEffect(() => {
    if (!searchText) {
      dispatch(contactsActions.queryContacts())
    } else {
      searchForContactsDebounced(searchText)
    }
  }, [searchText])

  useEffect(() => {
    if (mounted.current && !searchText) {
      dispatch(contactsActions.queryContacts())
    }
    mounted.current = true
    setSearchText('')
  }, [currentTeamUuid])

  return (
    <>
      <Header>
        <Col hideOnMobile>
          <Title>CONTACTS</Title>
          <BusinessName>{_.get(team, 'name')}</BusinessName>
        </Col>

        <Row>
          <ContactSearchInput value={searchText} onChange={onChange} />

          <ContactsDropdown />
        </Row>
      </Header>

      <ContactList loadSearchResults={!!searchText} openModal={openModal} />

      <ContactModalToggle component={AddButton} />

      {contactFormModalIsOpen && (
        <Modal>
          <ContactForm
            title="EDIT CONTACT"
            closeModal={closeModal}
            openDeleteContactModal={openDeleteContactModal}
          />
        </Modal>
      )}

      {deleteContactConfirmModalIsOpen && (
        <ConfirmDeleteModal>
          <ConfirmDeleteCol>
            Are you sure you want to delete this contact?
            <Row style={{ marginTop: 10 }}>
              <Button secondary onClick={closeDeleteContactModal} type="button">
                Cancel
              </Button>

              <Button
                style={{ marginLeft: 15 }}
                primary
                onClick={deleteContact}
                type="button"
              >
                Delete
              </Button>
            </Row>
          </ConfirmDeleteCol>
        </ConfirmDeleteModal>
      )}
    </>
  )
}

const Col = styled.div`
  ${props =>
    props.hideOnMobile &&
    media.tablet`
    display: none;
  `}
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;

  ${media.tablet`
    flex: 1;
  `}
`

const Title = styled.h1`
  margin-bottom: 5px;
`

const BusinessName = styled.div`
  color: ${props => props.theme.page.subheadingColor};
  font-size: 14px;
`

const Header = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  justify-content: space-between;
  ${media.tablet`
    margin: 0px;
    padding: 12px;
    background: rgb(244, 244, 244);
  `}
`

const ConfirmDeleteCol = styled(Col)`
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 16px;
  width: 282px;
`

export default ContactsPage
