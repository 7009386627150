import _ from 'lodash'
import ApiClient from 'components/ApiClient'
import {
  contact as contactSchema,
  phoneNumber as phoneNumberSchema,
  emailAddress as emailAddressSchema,
  job as jobSchema,
} from '../schemas/contacts'

const client = new ApiClient()
const API_ENDPOINT = __CONFIG__.apiPath.contacts

export const createContact = data =>
  client.post(API_ENDPOINT.base, { data, schema: contactSchema })

export const deleteContact = uuid =>
  client.delete(_.replace(API_ENDPOINT.get, ':uuid', uuid))

export const deleteAllImportedContacts = uuid =>
  client.delete(_.replace(API_ENDPOINT.get, ':uuid', uuid))

export const getContact = uuid =>
  client.get(_.replace(API_ENDPOINT.get, ':uuid', uuid), {
    schema: contactSchema,
  })

export const queryContacts = params =>
  client.get(API_ENDPOINT.base, {
    params,
    schema: [contactSchema],
    isArray: true,
  })

export const searchContacts = params =>
  client.get(API_ENDPOINT.search, {
    params,
    schema: [contactSchema],
    isArray: true,
  })

export const removeContact = uuid =>
  client.delete(_.replace(API_ENDPOINT.get, ':uuid', uuid), {
    schema: contactSchema,
  })

export const updateContact = data =>
  client.patch(_.replace(API_ENDPOINT.get, ':uuid', data.uuid), {
    schema: contactSchema,
    data,
  })

export const createPhoneNumber = (contactUuid, data) =>
  client.post(
    _.replace(API_ENDPOINT.phoneNumbers.base, ':contactUuid', contactUuid),
    { data, schema: phoneNumberSchema }
  )

export const deletePhoneNumber = (contactUuid, phoneNumberUuid) =>
  client.delete(
    _.replace(
      _.replace(API_ENDPOINT.phoneNumbers.get, ':contactUuid', contactUuid),
      ':phoneNumberUuid',
      phoneNumberUuid
    ),
    { schema: phoneNumberSchema }
  )

export const getPhoneNumber = (contactUuid, phoneNumberUuid) =>
  client.get(
    _.replace(
      _.replace(API_ENDPOINT.phoneNumbers.get, ':contactUuid', contactUuid),
      ':phoneNumberUuid',
      phoneNumberUuid
    ),
    { schema: phoneNumberSchema }
  )

export const queryPhoneNumbers = contactUuid =>
  client.get(_.replace(API_ENDPOINT.phoneNumbers.base, ':uuid', contactUuid), {
    schema: [phoneNumberSchema],
    isArray: true,
  })

export const updatePhoneNumber = (contactUuid, phoneNumber) =>
  client.patch(
    _.replace(
      _.replace(API_ENDPOINT.phoneNumbers.get, ':contactUuid', contactUuid),
      ':phoneNumberUuid',
      phoneNumber.uuid
    ),
    { schema: phoneNumberSchema, data: phoneNumber }
  )

export const createEmailAddress = (contactUuid, data) =>
  client.post(
    _.replace(API_ENDPOINT.emailAddresses.base, ':contactUuid', contactUuid),
    { data, schema: emailAddressSchema }
  )

export const deleteEmailAddress = (contactUuid, emailAddressUuid) =>
  client.delete(
    _.replace(
      _.replace(API_ENDPOINT.emailAddresses.get, ':contactUuid', contactUuid),
      ':emailAddressUuid',
      emailAddressUuid
    ),
    { schema: emailAddressSchema }
  )

export const getEmaillAddress = (contactUuid, emailAddressUuid) =>
  client.get(
    _.replace(
      _.replace(API_ENDPOINT.emailAddresses.get, ':contactUuid', contactUuid),
      ':emailAddressUuid',
      emailAddressUuid
    ),
    { schema: emailAddressSchema }
  )

export const queryEmailAddresses = contactUuid =>
  client.get(
    _.replace(API_ENDPOINT.emailAddresses.base, ':uuid', contactUuid),
    { schema: [emailAddressSchema], isArray: true }
  )

export const updateEmailAddress = (contactUuid, emailAddress) =>
  client.patch(
    _.replace(
      _.replace(API_ENDPOINT.emailAddresses.get, ':contactUuid', contactUuid),
      ':emailAddressUuid',
      emailAddress.uuid
    ),
    { schema: emailAddressSchema, data: emailAddress }
  )

export const uploadContactsImport = (file, teamUuid) =>
  client.post(_.replace(API_ENDPOINT.import.base, ':teamUuid', teamUuid), {
    attachments: { file },
    headers: {
      'Content-Disposition': `attachment; filename="${file.name}"`,
    },
  })

export const queryContactsImports = teamUuid =>
  client.get(_.replace(API_ENDPOINT.import.base, ':teamUuid', teamUuid), {
    isArray: true,
  })

export const exportContacts = teamUuid =>
  client.post(_.replace(API_ENDPOINT.export.base, ':teamUuid', teamUuid), {
    data: { job_type: 'csv' },
    schema: jobSchema,
  })

export const getExportContacts = (teamUuid, uuid) =>
  client.get(
    _.replace(
      API_ENDPOINT.export.get,
      /:teamUuid|:uuid/g,
      match => ({ ':teamUuid': teamUuid, ':uuid': uuid }[match])
    ),
    { schema: jobSchema }
  )

export const schema = {
  contact: contactSchema,
  emailAddress: emailAddressSchema,
  phoneNumber: phoneNumberSchema,
}
