import styled from 'styled-components'
import { useSelector } from 'react-redux'

import _Spinner from 'components/Spinner'
import {
  getTeamContactsCount,
  getTeamContactsLoading,
  getTeamContactsHasMore,
} from 'selectors/contactsSelector'

export const EndText = styled(() => {
  const teamContactsCount = useSelector(getTeamContactsCount)
  const teamContactsLoading = useSelector(getTeamContactsLoading)
  const teamContactsHasMore = useSelector(getTeamContactsHasMore)

  const endText = `${teamContactsCount} contact${
    teamContactsCount !== 1 ? 's' : ''
  } shown`

  return teamContactsLoading ? (
    <Spinner />
  ) : (
    !teamContactsHasMore && <Span>{endText}</Span>
  )
})``

const Spinner = styled(_Spinner)`
  padding: 10px 0px;
`

const Span = (styled.span || styled.Text)`
  display: block;
  padding: 15px 0px;
  text-align: center;
  font-weight: 600;
  color: ${props => props.theme.colors.gray};
`
