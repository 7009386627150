import styled from 'styled-components'
import React, { forwardRef, useState, useImperativeHandle } from 'react'
import useModal from 'use-react-modal'

import Button from 'components/Buttons'
import ButtonRow from 'components/Buttons/ButtonRow'

const PreDeleteModal = styled(
  forwardRef(({ className, ...props }, ref) => {
    const [formProps, setFormProps] = useState({
      onConfirm: () => {},
      type: 'record',
      title: 'Delete',
    })

    const { openModal, isOpen, Modal, closeModal } = useModal()

    const summon = (e, _props) => {
      setFormProps(prevState => ({ ...prevState, ..._props }))
      openModal(e)
    }

    useImperativeHandle(ref, () => ({
      summon,
    }))

    if (!isOpen) return null

    const { type, onConfirm, title } = formProps

    const onDeleteClick = () => {
      onConfirm()
      closeModal()
    }

    return (
      <Modal {...props}>
        <Col>
          <Title>{title}</Title>

          <Body>
            {`Are you sure you want to delete this ${type}? This can't be undone.`}
          </Body>

          <ButtonRow>
            <Button onClick={closeModal} secondary type="button">
              Cancel
            </Button>

            <Button onClick={onDeleteClick} type="button" primary red>
              Delete
            </Button>
          </ButtonRow>
        </Col>
      </Modal>
    )
  })
)``

const Col = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 24px;
  width: 358px;
`

const Title = styled.h2`
  color: #535353;
  font-size: 21px;
  letter-spacing: 0.39px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 24px;
`

const Body = styled.p`
  margin-bottom: 30px;
  font-size: 14px;
  letter-spacing: 0.26px;
  line-height: 17px;
`

export default PreDeleteModal
