import _AuthCard from 'components/Cards/AuthCard'
import InviteForm from 'components/InviteForm'

const List = () => {
  return (
    <AuthCard>
      <Title>Your Invitations</Title>

      <Graf>
        You’ve been invited to one or more organizations. Please let us know
        which invitations you’d like to accept:
      </Graf>

      <InviteForm />
    </AuthCard>
  )
}

const AuthCard = styled(_AuthCard)`
  max-width: 812px;
`

const Title = styled.h1`
  color: #1e1e1e;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0.68px;
  line-height: 1.5;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0;
`

const Graf = styled.p`
  color: #787878;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
`

export default List
