import styled from 'styled-components'
import { connect } from 'react-redux'
import { func, object, string } from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import queryString from 'query-string'

import { currentTeamIdSelector } from 'selectors/teamsSelector'
import { getActiveConversationFilters } from 'selectors/formSelector'

import { actions as conversationsActions } from 'modules/conversations'

import { Dropdown, Option } from 'components/Dropdown'
import { EmailConversationsModalToggle } from 'components/Modals'

import _ShareIcon from '../Icons/ShareIcon'

const mapDispatchToProps = {
  toggleShareModal: conversationsActions.toggleShareModal,
}

const mapStateToProps = state => ({
  currentTeamId: currentTeamIdSelector(state),
  filters: getActiveConversationFilters(state),
})

const ShareCallsDropdown = ({
  toggleShareModal, filters, currentTeamId, ...props
}) => {
  let dropdownHolder = {}

  if (!currentTeamId || !filters) return null

  const _queryString = queryString.stringify(filters, { arrayFormat: 'bracket' })
  const printURL = `/calls/${currentTeamId}/?print&${_queryString}`

  return (
    <Dropdown
      ref={_dropdown => { dropdownHolder = _dropdown }}
      toggle={<ShareIcon />}
      noCaret
      right
      {...props}
    >
      <EmailConversationsModalToggle
        className='dropdownItem'
        component={Option}
        preSave={() => {
          dropdownHolder.closeDropdown()
        }}
      >
        Email
      </EmailConversationsModalToggle>

      <Option
        className='dropdownItem'
        onSelect={() => dropdownHolder.closeDropdown()}
        href={printURL}
        target='_blank'
      >
        Print
      </Option>
    </Dropdown>
  )
}

ShareCallsDropdown.propTypes = {
  toggleShareModal: func.isRequired,
  currentTeamId: string,
  filters: object,
}

ShareCallsDropdown.defaultProps = {
  filters: null,
  currentTeamId: null,
}

const ShareIcon = styled(_ShareIcon)``

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(ShareCallsDropdown))
