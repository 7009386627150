import styled from 'styled-components'
import Nav from '../Nav'
import ShareCallsDropdown from './ShareCallsDropdown'

const TeamNav = styled(({ ...props }) => (
  <Nav {...props}>
    <li>
      <ShareCallsDropdown />
    </li>
  </Nav>
))``

export default TeamNav
