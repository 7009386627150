import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getFirstEmailAddressUuidByContactUuid } from 'selectors/contactsSelector'

import Email from 'components/Email'

const FirstEmail = styled(({ contactUuid, ...props }) => {
  const uuid = useSelector(
    state => getFirstEmailAddressUuidByContactUuid(state, { uuid: contactUuid }),
  )

  if (!uuid) return null

  return <Email uuid={uuid} {...props} />
})``

export default FirstEmail
