import React, { useRef, useEffect } from 'react'
import { Switch, Redirect, useRouteMatch, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Route from 'components/Route'
import { currentOrganizationIdSelector } from 'selectors/organizationsSelector'

import TeamForm from './Form'
import TeamsList from './TeamsList'

const Teams = () => {
  const organizationUuid = useSelector(currentOrganizationIdSelector)
  const organizationUuidRef = useRef(null)
  const teamEditMatch = useRouteMatch(
    `/settings/${organizationUuid}/teams/:team_id`
  )
  const { replace } = useHistory()

  useEffect(() => {
    if (organizationUuidRef.current && teamEditMatch) {
      replace(`/settings/${organizationUuid}/teams`)
    }
    organizationUuidRef.current = organizationUuid
  }, [organizationUuid])

  if (!organizationUuid) return null

  return (
    <Switch>
      <Route
        exact
        path={`/settings/${organizationUuid}/teams/:team_id`}
        component={TeamForm}
      />
      <Route
        exact
        path={`/settings/${organizationUuid}/teams`}
        component={TeamsList}
      />
      <Redirect
        strict
        from={`/settings/${organizationUuid}`}
        to={`/settings/${organizationUuid}/teams`}
      />
    </Switch>
  )
}

export default Teams
