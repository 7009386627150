import styled, { css } from 'styled-components'

export default styled(({
  dropdown,
  bold,
  light,
  right,
  shrink,
  grow,
  highlight,
  inset,
  breakWord,
  ellipsis,
  children,
  ...props
}) => (
  <div {...props}>{ellipsis ? <Span>{children}</Span> : children}</div>
))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 25%;
  padding: 16px 18px;
  overflow: hidden;

  > * {

    &,
    > li {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ${({ dropdown }) => dropdown && css`
    overflow: visible;
    position: relative;
  `}

  ${({ bold }) => bold && css`
    color: ${props => props.theme.table.titleColor};
    font-size: 14px;
    font-weight: bold;
  `}
  ${({ light }) => light && css`
    font-size: 12px;
    line-height: 14px;
    color: ${props => props.theme.table.lightColor};
  `}
  ${({ highlight }) => highlight && css`
    font-weight: bold;
    color: ${props => props.theme.table.highlightColor};
  `}
  ${({ right }) => right && css`
    text-align: right;
  `}
  ${({ shrink }) => shrink && css`
    flex: 0 0 auto;
  `}
  ${({ grow }) => grow && css`
    flex: 1 1 100%;
  `}
  ${({ inset }) => inset && css`
    padding-left: 26px;
  `}
  ${({ width }) => width && css`
    width: ${width};
  `}
  ${({ breakWord }) => breakWord && css`
    word-break: break-word;
  `}
`

const Span = (styled.span || styled.Text)`
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
