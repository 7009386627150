import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { onlyShow } from 'utility/styles'

import {
  getOrganizationByUuid,
  currentUserCanLeaveOrganizationByUuid,
} from 'selectors/organizationsSelector'
import {
  getTeamByUuid,
  currentUserCanLeaveTeamByUuid,
} from 'selectors/teamsSelector'

import LeaveButton from './LeaveButton'

const Row = ({ uuid, isOrganization }) => {
  const entitySelctor = isOrganization ? getOrganizationByUuid : getTeamByUuid
  const entity = useSelector(state =>
    entitySelctor(state, { uuid, teamUuid: uuid })
  )
  const canLeaveSelector = isOrganization
    ? currentUserCanLeaveOrganizationByUuid
    : currentUserCanLeaveTeamByUuid
  const canLeaveEntity = useSelector(state =>
    canLeaveSelector(state, { uuid, teamUuid: uuid })
  )

  return (
    <StyledRow isOrganization={isOrganization}>
      <Col>{entity.get('name')}</Col>
      <Col right>
        <LeaveButton
          disabled={!canLeaveEntity}
          uuid={uuid}
          isOrganization={isOrganization}
        />
      </Col>
    </StyledRow>
  )
}

const StyledRow = styled.div`
  display: flex;
  padding: 14px;
  border-bottom: 1px solid #e3e3e3;
  justify-content: space-between;
  align-items: center;
  ${({ isOrganization }) => !isOrganization && 'padding-left: 28px;'}
  ${({ isOrganization }) => isOrganization && 'font-weight: bold;'}
`

const Col = styled.div`
  margin-left: ${({ right }) => (right ? '14px' : 0)};
  ${({ right }) => !!right && 'flex: 0 0 auto;'}
  ${onlyShow(1).line}
  position: relative;
`

export default Row
