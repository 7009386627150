import styled from 'styled-components'
import { Link as _Link } from 'react-router-dom'
import AddIcon from './AddIcon'
import AddIconHolder from './AddIconHolder'
import ButtonElement from './ButtonElement'

const AddLink = styled(({ to, ...props }) => (
  <AddIconHolder {...props}>
    <Link
      as={_Link}
      to={to}
    >
      <AddIcon />
    </Link>
  </AddIconHolder>
))``

const Link = styled(ButtonElement)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default AddLink
