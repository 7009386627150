import { useSelector } from 'react-redux'

import { currentOrganizationIdSelector } from 'selectors/organizationsSelector'
import Link from 'components/Link'

const BackLink = ({ className, children }) => {
  const organizationUuid = useSelector(currentOrganizationIdSelector)

  if (!organizationUuid) return null

  return (
    <Link
      className={className}
      to={`/settings/${organizationUuid}/teams`}
      replace
    >
      {children}
    </Link>
  )
}

export default BackLink
