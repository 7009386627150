import createSagaMiddleware from 'redux-saga'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router/immutable'
import { keyIn } from 'utility/immutable'
import { initialState as initialAuthState } from 'modules/auth'
import { fromJS } from 'immutable'
import history from './history'

import sagas from './sagas'
import createRootReducer from './rootReducers'

let initialState = {}

if (window.__INITIAL_STATE__) {
  initialState = window.__INITIAL_STATE__
}

// Redux DevTools Extension for Chrome and Firefox
const reduxDevTool = () => {
  return typeof window === 'object' &&
    typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f
}

function configureStore(initState, hist) {
  const sagaMiddleware = createSagaMiddleware()

  const middleware = applyMiddleware(sagaMiddleware, routerMiddleware(history))

  const composedStoreEnhancer = compose(
    middleware,
    reduxDevTool()
  )

  const appReducer = createRootReducer(hist)

  const rootReducer = (state, action) => {
    let newState = state
    if (action.type === 'RESET_APP') {
      const persistedState = ['form', 'router']
      newState = state.filter(keyIn(persistedState)).withMutations(_state =>
        _state.set(
          'auth',
          initialAuthState()
            .set('loaded', state.getIn(['auth', 'loaded']))
            .set('jwtPublicKey', state.getIn(['auth', 'jwtPublicKey']))
        )
      )
    }

    return appReducer(newState, action)
  }

  const store = composedStoreEnhancer(createStore)(rootReducer, initState)

  sagaMiddleware.run(sagas)

  if (module.hot) {
    module.hot.accept('./rootReducers', () => {
      store.replaceReducer(createRootReducer(history))
    })
  }

  return store
}

const store = configureStore(fromJS(initialState), history)

export { store, history }

export default store
