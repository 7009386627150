import styled from 'styled-components'
import {
  arrayOf, any, func, shape, bool,
} from 'prop-types'
import TabsList from './TabsList'
import TabItem from './TabItem'
import DumbTabLink from './DumbTabLink'
import TabContent from './TabContent'

class DumbTabs extends React.Component {
  static propTypes = {
    tabs: arrayOf(shape({
      name: any.isRequired,
      render: func.isRequired,
    })).isRequired,
    bordered: bool,
  }

  static defaultProps = {
    bordered: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      activeTab: 0,
    }

    this.goToTab = this.goToTab.bind(this)
  }

  goToTab(activeTab) {
    this.setState({ activeTab })
  }

  render() {
    const { activeTab } = this.state
    const { tabs, bordered, ...props } = this.props
    return (
      <div {...props}>
        <TabsList>
          {tabs.map((tab, key) => (
            <TabItem key={key}>
              <DumbTabLink
                className={activeTab === key ? 'active' : ''}
                onClick={() => this.goToTab(key)}
                to='#'
              >
                {tab.name}
              </DumbTabLink>
            </TabItem>
          ))}
        </TabsList>

        <TabContent bordered={bordered}>
          {tabs.map((tab, key) => (
            <DumbTabContent
              key={key}
              active={activeTab === key}
            >
              {tab.render()}
            </DumbTabContent>
          ))}
        </TabContent>
      </div>
    )
  }
}

const DumbTabContent = (styled.div || styled.View)`
  display: ${props => (props.active ? 'block' : 'none')};}
`

export default styled(DumbTabs)``
