import styled from 'styled-components'
import { node } from 'prop-types'

const Nav = styled(({ children, ...props }) => (
  <ul {...props}>
    {children}
  </ul>
))`
  align-items: stretch;
  display: flex;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  user-select: none;

  li {
    position: relative;
    align-items: center;
    display: flex;
  }

  li + li {
    margin-left: ${props => props.theme.nav.marginHorizontal};
  }
`

Nav.propTypes = {
  children: node,
}

Nav.defaultProps = {
  children: null,
}

export default Nav
