import { useSelector, useDispatch } from 'react-redux'
import { actions as organizationsActions } from 'modules/organizations'
import {
  canRemoveOrgAdminPrivilegeByCurrentOrgUuidSelector,
  getCurrentOrganizationMembers,
  getCurrentOrganizationMemberUuids,
  getMemberByUuid,
  getQueryOrganizationMembersError,
  getQueryOrganizationMembersLoading,
  getQueryOrganizationMembersUuids,
  memberCanLeaveCurrentOrganizationByUuid,
} from 'selectors/organizationsSelector'

export default function useOrganizationMembers(uuid = null) {
  const dispatch = useDispatch()

  const canRemoveOrgAdminPrivilegeByCurrentOrgUuid = useSelector(
    canRemoveOrgAdminPrivilegeByCurrentOrgUuidSelector
  )
  const currentOrganizationMembers = useSelector(getCurrentOrganizationMembers)
  const currentOrganizationMemberUuids = useSelector(
    getCurrentOrganizationMemberUuids
  )
  const memberByUuid = useSelector(state => getMemberByUuid(state, { uuid }))
  const memberCanLeaveCurrentOrganization = useSelector(state =>
    memberCanLeaveCurrentOrganizationByUuid(state, { uuid })
  )
  const queryOrganizationMembersError = useSelector(
    getQueryOrganizationMembersError
  )
  const queryOrganizationMembersLoading = useSelector(
    getQueryOrganizationMembersLoading
  )
  const queryOrganizationMembersUuids = useSelector(
    getQueryOrganizationMembersUuids
  )

  const queryOrganizationMembers = values => {
    if (!queryOrganizationMembersLoading) {
      dispatch(organizationsActions.queryOrganizationMembers(values))
    }
  }

  const deleteOrganizationMembership = values => {
    dispatch(organizationsActions.deleteMembership(values))
  }

  const updateOrganizationMembership = values => {
    dispatch(organizationsActions.updateOrganizationMembership(values))
  }

  return {
    canRemoveOrgAdminPrivilegeByCurrentOrgUuid,
    currentOrganizationMembers,
    currentOrganizationMemberUuids,
    deleteOrganizationMembership,
    memberCanLeaveCurrentOrganization,
    queryOrganizationMembers,
    queryOrganizationMembersError,
    queryOrganizationMembersLoading,
    queryOrganizationMembersUuids,
    memberByUuid,
    updateOrganizationMembership,
  }
}
