import styled from 'styled-components'
import { connect } from 'react-redux'
import { string } from 'prop-types'
import {
  getColorFromUuid,
  getInitialismFromUuid,
} from 'selectors/statusesSelector'

const mapStateToProps = (state, { statusUuid: uuid }) => {
  const statusColor = getColorFromUuid(state, { uuid })
  const statusInitialism = getInitialismFromUuid(state, { uuid })

  return {
    statusColor,
    statusInitialism,
  }
}

const mapDispatchToProps = {}

const Initialism = (styled.span || styled.Text).attrs(
  ({ statusInitialism }) => ({
    children: statusInitialism,
  })
)`
  color: ${({ statusColor }) =>
    (statusColor && `#${statusColor}`) || 'inherit'};
  font-weight: 700;
`

Initialism.propTypes = {
  statusColor: string,
  statusInitialism: string.isRequired,
  statusUuid: string.isRequired,
}

Initialism.defaultProps = {
  statusColor: null,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Initialism)
