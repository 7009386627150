import styled from 'styled-components'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import useAuth from 'hooks/useAuth'

import { Login as _LoginComponent } from 'components/AuthForms'
import AuthPage from 'components/Page/AuthPage'

const FORM_TITLE = 'Sign In'
const FORM_LINKS = [
  {
    path: '/reset_password',
    label: 'Forgot your password?',
  },
  {
    path: '/signup',
    label: 'Need an account?',
  },
]

const LoginComponent = styled(_LoginComponent).attrs({
  title: FORM_TITLE,
  links: FORM_LINKS,
})``

const Login = () => {
  const { user } = useAuth()
  const { replace } = useHistory()

  useEffect(() => {
    if (user) replace('/calls')
  }, [])

  return (
    <AuthPage>
      <LoginComponent />
    </AuthPage>
  )
}

export default Login
