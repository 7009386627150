import styled from 'styled-components'
import { connect } from 'react-redux'
import { func, string } from 'prop-types'

import { XIcon as _XIcon } from 'components/Icons'
import {
  getFullNameFromContactUuid,
  getBussinessNameFromContactUuid,
} from 'selectors/contactsSelector'

const FullName = (styled.span || styled.Text)`
  color: #fff;
`
const XIcon = styled(_XIcon)`
  cursor: pointer;
  font-size: 12px;
  color: #fff;
  margin-left: 4px;
`

const mapStateToProps = (state, ownProps) => {
  const { contactUuid: uuid } = ownProps

  const fullName = getFullNameFromContactUuid(state, { uuid })
  const businessName = getBussinessNameFromContactUuid(state, { uuid })

  return {
    fullName,
    businessName,
  }
}

const mapDispatchToProps = {}

const ContactName = ({
  fullName, businessName, contactUuid, onClearClick, ...props
}) => (
  <div {...props}>
    <FullName>{fullName || businessName}</FullName>
    <XIcon onClick={onClearClick} />
  </div>
)

ContactName.propTypes = {
  contactUuid: string.isRequired,
  fullName: string,
  onClearClick: func.isRequired,
}

ContactName.defaultProps = {
  fullName: '',
}

export default styled(connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactName))`
  border: 1px solid #245da0;
  border-radius: 17px;
  padding: 0 4px;
  background-color: #245da0;
  display: flex;
  align-items: center;
`
