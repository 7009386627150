import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Dropdown as _Dropdown, Option } from 'components/Dropdown'

const PHONE_TYPES = [
  { value: 'Off', label: 'Office' },
  { value: 'Mob', label: 'Mobile' },
  { value: 'Hom', label: 'Home' },
  { value: 'Oth', label: 'Other' },
]

const PhoneTypesDropdown = styled(
  ({ input: { value, onChange }, meta, ...props }) => {
    const _dropdownHolder = useRef(null)

    useEffect(() => {
      if (!value) setTimeout(() => onChange(PHONE_TYPES[0].value), 0)
    }, [])

    const selectedType =
      PHONE_TYPES.find(({ value: _value }) => value === _value) || {}

    return (
      <div {...props}>
        <Dropdown
          ref={_dropdownHolder}
          toggle={selectedType.label || PHONE_TYPES[0].value}
        >
          {PHONE_TYPES.map(phoneType => (
            <Option
              active={phoneType.value === value}
              className="dropdownItem"
              id={phoneType.value}
              key={phoneType.value}
              onSelect={() => {
                onChange(phoneType.value)
                _dropdownHolder.current.closeDropdown()
              }}
            >
              <span>{phoneType.label}</span>
            </Option>
          ))}
        </Dropdown>
      </div>
    )
  }
)`
  position: relative;
  font-size: 14px;
  line-height: 25px;
  padding-bottom: 20px;

  .toggle {
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
  }
`

PhoneTypesDropdown.propTypes = {}

PhoneTypesDropdown.defaultProps = {}

const Dropdown = styled(_Dropdown)`
  width: 125px;
`

export default PhoneTypesDropdown
