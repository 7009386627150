import styled from 'styled-components'
import { object } from 'prop-types'

import { Dropdown, Option } from 'components/Dropdown'
import { SortIcon } from 'components/Icons'

import FilterToggle from './FilterToggle'

const SORT_OPTIONS = {
  newest: 'Newest',
  oldest: 'Oldest',
  contact: 'Contact',
  owner: 'Owner',
  status: 'Status',
}

const SortField = ({
  input: { value: selectedSort, onChange },
  meta,
  ...props
}) => {
  let _dropdownHolder = {}

  return (
    <div {...props}>
      <Dropdown
        right
        ref={_dropdown => {
          _dropdownHolder = _dropdown
        }}
        toggle={
          <FilterToggle title="Sort by">
            <span>
              <SortIcon />
              {SORT_OPTIONS[selectedSort]}
            </span>
          </FilterToggle>
        }
        noCaret
      >
        {Object.keys(SORT_OPTIONS).map((sortValue, index) => (
          <Option
            active={sortValue === selectedSort}
            className="dropdownItem"
            closeDropdown={_dropdownHolder.closeDropdown}
            key={index} // eslint-disable-line react/no-array-index-key
            onSelect={() => {
              onChange(sortValue)
              _dropdownHolder.closeDropdown()
            }}
          >
            {SORT_OPTIONS[sortValue]}
          </Option>
        ))}
      </Dropdown>
    </div>
  )
}

SortField.propTypes = {
  input: object.isRequired,
  meta: object,
}

SortField.defaultProps = {
  meta: null,
}

export default styled(SortField)`
  height: 100%;

  .dropdown {
    width: 100%;
  }
`
