import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { element } from 'prop-types'
import { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router/immutable'
import 'react-dates/initialize'
import {} from 'react-dates/lib/css/_datepicker.css'
import { QueryParamProvider } from 'use-query-params'
import { Route } from 'react-router-dom'
import { Provider as ModalProvider } from 'use-react-modal'

import { actions as authActions } from 'modules/auth'
import { authDataSelector } from 'selectors/authSelector'

import PhoneSlateCookieConsent from 'components/CookieConsent'
import GoogleAnalytics from 'components/GoogleAnalytics'
import MetaTags from 'components/MetaTags'
import Theme from 'components/Theme'
import ToastContainer from 'components/ToastContainer'
import { Provider as Modals } from 'components/Modals'

const GlobalStyle = createGlobalStyle`
  #app-container {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
`

const Root = ({ history, routes }) => {
  const dispatch = useDispatch()
  const loading = useSelector(state => authDataSelector(state).get('loading'))

  const mounted = useRef(false)

  useEffect(() => {
    if (!mounted.current) {
      dispatch(authActions.load())
      mounted.current = true
    }
  }, [dispatch])

  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />

      <MetaTags />

      <PhoneSlateCookieConsent />

      <ModalProvider background="rgba(0, 0, 0, 0.75)">
        <Modals>
          <ConnectedRouter history={history}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <GoogleAnalytics>
                <ToastContainer />
                {!loading ? routes : null}
              </GoogleAnalytics>
            </QueryParamProvider>
          </ConnectedRouter>
        </Modals>
      </ModalProvider>
    </ThemeProvider>
  )
}

Root.propTypes = {
  routes: element.isRequired,
}

export default Root
