import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

const GoogleAnalytics = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  const location = useLocation()

  useEffect(() => {
    ReactGA.initialize('UA-43244011-5')
    setLoaded(true)
  }, [setLoaded])

  useEffect(() => {
    if (loaded) {
      ReactGA.pageview(location.pathname)
    }
  }, [location.pathname, loaded])

  return children
}

export default GoogleAnalytics
