import memoize from 'lru-memoize'
import {
  createValidator,
  email,
  match,
  minLength,
  required,
} from 'utility/validation'

const PersonalInfoFormValidation = createValidator({
  email: [email],
  password: [required],
  new_password: [minLength(8)],
  new_password_confirm: [match('new_password')],
})

export default memoize(10)(PersonalInfoFormValidation)
