import styled from 'styled-components'
import _Page from 'components/Page'
import NotFoundNavBar from 'components/NavBar/NotFoundNavBar'
import CardTitle from 'components/Cards/CardTitle'
import CardMessage from 'components/Cards/CardMessage'

const NotFound = () => (
  <Page navBar={NotFoundNavBar}>
    <CardTitle>Maintenance</CardTitle>
    <CardMessage>
      Sorry, the site is down for scheduled maintenance.
    </CardMessage>
  </Page>
)

const Page = styled(_Page)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export default NotFound
