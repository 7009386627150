import styled from 'styled-components'
import { func, string } from 'prop-types'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'

import { actions as conversationsActions } from 'modules/conversations'
import { getSubjectFromUuid } from 'selectors/conversationsSelector'

const Input = (styled.input || styled.TextInput)`
  border: 1px solid transparent;
  background-color: transparent;
  outline: none!important;
  -webkit-appearance: none;
  display: block;
  width: 100%;
  background-image: none;
  border-radius: 3px;
  padding: 4px 4px 4px 20px;

  &:focus,
  &:hover {
    border-color: #cbcbcb;
    background-color: #fff;
  }
`

const mapStateToProps = (state, { conversationUuid: uuid }) => {
  const subject = getSubjectFromUuid(state, { uuid })

  return {
    subject,
  }
}

const mapDispatchToProps = {
  updateConversation: conversationsActions.updateConversation,
}

class Subject extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: null,
    }
  }

  handleChange = ({ target: { value } }) => {
    this.setState({ value })
  }

  handleBlur = () => {
    const { value } = this.state
    const { conversationUuid, subject, updateConversation } = this.props

    // const clearState = () => {
    //   this.setState({ value: null })
    // }

    if (
      value !== subject
      && value !== null
    ) {
      updateConversation(fromJS({
        uuid: conversationUuid,
        subject: value,
      }))
      // .then(
      //   clearState,
      //   clearState
      // )
    }
  }

  render() {
    const { value: _value } = this.state
    const {
      conversationUuid,
      subject,
      updateConversation,
      ...props
    } = this.props

    const value = (_value === null ? subject : _value) || ''

    return (
      <div {...props}>
        <Input
          type='text'
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
      </div>
    )
  }
}

Subject.propTypes = {
  conversationUuid: string.isRequired,
  subject: string,
  updateConversation: func.isRequired,
}

Subject.defaultProps = {
  subject: '',
}

export default styled(connect(mapStateToProps, mapDispatchToProps)(Subject))`
  position: relative;
  line-height: 1.25;
  color: #9d9d9d;
  font-size: 12px;

  &::before {
    content: 're: ';
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    line-height: 1;
  }
`
