import styled from 'styled-components'

export default (styled.button || styled.Button)`
  background-color: ${props => props.theme.colors.red};
  border: 0;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  height: 74px;
  margin: 0 56px 56px 0;
  width: 74px;
  pointer-events: all;
  &,
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
`
