import _ from 'lodash'
import memoize from 'lru-memoize'
import {
  createValidator, required, email, phone,
} from 'utility/validation'
import { FORM_ERROR } from 'final-form'

/* eslint-disable camelcase */
const oneOfFirstLastBusinessRequired = (value, { first_name, last_name, business_name }) => {
  if (!_.some([first_name, last_name, business_name])) {
    return 'Fill in one of: first name, last name, business name.'
  }

  return null
}
/* eslint-enable camelcase */

const ContactFormValidation = createValidator({
  phone_numbers: {
    phone_type: [required],
    phone_number: [required, phone],
  },
  email_addresses: {
    email: [required, email],
  },
  [FORM_ERROR]: [oneOfFirstLastBusinessRequired],
})

export default memoize(10)(ContactFormValidation)
