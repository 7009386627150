import _ from 'lodash'
import { eventChannel } from 'redux-saga'
import { call, fork, put, take } from 'redux-saga/effects'

import { actions as uiActions } from 'modules/ui'

function initScreenResize() {
  if (!window || !window.addEventListener) return false

  return eventChannel(emitter => {
    function resize() {
      emitter(uiActions.screenResize(window.innerWidth))
    }

    const dispatchResize = _.debounce(resize, 400)
    window.addEventListener('resize', dispatchResize)
    window.addEventListener('orientationchange', dispatchResize)

    return () => {
      window.removeEventListener('resize', dispatchResize)
      window.removeEventListener('orientationchange', dispatchResize)
    }
  })
}

function* watchUi() {
  const channel = yield call(initScreenResize)
  while (true && !!channel) {
    const action = yield take(channel)
    yield put(action)
  }
}

export const uiSaga = [fork(watchUi)]
