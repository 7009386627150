import styled from 'styled-components'
import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import useModal from 'use-react-modal'
import { toast } from 'react-toastify'

import { media } from 'utility/styles'

import { getContactsLock } from 'selectors/contactsSelector'

import ContactForm from 'components/ContactForm/Form'

const ContactModal = styled(
  forwardRef(({ className, ...props }, ref) => {
    const { openModal, isOpen, Modal, closeModal } = useModal()
    const teamContactsLocked = useSelector(getContactsLock)

    const [formProps, setFormProps] = useState({
      postSave: () => {},
      contactUuid: null,
      form: null,
    })

    const summon = (e, _props) => {
      if (teamContactsLocked) {
        toast('Contacts cannot be edited right now. An import is processing.')
        return
      }

      setFormProps(prevState => ({ ...prevState, ..._props }))
      openModal(e)
    }

    useImperativeHandle(ref, () => ({
      summon,
    }))

    if (!isOpen) return null

    return (
      <Modal className={className} {...props}>
        <ContactForm closeModal={closeModal} {...formProps} />
      </Modal>
    )
  })
)`
  width: 350px;

  ${media.tablet`
    width: 100%;
  `}
`

export default ContactModal
