import _ from 'lodash'

const isEmpty = value => value === undefined || value === null || value === ''
const join = rules => (value, data) =>
  rules.map(rule => rule(value, data)).filter(error => !!error)[0]

export function time(value) {
  const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/i
  if (!timeRegex.test(value)) {
    return 'Valid time required'
  }

  return null
}

export function email(value) {
  // Let's not start a debate on email regex! This one is quite standard
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(value)) {
    return 'Invalid email address'
  }

  return null
}

export function required(value) {
  if (isEmpty(value)) {
    return 'Required'
  }

  return null
}

export function minLength(min) {
  return value => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`
    }

    return null
  }
}

export function maxLength(max) {
  return value => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`
    }

    return null
  }
}

export function containsInteger(max) {
  return value => {
    if (!isEmpty(value) && !/\d/.test(value)) {
      return 'Must contain a number'
    }

    return null
  }
}

export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    return 'Must be an integer'
  }

  return null
}

export function oneOf(enumeration) {
  return value => {
    if (!enumeration.indexOf(value)) {
      return `Must be one of: ${enumeration.join(', ')}`
    }

    return null
  }
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return 'Do not match'
      }
    }

    return null
  }
}

export function phone(value) {
  // Let's not start a debate on phone regex! This one is the best I can find, the best way to
  // do it correctly is utilizing a third party verification, but for our use case, it is
  // just overkill
  const pattern = /^(\+{0,1}[0-9]{1,}){0,1}\s{0,}[(]{0,1}[0-9]{1,4}[)]{0,1}[.-\s/0-9]*$/g
  if (!isEmpty(value) && !pattern.test(value)) {
    return 'Invalid phone number'
  }

  return null
}

// field arrays
export function minNumber(min, name) {
  return value => {
    const string = name ? `${min} ${name}` : `${min}`
    if (!value || value.length < min) {
      return `At least ${string} required`
    }

    return null
  }
}

export function createValidator(rules, section, activate) {
  return (data = {}) => {
    data = data.toJS ? data.toJS() : data // eslint-disable-line no-param-reassign
    data = section && data.section ? data[section] : data // eslint-disable-line no-param-reassign
    if (activate && typeof data[activate] !== 'undefined') {
      if (!data[activate]) {
        return {}
      }
    }

    // errors to return
    const errors = {}

    // get error for form field or fieldarray field
    const getError = (fieldRules, dataSegment, fieldName) => {
      const rule = join([].concat(fieldRules))
      const error = rule(_.get(dataSegment, fieldName), dataSegment)
      if (error) {
        return error
      }
      return false
    }

    // Loop through all fields named in validation rules
    Object.keys(rules).forEach(key => {
      if (_.isArray(rules[key])) {
        // If the value for the field key is array of rules, check for field error
        const error = getError(rules[key], data, key)
        if (error) {
          errors[key] = error
        }
      } else if (_.isObject(rules[key])) {
        // If the value for the field key is array of rules, check for field error
        // Empty array for field-level errors, keyed by FieldArray index
        const fieldArrayErrors = []
        // Empty object for any array-level error (needs to be keyed as _error)
        const arrayErrors = {}
        // Loop over the fields for each item in the FieldArray
        Object.keys(rules[key]).forEach(innerKey => {
          if (innerKey === '_array') {
            arrayErrors._error = getError(rules[key][innerKey], data, key)
          } else {
            // Otherwise, loop through each item in the FieldArray and check for field-level errors
            _.forEach(_.get(data, key), (arrayObj, arrayIndex) => {
              const arrayItemError = getError(
                rules[key][innerKey],
                arrayObj,
                innerKey
              )
              if (arrayItemError) {
                if (!fieldArrayErrors[arrayIndex]) {
                  fieldArrayErrors[arrayIndex] = {}
                }
                fieldArrayErrors[arrayIndex][innerKey] = arrayItemError
              }
            })
          }
        })
        // If there's an array-level error, use that; otherwise, use field-level errors
        if (arrayErrors._error) {
          errors[key] = arrayErrors
        } else if (fieldArrayErrors.length) {
          errors[key] = fieldArrayErrors
        }
      }
    })

    return errors
  }
}

// export function createValidator(rules) {
//   return (data = {}) => {
//     const errors = {}
//     Object.keys(rules).forEach((key) => {
//       // concat enables both functions and arrays of functions
//       const rule = join([].concat(rules[key]))
//       const error = rule(data[key], data)
//       if (error) {
//         errors[key] = error
//       }
//     })
//     return errors
//   }
// }
