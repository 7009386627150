import { Switch } from 'react-router-dom'
import Route from 'components/Route'
import NotFound from 'components/NotFound'

import Create from './Create'
import Inactive from './Inactive'

const Organizations = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/create`} component={Create} />
    <Route path={`${match.path}/:org_id/inactive`} component={Inactive} />
    <Route path="*" component={NotFound} status={404} />
  </Switch>
)

export default Organizations
