import styled from 'styled-components'
import AuthCard from 'components/Cards/AuthCard'
import LoginForm from './LoginForm'

const Login = styled(({ className, ...props }) => (
  <AuthCard className={className}>
    <LoginForm {...props} />
  </AuthCard>
))``

export default Login
