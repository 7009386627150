import { schema } from 'normalizr'

const idAttribute = 'uuid'

export const job = new schema.Entity('jobs', undefined, {
  idAttribute,
})

export const emailAddress = new schema.Entity('email_addresses', undefined, {
  idAttribute,
})

export const phoneNumber = new schema.Entity('phone_numbers', undefined, {
  idAttribute,
})

export const contact = new schema.Entity(
  'contacts',
  {
    phone_numbers: [phoneNumber],
    email_addresses: [emailAddress],
  },
  { idAttribute }
)
