import { Switch } from 'react-router-dom'
import Route from 'components/Route'
import Page from 'components/Page'
import NavBar from 'components/NavBar/RegisterNavBar'

import Microsoft from './OAuth/Microsoft'
import Account from './Account'

const AccountPage = () => (
  <Page navBar={NavBar}>
    <Switch>
      <Route
        path="/account/oauth/microsoft"
        component={Microsoft}
        loginRequired
      />

      <Route path="/account" component={Account} loginRequired />
    </Switch>
  </Page>
)

export default AccountPage
