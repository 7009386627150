import React, { useEffect, useState } from 'react'
import useAuth from 'hooks/useAuth'
import { Redirect, Route as RRRoute } from 'react-router-dom'
import { fromJS } from 'immutable'

import useOrganizations from 'hooks/useOrganizations'
import useAuthInvites from 'hooks/useAuthInvites'
import { deleteAuthToken } from 'utility/cookie'

import _Spinner from 'components/Spinner'

const FREEPLAY_MODE = __CONFIG__.freeplayMode

const Route = ({
  activeAccountRequired = false,
  component: Component,
  loginRequired = false,
  organizationRequired = false,
  redirect,
  location,
  ...rest
}) => {
  const { loaded, user } = useAuth()

  const {
    organizationUuids,
    queryOrganizations,
    organizationsLoading,
    currentOrganizationUuid,
    currentOrganizationHasActiveSubscription,
    redirectInactiveOrganization,
  } = useOrganizations()

  const {
    activeOrganizationInvitesReceived,
    queryInvitesReceived,
  } = useAuthInvites()

  const [organizationsLoaded, setOrganizationsLoaded] = useState(false)
  const [invitesLoaded, setInvitesLoaded] = useState(false)

  useEffect(() => {
    if (!user) {
      setOrganizationsLoaded(false)
      setInvitesLoaded(false)
    }
  }, [user])

  useEffect(() => {
    if (user && loaded && organizationRequired && !organizationsLoaded) {
      new Promise((resolve, reject) => {
        queryOrganizations(fromJS({ resolve, reject }))
      })
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          setOrganizationsLoaded(true)
        })

      new Promise((resolve, reject) => {
        queryInvitesReceived(fromJS({ resolve, reject }))
      })
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          setInvitesLoaded(true)
        })
    }
  }, [loaded, organizationRequired, organizationsLoaded])

  useEffect(() => {
    if (
      currentOrganizationHasActiveSubscription === false &&
      activeAccountRequired &&
      !FREEPLAY_MODE
    ) {
      redirectInactiveOrganization()
    }
  }, [
    currentOrganizationHasActiveSubscription,
    currentOrganizationUuid,
    activeAccountRequired,
  ])

  const authenticated = !loginRequired || (user && loginRequired)

  if (loaded && !authenticated) {
    deleteAuthToken()
  }

  return (
    <RRRoute
      {...rest}
      render={_props => {
        if (!loaded) return <Spinner />

        if (!authenticated) {
          return (
            <Redirect
              to={{
                pathname: redirect || '/login',
                state: { from: location },
              }}
            />
          )
        }

        if (
          activeAccountRequired &&
          currentOrganizationHasActiveSubscription === false &&
          FREEPLAY_MODE === false
        ) {
          return <Spinner />
        }

        if (organizationRequired && organizationUuids.isEmpty()) {
          if (!organizationsLoaded || !invitesLoaded || organizationsLoading) {
            return <Spinner />
          }

          if (!activeOrganizationInvitesReceived.isEmpty()) {
            return <Redirect to="/invite/list" />
          }

          return <Redirect to="/organizations/create" />
        }

        return <Component {..._props} />
      }}
    />
  )
}

const Spinner = styled(_Spinner)`
  margin: auto;
`

export default Route
