const ExportHelpContent = [
  {
    name: 'Outlook',
    Content: () => (
      <p>
        {
          'To use your Outlook contacts in PhoneSlate, first export your contacts. In Outlook, click \
          the File tab, then click Options, then click Advanced. Under Export, click Export and \
          follow the instructions. Export your contacts as a comma separated value (CSV) file, and \
          upload that file below. For more information, see Microsoft\'s '
        }
        <a
          href='https://support.office.com/en-us/article/export-contacts-from-outlook-10f09abd-643c-4495-bb80-543714eca73f'
          target='_blank'
          rel='noopener noreferrer'
        >
          detailed walk-through
        </a>
        .
      </p>
    ),
  },

  {
    name: 'Gmail',
    Content: () => (
      <p>
        To use your Gmail contacts in PhoneSlate, first export your contacts. Navigate to
        <a
          href='https://www.google.com/contacts'
          target='_blank'
          rel='noopener noreferrer'
        >
          Google Contacts
        </a>
        {
          ', select all of your contacts, click the "More Actions" icon in the top-left \
          and click Export. \
          Export your contacts as a comma separated value (CSV) file, and \
          upload that file below. For more information, see Google\'s'
        }
        <a
          href='https://support.google.com/contacts/answer/7199294'
          target='_blank'
          rel='noopener noreferrer'
        >
          detailed walk-through
        </a>
        .
      </p>
    ),
  },
]

export default ExportHelpContent
