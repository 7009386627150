import React, { useRef, useState } from 'react'
import { fromJS } from 'immutable'
import { Form as ReactFinalForm, Field } from 'react-final-form'

import { Form, FormRow, CheckboxField, FormError } from 'components/Form'
import DeleteButton from 'components/Buttons/BlankIconButtonDelete'

import useOrganizationMembers from 'hooks/useOrganizationMembers'

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalClose,
  ModalTitle,
  ModalFooter,
} from 'components/Modal'

import Button from 'components/Buttons'
import ButtonRow from 'components/Buttons/ButtonRow'

const EditOrganizationMember = ({
  handleCancel,
  isOpen,
  memberUuid,
  onDeleteClick,
  ...props
}) => {
  const {
    canRemoveOrgAdminPrivilegeByCurrentOrgUuid,
    memberByUuid,
    memberCanLeaveCurrentOrganization,
    updateOrganizationMembership,
  } = useOrganizationMembers(memberUuid)

  const [loading, setLoading] = useState(false)
  const initialValues = useRef(memberByUuid.toJS())

  const onSubmit = async (
    {
      uuid,
      organization,
      is_org_admin,
      // is_active,
    },
    { reset }
  ) => {
    return new Promise((resolve, reject) => {
      setLoading(true)

      updateOrganizationMembership(
        fromJS({
          uuid,
          organization,
          is_org_admin,
          // is_active,
          resolve,
          reject,
        })
      )
    })
      .then(response => {
        initialValues.current = response
        reset(response)
        return undefined
      })
      .catch(errors => {
        return errors
      })
      .finally(() => {
        setLoading(false)
        handleCancel()
      })
  }

  return (
    <Modal isOpen={isOpen} {...props}>
      <ReactFinalForm onSubmit={onSubmit} initialValues={initialValues.current}>
        {({
          error,
          form,
          handleSubmit,
          invalid,
          initialValues: _initialValues,
          pristine,
          submit,
          submitError,
          submitting,
        }) => {
          const isOrgAdminDisabled =
            _initialValues.is_org_admin &&
            !canRemoveOrgAdminPrivilegeByCurrentOrgUuid

          const isRemoveButtonDisabled = !memberCanLeaveCurrentOrganization

          return (
            <Form onSubmit={handleSubmit}>
              <ModalHeader>
                <ModalTitle>Edit Member</ModalTitle>
                <ModalClose onClick={handleCancel} />
              </ModalHeader>

              <ModalBody>
                <FormRow>
                  <Field
                    component={CheckboxField}
                    disabled={isOrgAdminDisabled}
                    id={`${memberUuid}_is_org_admin`}
                    inputName="is_org_admin"
                    labelText="Make this user an Organization Admin"
                    name="is_org_admin"
                  />
                </FormRow>

                {/* <FormRow>
                  <Field
                    name="is_active"
                    inputName="is_active"
                    id={`${memberUuid}_is_active`}
                    component={CheckboxField}
                    labelText="Active"
                  />
                </FormRow> */}

                <FormRow>
                  <DeleteButton
                    disabled={isRemoveButtonDisabled}
                    onClick={onDeleteClick}
                    type="button"
                  >
                    Remove User
                  </DeleteButton>
                </FormRow>
                {isRemoveButtonDisabled && (
                  <ErrorMsg>
                    Users cannot be removed if they are the sole admin on a
                    team or if they are the user who supplied the current payment
                    method.
                  </ErrorMsg>
                )}
              </ModalBody>

              <ModalFooter>
                {!!submitError && <FormError>{submitError}</FormError>}

                {!!error && !pristine && <FormError>{error}</FormError>}

                <ButtonRow>
                  <Button
                    disabled={loading}
                    onClick={handleCancel}
                    secondary
                    type="button"
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={submitting || invalid || pristine}
                    loading={loading}
                    loadingText="Saving..."
                    onClick={submit}
                    type="submit"
                    red
                  >
                    Save
                  </Button>
                </ButtonRow>
              </ModalFooter>
            </Form>
          )
        }}
      </ReactFinalForm>
    </Modal>
  )
}

const ErrorMsg = (styled.div || styled.View)`
  color: ${props => props.theme.colors.warningRed};
`

export default EditOrganizationMember
