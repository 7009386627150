import _ReactModal from 'react-modal'
import cx from 'classnames'
import styled, { css } from 'styled-components'
import { any, bool } from 'prop-types'

import Theme from 'components/Theme'

import {} from './Modal.css'

const Modal = ({ children, className, close, ...props }) => (
  <ReactModal
    portalClassName="modal"
    overlayClassName="modal-overlay"
    className={cx('modal-content', className)}
    bodyOpenClassName="modal-open"
    closeTimeoutMS={200}
    style={{
      content: {},
      overlay: { zIndex: Theme.layers.overlayModal },
    }}
    appElement={document.getElementById('app-container')}
    {...props}
  >
    {children}
  </ReactModal>
)

Modal.propTypes = {
  show: bool,
  children: any,
  className: any,
  close: bool,
  wide: bool,
}

Modal.defaultProps = {
  show: false,
  children: null,
  className: null,
  close: false,
  wide: false,
}

const ReactModal = styled(_ReactModal)`
  ${props =>
    props.wide &&
    css`
      max-width: 592px !important;
    `}
`

export default Modal
