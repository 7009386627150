import styled from 'styled-components'

import { media } from 'utility/styles'

export default (styled.div || styled.View)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 0;
  padding: 15px;
  flex: 0 1;

  ${media.tablet`
    flex: 1;
    padding: 0;
  `}
`
