import styled from 'styled-components'
import { connect } from 'react-redux'
import { object, func } from 'prop-types'
import { list } from 'react-immutable-proptypes'
import {
  getInitialismFromUuid as _getInitialismFromUuid,
  getCurrentActiveTeamStatusUuids,
} from 'selectors/statusesSelector'

import MultiDropdownField from './MultiDropdownField'
import Initialism from '../Conversation/Initialism'

const mapStateToProps = state => {
  const statuses = getCurrentActiveTeamStatusUuids(state)
  const getInitialismFromUuid = uuid => _getInitialismFromUuid(state, { uuid })

  return {
    getInitialismFromUuid,
    statuses,
  }
}

const mapDispatchToProps = {}

const StatusField = ({ getInitialismFromUuid, statuses, meta, ...props }) => (
  <MultiDropdownField
    label="Statuses"
    options={statuses}
    optionText={status => <Initialism statusUuid={status} />}
    toggleText={getInitialismFromUuid}
    {...props}
  />
)

StatusField.propTypes = {
  getInitialismFromUuid: func.isRequired,
  statuses: list.isRequired,
  meta: object.isRequired,
}

StatusField.defaultProps = {}

export default styled(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StatusField)
)``
