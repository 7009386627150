import React, { useState } from 'react'
import styled from 'styled-components'

import { LeaveEntityModal } from 'components/Modal'
import _Button from 'components/Buttons'

const LeaveButton = ({ isOrganization, uuid, ...props }) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <Button
        onClick={() => {
          setShowModal(true)
        }}
        {...props}
      />

      <LeaveEntityModal
        isOpen={showModal}
        handleCancel={() => setShowModal(false)}
        isOrganization={isOrganization}
        uuid={uuid}
      />
    </>
  )
}

const Button = styled(_Button).attrs({
  link: true,
  children: 'Leave',
})`
  padding: 0;
  font-size: 12px;
`

export default LeaveButton
