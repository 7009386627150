import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Form as ReactFinalForm } from 'react-final-form'
import { fromJS } from 'immutable'

import { getEnterpriseServerAddress } from 'utility/cookie'

import { FloatingLabelFinalField } from 'components/FloatingLabelInput'
import { FormRow, Form as _Form, FormError } from 'components/Form'
import useAuth from 'hooks/useAuth'

import validate from './LoginFormValidation'

import Title from '../Title'
import Link from '../Link'
import Button from '../Button'

import SubdomainRow from './SubDomainRow'

const Form = styled(_Form)``

const LoginForm = ({
  error,
  handleSubmit,
  hasValidationErrors,
  links,
  pristine,
  submitting,
  submitError,
  title,
}) => {
  const { loggingIn, loginError } = useAuth()
  const loginErrorText = loginError
    ? loginError.getIn(['data', 'detail']) || loginError.get('message')
    : null

  return (
    <Form onSubmit={handleSubmit}>
      {title && <Title>{title}</Title>}

      <FormRow>
        <FloatingLabelFinalField
          id="login-email"
          name="email"
          type="text"
          placeholder="Email"
          textContentType="emailAddress"
          autoCompleteType="email"
        />
      </FormRow>

      <FormRow>
        <FloatingLabelFinalField
          id="login-password"
          name="password"
          type="password"
          placeholder="Password"
          textContentType="password"
          autoCompleteType="password"
        />
      </FormRow>

      <SubdomainRow />

      {!!loginErrorText && <FormError>{loginErrorText}</FormError>}

      {!!(error || submitError) && !pristine && (
        <FormError>{error || submitError}</FormError>
      )}

      <FormRow half>
        <Button
          primary
          disabled={submitting || hasValidationErrors || pristine || loggingIn}
          loading={submitting || loggingIn}
          loadingText="Signing In..."
          onClick={handleSubmit}
          type="submit"
        >
          Sign In
        </Button>
      </FormRow>

      {links &&
        links.length > 0 &&
        links.map((link, key) => (
          // eslint-disable-next-line react/no-array-index-key
          <Link key={key} to={link.path}>
            {link.label}
          </Link>
        ))}
    </Form>
  )
}

const WrappedLoginForm = props => {
  const { loginFromForm } = useAuth()
  const [isReady, setIsReady] = useState(false)

  const initialValues = useRef({
    email: null,
    password: null,
    enterpriseServerAddress: null,
  })

  useEffect(() => {
    const getEnterpriseServerAddressFromLocalStorage = async () => {
      const enterpriseServerAddress = await getEnterpriseServerAddress()

      if (enterpriseServerAddress) {
        initialValues.current.enterpriseServerAddress = enterpriseServerAddress
      }
      setIsReady(true)
    }

    getEnterpriseServerAddressFromLocalStorage()
  }, [])

  if (!isReady) return null

  return (
    <ReactFinalForm
      initialValues={initialValues.current}
      onSubmit={async values => {
        return new Promise(resolve => {
          loginFromForm(fromJS({ ...values, resolve }))
        })
      }}
      validate={validate}
    >
      {_props => <LoginForm {...props} {..._props} />}
    </ReactFinalForm>
  )
}

export default WrappedLoginForm
