import { schema } from 'normalizr'
import { contact } from './contacts'

const idAttribute = 'uuid'

export const call = new schema.Entity('calls', undefined, { idAttribute })

export const conversation = new schema.Entity(
  'conversations',
  {
    calls: [call],
    contact,
  },
  { idAttribute },
)
