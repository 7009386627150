import React, { useEffect } from 'react'

import useOrganizationInvites from 'hooks/useOrganizationInvites'
import useOrganizationMembers from 'hooks/useOrganizationMembers'
import useOrganizations from 'hooks/useOrganizations'

import Spinner from 'components/Spinner'
import { FormError } from 'components/Form'
import _InviteRow from './InviteRow'
import Form from './Form'

const Invites = () => {
  const {
    organizationUuidsFilteredForSettings,
    queryOrganizationInvitesLoading,
    queryOrganizationInvitesError,
    queryOrganizationInvites,
  } = useOrganizationInvites()
  const { queryOrganizationMembers } = useOrganizationMembers()
  const { currentOrganizationUuid } = useOrganizations()

  useEffect(() => {
    if (currentOrganizationUuid) {
      queryOrganizationInvites()
    }
  }, [currentOrganizationUuid])

  useEffect(() => {
    if (currentOrganizationUuid) {
      queryOrganizationMembers()
    }
  }, [currentOrganizationUuid])

  if (!currentOrganizationUuid) return null

  return (
    <InvitesContainer>
      <Title>Invites</Title>

      <Form organization={currentOrganizationUuid} />

      {queryOrganizationInvitesLoading && <Spinner />}

      {organizationUuidsFilteredForSettings.map(invites => (
        <InviteRow
          key={invites.getIn([0, 'recipient_email'])}
          email={invites.getIn([0, 'recipient_email'])}
          invites={invites}
        />
      ))}

      {queryOrganizationInvitesError && (
        <FormError>
          {queryOrganizationInvitesError.message ||
            (queryOrganizationInvitesError.getIn &&
              queryOrganizationInvitesError.getIn(['data', 'detail'])) ||
            queryOrganizationInvitesError}
        </FormError>
      )}
    </InvitesContainer>
  )
}

const Title = styled.h1`
  text-transform: uppercase;
  font-size: 26px;
`

const InvitesContainer = styled.div`
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 48px;
`

const InviteRow = styled(_InviteRow)`
  border-top: 1px solid #e3e3e3;
  padding: 15px 5px;
`

export default Invites
