import styled from 'styled-components'
import { func, any, string } from 'prop-types'
import cn from 'classnames'
import Caret from 'components/Caret'
import theme from './theme'
import FilterLabel from './FilterLabel'

const FilterToggle = ({ onClick, title, className, children }) => (
  <button
    type="button"
    className={cn('btn', 'btn-link', className)}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
  >
    <ToggleMain>
      <FilterLabel>{title}</FilterLabel>
      {children}
    </ToggleMain>
    <Caret className="caret" />
  </button>
)

FilterToggle.propTypes = {
  onClick: func,
  children: any,
  title: string,
  className: string,
}

FilterToggle.defaultProps = {
  onClick: () => {},
  children: null,
  title: '',
  className: '',
}

const ToggleMain = (styled.div || styled.View)`
  display: flex;
  flex-direction: column;
  i {
    padding-right: 6px;
  }
`

export default styled(FilterToggle)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${theme.filterItemPadding};

  background-color: ${theme.filterBg};
  border: 0;
  color: ${theme.filterButtonColor};
  float: none;
  outline: none;
  text-align: left;

  ${ToggleMain} {
    min-width: 0;
    > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  span.caret {
    color: ${theme.filterCaretColor};
    margin-left: 18px;
  }

  &:hover,
  &:focus {
    background-color: ${theme.filterHoverBg};
    color: ${theme.filterButtonHoverColor};
    text-decoration: none;

    > span {
      color: ${theme.filterLabelHoverColor};
    }
  }

  &:focus {
    &,
    &:active {
      background-color: ${theme.filterHoverBg};
      color: ${theme.filterButtonHoverColor};
      outline: none;
      text-decoration: none;
    }
  }
`
