import styled from 'styled-components'

export default styled.h1`
  color: #1e1e1e;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0.68px;
  line-height: 1.5;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0;
`
