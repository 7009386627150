import styled from 'styled-components'

export default (styled.div || styled.View)`
  bottom: 0;
  position: fixed;
  right: 0;
  z-index: ${props => props.theme.layers.overlay};
  pointer-events: none;
  * {
    pointer-events: initial;
  }
`
