import { useSelector } from 'react-redux'
import { isMobileSelector } from 'selectors/uiSelector'

export default function useUi() {
  const isMobile = useSelector(isMobileSelector)

  return {
    isMobile,
  }
}
