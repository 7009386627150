import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useModal from 'use-react-modal'
import { fromJS } from 'immutable'

import { actions as teamsActions } from 'modules/teams'

import { currentOrganizationIdSelector } from 'selectors/organizationsSelector'
import { getDeleteTeamLoading, getDeleteTeamError } from 'selectors/teamsSelector'

import DeleteIconButton from 'components/Buttons/DeleteIconButton'
import ButtonRow from 'components/Buttons/ButtonRow'
import Button from 'components/Buttons'
import { FormError } from 'components/Form'

const Title = styled.h4`
  text-transform: uppercase;
`

const DeleteTeamButton = ({ teamUuid }) => {
  const dispatch = useDispatch()
  const { replace } = useHistory()
  const currentOrganizationUuid = useSelector(currentOrganizationIdSelector)
  const deleteTeamLoading = useSelector(getDeleteTeamLoading)
  const deleteTeamError = useSelector(getDeleteTeamError)
  const [openModal, closeModal, isOpen, _Modal] = useModal()

  const Modal = styled(_Modal)`
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    padding: 22px;
  `

  const onClick = e => {
    openModal(e)
  }

  const onCancel = () => {
    closeModal()
  }

  const onConfirm = () => {
    return new Promise((resolve, reject) => {
      dispatch(teamsActions.deleteTeam(fromJS({
        uuid: teamUuid,
        resolve,
        reject,
      })))
    }).then(() => {
      closeModal()
      replace(`/settings/${currentOrganizationUuid}/teams`)
    })
  }

  return (
    <>
      <DeleteIconButton onClick={onClick}>
        Delete Team
      </DeleteIconButton>

      {isOpen && (
        <Modal>
          <Title>
            Delete this team?
          </Title>

          <ButtonRow>
            <Button
              secondary
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button
              red
              disabled={deleteTeamLoading}
              onClick={onConfirm}
            >
              {deleteTeamLoading ? 'Deleting' : 'Delete'}
            </Button>
          </ButtonRow>

          {deleteTeamError && (
            <FormError>{deleteTeamError.message || deleteTeamError}</FormError>
          )}
        </Modal>
      )}
    </>
  )
}

export default DeleteTeamButton
