import styled from 'styled-components'
import { object, string, func } from 'prop-types'
import { List } from 'immutable'
import { list } from 'react-immutable-proptypes'

import { Dropdown, Option } from 'components/Dropdown'
import { Icon } from 'components/Icons'

import FilterToggle from './FilterToggle'

const MultiDropdownField = ({
  iconType, label, options, optionText, toggleText,
  input: { value: selectedOptions, onChange }, input,
  ...props
}) => {
  const parse = selectedUuid => {
    if (selectedOptions.includes(selectedUuid)) {
      return selectedOptions.filterNot(uuid => uuid === selectedUuid)
    }

    return selectedOptions.push(selectedUuid)
  }

  const getToggleLabel = () => {
    if (!selectedOptions.size) return 'All'
    const getToggleText = toggleText || optionText
    const toggleLabel = getToggleText(selectedOptions.get(0))
    const suffix = selectedOptions.size > 1
      ? ` +${selectedOptions.size - 1}`
      : ''

    return toggleLabel + suffix
  }

  return (
    <div {...props}>
      <Dropdown
        toggle={(
          <FilterToggle title={label}>
            <span>
              {iconType ? <Icon type={iconType} /> : null}
              {getToggleLabel()}
            </span>
          </FilterToggle>
        )}
        noCaret
      >
        <Option
          active={selectedOptions.size === 0}
          onSelect={() => onChange(List())}
        >
          All
        </Option>

        {options && options.map((option) => (
          <Option
            active={selectedOptions.includes(option)}
            className='dropdownItem'
            key={option}
            onSelect={() => onChange(parse(option))}
          >
            {optionText(option)}
          </Option>
        ))}
      </Dropdown>
    </div>
  )
}

MultiDropdownField.propTypes = {
  iconType: string,
  input: object.isRequired,
  label: string.isRequired,
  options: list.isRequired,
  optionText: func.isRequired,
  toggleText: func,
}

MultiDropdownField.defaultProps = {
  iconType: '',
  toggleText: null,
}

export default styled(MultiDropdownField)`
  height: 100%;
`
