import styled from 'styled-components'
import { Icon } from 'components/Icons'

export default styled(({ handleClick, onClick, className }) => (
  <button className={className} type='button' onClick={handleClick || onClick}>
    <Icon type='x' />
  </button>
))`
  background: none;
  border: 0;
  color: #818181;
  font-size: 21px;
  margin: 16px 17px 0 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  &,
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
`
