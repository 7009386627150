import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import { change as reduxFormChange } from 'redux-form/immutable'

import { getContactIdFilter } from 'selectors/conversationFiltersSelector'

import { actions as contactsActions } from 'modules/contacts'

import _ContactPill from 'components/ContactPill'
import _SearchInput from 'components/SearchInput'
import { DropdownList as _DropdownList } from 'components/Dropdown'
import ContactsSearchList from 'components/ContactsSearchList'

import theme from './theme'

const DropdownList = styled(_DropdownList)`
  padding: 12px;
  width: 650px;
  max-height: 220px;
  overflow: auto;
`

const SearchInput = styled(_SearchInput)`
  width: 100%;
  height: 34px;
`

const ContactPill = styled(_ContactPill)`
  flex: 0 0 auto;
  font-size: ${theme.contactPillFontSizeDefault};
  line-height: ${theme.contactPillHeightDefault};
  margin-left: 8px;
`

const KeywordDropdownList = ({
  onInitSearch = () => {},
  handleSelect,
  handleFreeClick,
  postSave,
  ...props
}) => {
  useEffect(() => {
    onInitSearch()
  }, [])

  return (
    <DropdownList right {...props}>
      <ContactsSearchList
        showDetails
        handleSelect={handleSelect}
        handleFreeClick={handleFreeClick}
        showAddContactButton
        postSave={postSave}
      />
    </DropdownList>
  )
}

const KeywordField = styled(({ className = null, input: { onChange } }) => {
  const dispatch = useDispatch()
  const searchContacts = keyword =>
    dispatch(contactsActions.searchContacts(keyword))
  const searchContactsClear = () =>
    dispatch(contactsActions.searchContactsClear())

  const contactFilterValue = useSelector(getContactIdFilter)

  const change = (formName, fieldName, value) =>
    dispatch(reduxFormChange(formName, fieldName, value))

  const [state, setState] = useState({
    keyword: '',
    open: false,
  })

  const inputRef = useRef(null)
  const blurTimer = useRef(false)

  useEffect(() => {
    return () => {
      clearTimeout(blurTimer.current)
    }
  }, [])

  const typeAheadCallBack = _keyword => {
    searchContacts(_keyword)
  }

  const typeAheadDebounce = useRef(
    _.debounce(_keyword => typeAheadCallBack(_keyword), 500)
  ).current

  const closeDropdown = target => {
    if (target !== inputRef.current) {
      setState(prevState => ({ ...prevState, open: false }))
    }
  }

  const handleContactClearClick = () => {
    change('conversationFilters', 'contact_uuid', null)
  }

  const handleKeywordClearClick = () => {
    setState(prevState => ({ ...prevState, keyword: '' }))
    change('conversationFilters', 'keyword', '')
  }

  const handleKeyUp = event => {
    event.nativeEvent.stopImmediatePropagation()

    if (event.keyCode === 13) {
      // Enter
      setState(prevState => ({ ...prevState, open: false }))
      event.target.blur()
    } else if (event.keyCode === 27) {
      // Esc
      setState(prevState => ({ ...prevState, keyword: '' }))
      change('conversationFilters', 'contact_uuid', null)
      onChange('')
      searchContactsClear()
    }
  }

  const handleSelect = contact => {
    change('conversationFilters', 'contact_uuid', contact)
    change('conversationFilters', 'keyword', '')

    setState(prevState => ({
      ...prevState,
      keyword: '',
      open: false,
    }))
  }

  const handleFreeClick = (shouldFocus = true) => {
    if (shouldFocus) inputRef.current.focus()
    clearTimeout(blurTimer.current)
  }

  const onInputChange = evt => {
    evt.persist()
    setState(prevState => ({ ...prevState, keyword: evt.target.value }))
    typeAheadDebounce(evt.target.value)
  }

  return (
    <div className={className}>
      <SearchInput
        ref={inputRef}
        value={state.keyword}
        onChange={onInputChange}
        onFocus={() => {
          setState(prevState => ({ ...prevState, open: true }))
        }}
        onBlur={evt => {
          evt.persist()
          blurTimer.current = setTimeout(() => onChange(evt.target.value), 100)
        }}
        onKeyUp={handleKeyUp}
        placeholder="Contact or keyword..."
        onClearClick={handleKeywordClearClick}
      >
        {contactFilterValue && (
          <ContactPill
            contactUuid={contactFilterValue}
            onClearClick={handleContactClearClick}
          />
        )}
      </SearchInput>

      {state.open && (
        <KeywordDropdownList
          closeDropdown={closeDropdown}
          handleSelect={handleSelect}
          handleFreeClick={handleFreeClick}
          onInitSearch={() => typeAheadCallBack(state.keyword)}
          postSave={() => searchContacts(state.keyword)}
        />
      )}
    </div>
  )
})`
  display: flex;
  padding: ${theme.filterItemPadding};
`

export default KeywordField
