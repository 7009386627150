import memoize from 'lru-memoize'
import { createValidator, minNumber } from 'utility/validation'

const TeamFormValidation = createValidator({
  members: {
    _array: [minNumber(1, 'member')],
  },
})

export default memoize(10)(TeamFormValidation)
