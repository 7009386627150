import { Switch } from 'react-router-dom'
import Route from 'components/Route'
import Page from 'components/Page'
import NavBar from 'components/NavBar/DefaultNavBar'
import NotFound from 'components/NotFound'

import Confirm from './Confirm'
import List from './List'

const Invite = ({ match }) => (
  <Page navBar={NavBar}>
    <Switch>
      <Route path={`${match.path}/confirm`} component={Confirm} />
      <Route path={`${match.path}/list`} component={List} />
      <Route path="*" component={NotFound} status={404} />
    </Switch>
  </Page>
)

export default Invite
