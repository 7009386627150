import { createSelector } from 'reselect'

export const uiDataSelector = createSelector(
  state => state.get('ui'),
  uiData => uiData
)

export const isMobileSelector = createSelector(
  uiDataSelector,
  uiData => uiData.get('isMobile')
)
