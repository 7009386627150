import memoize from 'lru-memoize'
import {
  createValidator, required, minLength, match,
} from 'utility/validation'

const RegisterFullFormValidation = createValidator({
  password: [required, minLength(8)],
  password_confirm: [match('password')],
})
export default memoize(10)(RegisterFullFormValidation)
