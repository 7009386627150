import _ from 'lodash'
import { FieldArray } from 'react-final-form-arrays'
import { Field } from 'react-final-form'

import { FormRow as _FormRow } from 'components/Form'
import AddButton from 'components/Buttons/BlankIconButtonAdd'
import { FloatingLabelFinalField } from 'components/FloatingLabelInput'

import _PhoneTypeDropdown from './PhoneTypeDropdown'
import TrashIcon from './TrashIcon'

const FormRow = styled(_FormRow)`
  align-items: flex-end;
  > div {
    flex: 1 1 50%;
  }
`

const PhoneTypeDropdown = styled(_PhoneTypeDropdown)`
  margin-right: 15px;
`

const EMPTY_FIELD = { uuid: null, phone_type: null, phone_number: null }

const PhoneNumbersArray = ({ fields: { value }, fields }) => {
  const isEmptyRow = !_.every(value, 'phone_number')

  return (
    <div>
      {fields.map((name, index) => (
        <FormRow key={value[index].uuid || index}>
          <Field name={`${name}.phone_type`} component={PhoneTypeDropdown} />

          <FloatingLabelFinalField
            id={`contact-form-${name}`}
            name={`${name}.phone_number`}
            type="text"
            placeholder="Phone number"
          />

          <TrashIcon
            onClick={() => {
              fields.remove(index)
            }}
          />
        </FormRow>
      ))}

      {!isEmptyRow && (
        <AddButton
          onKeyDown={() => {}}
          onClick={() => fields.push(EMPTY_FIELD)}
        >
          Add Phone
        </AddButton>
      )}
    </div>
  )
}

const PhoneNumbersArrayWrapper = () => {
  return <FieldArray name="phone_numbers" component={PhoneNumbersArray} />
}

export default PhoneNumbersArrayWrapper
