import styled, { css } from 'styled-components'

import { media } from 'utility/styles'

export default styled.nav`
  display: flex;
  height: 52px;
  background-color: #25263d;
  color: #9d9d9d;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 8;
  padding-left: 15px;
  padding-right: 15px;

  > * {
    min-width: 0;
  }

  ${({ marketing }) =>
    marketing &&
    css`
      padding-top: 12px;
    `}

  .caret {
    color: inherit;
  }

  ${media.tablet`
    padding-left: 0;
  `}
`
