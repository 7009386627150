import _ from 'lodash'
import ApiClient from 'components/ApiClient'
import {
  team as teamSchema,
  status as statusSchema,
  member as memberSchema,
  invite as inviteSchema,
} from '../schemas/teams'

const client = new ApiClient()
const API_ENDPOINT = __CONFIG__.apiPath.teams

export const createTeam = data =>
  client.post(API_ENDPOINT.base, { data, schema: teamSchema })

export const deleteTeam = uuid =>
  client.delete(_.replace(API_ENDPOINT.get, ':uuid', uuid), {})

export const getTeam = uuid =>
  client.get(_.replace(API_ENDPOINT.get, ':uuid', uuid), { schema: teamSchema })

export const queryTeams = () =>
  client.get(API_ENDPOINT.base, {
    params: { filters: ['team_member'] },
    schema: [teamSchema],
    isArray: true,
  })

export const queryAdminTeams = (params = {}) =>
  client.get(API_ENDPOINT.base, {
    params: { limit: 100, filters: ['org_admin', 'team_admin'], ...params },
    schema: [teamSchema],
    isArray: true,
  })

export const updateTeam = (uuid, data) =>
  client.patch(_.replace(API_ENDPOINT.get, ':uuid', uuid), {
    data,
    schema: teamSchema,
  })

export const updateStatus = (teamUuid, statusUuid, data) =>
  client.patch(
    _.replace(
      API_ENDPOINT.statuses.get,
      /:teamUuid|:uuid/g,
      match => ({ ':teamUuid': teamUuid, ':uuid': statusUuid }[match])
    ),
    { data, schema: statusSchema }
  )

export const addStatus = (teamUuid, data) =>
  client.post(_.replace(API_ENDPOINT.statuses.base, ':teamUuid', teamUuid), {
    data,
    schema: statusSchema,
  })

export const queryMembers = (teamUuid, keyword) =>
  client.get(_.replace(API_ENDPOINT.members.base, ':teamUuid', teamUuid), {
    schema: [memberSchema],
    params: { search: keyword },
    isArray: true,
  })

export const getMembership = (teamUuid, memberUuid) =>
  client.get(
    _.replace(
      API_ENDPOINT.members.get,
      /:teamUuid|:uuid/g,
      match => ({ ':teamUuid': teamUuid, ':uuid': memberUuid }[match])
    ),
    { schema: memberSchema }
  )

export const addMembership = (teamUuid, data) =>
  client.post(_.replace(API_ENDPOINT.members.base, ':teamUuid', teamUuid), {
    data,
    schema: memberSchema,
  })

export const updateMembership = (teamUuid, memberUuid, data) =>
  client.patch(
    _.replace(
      API_ENDPOINT.members.get,
      /:teamUuid|:uuid/g,
      match => ({ ':teamUuid': teamUuid, ':uuid': memberUuid }[match])
    ),
    { data, schema: memberSchema }
  )

export const deleteMembership = (teamUuid, memberUuid) =>
  client.delete(
    _.replace(
      API_ENDPOINT.members.get,
      /:teamUuid|:uuid/g,
      match => ({ ':teamUuid': teamUuid, ':uuid': memberUuid }[match])
    ),
    {}
  )

export const addMembershipInvite = (teamUuid, userUuid) =>
  client.post(_.replace(API_ENDPOINT.invites.base, ':teamUuid', teamUuid), {
    data: { recipient_user: userUuid },
    schema: memberSchema,
  })

export const queryMembershipInvites = teamUuid =>
  client.get(_.replace(API_ENDPOINT.invites.base, ':teamUuid', teamUuid), {
    schema: [inviteSchema],
  })

export const getMembershipInvite = (teamUuid, inviteUuid) =>
  client.get(
    _.replace(
      API_ENDPOINT.members.get,
      /:teamUuid|:uuid/g,
      match => ({ ':teamUuid': teamUuid, ':uuid': inviteUuid }[match])
    ),
    { schema: inviteSchema }
  )

export const deleteMembershipInvite = (teamUuid, inviteUuid) =>
  client.delete(
    _.replace(
      API_ENDPOINT.members.get,
      /:teamUuid|:uuid/g,
      match => ({ ':teamUuid': teamUuid, ':uuid': inviteUuid }[match])
    ),
    {}
  )

export const updateMembershipInvite = (teamUuid, inviteUuid, data) =>
  client.patch(
    _.replace(
      API_ENDPOINT.members.get,
      /:teamUuid|:uuid/g,
      match => ({ ':teamUuid': teamUuid, ':uuid': inviteUuid }[match])
    ),
    { data, schema: inviteSchema }
  )
