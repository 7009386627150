import { useSelector } from 'react-redux'

import { currentTeamIdSelector } from 'selectors/teamsSelector'
import Link from 'components/Link'

const BackLink = ({ className, children }) => {
  const currentTeamUuid = useSelector(currentTeamIdSelector)

  if (!currentTeamUuid) return null

  return (
    <Link
      className={className}
      to={`/contacts/${currentTeamUuid}`}
    >
      {children}
    </Link>
  )
}

export default BackLink
