import styled from 'styled-components'
import Icon from './Icon'

export default styled(Icon).attrs({
  type: 'plus',
})`
  display: inline-block;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.green};
  width: 21px;
  height: 21px;
  color: white;
  text-align: center;
  font-size: 13px;
  &:before {
    line-height: 21px;
  }
`
