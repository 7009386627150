import React, { useEffect } from 'react'
import { Form as ReactFinalForm } from 'react-final-form'
import { fromJS } from 'immutable'

import useOrganizationInvites from 'hooks/useOrganizationInvites'
import useOrganizationMembers from 'hooks/useOrganizationMembers'

import { FloatingLabelFinalField } from 'components/FloatingLabelInput'
import { Form as _Form, FormError as _FormError } from 'components/Form'
import Button from 'components/Buttons'

import validate from './validate'

const InnerForm = ({
  form,
  handleSubmit,
  invalid,
  pristine,
  submitError,
  submitErrors,
  submitting,
}) => {
  const onSubmit = () => {
    return handleSubmit().then(() => form.reset())
  }

  return (
    <Form onSubmit={onSubmit}>
      <Field
        id="recipient_email"
        name="recipient_email"
        type="email"
        placeholder="Email"
      />

      <Button
        primary
        disabled={
          submitting || invalid || pristine || submitError || submitErrors
        }
        onClick={onSubmit}
        type="submit"
      >
        Invite
      </Button>

      {!!submitError && !pristine && <FormError>{submitError}</FormError>}
    </Form>
  )
}

const FormWrapper = ({ organization, ...props }) => {
  const { createOrganizationInvite } = useOrganizationInvites()
  const {
    queryOrganizationMembers,
    currentOrganizationMembers,
  } = useOrganizationMembers()

  useEffect(() => {
    if (organization) {
      queryOrganizationMembers()
    }
  }, [organization])

  const initialValues = {
    recipient_email: null,
  }

  if (currentOrganizationMembers.isEmpty()) return null

  return (
    <ReactFinalForm
      validate={values => {
        if (
          currentOrganizationMembers
            .map(member => member.get('email'))
            .includes(values.recipient_email)
        ) {
          return {
            recipient_email: 'User already exists.',
          }
        }
        return validate(values)
      }}
      initialValues={initialValues}
      onSubmit={values =>
        new Promise((resolve, reject) => {
          createOrganizationInvite(
            fromJS({
              ...values,
              organization,
              resolve,
              reject,
            })
          )
        })
          .then(() => ({}))
          .catch(({ errors }) => {
            if (!_.isEmpty(errors)) {
              return _.mapValues(errors, error => {
                if (!_.isArray(error)) return error

                return _.first(error)
              })
            }
          })
      }
    >
      {_props => <InnerForm {..._props} {...props} />}
    </ReactFinalForm>
  )
}

const FormError = styled(_FormError)`
  margin-left: 24px;
`

const Form = styled(_Form)`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

const Field = styled(FloatingLabelFinalField)`
  width: 250px;
  margin-right: 24px;
`

export default FormWrapper
