import { useSelector, useDispatch } from 'react-redux'
import { actions as authActions } from 'modules/auth'
import {
  activeOrganizationInvitesReceivedSelector,
  invitesReceivedSelector,
  inviteReceivedByUuidSelector,
} from 'selectors/authSelector'

export default function useAuthInvites({ uuid } = {}) {
  const dispatch = useDispatch()

  const invitesReceived = useSelector(invitesReceivedSelector)
  const inviteReceivedByUuid = useSelector(state =>
    inviteReceivedByUuidSelector(state, uuid)
  )
  const activeOrganizationInvitesReceived = useSelector(
    activeOrganizationInvitesReceivedSelector
  )

  const queryInvitesReceived = values => {
    dispatch(authActions.queryInvitesReceived(values))
  }

  return {
    activeOrganizationInvitesReceived,
    invitesReceived,
    inviteReceivedByUuid,
    queryInvitesReceived,
  }
}
