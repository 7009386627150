import memoize from 'lru-memoize'
import { createValidator, time, required } from 'utility/validation'

const LoginFormValidation = createValidator({
  owner: [required],
  contact: [required],
  'call.status': [required],
  'call.date': [required],
  'call.time': [required, time],
})
export default memoize(10)(LoginFormValidation)
