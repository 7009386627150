import styled from 'styled-components'
import { Form as ReactFinalForm } from 'react-final-form'
import { fromJS } from 'immutable'

import { FloatingLabelFinalField } from 'components/FloatingLabelInput'
import Spinner from 'components/Spinner'
import { FormRow, Form as _Form, FormError } from 'components/Form'
import useAuth from 'hooks/useAuth'

import validate from './RegisterFormValidation'

import Title from '../Title'
import Link from '../Link'
import Button from '../Button'

const Form = styled(_Form)``

const RegisterForm = ({
  error,
  handleSubmit,
  invalid,
  links,
  pristine,
  submitting,
  title,
}) => {
  const { registering, registerError } = useAuth()
  const registerErrorText = registerError
    ? registerError.getIn(['data', 'detail']) ||
      'There was an error registering your account.'
    : null

  return (
    <Form onSubmit={handleSubmit}>
      {title && <Title>{title}</Title>}

      <FormRow>
        <FloatingLabelFinalField
          id="recipient-email"
          name="recipient_email"
          type="text"
          placeholder="Email"
          textContentType="emailAddress"
          autoCompleteType="email"
        />
      </FormRow>

      {!!registerErrorText && <FormError>{registerErrorText}</FormError>}

      {!!error && !pristine && <FormError>{error}</FormError>}

      <FormRow half>
        <Button
          primary
          disabled={submitting || invalid || pristine}
          onClick={handleSubmit}
          loading={submitting || registering}
          loadingText="Signing Up..."
          type="submit"
        >
          Sign up
        </Button>
      </FormRow>

      {links &&
        links.length > 0 &&
        links.map((link, key) => (
          // eslint-disable-next-line react/no-array-index-key
          <Link key={key} to={link.path}>
            {link.label}
          </Link>
        ))}
    </Form>
  )
}

const WrappedRegisterForm = props => {
  const { register } = useAuth()

  const initialValues = {
    recipient_email: null,
  }

  return (
    <ReactFinalForm
      initialValues={initialValues}
      onSubmit={values => {
        register(fromJS(values))
      }}
      validate={validate}
    >
      {_props => <RegisterForm {...props} {..._props} />}
    </ReactFinalForm>
  )
}

export default WrappedRegisterForm
