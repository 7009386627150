import styled from 'styled-components'
import { bool } from 'prop-types'
import { Fragment } from 'react'
import _Button from 'components/Buttons'
import { Icon } from 'components/Icons'

const MoreToggle = ({ expanded, ...props }) => (
  <Button {...props}>
    {expanded
      ? (
        <Fragment>
          Less
          <UpIcon />
        </Fragment>
      )
      : (
        <Fragment>
          More
          <DownIcon />
        </Fragment>
      )
    }
  </Button>
)

MoreToggle.propTypes = {
  expanded: bool,
}

MoreToggle.defaultProps = {
  expanded: false,
}

const Button = styled(_Button).attrs({
  link: true,
})`
  text-transform: none;
  font-size: 12px;
  color: #2a74ca;
  padding: 0;
  border: 0;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const UpIcon = styled(Icon).attrs({
  type: 'chevron-up',
})`
  font-size: 8px;
  margin-left: 5px;
`

const DownIcon = styled(UpIcon).attrs({
  type: 'chevron-down',
})``

export default styled(MoreToggle)``
