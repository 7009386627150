import Invites from './Invites'
import List from './List'

const Users = () => (
  <>
    <MembersContainer>
      <Title>Organization Members</Title>

      <List />
    </MembersContainer>

    <Invites />
  </>
)

const Title = styled.h1`
  text-transform: uppercase;
  font-size: 26px;
`

const MembersContainer = styled.div`
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 48px;
`

export default Users
