import _ from 'lodash'
import React, { useState } from 'react'
import { Form as ReactFinalForm } from 'react-final-form'
import { useDispatch } from 'react-redux'

import { uploadContactsImport } from 'modules/contacts'
import { FormError } from 'components/Form'
import { CheckIcon as _CheckIcon } from 'components/Icons'

import Alert from './Alert'
import Button, { Label } from './Button'
import FileField from './FileField'

const CheckIcon = styled(_CheckIcon)`
  font-size: 10px;
  line-height: inherit;
  color: #fff;
`

const SubHed = styled.h2`
  color: #434A54;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 22px;
`

const HelpText = styled.p`

`

const FormContainer = styled.div`
  margin-bottom: 24px;
`

const validate = values => {
  const errors = {}
  if (!values.file) {
    errors.file = 'CSV or VCF file required'
  }

  return errors
}

const Form = ({
  submitError, handleSubmit, submitting, values, form,
}) => {
  const fileName = _.get(values, 'file.name')
  const filePopulated = !!fileName
  const [submittedFileName, setSubmittedFileName] = useState(null)

  return (
    <form
      onSubmit={event => {
        return handleSubmit(event)
          .then(errors => {
            if (_.isEmpty(errors)) {
              form.reset()
              setSubmittedFileName(fileName)
            }
          })
      }}
    >
      {filePopulated && (
        <Alert>{`${fileName} has been selected.`}</Alert>
      )}

      {!!submittedFileName && (
        <Alert>{`${submittedFileName} has been uploaded. You will be notified when the import is complete.`}</Alert>
      )}

      <SubHed>
        Upload your contacts file
      </SubHed>

      <FormContainer>
        <Label
          htmlFor='importContactField'
          completed={filePopulated}
          circle={filePopulated
            ? <CheckIcon />
            : 1
          }
        >
          Select File
        </Label>

        <FileField
          id='importContactField'
          name='file'
          accept='.csv, .vcf'
        />

        <Button
          circle='2'
          disabled={submitting || !filePopulated}
          type='submit'
        >
          Import File
        </Button>

        {!!submitError && (
          <FormError>{submitError}</FormError>
        )}
      </FormContainer>

      <HelpText>
        Having trouble? Email us at&nbsp;
        <a
          href='mailto:support@phoneslate.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          support@phoneslate.com
        </a>
        .
      </HelpText>
    </form>
  )
}

const WrappedImportForm = () => {
  const dispatch = useDispatch()

  const onSubmit = ({ file }, form, callback) => {
    dispatch(uploadContactsImport({ file, callback }))
  }

  return (
    <ReactFinalForm
      onSubmit={onSubmit}
      validate={validate}
      component={Form}
    />
  )
}

export default WrappedImportForm
