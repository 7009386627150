import _ from 'lodash'
import styled from 'styled-components'
import { useSelector, connect, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { reduxForm, FieldArray, SubmissionError } from 'redux-form/immutable'

import { Form as _Form, FormError } from 'components/Form'
import {
  currentTeamIdSelector,
} from 'selectors/teamsSelector'
import _Button from 'components/Buttons'
import ButtonRow from 'components/Buttons/ButtonRow'
import _MemberFields from 'components/MemberFieldsArray'

import { actions as conversationActions } from 'modules/conversations'

import validate from './validate'

const Form = styled(_Form)``

const mapToForm = {
  form: 'emailConversations',
  validate,
  initialValues: {
    members: [],
  },
}

const mapStateToProps = () => ({})

const Row = styled.div`
  display: flex;
`

const CancelButton = styled(_Button).attrs({
  secondary: true,
})`
  border-radius: 3px;
`

const SaveButton = styled(_Button).attrs({
  red: true,
})`
  border-radius: 3px;
`

const MemberFields = styled(_MemberFields)`
  margin-bottom: 72px;
`

const TeamForm = ({
  pristine, submitting, valid, handleSubmit, reset, error, closeModal = () => {},
}) => {
  const dispatch = useDispatch()
  const teamUuid = useSelector(currentTeamIdSelector)

  const submit = handleSubmit(values => {
    return new Promise((resolve, reject) => {
      dispatch(conversationActions.emailConversations(
        values.set('resolve', resolve).set('reject', reject),
      ))
    })
      .then(({ errors = {} }) => {
        if (_.isEmpty(errors)) {
          toast('Sent')
          reset()
          return
        }

        throw new SubmissionError(errors)
      })
  })

  return (
    <Form onSubmit={submit}>
      <FieldArray
        name='members'
        component={MemberFields}
        props={{
          teamUuid,
          hideTitle: true,
          hideMemberDetails: true,
          isTeam: true,
        }}
      />

      <Row>
        <ButtonRow>
          <CancelButton
            disabled={submitting}
            onClick={closeModal}
          >
            Cancel
          </CancelButton>

          <SaveButton
            type='submit'
            disabled={pristine || submitting || !valid}
            loading={submitting}
            loadingText='Sending...'
          >
            Send
          </SaveButton>
        </ButtonRow>
      </Row>

      {!!error
        && (
          <FormError>
            {error}
          </FormError>
        )
      }
    </Form>
  )
}

export default connect(mapStateToProps)(
  reduxForm(mapToForm)(TeamForm),
)
