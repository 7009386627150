import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, connect, useDispatch } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom'
import { reduxForm, FieldArray } from 'redux-form/immutable'
import { fromJS } from 'immutable'

import {
  getTeamByUuid,
  getTeamWithMembersAndStatusesByUuid,
  getTeamUpdateLoading,
} from 'selectors/teamsSelector'
import { currentOrganizationIdSelector } from 'selectors/organizationsSelector'

import { actions as teamActions } from 'modules/teams'

import { keyIn } from 'utility/immutable'
import _Button from 'components/Buttons'
import ButtonRow from 'components/Buttons/ButtonRow'
import { FloatingLabelField as _FloatingLabelField } from 'components/FloatingLabelInput'
import _MemberFields from 'components/MemberFieldsArray'
import { FormRow as _FormRow, Form as _Form, FormError } from 'components/Form'

import validate from './TeamFormValidation'
import BackLink from './BackLink'
import StatusFields from './StatusFields'
import DeleteTeamButton from './DeleteTeamButton'

const Form = styled(_Form)``

const initializeValuesFromTeam = team => {
  const emptyFormValues = {
    uuid: null,
    name: null,
    members: fromJS([]),
    statuses: fromJS([]),
  }

  return team
    ? team.filter(keyIn(Object.keys(emptyFormValues)))
    : fromJS(emptyFormValues)
}

const mapToForm = {
  form: 'team',
  validate,
}

const mapStateToProps = (state, { teamUuid }) => {
  const team = getTeamWithMembersAndStatusesByUuid(state, { teamUuid })

  const initialValues = initializeValuesFromTeam(team)

  return {
    initialValues,
  }
}

const LeftCol = styled.div`
  flex: 1 1 100%;
`

const RightCol = styled.div`
  flex: 0 0 315px;
  margin-left: 90px;
`

const Row = styled.div`
  display: flex;
`

const CancelButton = styled(_Button).attrs({
  secondary: true,
})`
  border-radius: 3px;
`

const SaveButton = styled(_Button).attrs({
  red: true,
})`
  border-radius: 3px;
`

const Title = styled.h1`
  font-size: 21px;
  text-transform: uppercase;
  margin-bottom: 48px;
`

const MemberFields = styled(_MemberFields)`
  margin-bottom: 72px;
`

const FormRow = styled(_FormRow)`
  margin-bottom: 52px;
`

const FloatingLabelField = styled(_FloatingLabelField)`
  flex: 1;
`

const TeamForm = ({
  teamUuid,
  pristine,
  submitting,
  valid,
  handleSubmit,
  initialValues,
  initialize,
  error,
}) => {
  const loading = useSelector(getTeamUpdateLoading)
  const team = useSelector(state =>
    getTeamWithMembersAndStatusesByUuid(state, { teamUuid })
  )
  const dispatch = useDispatch()

  const submit = handleSubmit(values => {
    return new Promise((resolve, reject) => {
      dispatch(
        teamActions.updateTeam(
          fromJS({
            team: values,
            initial: initialValues,
            resolve,
            reject,
          })
        )
      )
    }).then(newFullTeam => {
      initialize(newFullTeam)
    })
  })

  if (!team) return null

  return (
    <>
      <Form onSubmit={submit}>
        <Row>
          <LeftCol>
            <Title>{`Edit Team: ${team.get('name')}`}</Title>

            <FormRow>
              <FloatingLabelField
                id="name"
                name="name"
                type="text"
                placeholder="Team Name"
              />
            </FormRow>

            <div>
              <FieldArray
                name="members"
                component={MemberFields}
                props={{ teamUuid, initialValues }}
              />
            </div>

            <div>
              <FieldArray
                name="statuses"
                rerenderOnEveryChange
                component={StatusFields}
                props={{ teamUuid }}
              />
            </div>
          </LeftCol>

          <RightCol>
            <ButtonRow>
              <CancelButton as={BackLink} disabled={submitting}>
                Cancel
              </CancelButton>

              <SaveButton
                type="submit"
                disabled={pristine || submitting || !valid}
                loading={loading}
                loadingText="Saving..."
              >
                Save
              </SaveButton>
            </ButtonRow>
            {error ? <FormError>{error}</FormError> : null}
          </RightCol>
        </Row>
      </Form>

      <DeleteTeamButton teamUuid={teamUuid} />
    </>
  )
}

const ConnectedTeamForm = connect(mapStateToProps)(
  reduxForm(mapToForm)(TeamForm)
)

const WrappedTeamForm = () => {
  const { team_id: teamUuid } = useParams()

  const team = useSelector(state => getTeamByUuid(state, { teamUuid }))
  const currentOrganizationUuid = useSelector(currentOrganizationIdSelector)

  const dispatch = useDispatch()

  useEffect(() => {
    // componentDidMount
    if (teamUuid && !team) {
      dispatch(teamActions.getTeam(teamUuid))
    }
  }, [dispatch, teamUuid, team])

  if (!(teamUuid && team)) return null

  if (team.get('organization') !== currentOrganizationUuid) {
    return <Redirect to={`/settings/${currentOrganizationUuid}/teams`} />
  }

  return <ConnectedTeamForm teamUuid={teamUuid} />
}

export default WrappedTeamForm
