import styled from 'styled-components'
import AuthPage from 'components/Page/AuthPage'
import AuthCard from 'components/Cards/AuthCard'
import { Link } from 'components/AuthForms'

const RegisterConfirm = ({
  className,
  registrationToken,
  title,
  staticContext,
  ...props
}) => (
  <AuthPage {...props}>
    <AuthCard>
      <Title>Email Verification Sent</Title>
      <Text>
        Check your email for an email verification link. Be sure to check your
        spam folder.
      </Text>
      <Link to="/login">Sign in</Link>
    </AuthCard>
  </AuthPage>
)

const Title = (styled.h1 || styled.Text)`
  font-size: 36px;
  margin-top: 20px;
  margin-bottom: 10px;
`

const Text = (styled.p || styled.Text)`
  margin-bottom: 20px;
`

export default RegisterConfirm
