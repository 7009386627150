import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'

import ContactName from 'components/ContactName'
import { Dropdown } from 'components/Dropdown'
import Placeholder from 'components/Placeholder'

import ContactDropdown from './ContactDropdown'

export const Toggle = styled(({ placeholder, ...props }) => {
  if (props.contactUuid) {
    return <ContactName {...props} />
  }

  return <Placeholder>{placeholder}</Placeholder>
})`
  position: relative;
  max-width: 100%;
  color: initial;
`

const ContactDropdownHolder = styled(
  ({
    contactUuid,
    handleSelect = () => {},
    placeholder = 'Contact',
    omitBusiness = false,
    ...props
  }) => {
    const dropdownRef = useRef()

    const [state, setState] = useState({ open: false, newContactUuid: null })

    const closeDropdown = () => {
      dropdownRef.current.closeDropdown()
    }

    const handleContactSelect = newContactUuid => {
      setState(prevState => ({ ...prevState, newContactUuid }))

      handleSelect(newContactUuid)

      closeDropdown()
    }

    const uuid = state.newContactUuid || contactUuid

    useEffect(() => {
      setState(prevState => ({ ...prevState, newContactUuid: null }))
    }, [contactUuid])

    return (
      <Dropdown
        ref={dropdownRef}
        toggle={
          <Toggle
            contactUuid={uuid}
            placeholder={placeholder}
            omitBusiness={omitBusiness}
          />
        }
        {...props}
      >
        <ContactDropdown 
          handleContactSelect={handleContactSelect} 
          defaultContactUuid={uuid}
        />
      </Dropdown>
    )
  }
)`
  min-width: 411px;
  padding: 22px 0;
  top: 40px;
`

export default ContactDropdownHolder
