import styled from 'styled-components'
import NavBar from 'components/NavBar/NavBar'
import Collapse from 'components/NavBar/Collapse'
import BrandLink from 'components/NavBar/BrandLink'
import _OrganizationNav from 'components/OrganizationNav/OrganizationNav'
import _ProfileNav from 'components/ProfileNav/ProfileNav'

const OrganizationNav = styled(_OrganizationNav)`
  margin-left: 15px;
  margin-right: auto;
`

const OrgPageNavBar = styled(({ ...props }) => (
  <NavBar {...props}>
    <BrandLink />

    <Collapse>
      <OrganizationNav />
      <ProfileNav />
    </Collapse>
  </NavBar>
))``

const ProfileNav = styled(_ProfileNav)`
  margin-left: auto;
`

export default OrgPageNavBar
