import _ from 'lodash'
import { fromJS } from 'immutable'
import { createSelector } from 'reselect'

import { keyIn } from 'utility/immutable'

import { currentTeamIdSelector } from 'selectors/teamsSelector'

const passProps = (state, props) => props

/**
 * Contacts
 * =============
 */

export const contactsDataSelector = createSelector(
  state => state.get('contacts'),
  contactsData => contactsData
)

export const contactsPageSelector = createSelector(
  contactsDataSelector,
  contactsData =>
    contactsData
      .toJS()
      .teamContacts.map(([key, contact]) => [key, contact.toJS()])
)

export const contactsListSelector = createSelector(
  contactsDataSelector,
  contactsData => contactsData && contactsData.get('contacts')
)

export const phoneNumberListSelector = createSelector(
  contactsDataSelector,
  contactsData => contactsData && contactsData.get('phoneNumbers')
)

export const emailAddressListSelector = createSelector(
  contactsDataSelector,
  contactsData => contactsData && contactsData.get('emailAddresses')
)

export const getContactByUuid = createSelector(
  contactsListSelector,
  passProps,
  (contacts, { uuid }) => contacts && contacts.get(uuid)
)

export const getContactWithEmailAddressesAndPhoneNumbersByUuid = createSelector(
  getContactByUuid,
  emailAddressListSelector,
  phoneNumberListSelector,
  (contact, emailAddresses, phoneNumbers) => {
    if (!(contact && emailAddresses && phoneNumbers)) return null

    const contactEmailAddresses = emailAddresses
      .filter(keyIn(contact.get('email_addresses')))
      .toList()
    const contactPhoneNumbers = phoneNumbers
      .filter(keyIn(contact.get('phone_numbers')))
      .toList()

    return contact
      .set('email_addresses', contactEmailAddresses)
      .set('phone_numbers', contactPhoneNumbers)
  }
)

export const getUpdateContactId = createSelector(
  contactsDataSelector,
  contactState => contactState.get('updateContactId')
)

export const getContactsLock = createSelector(
  contactsDataSelector,
  currentTeamIdSelector,
  (contactState, teamUuid) =>
    contactState.get('contactsLockedTeamUuids').includes(teamUuid)
)

export const contactFormSelector = createSelector(
  contactsDataSelector,
  contactsState => {
    const updateContactId = contactsState.get('updateContactId')
    const updateFormContact =
      contactsState.getIn(['contacts', updateContactId]) || null

    return updateFormContact
  }
)

export const updateFormInitialValuesSelector = createSelector(
  contactFormSelector,
  updateFormContact => {
    const emptyFormValues = {
      uuid: null,
      first_name: null,
      last_name: null,
      business_name: null,
      phone_numbers: [],
      email_addresses: [],
    }

    return updateFormContact && updateFormContact.get('uuid')
      ? updateFormContact.filter(keyIn(Object.keys(emptyFormValues)))
      : fromJS(emptyFormValues)
  }
)

export const getTeamContactUuids = createSelector(
  contactsDataSelector,
  contactsData => contactsData && contactsData.get('teamContacts')
)

export const getTeamContactsCount = createSelector(
  contactsDataSelector,
  contactsData => contactsData.get('teamContactsCount')
)

export const getTeamContactsLength = createSelector(
  getTeamContactUuids,
  uuids => uuids.count()
)

export const getTeamContactsHasMore = createSelector(
  getTeamContactsCount,
  getTeamContactsLength,
  (count, length) => count > length
)

export const getTeamContactsLoading = createSelector(
  contactsDataSelector,
  contactsData => contactsData && contactsData.get('teamContactsLoading')
)

export const getSearchContactsUuids = createSelector(
  contactsDataSelector,
  contactsData => contactsData && contactsData.get('searchContactsUuids')
)

export const getSearchContactsLoading = createSelector(
  contactsDataSelector,
  contactsData => contactsData && contactsData.get('searchContactsLoading')
)

export const getSearchContactsError = createSelector(
  contactsDataSelector,
  contactsData => contactsData && contactsData.get('searchContactsError')
)

export const getNewContactFormPostSaveTarget = createSelector(
  contactsDataSelector,
  contactsData =>
    contactsData && contactsData.get('newContactFormPostSaveTarget')
)

/**
 * Name
 * =============
 */
export const getFullNameFromContactUuid = createSelector(
  getContactByUuid,
  contact => {
    if (!contact) return ''
    const firstName = contact.get('first_name')
    const lastName = contact.get('last_name')
    return [firstName, lastName].join(' ').trim()
  }
)

export const getFullNameBackwardsFromContactUuid = createSelector(
  getContactByUuid,
  contact => {
    if (!contact) return ''
    const firstName = contact.get('first_name')
    const lastName = contact.get('last_name')
    return [lastName, firstName].join(' ').trim()
  }
)

export const getBussinessNameFromContactUuid = createSelector(
  getContactByUuid,
  contact => contact && contact.get('business_name')
)

/**
 * Phone Numbers
 * =============
 */

export const getPhoneNumberByUuid = createSelector(
  phoneNumberListSelector,
  passProps,
  (phoneNumbers, { uuid }) => phoneNumbers && phoneNumbers.get(uuid)
)

export const getPhoneNumberUuidsFromContactUuid = createSelector(
  getContactByUuid,
  contact => (contact && contact.get('phone_numbers')) || fromJS([])
)

export const getPhoneNumberFromPhoneNumberUuid = createSelector(
  getPhoneNumberByUuid,
  phoneNumber => phoneNumber && phoneNumber.get('phone_number')
)

export const getPhoneTypeFromPhoneNumberUuid = createSelector(
  getPhoneNumberByUuid,
  phoneNumber => phoneNumber && phoneNumber.get('phone_type')
)

export const getPhoneNumbersFromContactUuid = createSelector(
  state => state,
  passProps,
  (state, { uuid }) => {
    const contacts = state.get('contacts').toJS()
    const fullNumbers = contacts.phoneNumbers
    const contactPhoneIDs = _.get(
      contacts,
      `contacts[${uuid}].phone_numbers`,
      []
    )
    return fromJS(
      contactPhoneIDs.reduce(
        (acc, id) => ({ ...acc, [id]: fullNumbers[id] }),
        {}
      )
    )
  }
)

export const getFirstPhoneNumbersUuidByContactUuid = createSelector(
  getPhoneNumberUuidsFromContactUuid,
  phoneUuids => phoneUuids.first()
)

/**
 * Email Addresses
 * =============
 */

export const getEmailAddressByUuid = createSelector(
  emailAddressListSelector,
  passProps,
  (emailAddresses, { uuid }) => emailAddresses && emailAddresses.get(uuid)
)

export const getEmailAddressUuidsFromContactUuid = createSelector(
  getContactByUuid,
  contact => (contact && contact.get('email_addresses')) || fromJS([])
)

export const getEmailAddressFromEmailAddressUuid = createSelector(
  getEmailAddressByUuid,
  emailAddress => emailAddress && emailAddress.get('email')
)

export const getFirstEmailAddressUuidByContactUuid = createSelector(
  getEmailAddressUuidsFromContactUuid,
  emailUuids => emailUuids.first()
)

export const getFirstEmailAddressByContactUuid = createSelector(
  getFirstEmailAddressUuidByContactUuid,
  emailAddressListSelector,
  (firstEmailUuid, emails) => {
    if (!firstEmailUuid) return null

    return emails.getIn([firstEmailUuid, 'email'])
  }
)

export const getEmailAddressesFromContactUuid = createSelector(
  state => state,
  passProps,
  (state, { uuid }) => {
    const contacts = state.get('contacts').toJS()
    const fullEmails = contacts.emailAddresses
    const contactEmailIDs = _.get(
      contacts,
      `contacts[${uuid}].email_addresses`,
      []
    )
    return fromJS(
      contactEmailIDs.reduce(
        (acc, id) => ({ ...acc, [id]: fullEmails[id] }),
        {}
      )
    )
  }
)

/**
 * Modals
 * =============
 */
export const getNewContactFormOpen = createSelector(
  contactsDataSelector,
  contactsData => contactsData && contactsData.get('newContactFormOpen')
)

/**
 * Export Jobs
 * =============
 */
export const getExportJobs = createSelector(
  contactsDataSelector,
  contactsData => contactsData && contactsData.get('exportJobs')
)

export const getExportJobByUuid = createSelector(
  getExportJobs,
  passProps,
  (jobs, uuid) => uuid && jobs.get(uuid)
)
