import useAuth from 'hooks/useAuth'

import _Button from 'components/Buttons'

const FoldersList = () => {
  const { microsoftFolders } = useAuth()

  return (
    <>
      <h2>Contacts Folders:</h2>

      {microsoftFolders.map(folder => (
        <Button
          key={folder.get('uuid')}
          onClick={() => console.log(folder.get('uuid'))}
        >
          {folder.get('folder_name')}
        </Button>
      ))}
    </>
  )
}

const Button = styled(_Button).attrs({
  red: true,
  primary: true,
})``

export default FoldersList
