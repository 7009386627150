import styled from 'styled-components'
import NavBar from 'components/NavBar'
import BrandLink from 'components/NavBar/BrandLink'

const AuthNavBar = styled(({ ...props }) => (
  <NavBar {...props}>
    <BrandLink />
  </NavBar>
))``

export default AuthNavBar
