import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { SearchIcon as _SearchIcon, XIcon as _XIcon } from 'components/Icons'

const Input = styled.input`
  outline: none;
  flex: 1;
  border: 0;
  appearance: none;
  padding: 0 10px;
  align-self: stretch;
  background: none;
`

export default styled(
  forwardRef(({ className, children, onClearClick, value, ...props }, ref) => (
    <SearchHolder className={className}>
      <SearchIcon />
      {children}
      <Input ref={ref} type="text" value={value} {...props} />
      {value && <XIcon onClick={onClearClick} />}
    </SearchHolder>
  ))
)``

const SearchHolder = (styled.div || styled.View)`
  border-radius: 17px;
  border: 1px solid #cccccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 10px;
`

const SearchIcon = styled(_SearchIcon)`
  color: #ccd1d9;
  pointer-events: none;
`

const XIcon = styled(_XIcon)`
  color: #ccd1d9;
  cursor: pointer;
`
