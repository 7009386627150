import styled from 'styled-components'
import Nav from 'components/Nav'
import NavBar from 'components/NavBar'
import BrandLink from 'components/NavBar/BrandLink'
import _Collapse from 'components/NavBar/Collapse'
import NavLink from 'components/NavLink'
import { ProfileNav as _ProfileNav } from 'components/ProfileNav'

const HomeNavBarContainer = styled(NavBar)`
  padding: 30px;
  align-items: center;
`

const HomeNavBar = styled(({ ...props }) => (
  <HomeNavBarContainer {...props}>
    <BrandLink marketing />

    <Collapse>
      <MarketingNav />
      <ProfileNav />
    </Collapse>
  </HomeNavBarContainer>
))``

const Collapse = styled(_Collapse)`
  justify-content: flex-end;
`

const MarketingNav = styled(() => (
  <Nav>
    <li>
      <NavLink as="a" href="#pricing">
        Pricing
      </NavLink>
    </li>
    <li>
      <NavLink as="a" href="#features">
        Features
      </NavLink>
    </li>
  </Nav>
))`
  margin-right: 15px;
  margin-left: auto;
`

const ProfileNav = styled(_ProfileNav)`
  margin-left: ${props => props.theme.nav.marginHorizontal};
`

export default HomeNavBar
