import styled from 'styled-components'
import EditContactButton from 'components/EditContactButton'

export default styled(EditContactButton).attrs({
  link: true,
  children: 'Details',
})`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 12px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`
