import styled from 'styled-components'
import _DatePicker from 'components/DatePicker'
import moment from 'moment'

const DatePicker = styled(_DatePicker)`
  input {
    padding: 0;
  }
`

const AddConversationDatePicker = ({
  input: { value, onChange },
  className,
}) => {
  const handleChange = date => {
    const newDatePicker = date
      .clone()
      .utc()
      .format()
    onChange(newDatePicker)
  }

  const date = value ? moment(value) : moment()

  return (
    <div className={className}>
      <DatePicker handleChange={handleChange} openDirection="up" value={date} />
    </div>
  )
}

export default styled(AddConversationDatePicker)`
  margin-right: 15px;
`
