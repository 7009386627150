import styled from 'styled-components'

const icons = {
  'add-user': '\f100',
  calendar: '\f101',
  check: '\f102',
  'chevron-down': '\f103',
  'chevron-up': '\f104',
  'circle-exclam': '\f11a',
  'circle-minus': '\f118',
  'circle-plus': '\f105',
  'circle-x': '\f116',
  contacts: '\f106',
  filter: '\f11c',
  gear: '\f107',
  gift: '\f11e',
  globe: '\f11d',
  'grip-lines': '\f11b',
  list: '\f11f',
  lock: '\f108',
  mobile: '\f120',
  money: '\f109',
  pencil: '\f10a',
  phone: '\f10b',
  'phone-home': '\f10c',
  'phone-mobile': '\f10d',
  'phone-office': '\f10e',
  plus: '\f10f',
  print: '\f121',
  search: '\f110',
  share: '\f111',
  sort: '\f112',
  trash: '\f113',
  tribar: '\f119',
  user: '\f114',
  users: '\f122',
  x: '\f115',
}

const Icon = styled(({ type, ...props }) => <i {...props} />)`
  height: fit-content;
  &::before {
    content: '${props => icons[props.type]}';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-family: 'icons';
    font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
  }
`

export default Icon
