import _ from 'lodash'
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { fromJS } from 'immutable'
import { FORM_ERROR } from 'final-form'
import { toast } from 'react-toastify'
import ContactsExportToast from 'components/ContactsExportToast'
import {
  createContact as createContactApi,
  deleteContact as deleteContactApi,
  deleteAllImportedContacts as deleteAllImportedContactApi,
  createEmailAddress as createEmailAddressApi,
  createPhoneNumber as createPhoneNumberApi,
  deletePhoneNumber as deletePhoneApi,
  deleteEmailAddress as deleteEmailApi,
  exportContacts as exportContactsApi,
  getContact as getContactApi,
  getExportContacts as getExportContactsApi,
  queryContacts as queryContactsApi,
  queryContactsImports as queryContactsImportsApi,
  searchContacts as searchContactsApi,
  updateContact as updateContactApi,
  updateEmailAddress as updateEmailAddressApi,
  updatePhoneNumber as updatePhoneNumberApi,
  uploadContactsImport as uploadContactsImportApi,
} from 'api/contacts'

import { constants as teamsContants } from 'modules/teams'
import {
  constants as contactsConstants,
  actions as contactsActions,
} from 'modules/contacts'

import {
  getTeamContactsLength,
  getContactWithEmailAddressesAndPhoneNumbersByUuid,
} from 'selectors/contactsSelector'
import { currentTeamIdSelector } from 'selectors/teamsSelector'

import { pickAlteredKeys } from 'utility/helpers'

function segmentChildProperties(values, initialValues) {
  const [rest, addedValues] = _.partition(values, 'uuid')
  const deletedValues = _.filter(
    initialValues,
    ({ uuid }) => !_.some(rest, { uuid })
  )

  const updatedValues = _.map(
    _.differenceWith(rest, initialValues, _.isEqual),
    obj => {
      const initialObj = _.find(initialValues, { uuid: obj.uuid })
      return pickAlteredKeys(obj, initialObj, ['uuid', 'team'])
    }
  )

  return {
    add: addedValues,
    update: updatedValues,
    delete: deletedValues,
  }
}

function* getContact({ payload: uuid }) {
  let response

  try {
    const {
      entities: {
        contacts = {},
        email_addresses: emailAddresses = {},
        phone_numbers: phoneNumbers = {},
      },
      result: contactUuid,
    } = yield call(getContactApi, uuid)

    response = {
      contacts,
      phoneNumbers,
      emailAddresses,
      contactUuid,
    }

    yield put({
      type: contactsConstants.GET_CONTACT_SUCCESS,
      payload: fromJS(response),
    })
  } catch (error) {
    yield put({
      type: contactsConstants.GET_CONTACT_FAIL,
      payload: fromJS({ error }),
    })

    throw error
  }

  return fromJS(response)
}

function* createEmailAddress({ payload }) {
  const { contact, ...emailAddress } = payload.toJS()

  try {
    const {
      entities: { email_addresses: emailAddresses },
      result: emailAddressUuid,
    } = yield call(createEmailAddressApi, contact, emailAddress)

    const response = fromJS({ emailAddresses, emailAddressUuid })

    yield put({
      type: contactsConstants.CREATE_EMAIL_SUCCESS,
      payload: response,
    })

    return response
  } catch (createEmailAddressError) {
    yield put({
      type: contactsConstants.CREATE_EMAIL_FAIL,
      payload: fromJS({ createEmailAddressError }),
    })

    return fromJS({})
  }
}

function* createPhoneNumber({ payload }) {
  const { contact, ...phoneNumber } = payload.toJS()

  try {
    const {
      entities: { phone_numbers: phoneNumbers },
      result: phoneNumberUuid,
    } = yield call(createPhoneNumberApi, contact, phoneNumber)

    const response = fromJS({ phoneNumbers, phoneNumberUuid })

    yield put({
      type: contactsConstants.CREATE_PHONE_SUCCESS,
      payload: response,
    })

    return response
  } catch (createPhoneError) {
    yield put({
      type: contactsConstants.CREATE_PHONE_FAIL,
      payload: fromJS({ createPhoneError }),
    })

    return fromJS({})
  }
}

function* updateEmailAddress({ payload }) {
  const { contact: contactUuid, ...emailAddress } = payload.toJS()

  try {
    const {
      entities: { email_addresses: emailAddresses },
      result: emailAddressUuid,
    } = yield call(updateEmailAddressApi, contactUuid, emailAddress)

    const response = fromJS({ emailAddresses, emailAddressUuid })

    yield put({
      type: contactsConstants.UPDATE_EMAIL_SUCCESS,
      payload: response,
    })

    return response
  } catch (updateEmailAddressError) {
    yield put({
      type: contactsConstants.UPDATE_EMAIL_FAIL,
      payload: fromJS({ updateEmailAddressError }),
    })

    return fromJS({})
  }
}

function* updatePhoneNumber({ payload }) {
  const { contact: contactUuid, ...phoneNumber } = payload.toJS()

  try {
    const {
      entities: { phone_numbers: phoneNumbers },
      result: phoneNumberUuid,
    } = yield call(updatePhoneNumberApi, contactUuid, phoneNumber)

    const response = fromJS({ phoneNumbers, phoneNumberUuid })

    yield put({
      type: contactsConstants.UPDATE_PHONE_SUCCESS,
      payload: response,
    })

    return response
  } catch (updatePhoneNumberError) {
    yield put({
      type: contactsConstants.UPDATE_PHONE_FAIL,
      payload: fromJS({ updatePhoneNumberError }),
    })

    return fromJS({})
  }
}

function* deleteEmailAddress(action) {
  const { uuid: emailAddressUuid, contact: contactUuid } = action.payload.toJS()

  try {
    const {
      entities: { email_addresses: emailAddresses },
    } = yield call(deleteEmailApi, contactUuid, emailAddressUuid)

    const response = fromJS({ emailAddresses, emailAddressUuid })

    yield put({
      type: contactsConstants.DELETE_EMAIL_SUCCESS,
      payload: response,
    })

    return response
  } catch (deleteEmailAddressError) {
    yield put({
      type: contactsConstants.DELETE_EMAIL_FAIL,
      payload: fromJS({ deleteEmailAddressError }),
    })

    return fromJS({})
  }
}

function* deletePhoneNumber(action) {
  const { uuid: phoneNumberUuid, contact: contactUuid } = action.payload.toJS()

  try {
    const {
      entities: { phone_numbers: phoneNumbers },
    } = yield call(deletePhoneApi, contactUuid, phoneNumberUuid)

    const response = fromJS({ phoneNumbers, phoneNumberUuid })

    yield put({
      type: contactsConstants.DELETE_PHONE_SUCCESS,
      payload: response,
    })

    return response
  } catch (deletePhoneNumberError) {
    yield put({
      type: contactsConstants.DELETE_PHONE_FAIL,
      payload: fromJS({ deletePhoneNumberError }),
    })

    return fromJS({})
  }
}

function* deleteContact({ payload: contactUuid }) {
  try {
    yield call(deleteContactApi, contactUuid)
    yield put({
      type: contactsConstants.DELETE_CONTACT_SUCCESS,
      payload: contactUuid,
    })
  } catch (error) {
    yield put({
      type: contactsConstants.DELETE_CONTACT_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* deleteAllImportedContacts({ payload: contactUuid }) {
  try {
    yield call(deleteAllImportedContactApi, contactUuid)
    yield put({
      type: contactsConstants.DELETE_ALL_IMPORTED_CONTACTS_SUCCESS,
      payload: contactUuid,
    })
  } catch (error) {
    yield put({
      type: contactsConstants.DELETE_ALL_IMPORTED_CONTACTS_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* queryContacts() {
  try {
    const teamUuid = yield select(currentTeamIdSelector)
    const offset = yield select(getTeamContactsLength)
    const {
      entities,
      result: teamContacts,
      count: teamContactsCount,
    } = yield call(queryContactsApi, { team_uuid: teamUuid, offset })
    yield put({
      type: contactsConstants.QUERY_CONTACTS_SUCCESS,
      payload: fromJS({ ...entities, teamContacts, teamContactsCount }),
    })
  } catch (error) {
    yield put({
      type: contactsConstants.QUERY_CONTACTS_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* searchContacts({ payload: { keyword, limit = 4 } }) {
  try {
    const teamUuid = yield select(currentTeamIdSelector)
    const {
      entities: {
        contacts,
        phone_numbers: phoneNumbers,
        email_addresses: emailAddresses,
      },
      result: searchContactsUuids,
    } = yield call(searchContactsApi, { team_uuid: teamUuid, keyword, limit })

    yield put({
      type: contactsConstants.SEARCH_CONTACTS_SUCCESS,
      payload: fromJS({
        contacts,
        phoneNumbers,
        emailAddresses,
        searchContactsUuids,
      }),
    })
  } catch (error) {
    yield put({
      type: contactsConstants.SEARCH_CONTACTS_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* saveContact(action) {
  const { phoneNumbers, emailAddresses, contact } = action.payload.toJS()

  const isUpdate = action.type === contactsConstants.UPDATE_CONTACT

  try {
    const { result: contactUuid } = yield call(
      isUpdate ? updateContactApi : createContactApi,
      contact
    )

    // for creation, add uuid to the contact
    contact.uuid = contactUuid

    const constantMap = {
      phoneNumbers: {
        add: [createPhoneNumber, contactsConstants.CREATE_PHONE],
        update: [updatePhoneNumber, contactsConstants.UPDATE_PHONE],
        delete: [deletePhoneNumber, contactsConstants.DELETE_PHONE],
      },
      emailAddresses: {
        add: [createEmailAddress, contactsConstants.CREATE_EMAIL],
        update: [updateEmailAddress, contactsConstants.UPDATE_EMAIL],
        delete: [deleteEmailAddress, contactsConstants.DELETE_EMAIL],
      },
    }

    yield all(
      _.flattenDeep([
        _.map(phoneNumbers, (phoneArray, actionKey) => {
          return _.map(phoneArray, phone => {
            const [saga, type] = _.get(constantMap, ['phoneNumbers', actionKey])
            const payload = fromJS({ contact: contactUuid, ...phone })
            return call(saga, { type, payload })
          })
        }),
        _.map(emailAddresses, (emailArray, actionKey) => {
          return _.map(emailArray, email => {
            const [saga, type] = _.get(constantMap, [
              'emailAddresses',
              actionKey,
            ])
            const payload = fromJS({ contact: contactUuid, ...email })
            return call(saga, { type, payload })
          })
        }),
      ])
    )

    const newContact = yield call(
      getContact,
      contactsActions.getContact(contactUuid)
    )

    const type = isUpdate
      ? contactsConstants.UPDATE_CONTACT_SUCCESS
      : contactsConstants.CREATE_CONTACT_SUCCESS

    yield put({
      type,
      payload: fromJS(newContact),
    })

    const finalPayload = yield select(state =>
      getContactWithEmailAddressesAndPhoneNumbersByUuid(state, {
        uuid: contactUuid,
      })
    )

    return finalPayload
  } catch (error) {
    const type = isUpdate
      ? contactsConstants.UPDATE_CONTACT_FAIL
      : contactsConstants.CREATE_CONTACT_FAIL

    yield put({
      type,
      payload: fromJS({ error }),
    })

    throw error
  }
}

function* createOrUpdateContactFromForm({ payload }) {
  const {
    contact: {
      phone_numbers: phoneNumbers,
      email_addresses: emailAddresses,
      ...contact
    },
    initial: {
      phone_numbers: phoneNumbersInitial,
      email_addresses: emailAddressesInitial,
    },
    resolve,
    // reject,
  } = payload.toJS()

  try {
    const childCalls = [
      segmentChildProperties(phoneNumbers, phoneNumbersInitial),
      segmentChildProperties(emailAddresses, emailAddressesInitial),
    ]

    const [segmentedPhoneNumbers, segmentedEmailAddresses] = childCalls

    const type = contact.uuid
      ? contactsConstants.UPDATE_CONTACT
      : contactsConstants.CREATE_CONTACT

    const response = yield call(saveContact, {
      type,
      payload: fromJS({
        contact,
        phoneNumbers: segmentedPhoneNumbers,
        emailAddresses: segmentedEmailAddresses,
      }),
    })

    resolve({ contact: response.toJS(), errors: {} })
  } catch (error) {
    resolve({
      errors: {
        ..._.get(error, 'response.data', {}),
        [FORM_ERROR]: 'There was an error submitting this contact.',
      },
    })
  }
}

function* uploadContactsImport({ payload }) {
  const { file, callback } = payload

  try {
    const teamUuid = yield select(currentTeamIdSelector)
    const response = yield call(uploadContactsImportApi, file, teamUuid)

    yield put({
      type: contactsConstants.UPLOAD_CONTACTS_IMPORT_SUCCESS,
      payload: fromJS({ ...response }),
    })
    callback()
  } catch (error) {
    yield put({
      type: contactsConstants.UPLOAD_CONTACTS_IMPORT_FAIL,
      payload: fromJS({ error }),
    })

    callback({
      [FORM_ERROR]: error.message || _.get(error, 'data.detail'),
    })
  }
}

function* queryContactsImports() {
  try {
    const teamUuid = yield select(currentTeamIdSelector)
    if (teamUuid) {
      const response = yield call(queryContactsImportsApi, teamUuid)

      const completedStatuses = ['Complete', 'Error']
      const mostRecentImportStatus = _.get(
        response,
        '0.status',
        completedStatuses[0]
      )
      const isProcessingImport = !_.includes(
        completedStatuses,
        mostRecentImportStatus
      )

      if (isProcessingImport) {
        yield put(contactsActions.contactsLock(teamUuid))
      }
    }
  } catch (error) {
    console.log(error)
  }
}

function* exportContacts() {
  try {
    const teamUuid = yield select(currentTeamIdSelector)
    const { result: uuid } = yield call(exportContactsApi, teamUuid)
    toast(<ContactsExportToast jobUuid={uuid} />, { autoClose: false })
    yield take(
      ({ type, payload }) =>
        type === contactsConstants.EXPORT_JOB_COMPLETED && payload === uuid
    )

    const {
      entities: { jobs },
    } = yield call(getExportContactsApi, teamUuid, uuid)
    yield put(contactsActions.getExportJob(jobs))
  } catch (error) {
    console.log(error)
  }
}

function* onSetCurrentTeamId({ payload }) {
  yield put(contactsActions.clearTeamContacts())
  if (payload) {
    yield put(contactsActions.queryContactsImports())
  }
}

function* watchContacts() {
  yield takeEvery(
    contactsConstants.CREATE_OR_UPDATE_CONTACT_FROM_FORM,
    createOrUpdateContactFromForm
  )
  yield takeEvery(contactsConstants.GET_CONTACT, getContact)
  yield takeEvery(contactsConstants.CREATE_CONTACT, saveContact)
  yield takeEvery(contactsConstants.UPDATE_CONTACT, saveContact)
  yield takeEvery(contactsConstants.DELETE_CONTACT, deleteContact)
  yield takeLatest(contactsConstants.QUERY_CONTACTS, queryContacts)
  yield takeLatest(contactsConstants.SEARCH_CONTACTS, searchContacts)
  yield takeEvery(
    contactsConstants.DELETE_ALL_IMPORTED_CONTACTS,
    deleteAllImportedContacts
  )

  yield takeEvery(contactsConstants.CREATE_EMAIL, createEmailAddress)
  yield takeEvery(contactsConstants.CREATE_PHONE, createPhoneNumber)
  yield takeEvery(contactsConstants.DELETE_EMAIL, deleteEmailAddress)
  yield takeEvery(contactsConstants.UPDATE_EMAIL, updateEmailAddress)
  yield takeEvery(contactsConstants.DELETE_PHONE, deletePhoneNumber)
  yield takeEvery(contactsConstants.UPDATE_PHONE, updatePhoneNumber)

  yield takeLatest(
    contactsConstants.UPLOAD_CONTACTS_IMPORT,
    uploadContactsImport
  )
  yield takeLatest(
    contactsConstants.QUERY_CONTACTS_IMPORTS,
    queryContactsImports
  )
  yield takeLatest(teamsContants.SET_CURRENT_TEAM_ID, onSetCurrentTeamId)
  yield takeLatest(contactsConstants.CREATE_EXPORT_CONTACTS_JOB, exportContacts)
}

export const contactsSaga = [fork(watchContacts)]
