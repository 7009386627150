import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { withRouter } from 'react-router'
import { replace } from 'connected-react-router/immutable'

import { changeParam } from 'utility/routes'
import { onlyShow } from 'utility/styles'
import { actions as teamsActions } from 'modules/teams'
import {
  currentTeamSelector,
  currentTeamIdSelector,
  teamUuidsSelector,
  memberTeamsListSelector,
} from 'selectors/teamsSelector'
import { Dropdown, Option, Title } from '../Dropdown'

const DROPDOWN_TITLE = 'Select Team'

const TeamDropdown = ({
  history,
  location,
  match,
  staticContext,
  ...props
}) => {
  const teams = useSelector(memberTeamsListSelector)
  const teamUuids = useSelector(teamUuidsSelector)
  const currentTeam = useSelector(currentTeamSelector)
  const currentTeamId = useSelector(currentTeamIdSelector)
  const dispatch = useDispatch()
  const dropdownHolder = useRef({})

  const mounted = useRef()
  useEffect(() => {
    if (teamUuids.isEmpty() && !mounted.current) {
      dispatch(teamsActions.queryTeams())
      mounted.current = true
    }
  }, [dispatch, teamUuids])

  if (!_.every([teamUuids.size])) return null

  return (
    <Dropdown
      ref={dropdownHolder}
      toggle={
        <Name>{currentTeam ? currentTeam.get('name') : 'Select team'}</Name>
      }
      right
      {...props}
    >
      <Title>{DROPDOWN_TITLE}</Title>

      {teams.toArray().map(team => {
        const name = team.get('name')
        const uuid = team.get('uuid')

        return (
          <Option
            active={uuid === currentTeamId}
            className="dropdownItem"
            id={uuid}
            key={uuid}
            onSelect={_id => {
              if (currentTeamId !== _id) {
                dispatch(replace(changeParam(match, 'team_id', _id)))
              }
              dropdownHolder.current.closeDropdown()
            }}
          >
            {name}
          </Option>
        )
      })}
    </Dropdown>
  )
}

const Name = styled.div`
  ${onlyShow(1).line}
`

export default withRouter(TeamDropdown)
