import { createAction, handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import { normalize } from 'normalizr'

import {
  invite as inviteSchema,
  member as memberSchema,
  organization as organizationSchema,
} from 'schemas/organizations'

const ACTIVATE_CURRENT_ORGANIZATION_SUBSCRIPTION =
  'app/organizations/ACTIVATE_CURRENT_ORGANIZATION_SUBSCRIPTION'
const ACTIVATE_CURRENT_ORGANIZATION_SUBSCRIPTION_FAIL =
  'app/organizations/ACTIVATE_CURRENT_ORGANIZATION_SUBSCRIPTION_FAIL'
const ACTIVATE_CURRENT_ORGANIZATION_SUBSCRIPTION_SUCCESS =
  'app/organizations/ACTIVATE_CURRENT_ORGANIZATION_SUBSCRIPTION_SUCCESS'

const CANCEL_CURRENT_ORGANIZATION_SUBSCRIPTION =
  'app/organizations/CANCEL_CURRENT_ORGANIZATION_SUBSCRIPTION'
const CANCEL_CURRENT_ORGANIZATION_SUBSCRIPTION_FAIL =
  'app/organizations/CANCEL_CURRENT_ORGANIZATION_SUBSCRIPTION_FAIL'
const CANCEL_CURRENT_ORGANIZATION_SUBSCRIPTION_SUCCESS =
  'app/organizations/CANCEL_CURRENT_ORGANIZATION_SUBSCRIPTION_SUCCESS'

const CREATE_ORGANIZATION = 'app/organizations/CREATE_ORGANIZATION'
const CREATE_ORGANIZATION_FAIL = 'app/organizations/CREATE_ORGANIZATION_FAIL'
const CREATE_ORGANIZATION_SUCCESS =
  'app/organizations/CREATE_ORGANIZATION_SUCCESS'

const DELETE_MEMBERSHIP = 'app/organizations/DELETE_MEMBERSHIP'
const DELETE_MEMBERSHIP_FAIL = 'app/organizations/DELETE_MEMBERSHIP_FAIL'
const DELETE_MEMBERSHIP_SUCCESS = 'app/organizations/DELETE_MEMBERSHIP_SUCCESS'

const GET_ORGANIZATION = 'app/organizations/GET_ORGANIZATION'
const GET_ORGANIZATION_FAIL = 'app/organizations/GET_ORGANIZATION_FAIL'
const GET_ORGANIZATION_SUCCESS = 'app/organizations/GET_ORGANIZATION_SUCCESS'

const ORGANIZATION_INVITES_CLEAR =
  'app/organizations/ORGANIZATION_INVITES_CLEAR'

const QUERY_ORGANIZATIONS = 'app/organizations/QUERY_ORGANIZATIONS'
const QUERY_ORGANIZATIONS_FAIL = 'app/organizations/QUERY_ORGANIZATIONS_FAIL'
const QUERY_ORGANIZATIONS_SUCCESS =
  'app/organizations/QUERY_ORGANIZATIONS_SUCCESS'

const QUERY_ORGANIZATION_INVOICES =
  'app/organizations/QUERY_ORGANIZATION_INVOICES'
const QUERY_ORGANIZATION_INVOICES_FAIL =
  'app/organizations/QUERY_ORGANIZATION_INVOICES_FAIL'
const QUERY_ORGANIZATION_INVOICES_SUCCESS =
  'app/organizations/QUERY_ORGANIZATION_INVOICES_SUCCESS'

const QUERY_ORGANIZATION_MEMBERSHIPS =
  'app/organizations/QUERY_ORGANIZATION_MEMBERSHIPS'
const QUERY_ORGANIZATION_MEMBERSHIPS_FAIL =
  'app/organizations/QUERY_ORGANIZATION_MEMBERSHIPS_FAIL'
const QUERY_ORGANIZATION_MEMBERSHIPS_SUCCESS =
  'app/organizations/QUERY_ORGANIZATION_MEMBERSHIPS_SUCCESS'

const GET_ORGANIZATION_INVITE = 'app/organizations/GET_ORGANIZATION_INVITE'
const GET_ORGANIZATION_INVITE_FAIL =
  'app/organizations/GET_ORGANIZATION_INVITE_FAIL'
const GET_ORGANIZATION_INVITE_SUCCESS =
  'app/organizations/GET_ORGANIZATION_INVITE_SUCCESS'

const QUERY_ORGANIZATION_INVITES =
  'app/organizations/QUERY_ORGANIZATION_INVITES'
const QUERY_ORGANIZATION_INVITES_FAIL =
  'app/organizations/QUERY_ORGANIZATION_INVITES_FAIL'
const QUERY_ORGANIZATION_INVITES_SUCCESS =
  'app/organizations/QUERY_ORGANIZATION_INVITES_SUCCESS'

const UPDATE_ORGANIZATION_INVITE =
  'app/organizations/UPDATE_ORGANIZATION_INVITE'
const UPDATE_ORGANIZATION_INVITE_FAIL =
  'app/organizations/UPDATE_ORGANIZATION_INVITE_FAIL'
const UPDATE_ORGANIZATION_INVITE_SUCCESS =
  'app/organizations/UPDATE_ORGANIZATION_INVITE_SUCCESS'

const UPDATE_ORGANIZATION_MEMBERSHIP =
  'app/organizations/UPDATE_ORGANIZATION_MEMBERSHIP'
const UPDATE_ORGANIZATION_MEMBERSHIP_FAIL =
  'app/organizations/UPDATE_ORGANIZATION_MEMBERSHIP_FAIL'
const UPDATE_ORGANIZATION_MEMBERSHIP_SUCCESS =
  'app/organizations/UPDATE_ORGANIZATION_MEMBERSHIP_SUCCESS'

const CREATE_ORGANIZATION_INVITE =
  'app/organizations/CREATE_ORGANIZATION_INVITE'
const CREATE_ORGANIZATION_INVITE_FAIL =
  'app/organizations/CREATE_ORGANIZATION_INVITE_FAIL'
const CREATE_ORGANIZATION_INVITE_SUCCESS =
  'app/organizations/CREATE_ORGANIZATION_INVITE_SUCCESS'

const CREATE_ORGANIZATION_PAYMENT_METHOD =
  'app/organizations/CREATE_ORGANIZATION_PAYMENT_METHOD'
const CREATE_ORGANIZATION_PAYMENT_METHOD_FAIL =
  'app/organizations/CREATE_ORGANIZATION_PAYMENT_METHOD_FAIL'
const CREATE_ORGANIZATION_PAYMENT_METHOD_SUCCESS =
  'app/organizations/CREATE_ORGANIZATION_PAYMENT_METHOD_SUCCESS'

const REDIRECT_INACTIVE_ORGANIZATION =
  'app/organizations/REDIRECT_INACTIVE_ORGANIZATION'
const REDIRECT_ORGANIZATION = 'app/organizations/REDIRECT_ORGANIZATION'
const REDIRECT_ORGANIZATION_SUCCESS =
  'app/organizations/REDIRECT_ORGANIZATION_SUCCESS'

const SEARCH_ORGANIZATION_MEMBERS =
  'app/organizations/SEARCH_ORGANIZATION_MEMBERS'
const SEARCH_ORGANIZATION_MEMBERS_CLEAR =
  'app/organizations/SEARCH_ORGANIZATION_MEMBERS_CLEAR'
const SEARCH_ORGANIZATION_MEMBERS_FAIL =
  'app/organizations/SEARCH_ORGANIZATION_MEMBERS_FAIL'
const SEARCH_ORGANIZATION_MEMBERS_SUCCESS =
  'app/organizations/SEARCH_ORGANIZATION_MEMBERS_SUCCESS'
const SET_CURRENT_ORGANIZATION_ID =
  'app/organizations/SET_CURRENT_ORGANIZATION_ID'
const SET_MEMBER_EDIT_ID = 'app/organizations/SET_MEMBER_EDIT_ID'

const SOCKET_ORGANIZATION_INVITATION =
  'app/organizations/SOCKET_ORGANIZATION_INVITATION'
const SOCKET_ORGANIZATION_MEMBERSHIP =
  'app/organizations/SOCKET_ORGANIZATION_MEMBERSHIP'
const SOCKET_DELETE_ORGANIZATION_MEMBERSHIP =
  'app/organizations/SOCKET_DELETE_ORGANIZATION_MEMBERSHIP'
const SOCKET_REMOVE_ORGANIZATION_FROM_ORGANIZATION_UUIDS =
  'app/organization/SOCKET_REMOVE_ORGANIZATION_FROM_ORGANIZATION_UUIDS'
const SOCKET_ORGANIZATION = 'app/organizations/SOCKET_ORGANIZATION'
const SOCKET_DELETE_ORGANIZATION =
  'app/organizations/SOCKET_DELETE_ORGANIZATION'

export const constants = {
  ACTIVATE_CURRENT_ORGANIZATION_SUBSCRIPTION,
  ACTIVATE_CURRENT_ORGANIZATION_SUBSCRIPTION_FAIL,
  ACTIVATE_CURRENT_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  CANCEL_CURRENT_ORGANIZATION_SUBSCRIPTION,
  CANCEL_CURRENT_ORGANIZATION_SUBSCRIPTION_FAIL,
  CANCEL_CURRENT_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  CREATE_ORGANIZATION,
  CREATE_ORGANIZATION_FAIL,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_INVITE,
  CREATE_ORGANIZATION_INVITE_FAIL,
  CREATE_ORGANIZATION_INVITE_SUCCESS,
  CREATE_ORGANIZATION_PAYMENT_METHOD,
  CREATE_ORGANIZATION_PAYMENT_METHOD_FAIL,
  CREATE_ORGANIZATION_PAYMENT_METHOD_SUCCESS,
  DELETE_MEMBERSHIP_FAIL,
  DELETE_MEMBERSHIP_SUCCESS,
  DELETE_MEMBERSHIP,
  GET_ORGANIZATION_FAIL,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION,
  GET_ORGANIZATION_INVITE,
  GET_ORGANIZATION_INVITE_FAIL,
  GET_ORGANIZATION_INVITE_SUCCESS,
  ORGANIZATION_INVITES_CLEAR,
  QUERY_ORGANIZATION_INVITES,
  QUERY_ORGANIZATION_INVITES_FAIL,
  QUERY_ORGANIZATION_INVITES_SUCCESS,
  QUERY_ORGANIZATION_INVOICES,
  QUERY_ORGANIZATION_INVOICES_FAIL,
  QUERY_ORGANIZATION_INVOICES_SUCCESS,
  QUERY_ORGANIZATION_MEMBERSHIPS_FAIL,
  QUERY_ORGANIZATION_MEMBERSHIPS_SUCCESS,
  QUERY_ORGANIZATION_MEMBERSHIPS,
  QUERY_ORGANIZATIONS_FAIL,
  QUERY_ORGANIZATIONS_SUCCESS,
  QUERY_ORGANIZATIONS,
  REDIRECT_INACTIVE_ORGANIZATION,
  REDIRECT_ORGANIZATION_SUCCESS,
  REDIRECT_ORGANIZATION,
  SEARCH_ORGANIZATION_MEMBERS_CLEAR,
  SEARCH_ORGANIZATION_MEMBERS_FAIL,
  SEARCH_ORGANIZATION_MEMBERS_SUCCESS,
  SEARCH_ORGANIZATION_MEMBERS,
  SET_CURRENT_ORGANIZATION_ID,
  SET_MEMBER_EDIT_ID,
  SOCKET_DELETE_ORGANIZATION_MEMBERSHIP,
  SOCKET_DELETE_ORGANIZATION,
  SOCKET_ORGANIZATION_INVITATION,
  SOCKET_ORGANIZATION_MEMBERSHIP,
  SOCKET_ORGANIZATION,
  SOCKET_REMOVE_ORGANIZATION_FROM_ORGANIZATION_UUIDS,
  UPDATE_ORGANIZATION_INVITE,
  UPDATE_ORGANIZATION_INVITE_FAIL,
  UPDATE_ORGANIZATION_INVITE_SUCCESS,
  UPDATE_ORGANIZATION_MEMBERSHIP,
  UPDATE_ORGANIZATION_MEMBERSHIP_FAIL,
  UPDATE_ORGANIZATION_MEMBERSHIP_SUCCESS,
}

// ------------------------------------
// Actions
// ------------------------------------
const acceptOrganizationInvite = createAction(
  UPDATE_ORGANIZATION_INVITE,
  invite => invite.set('status_field', 'ACCEPTED')
)
const activateCurrentOrganizationSubscription = createAction(
  ACTIVATE_CURRENT_ORGANIZATION_SUBSCRIPTION
)
const cancelCurrentOrganizationSubscription = createAction(
  CANCEL_CURRENT_ORGANIZATION_SUBSCRIPTION
)
const createOrganization = createAction(CREATE_ORGANIZATION)
const createOrganizationInvite = createAction(CREATE_ORGANIZATION_INVITE)
const createOrganizationPaymentMethod = createAction(
  CREATE_ORGANIZATION_PAYMENT_METHOD
)
const declineOrganizationInvite = createAction(
  UPDATE_ORGANIZATION_INVITE,
  invite => invite.set('status_field', 'DECLINED')
)
const deleteMembership = createAction(DELETE_MEMBERSHIP)
const getOrganization = createAction(GET_ORGANIZATION)
const getOrganizationInvite = createAction(GET_ORGANIZATION_INVITE)
const organizationInvitesClear = createAction(ORGANIZATION_INVITES_CLEAR)
const queryOrganizations = createAction(QUERY_ORGANIZATIONS)
const queryOrganizationInvites = createAction(QUERY_ORGANIZATION_INVITES)
const queryOrganizationInvoices = createAction(QUERY_ORGANIZATION_INVOICES)
const queryOrganizationMembers = createAction(QUERY_ORGANIZATION_MEMBERSHIPS)
const redirectInactiveOrganization = createAction(
  REDIRECT_INACTIVE_ORGANIZATION
)
const redirectOrganization = createAction(REDIRECT_ORGANIZATION)
const revokeOrganizationInvite = createAction(
  UPDATE_ORGANIZATION_INVITE,
  invite => invite.set('status_field', 'REVOKED')
)
const searchOrganizationMembers = createAction(SEARCH_ORGANIZATION_MEMBERS)
const searchOrganizationMembersClear = createAction(
  SEARCH_ORGANIZATION_MEMBERS_CLEAR
)
const setCurrentOrganizationId = createAction(SET_CURRENT_ORGANIZATION_ID)
const socketOrganizationInvitation = createAction(
  SOCKET_ORGANIZATION_INVITATION
)
const socketOrganizationMembership = createAction(
  SOCKET_ORGANIZATION_MEMBERSHIP
)
const socketDeleteOrganizationMembership = createAction(
  SOCKET_DELETE_ORGANIZATION_MEMBERSHIP
)
const socketOrganization = createAction(SOCKET_ORGANIZATION)
const socketDeleteOrganization = createAction(SOCKET_DELETE_ORGANIZATION)
const socketRemoveOrganizationFromOrganizationUuids = createAction(
  SOCKET_REMOVE_ORGANIZATION_FROM_ORGANIZATION_UUIDS
)
const updateOrganizationMembership = createAction(
  UPDATE_ORGANIZATION_MEMBERSHIP
)

export const actions = {
  acceptOrganizationInvite,
  activateCurrentOrganizationSubscription,
  cancelCurrentOrganizationSubscription,
  createOrganization,
  createOrganizationInvite,
  createOrganizationPaymentMethod,
  declineOrganizationInvite,
  deleteMembership,
  getOrganization,
  getOrganizationInvite,
  organizationInvitesClear,
  queryOrganizationInvites,
  queryOrganizationInvoices,
  queryOrganizationMembers,
  queryOrganizations,
  redirectInactiveOrganization,
  redirectOrganization,
  revokeOrganizationInvite,
  searchOrganizationMembers,
  searchOrganizationMembersClear,
  setCurrentOrganizationId,
  socketDeleteOrganization,
  socketDeleteOrganizationMembership,
  socketOrganization,
  socketOrganizationInvitation,
  socketOrganizationMembership,
  socketRemoveOrganizationFromOrganizationUuids,
  updateOrganizationMembership,
}

export const initialState = () =>
  fromJS({
    activateCurrentOrganizationSubscriptionLoading: false,
    activateCurrentOrganizationSubscriptionError: null,

    cancelCurrentOrganizationSubscriptionLoading: false,
    cancelCurrentOrganizationSubscriptionError: null,

    createOrganizationLoading: false,
    createOrganizationError: null,

    createOrganizationPaymentMethodLoading: false,
    createOrganizationPaymentMethodError: null,

    currentOrganizationId: null,

    getOrganizationError: null,
    getOrganizationLoading: false,

    deleteMembershipError: null,
    deleteMembershipLoading: false,
    members: {},

    queryOrganizationMembersUuids: [],
    queryOrganizationMembersLoading: null,
    queryOrganizationMembersError: null,

    invites: {},
    inviteUuids: [],
    queryOrganizationInvitesLoading: false,
    queryOrganizationInvitesError: null,

    invoices: {},
    invoiceUuids: [],
    queryOrganizationInvoicesLoading: false,
    queryOrganizationInvoicesError: null,

    organizations: {},
    organizationUuids: [],
    organizationsError: undefined,
    organizationsLoading: false,

    searchOrganizationMembersError: null,
    searchOrganizationMembersList: [],
    searchOrganizationMembersLoading: false,
  })

export const reducers = {
  [ACTIVATE_CURRENT_ORGANIZATION_SUBSCRIPTION]: state =>
    state.merge({
      activateCurrentOrganizationSubscriptionLoading: true,
      activateCurrentOrganizationSubscriptionError: initialState().get(
        'activateCurrentOrganizationSubscriptionError'
      ),
    }),
  [ACTIVATE_CURRENT_ORGANIZATION_SUBSCRIPTION_FAIL]: (state, { payload }) =>
    state.merge({
      activateCurrentOrganizationSubscriptionLoading: initialState().get(
        'activateCurrentOrganizationSubscriptionLoading'
      ),
      activateCurrentOrganizationSubscriptionError: payload.get('error'),
    }),
  [ACTIVATE_CURRENT_ORGANIZATION_SUBSCRIPTION_SUCCESS]: state =>
    state.merge({
      activateCurrentOrganizationSubscriptionLoading: initialState().get(
        'activateCurrentOrganizationSubscriptionLoading'
      ),
    }),

  [CANCEL_CURRENT_ORGANIZATION_SUBSCRIPTION]: state =>
    state.merge({
      cancelCurrentOrganizationSubscriptionLoading: true,
      cancelCurrentOrganizationSubscriptionError: initialState().get(
        'cancelCurrentOrganizationSubscriptionError'
      ),
    }),
  [CANCEL_CURRENT_ORGANIZATION_SUBSCRIPTION_FAIL]: (state, { payload }) =>
    state.merge({
      cancelCurrentOrganizationSubscriptionLoading: initialState().get(
        'cancelCurrentOrganizationSubscriptionLoading'
      ),
      cancelCurrentOrganizationSubscriptionError: payload.get('error'),
    }),
  [CANCEL_CURRENT_ORGANIZATION_SUBSCRIPTION_SUCCESS]: state =>
    state.merge({
      cancelCurrentOrganizationSubscriptionLoading: initialState().get(
        'cancelCurrentOrganizationSubscriptionLoading'
      ),
    }),

  [CREATE_ORGANIZATION]: state =>
    state
      .set('createOrganizationLoading', true)
      .set(
        'createOrganizationError',
        initialState().get('createOrganizationError')
      ),
  [CREATE_ORGANIZATION_SUCCESS]: (state, { payload }) =>
    state.merge({
      createOrganizationLoading: initialState().get(
        'createOrganizationLoading'
      ),
      createOrganizationError: initialState().get('createOrganizationError'),
      organizations: payload.get('organizations'),
      members: payload.get('members'),
      organizationUuids: payload.get('organizationUuids'),
    }),
  [CREATE_ORGANIZATION_FAIL]: (state, { payload }) =>
    state
      .set(
        'createOrganizationLoading',
        initialState().get('createOrganizationLoading')
      )
      .set('createOrganizationError', payload.get('error')),

  [CREATE_ORGANIZATION_PAYMENT_METHOD]: state =>
    state.merge({
      createOrganizationPaymentMethodLoading: true,
      createOrganizationPaymentMethodError: initialState().get(
        'createOrganizationPaymentMethodError'
      ),
    }),
  [CREATE_ORGANIZATION_PAYMENT_METHOD_FAIL]: (state, { payload }) =>
    state.merge({
      createOrganizationPaymentMethodLoading: initialState().get(
        'createOrganizationPaymentMethodLoading'
      ),
      createOrganizationPaymentMethodError: payload.get('error'),
    }),
  [CREATE_ORGANIZATION_PAYMENT_METHOD_SUCCESS]: (state, { payload }) =>
    state.merge({
      createOrganizationPaymentMethodLoading: initialState().get(
        'createOrganizationPaymentMethodLoading'
      ),
      createOrganizationPaymentMethodError: initialState().get(
        'createOrganizationPaymentMethodError'
      ),
    }),

  [DELETE_MEMBERSHIP]: state =>
    state.merge({
      deleteMembershipError: initialState().get('deleteMembershipError'),
      deleteMembershipLoading: true,
    }),
  [DELETE_MEMBERSHIP_SUCCESS]: (state, { payload }) =>
    state.merge({
      deleteMembershipLoading: initialState().get('deleteMembershipLoading'),
    }),
  [DELETE_MEMBERSHIP_FAIL]: (state, { payload }) =>
    state.merge({
      deleteMembershipError: payload.get('error'),
      deleteMembershipLoading: initialState().get('deleteMembershipLoading'),
    }),

  [GET_ORGANIZATION]: state =>
    state.merge({
      getOrganizationLoading: true,
      getOrganizationError: initialState().get('getOrganizationError'),
    }),
  [GET_ORGANIZATION_SUCCESS]: (state, { payload }) =>
    state.mergeDeep({
      getOrganizationLoading: initialState().get('getOrganizationLoading'),
      getOrganizationError: initialState().get('getOrganizationError'),
      organizations: payload.get('organizations'),
      members: payload.get('members'),
    }),
  [GET_ORGANIZATION_FAIL]: (state, { payload }) =>
    state.merge({
      getOrganizationLoading: initialState().get('getOrganizationLoading'),
      getOrganizationError: payload.get('error'),
    }),

  [GET_ORGANIZATION_INVITE_SUCCESS]: (state, { payload }) => {
    const uuid = payload.get('uuid')
    return state
      .merge({
        invites: payload.get('invites'),
      })
      .update('inviteUuids', uuids => {
        if (uuids.includes(uuid)) {
          return uuids
        }

        return uuids.push(uuid)
      })
  },

  [ORGANIZATION_INVITES_CLEAR]: state =>
    state.set('inviteUuids', initialState().get('inviteUuids')),

  [QUERY_ORGANIZATIONS]: state =>
    state.merge({
      organizationsError: initialState().get('organizationsError'),
      organizationsLoading: true,
    }),
  [QUERY_ORGANIZATIONS_SUCCESS]: (state, { payload }) => {
    return state.merge({
      organizationUuids: payload.get('organizationUuids'),
      organizations: state
        .get('organizations')
        .merge(payload.get('organizations')),
      members: state.get('members').merge(payload.get('members')),
      organizationsError: initialState().get('organizationsError'),
      organizationsLoading: initialState().get('organizationsLoading'),
    })
  },
  [QUERY_ORGANIZATIONS_FAIL]: (state, { payload }) =>
    state.merge({
      organizationsLoading: initialState().get('organizationsLoading'),
      organizationsError: payload.get('error'),
    }),

  [QUERY_ORGANIZATION_INVITES]: state =>
    state
      .set(
        'queryOrganizationInvitesError',
        initialState().get('queryOrganizationInvitesError')
      )
      .set('queryOrganizationInvitesLoading', true),
  [QUERY_ORGANIZATION_INVITES_FAIL]: (state, { payload }) =>
    state
      .set('queryOrganizationInvitesError', payload.get('error'))
      .set(
        'queryOrganizationInvitesLoading',
        initialState().get('queryOrganizationInvitesLoading')
      ),
  [QUERY_ORGANIZATION_INVITES_SUCCESS]: (state, { payload }) =>
    state.merge({
      queryOrganizationInvitesError: initialState().get(
        'queryOrganizationInvitesError'
      ),
      queryOrganizationInvitesLoading: initialState().get(
        'queryOrganizationInvitesLoading'
      ),
      invites: payload.get('invites'),
      inviteUuids: payload.get('inviteUuids'),
    }),

  [QUERY_ORGANIZATION_INVOICES]: state =>
    state
      .set(
        'queryOrganizationInvoicesError',
        initialState().get('queryOrganizationInvoicesError')
      )
      .set('queryOrganizationInvoicesLoading', true),
  [QUERY_ORGANIZATION_INVOICES_FAIL]: (state, { payload }) =>
    state
      .set('queryOrganizationInvoicesError', payload.get('error'))
      .set(
        'queryOrganizationInvoicesLoading',
        initialState().get('queryOrganizationInvoicesLoading')
      ),
  [QUERY_ORGANIZATION_INVOICES_SUCCESS]: (state, { payload }) =>
    state.merge({
      queryOrganizationInvoicesError: initialState().get(
        'queryOrganizationInvoicesError'
      ),
      queryOrganizationInvoicesLoading: initialState().get(
        'queryOrganizationInvoicesLoading'
      ),
      invoices: payload.get('invoices'),
      invoiceUuids: payload.get('invoiceUuids'),
    }),

  [QUERY_ORGANIZATION_MEMBERSHIPS]: state =>
    state.merge({
      queryOrganizationMembersUuids: initialState().get(
        'queryOrganizationMembersUuids'
      ),
      queryOrganizationMembersError: initialState().get(
        'queryOrganizationMembersError'
      ),
      queryOrganizationMembersLoading: true,
    }),
  [QUERY_ORGANIZATION_MEMBERSHIPS_SUCCESS]: (state, { payload }) =>
    state.merge({
      members: state.get('members').merge(payload.get('members')),
      queryOrganizationMembersUuids: payload.get(
        'queryOrganizationMembersUuids'
      ),
      queryOrganizationMembersError: initialState().get(
        'queryOrganizationMembersError'
      ),
      queryOrganizationMembersLoading: initialState().get(
        'queryOrganizationMembersLoading'
      ),
    }),
  [QUERY_ORGANIZATION_MEMBERSHIPS_FAIL]: (state, { payload }) =>
    state.merge({
      queryOrganizationMembersLoading: initialState().get(
        'queryOrganizationMembersLoading'
      ),
      queryOrganizationMembersError: payload.get('error'),
    }),

  [SEARCH_ORGANIZATION_MEMBERS_CLEAR]: state =>
    state.merge({
      searchOrganizationMembersList: initialState().get(
        'searchOrganizationMembersList'
      ),
      searchOrganizationMembersError: initialState().get(
        'searchOrganizationMembersError'
      ),
      searchOrganizationMembersLoading: false,
    }),

  [SEARCH_ORGANIZATION_MEMBERS]: state =>
    state.merge({
      searchOrganizationMembersList: initialState().get(
        'searchOrganizationMembersList'
      ),
      searchOrganizationMembersError: initialState().get(
        'searchOrganizationMembersError'
      ),
      searchOrganizationMembersLoading: true,
    }),
  [SEARCH_ORGANIZATION_MEMBERS_SUCCESS]: (state, { payload }) =>
    state.merge({
      searchOrganizationMembersError: initialState().get(
        'searchOrganizationMembersError'
      ),
      searchOrganizationMembersLoading: initialState().get(
        'searchOrganizationMembersLoading'
      ),
      searchOrganizationMembersList:
        payload.get('searchOrganizationMembersList') ||
        initialState().get('searchOrganizationMembersList'),
      members: state.get('members').merge(payload.get('members')),
    }),
  [SEARCH_ORGANIZATION_MEMBERS_FAIL]: (state, { payload }) =>
    state.merge({
      searchOrganizationMembersLoading: initialState().get(
        'searchOrganizationMembersLoading'
      ),
      searchOrganizationMembersError:
        payload.get('error') ||
        initialState().get('searchOrganizationMembersError'),
    }),

  [SET_CURRENT_ORGANIZATION_ID]: (state, { payload }) =>
    state.merge({
      currentOrganizationId: payload,
    }),

  [UPDATE_ORGANIZATION_MEMBERSHIP_SUCCESS]: (state, { payload }) =>
    state.update('members', members => members.merge(payload.get('members'))),

  [SOCKET_REMOVE_ORGANIZATION_FROM_ORGANIZATION_UUIDS]: (state, { payload }) =>
    state.update('organizationUuids', organizationUuids =>
      organizationUuids.filterNot(uuid => uuid === payload)
    ),

  [SOCKET_ORGANIZATION_INVITATION]: (state, { payload }) => {
    const {
      entities: { invites },
      result: uuid,
    } = normalize(payload.toJS(), inviteSchema)

    const isCurrentOrganization =
      _.get(invites, [uuid, 'organization']) ===
      state.get('currentOrganizationId')

    _.set(invites, [uuid, 'token_type'], 'ORG_INVITE')

    return state
      .update('invites', _organizationInvites =>
        _organizationInvites.merge(invites)
      )
      .update('inviteUuids', uuids => {
        if (uuids.includes(uuid) || !isCurrentOrganization) {
          return uuids
        }

        return uuids.push(uuid)
      })
  },

  [SOCKET_ORGANIZATION_MEMBERSHIP]: (state, { payload }) => {
    const {
      entities: { members },
      result: uuid,
    } = normalize(payload.toJS(), memberSchema)
    const orgUuid = payload.get('organization')

    return state
      .update('members', _members => _members.merge(members))
      .updateIn(['organizations', orgUuid, 'members'], uuids => {
        if (!uuids) return

        if (uuids.includes(uuid)) {
          return uuids
        }

        return uuids.push(uuid)
      })
  },

  [SOCKET_DELETE_ORGANIZATION_MEMBERSHIP]: (state, { payload }) => {
    const uuid = payload.get('uuid')

    const newState = state.update('queryOrganizationMembersUuids', uuids =>
      uuids.filterNot(_uuid => _uuid === uuid)
    )

    const organization = state
      .get('organizations')
      .find(_organization => _organization.get('members').includes(uuid))

    if (organization) {
      return newState.updateIn(
        ['organizations', organization.get('uuid'), 'members'],
        members => {
          if (!members) return

          return members.filterNot(_uuid => _uuid === uuid)
        }
      )
    }

    return newState
  },

  [SOCKET_ORGANIZATION]: (state, { payload }) => {
    const {
      entities: { organizations, members },
      result: uuid,
    } = normalize(payload.toJS(), organizationSchema)

    return state
      .update('organizations', _organizations =>
        _organizations.merge(organizations).setIn([uuid, '_hasDetails'], true)
      )
      .update('members', _members => _members.merge(members))
      .update('organizationUuids', uuids => {
        if (uuids.includes(uuid)) {
          return uuids
        }

        return uuids.push(uuid)
      })
  },

  [SOCKET_DELETE_ORGANIZATION]: (state, { payload }) => {
    const uuid = payload.get('uuid')

    return state.update('organizationUuids', uuids =>
      uuids.filterNot(_uuid => _uuid === uuid)
    )
  },
}

export default handleActions(reducers, initialState())
