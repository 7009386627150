import { useSelector, useDispatch } from 'react-redux'
import { fromJS } from 'immutable'

import { getConversationContactUuidFromUuid } from 'selectors/conversationsSelector'

import { actions as conversationsActions } from 'modules/conversations'

import ContactDropdownHolder from 'components/ContactDropdown'

const ConversationRowContactDropdown = ({ conversationUuid, ...props }) => {
  const dispatch = useDispatch()

  const updateConversation = conversation =>
    dispatch(conversationsActions.updateConversation(conversation))

  const contactUuid = useSelector(state =>
    getConversationContactUuidFromUuid(state, { uuid: conversationUuid })
  )

  const handleSelect = newContactUuid => {
    updateConversation(
      fromJS({
        uuid: conversationUuid,
        contact: newContactUuid,
        selected_phone_number: null,
      })
    )
  }

  return (
    <ContactDropdownHolder
      handleSelect={handleSelect}
      contactUuid={contactUuid}
      {...props}
    />
  )
}

export default ConversationRowContactDropdown
