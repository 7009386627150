import AuthCard from 'components/Cards/AuthCard'
import Page from 'components/Page'
import NavBar from 'components/NavBar/OrgPageNavBar'
import Form from './Form'

const Create = () => (
  <Page navBar={NavBar}>
    <AuthCard>
      <Form />
    </AuthCard>
  </Page>
)

export default Create
