import { useSelector } from 'react-redux'
import { getPhoneNumberUuidsFromContactUuid } from 'selectors/contactsSelector'

import PhoneNumber from 'components/PhoneNumber'

const FirstPhoneNumber = ({ contactUuid, ...props }) => {
  const phoneNumberUuid = useSelector(
    state => getPhoneNumberUuidsFromContactUuid(state, { uuid: contactUuid }).first(),
  )

  if (!phoneNumberUuid) return null

  return <PhoneNumber phoneNumberUuid={phoneNumberUuid} {...props} />
}

export default FirstPhoneNumber
