import { useSelector } from 'react-redux'
import { routerPrintSelector } from 'selectors/routerSelector'

import Conversations from './components/Conversations'
import PrintConversations from './components/Print/PrintConversations'

const ConversationsPage = () => {
  const print = useSelector(routerPrintSelector)

  if (print) return <PrintConversations />

  return <Conversations />
}

export default ConversationsPage
