import styled from 'styled-components'
import _Page from 'components/Page'
import NotFoundNavBar from 'components/NavBar/NotFoundNavBar'
import CardTitle from 'components/Cards/CardTitle'
import CardMessage from 'components/Cards/CardMessage'

const NotFound = () => (
  <Page navBar={NotFoundNavBar}>
    <CardTitle>Not Found</CardTitle>
    <CardMessage>Sorry, we could not find that page.</CardMessage>
  </Page>
)

const Page = styled(_Page)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export default NotFound
