import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions as authActions } from 'modules/auth'
import { useQueryParam } from 'use-query-params'
import { isValidToken, decodeToken, isExpired } from 'utility/token'
import {
  authJwtPublicKeySelector,
  authLoadedSelector,
  authLoggingInSelector,
  authLoginErrorSelector,
  authRegisterErrorSelector,
  authRegisterFullErrorSelector,
  authRegisteringFullSelector,
  authRegisteringSelector,
  authRequestResetLinkError,
  authResetPasswordErrorSelector,
  authResettingPasswordSelector,
  authSendingResetLinkSelector,
  authUserSelector,
  microsoftFoldersLoadingSelector,
  microsoftFoldersSelector,
  updateProfileLoadingSelector,
} from 'selectors/authSelector'

export default function useAuth() {
  const dispatch = useDispatch()
  const jwtPublicKey = useSelector(authJwtPublicKeySelector)
  const loaded = useSelector(authLoadedSelector)
  const loggingIn = useSelector(authLoggingInSelector)
  const loginError = useSelector(authLoginErrorSelector)
  const microsoftFolders = useSelector(microsoftFoldersSelector)
  const microsoftFoldersLoading = useSelector(microsoftFoldersLoadingSelector)
  const registerError = useSelector(authRegisterErrorSelector)
  const registerFullError = useSelector(authRegisterFullErrorSelector)
  const registering = useSelector(authRegisteringSelector)
  const registeringFull = useSelector(authRegisteringFullSelector)
  const requestResetLinkError = useSelector(authRequestResetLinkError)
  const resetPasswordError = useSelector(authResetPasswordErrorSelector)
  const resettingPassword = useSelector(authResettingPasswordSelector)
  const sendingResetLink = useSelector(authSendingResetLinkSelector)
  const updateProfileLoading = useSelector(updateProfileLoadingSelector)
  const [registrationToken] = useQueryParam('token')
  let user = useSelector(authUserSelector)
  if (user) user = user.toJS()

  const login = values => {
    if (!loggingIn) {
      dispatch(authActions.login(values))
    }
  }

  const loginFromForm = values => {
    if (!loggingIn) {
      dispatch(authActions.loginFromForm(values))
    }
  }

  const register = values => {
    if (!registering) {
      dispatch(authActions.register(values))
    }
  }

  const queryMicrosoftFolders = () => {
    dispatch(authActions.queryMicrosoftFolders())
  }

  const microsoftOAuth = () => {
    dispatch(authActions.microsoftOAuth())
  }

  const microsoftOAuthCallback = params => {
    dispatch(authActions.microsoftOAuthCallback(params))
  }

  const registerFull = values => {
    if (!registeringFull) {
      dispatch(authActions.registerFull(values))
    }
  }

  const requestResetLink = values => {
    if (!sendingResetLink) {
      dispatch(authActions.requestResetLink(values))
    }
  }

  const resetPassword = values => {
    if (!resettingPassword) {
      dispatch(authActions.resetPassword(values))
    }
  }

  const updateProfile = values => {
    if (!updateProfileLoading) {
      dispatch(authActions.updateProfile(values))
    }
  }

  const logout = () => dispatch(authActions.logout())

  const decode = useCallback(token => {
    const decoded = decodeToken(token) || {}
    return {
      token,
      isExpired: isExpired(token),
      isValid: isValidToken(token, jwtPublicKey),
      ...decoded,
    }
  }, [])

  return {
    registration: decode(registrationToken),
    loaded,
    login,
    loginError,
    loginFromForm,
    loggingIn,
    logout,
    queryMicrosoftFolders,
    microsoftFolders,
    microsoftFoldersLoading,
    microsoftOAuth,
    microsoftOAuthCallback,
    register,
    registering,
    registerError,
    registerFull,
    registeringFull,
    registerFullError,
    requestResetLink,
    resetPassword,
    resetPasswordError,
    resettingPassword,
    sendingResetLink,
    requestResetLinkError,
    user,
    updateProfile,
  }
}
