import styled from 'styled-components'
import ButtonAsLink from './ButtonAsLink'
import Button from './Button'
// import theme from '../theme'

export default styled(Button).attrs(({ to }) => ({
  as: to ? ButtonAsLink : undefined,
  red: true,
}))`
  padding: 11px 22px;
  font-size: 15px !important;
  line-height: 21px;
  border-radius: 3px;
  color: white;
`
