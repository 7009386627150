import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useAuth from 'hooks/useAuth'
import { authUserSelector } from 'selectors/authSelector'
import Spinner from 'components/Spinner'
import _Button from 'components/Buttons'

const MicrosoftList = () => {
  return (
    <h1>List</h1>
  )
}


const Microsoft = () => {
  const user = useSelector(authUserSelector)
  const { microsoftOAuth } = useAuth()

  if (!user) return <Spinner />

  return (
    <>
      <h1>Microsoft</h1>

      <Button onClick={microsoftOAuth} />
    </>
  )
}

const Button = styled(_Button).attrs({
  red: true,
  primary: true,
  children: 'Authorize Microsoft',
})``

export default Microsoft
