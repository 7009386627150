import styled from 'styled-components'
import { string } from 'prop-types'
import { connect } from 'react-redux'

import { onlyShow } from 'utility/styles'
import { getOwnerUuidFromUuid } from 'selectors/conversationsSelector'
import { getShortNameFromUserUuid } from 'selectors/teamsSelector'

const mapStateToProps = (state, { conversationUuid: uuid }) => {
  const ownerUuid = getOwnerUuidFromUuid(state, { uuid })
  const ownerName =
    ownerUuid && getShortNameFromUserUuid(state, { uuid: ownerUuid })

  return {
    ownerName,
  }
}

const mapDispatchToProps = {}

const Owner = ({ conversationUuid, ownerName, ...props }) => (
  <div {...props}>{ownerName}</div>
)

Owner.propTypes = {
  conversationUuid: string.isRequired,
  ownerName: string,
}

Owner.defaultProps = {
  ownerName: null,
}

export default styled(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Owner)
)`
  font-size: 12px;
  color: #9d9d9d;
  ${onlyShow(1).line}
  width: 100%;

  &::before {
    content: 'for: ';
    display: inline-block;
    white-space: pre;
  }
`
