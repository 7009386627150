import { ModalConsumer } from '..'

const PreDeleteModalToggle = ({
  onConfirm = () => {},
  title = '',
  type = 'record',
  component: Component,
  ...props
}) => (
  <ModalConsumer>
    {({ preDelete }) => (
      <Component
        onClick={e => {
          preDelete.summon(e, { onConfirm, title, type })
        }}
        {...props}
      />
    )}
  </ModalConsumer>
)

export default PreDeleteModalToggle
