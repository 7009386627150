import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { currentTeamIdSelector } from 'selectors/teamsSelector'
import Nav from 'components/Nav'
import { ContactsIcon } from 'components/Icons'

const ContactsNav = styled((props) => {
  const currentTeamId = useSelector(currentTeamIdSelector)

  return (
    <Nav {...props}>
      <li>
        <Link to={`/contacts${currentTeamId ? `/${currentTeamId}` : ''}`}>
          <ContactsIcon />
        </Link>
      </li>
    </Nav>
  )
})``

export default ContactsNav
