import styled from 'styled-components'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { currentTeamIdSelector } from 'selectors/teamsSelector'
import { media } from 'utility/styles'

import marketingLogo from './phoneslate-logo-red-round.png'
import logo from './phoneslate-logo-red.png'

const mapStateToProps = state => ({
  currentTeamId: currentTeamIdSelector(state),
})

const BrandLink = styled(
  ({
    marketing,
    location,
    history,
    match,
    staticContext,
    currentTeamId,
    ...props
  }) => (
    <Link
      to={marketing ? '/' : _.compact(['/calls', currentTeamId]).join('/')}
      {...props}
    >
      <Logo marketing={marketing} />
      <span>PhoneSlate</span>
    </Link>
  )
)`
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 3.43px;
  text-transform: uppercase;
  user-select: none;
  flex: 0 0 auto;
  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
  }
  > span {
    vertical-align: middle;
  }
  > img {
    margin-right: 16px;
  }

  ${media.tablet`
    span {
      display: none;
    }
  `}
`

const Logo = styled.img.attrs(({ marketing }) => ({
  src: marketing ? marketingLogo : logo,
  alt: 'Phoneslate',
}))``

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(BrandLink)
)
