import styled from 'styled-components'
import { connect } from 'react-redux'
import { bool, string } from 'prop-types'

import { getTitleFromUuid } from 'selectors/statusesSelector'

import Initialism from './Initialism'

const Description = (styled.span || styled.Text)`
  margin-left: 3px;
`

const mapStateToProps = (state, { statusUuid: uuid }) => {
  const title = getTitleFromUuid(state, { uuid })

  return {
    title,
  }
}

const StatusHolder = ({
  statusUuid,
  expanded,
  color,
  initialism,
  title,
  ...props
}) => (
  <span {...props}>
    <Initialism statusUuid={statusUuid} />
    {expanded && <Description>({title})</Description>}
  </span>
)

StatusHolder.propTypes = {
  statusUuid: string.isRequired,
  expanded: bool,
}

StatusHolder.defaultProps = {
  expanded: false,
}

export default styled(
  connect(
    mapStateToProps,
    {}
  )(StatusHolder)
)`
  color: #000;
`
