import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { withRouter } from 'react-router'
import { replace } from 'connected-react-router/immutable'

import { changeParam } from 'utility/routes'
import { actions as organizationsActions } from 'modules/organizations'
import {
  currentOrganizationSelector,
  currentOrganizationIdSelector,
  organizationUuidsSelector,
  organizationsSelector,
} from 'selectors/organizationsSelector'
import { Dropdown, Option, Title } from '../Dropdown'

const DROPDOWN_TITLE = 'Select Organization'

const OrganizationDropdown = ({
  history,
  location,
  match,
  staticContext,
  ...props
}) => {
  const organizations = useSelector(organizationsSelector)
  const organizationUuids = useSelector(organizationUuidsSelector)
  const currentOrganization = useSelector(currentOrganizationSelector)
  const currentOrganizationId = useSelector(currentOrganizationIdSelector)
  const dispatch = useDispatch()
  const dropdownHolder = useRef({})

  useEffect(() => {
    if (organizationUuids.isEmpty()) {
      dispatch(organizationsActions.queryOrganizations())
    }
  }, [dispatch, organizationUuids])

  if (!_.every([organizationUuids.size])) {
    return null
  }

  return (
    <Dropdown
      ref={dropdownHolder}
      toggle={
        currentOrganization
          ? currentOrganization.get('name')
          : 'Select organization'
      }
      right
      {...props}
    >
      <Title>{DROPDOWN_TITLE}</Title>

      {organizations
        .filter(organization =>
          organizationUuids.includes(organization.get('uuid'))
        )
        .toArray()
        .map(organization => {
          const name = organization.get('name')
          const uuid = organization.get('uuid')

          return (
            <Option
              active={uuid === currentOrganizationId}
              className="dropdownItem"
              id={uuid}
              key={uuid}
              onSelect={_id => {
                dispatch(replace(changeParam(match, 'org_id', _id)))
                dropdownHolder.current.closeDropdown()
              }}
            >
              {name}
            </Option>
          )
        })}
    </Dropdown>
  )
}

export default withRouter(OrganizationDropdown)
