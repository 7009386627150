import _ from 'lodash'
import { getAuthToken } from 'utility/cookie'

import ApiClient from 'components/ApiClient'

import { invite as inviteSchema } from '../schemas/organizations'

const client = new ApiClient()
const API_ENDPOINT = __CONFIG__.apiPath.auth

export const login = data =>
  client.post(API_ENDPOINT.login, { data, omitToken: true })

export const load = () => client.get(API_ENDPOINT.profile)

export const updateProfile = data =>
  client.patch(API_ENDPOINT.profile, { data })

export const microsoftCallback = params => {
  return client.get(API_ENDPOINT.microsoftCallback, { params })
}

export const microsoftOAuth = () => {
  return client.post(API_ENDPOINT.microsoftOAuth, {
    data: { return_url: window.location.href },
  })
}

export const queryMicrosoftFolders = () => {
  return client.get(API_ENDPOINT.microsoftFolders)
}

export const refreshToken = async () => {
  const refresh = await getAuthToken('apiRefreshToken')
  return client.post(API_ENDPOINT.refresh, {
    data: { refresh },
    omitToken: true,
  })
}

export const register = data =>
  client.post(API_ENDPOINT.register, { data, omitToken: true })

export const registerFull = data =>
  client.post(API_ENDPOINT.registerFull, { data, omitToken: true })

export const resetPass = data =>
  client.put(API_ENDPOINT.resetPass, { data, omitToken: true })

export const resetLink = data =>
  client.post(API_ENDPOINT.resetLink, { data, omitToken: true })

export const getJwtPublicKey = () =>
  client.get(API_ENDPOINT.jwtPublicKey, { omitToken: true })

export const addDevice = data =>
  client.post(API_ENDPOINT.devices.base, { data })
export const deleteDevice = uuid =>
  client.delete(_.replace(API_ENDPOINT.devices.get, ':uuid', uuid), {})
export const queryDevices = () =>
  client.get(API_ENDPOINT.devices.base, { isArray: true })

export const queryInvitesReceived = () =>
  client.get(API_ENDPOINT.invites.received, {
    isArray: true,
    schema: [inviteSchema],
    params: { limit: 100 },
  })
