import styled from 'styled-components'
import { func, string } from 'prop-types'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'

import { actions as conversationsActions } from 'modules/conversations'
import { getMessageFromUuid } from 'selectors/conversationsSelector'

const mapStateToProps = (state, { conversationUuid: uuid }) => {
  const message = getMessageFromUuid(state, { uuid })

  return {
    message,
  }
}

const TextAreaHolder = (styled.div || styled.View)`
  textarea {
    border: 1px solid transparent;
    background-color: transparent;
    outline: none!important;
    -webkit-appearance: none;
    display: block;
    width: 100%;
    background-image: none;
    border-radius: 3px;
    padding: 4px;
    resize: none;
    font-weight: 300;

    &:focus,
    &:hover {
      border-color: #cbcbcb;
      background-color: #fff;
    }
  }
`

const mapDispatchToProps = {
  updateConversation: conversationsActions.updateConversation,
}

class Message extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: null,
    }

    this._input = {}
  }

  getTextareaHeight = () => {
    if (!this._input.offsetHeight) return 'auto'
    const style = window.getComputedStyle(this._input, null)
    const pt = parseInt(style.getPropertyValue('padding-top'), 10)
    const pb = parseInt(style.getPropertyValue('padding-bottom'), 10)
    const diff = this._input.offsetHeight - this._input.clientHeight
    return Math.min(Math.max((this._input.scrollHeight + pt + pb + diff), 60), 200)
  }

  handleChange = ({ target: { value } }) => {
    this.setState({ value })
  }

  handleBlur = () => {
    const { value } = this.state
    const { conversationUuid, message, updateConversation } = this.props

    this._input.scrollTop = 0

    if (
      value !== message
      && value !== null
    ) {
      updateConversation(fromJS({
        uuid: conversationUuid,
        message: value,
      }))
    }
  }

  render() {
    const { value: _value } = this.state
    const {
      conversationUuid,
      message,
      updateConversation,
      ...props
    } = this.props

    const value = (_value === null ? message : _value) || ''

    return (
      <TextAreaHolder {...props}>
        <textarea
          ref={(elem) => { this._input = elem }}
          type='text'
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          style={{ height: this.getTextareaHeight() }}
        />
      </TextAreaHolder>
    )
  }
}

Message.propTypes = {
  conversationUuid: string.isRequired,
  message: string,
  updateConversation: func.isRequired,
}

Message.defaultProps = {
  message: '',
}

export default styled(connect(mapStateToProps, mapDispatchToProps)(Message))`
  position: relative;
  line-height: 1.25;
  font-size: 12px;
`
