import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { object, string } from 'prop-types'

import { getContactByUuid } from 'selectors/contactsSelector'
import { Dropdown, Option } from 'components/Dropdown'
import _PhoneNumber from 'components/PhoneNumber'
import Placeholder from 'components/Placeholder'

import theme from './theme'

const AddConversationPhone = ({
  input: { value: selectedPhoneNumberUuid, onChange },
  placeholderText,
  meta,
  contactUuid,
  ...props
}) => {
  let _dropdownHolder

  const contactData = useSelector(state =>
    getContactByUuid(state, {
      uuid: contactUuid,
    })
  )

  const phoneNumbers = contactData ? contactData.get('phone_numbers') : []

  return (
    <div {...props}>
      <Dropdown
        ref={_dropdown => { _dropdownHolder = _dropdown }}
        toggle={selectedPhoneNumberUuid
          ? <PhoneNumber phoneNumberUuid={selectedPhoneNumberUuid} />
          : <Placeholder>Phone Number</Placeholder>}
        noCaret
      >
        {phoneNumbers.map(phoneNumberUuid => (
          <Option
            active={phoneNumberUuid === selectedPhoneNumberUuid}
            className='dropdownItem'
            id={phoneNumberUuid}
            key={phoneNumberUuid}
            onSelect={() => {
              onChange(phoneNumberUuid)
              _dropdownHolder.closeDropdown()
            }}
          >
            <PhoneNumber phoneNumberUuid={phoneNumberUuid} />
          </Option>
        ))}
        {phoneNumbers.size === 0 && <Option>No phone numbers</Option>}
      </Dropdown>
    </div>
  )
}

AddConversationPhone.propTypes = {
  input: object.isRequired,
  placeholderText: string,
}

AddConversationPhone.defaultProps = {
  placeholderText: '',
}

const PhoneNumber = styled(_PhoneNumber)`
  font-size: ${theme.fieldFontSize};
`

export default styled(AddConversationPhone)`
  margin-left: ${theme.fieldHorizontalMargin};
  .toggle {
    width: 140px;
  }
  .dropdown {
    width: 210px;
    right: 0;
  }
`
