import { createAction, handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import { normalize } from 'normalizr'

import { invite as inviteSchema } from 'schemas/organizations'

import { constants as organizationsConstants } from './organizations'

const GET_JWT_PUBLIC_KEY = 'app/auth/GET_JWT_PUBLIC_KEY'
const GET_JWT_PUBLIC_KEY_SUCCESS = 'app/auth/GET_JWT_PUBLIC_KEY_SUCCESS'
const GET_JWT_PUBLIC_KEY_FAIL = 'app/auth/GET_JWT_PUBLIC_KEY_FAIL'

const REFRESH_TOKEN = 'app/auth/REFRESH_TOKEN'
const REFRESH_TOKEN_FAIL = 'app/auth/REFRESH_TOKEN_FAIL'
const REFRESH_TOKEN_SUCCESS = 'app/auth/REFRESH_TOKEN_SUCCESS'

const LOAD = 'app/auth/LOAD'
const LOAD_SUCCESS = 'app/auth/LOAD_SUCCESS'
const LOAD_FAIL = 'app/auth/LOAD_FAIL'

const LOGIN = 'app/auth/LOGIN'
const LOGIN_SUCCESS = 'app/auth/LOGIN_SUCCESS'
const LOGIN_FAIL = 'app/auth/LOGIN_FAIL'
const LOGIN_FROM_FORM = 'app/auth/LOGIN_FROM_FORM'

const LOGOUT = 'app/auth/LOGOUT'
const LOGOUT_SUCCESS = 'app/auth/LOGOUT_SUCCESS'

const QUERY_INVITES_RECEIVED = 'app/auth/QUERY_INVITES_RECEIVED'
const QUERY_INVITES_RECEIVED_FAIL = 'app/auth/QUERY_INVITES_RECEIVED_FAIL'
const QUERY_INVITES_RECEIVED_SUCCESS = 'app/auth/QUERY_INVITES_RECEIVED_SUCCESS'

const MICROSOFT_O_AUTH = 'app/auth/MICROSOFT_O_AUTH'
const MICROSOFT_O_AUTH_SUCCESS = 'app/auth/MICROSOFT_O_AUTH_SUCCESS'
const MICROSOFT_O_AUTH_FAIL = 'app/auth/MICROSOFT_O_AUTH_FAIL'

const MICROSOFT_O_AUTH_CALLBACK = 'app/auth/MICROSOFT_O_AUTH_CALLBACK'
const MICROSOFT_O_AUTH_CALLBACK_SUCCESS =
  'app/auth/MICROSOFT_O_AUTH_CALLBACK_SUCCESS'
const MICROSOFT_O_AUTH_CALLBACK_FAIL = 'app/auth/MICROSOFT_O_AUTH_CALLBACK_FAIL'

const QUERY_MICROSOFT_FOLDERS = 'app/auth/QUERY_MICROSOFT_FOLDERS'
const QUERY_MICROSOFT_FOLDERS_SUCCESS =
  'app/auth/QUERY_MICROSOFT_FOLDERS_SUCCESS'
const QUERY_MICROSOFT_FOLDERS_FAIL = 'app/auth/QUERY_MICROSOFT_FOLDERS_FAIL'

const REGISTER = 'app/auth/REGISTER'
const REGISTER_SUCCESS = 'app/auth/REGISTER_SUCCESS'
const REGISTER_FAIL = 'app/auth/REGISTER_FAIL'

const REGISTER_FULL = 'app/auth/REGISTER_FULL'
const REGISTER_FULL_SUCCESS = 'app/auth/REGISTER_FULL_SUCCESS'
const REGISTER_FULL_FAIL = 'app/auth/REGISTER_FULL_FAIL'

const REGISTER_DEVICE_TOKEN = 'app/auth/REGISTER_DEVICE_TOKEN'
const REGISTER_DEVICE_TOKEN_SUCCESS = 'app/auth/REGISTER_DEVICE_TOKEN_SUCCESS'
const REGISTER_DEVICE_TOKEN_FAIL = 'app/auth/REGISTER_DEVICE_TOKEN_FAIL'

const DELETE_DEVICE_TOKEN = 'app/auth/DELETE_DEVICE_TOKEN'
const DELETE_DEVICE_TOKEN_SUCCESS = 'app/auth/DELETE_DEVICE_TOKEN_SUCCESS'
const DELETE_DEVICE_TOKEN_FAIL = 'app/auth/DELETE_DEVICE_TOKEN_FAIL'

const REQUEST_RESET_LINK = 'app/auth/REQUEST_RESET_LINK'
const REQUEST_RESET_LINK_SUCCESS = 'app/auth/REQUEST_RESET_LINK_SUCCESS'
const REQUEST_RESET_LINK_FAIL = 'app/auth/REQUEST_RESET_LINK_FAIL'

const RESET_PASSWORD = 'app/auth/RESET_PASSWORD'
const RESET_PASSWORD_SUCCESS = 'app/auth/RESET_PASSWORD_SUCCESS'
const RESET_PASSWORD_FAIL = 'app/auth/RESET_PASSWORD_FAIL'

const UPDATE_PROFILE = 'app/auth/UPDATE_PROFILE'
const UPDATE_PROFILE_FAIL = 'app/auth/UPDATE_PROFILE_FAIL'
const UPDATE_PROFILE_SUCCESS = 'app/auth/UPDATE_PROFILE_SUCCESS'

export const constants = {
  DELETE_DEVICE_TOKEN_FAIL,
  DELETE_DEVICE_TOKEN_SUCCESS,
  DELETE_DEVICE_TOKEN,
  QUERY_INVITES_RECEIVED,
  QUERY_INVITES_RECEIVED_FAIL,
  QUERY_INVITES_RECEIVED_SUCCESS,
  GET_JWT_PUBLIC_KEY_FAIL,
  GET_JWT_PUBLIC_KEY_SUCCESS,
  GET_JWT_PUBLIC_KEY,
  LOAD_FAIL,
  LOAD_SUCCESS,
  LOAD,
  LOGIN_FAIL,
  LOGIN_FROM_FORM,
  LOGIN_SUCCESS,
  LOGIN,
  LOGOUT,
  LOGOUT_SUCCESS,
  MICROSOFT_O_AUTH_CALLBACK_FAIL,
  MICROSOFT_O_AUTH_CALLBACK_SUCCESS,
  MICROSOFT_O_AUTH_CALLBACK,
  MICROSOFT_O_AUTH_FAIL,
  MICROSOFT_O_AUTH_SUCCESS,
  MICROSOFT_O_AUTH,
  QUERY_MICROSOFT_FOLDERS_FAIL,
  QUERY_MICROSOFT_FOLDERS_SUCCESS,
  QUERY_MICROSOFT_FOLDERS,
  REFRESH_TOKEN_FAIL,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN,
  REGISTER_DEVICE_TOKEN_FAIL,
  REGISTER_DEVICE_TOKEN_SUCCESS,
  REGISTER_DEVICE_TOKEN,
  REGISTER_FAIL,
  REGISTER_FULL_FAIL,
  REGISTER_FULL_SUCCESS,
  REGISTER_FULL,
  REGISTER_SUCCESS,
  REGISTER,
  REQUEST_RESET_LINK_FAIL,
  REQUEST_RESET_LINK_SUCCESS,
  REQUEST_RESET_LINK,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
}

// ------------------------------------
// Actions
// ------------------------------------
export const getJwtPublicKey = createAction(GET_JWT_PUBLIC_KEY)
export const load = createAction(LOAD)
export const login = createAction(LOGIN)
export const loginFromForm = createAction(LOGIN_FROM_FORM)
export const logout = createAction(LOGOUT)
export const queryInvitesReceived = createAction(QUERY_INVITES_RECEIVED)
export const queryMicrosoftFolders = createAction(QUERY_MICROSOFT_FOLDERS)
export const microsoftOAuth = createAction(MICROSOFT_O_AUTH)
export const microsoftOAuthCallback = createAction(MICROSOFT_O_AUTH_CALLBACK)
export const refreshTokenRefresh = createAction(REFRESH_TOKEN)
export const register = createAction(REGISTER)
export const registerDeviceToken = createAction(REGISTER_DEVICE_TOKEN)
export const deleteDeviceToken = createAction(DELETE_DEVICE_TOKEN)
export const registerFull = createAction(REGISTER_FULL)
export const requestResetLink = createAction(REQUEST_RESET_LINK)
export const resetLink = createAction(REQUEST_RESET_LINK)
export const resetPassword = createAction(RESET_PASSWORD)
export const updateProfile = createAction(UPDATE_PROFILE)

export const actions = {
  deleteDeviceToken,
  queryInvitesReceived,
  getJwtPublicKey,
  load,
  login,
  loginFromForm,
  logout,
  microsoftOAuth,
  microsoftOAuthCallback,
  queryMicrosoftFolders,
  refreshTokenRefresh,
  register,
  registerDeviceToken,
  registerFull,
  requestResetLink,
  resetLink,
  resetPassword,
  updateProfile,
}

export const initialState = () =>
  fromJS({
    jwtPublicKey: null,
    jwtPublicKeyError: null,

    loaded: false,
    loading: false,
    loadError: null,

    invitesReceived: {},
    invitesReceivedUuids: [],

    microsoftFolders: [],
    microsoftFoldersLoading: false,
    microsoftFoldersError: null,

    microsoftOAuthError: null,
    microsoftOAuthCallbackError: null,

    refreshTokenLoading: false,
    refreshTokenError: null,

    registering: false,
    registeredUser: null,
    registerError: null,

    registeringFull: false,
    registerFullError: null,

    deviceToken: null,
    deviceId: null,
    deviceTokenUuid: null,
    registeringDeviceToken: false,
    registerDeviceTokenError: null,

    loggedIn: false,
    loggingIn: false,
    loginError: null,

    loggingOut: false,

    sendingResetLink: false,
    requestResetLinkError: null,
    resettingPassword: false,
    resetPasswordError: null,
    user: null,

    updateProfileLoading: false,
    updateProfileError: false,
  })

export const reducers = {
  [GET_JWT_PUBLIC_KEY]: state =>
    state.merge({
      jwtPublicKey: initialState().get('jwtPublicKey'),
      jwtPublicKeyError: initialState().get('jwtPublicKeyError'),
    }),
  [GET_JWT_PUBLIC_KEY_SUCCESS]: (state, { payload }) =>
    state.merge({
      jwtPublicKey: payload.get('jwtPublicKey'),
      jwtPublicKeyError: initialState().get('jwtPublicKeyError'),
    }),
  [GET_JWT_PUBLIC_KEY_FAIL]: (state, { payload }) =>
    state.merge({
      jwtPublicKeyError: payload.get('error'),
    }),

  [LOGIN]: state =>
    state.merge({
      loggingIn: true,
      loggedIn: initialState().get('loggedIn'),
      loginError: initialState().get('loginError'),
    }),
  [LOGOUT]: state =>
    state.merge({
      loggedIn: false,
    }),
  [LOGIN_SUCCESS]: state =>
    state.merge({
      loggingIn: initialState().get('loggingIn'),
      loggedIn: true,
      loginError: initialState().get('loginError'),
    }),
  [LOGIN_FAIL]: (state, { payload }) =>
    state.merge({
      loggingIn: initialState().get('loggingIn'),
      loggedIn: initialState().get('loggedIn'),
      loginError: payload.get('error'),
    }),

  [LOAD]: state =>
    state.merge({
      loading: true,
      loaded: initialState().get('loaded'),
      loadError: initialState().get('loadError'),
      user: initialState().get('user'),
    }),
  [LOAD_SUCCESS]: (state, { payload }) =>
    state.merge({
      loading: initialState().get('loading'),
      loaded: true,
      loadError: initialState().get('loadError'),
      user: payload.get('user'),
    }),
  [LOAD_FAIL]: (state, { payload }) =>
    state.merge({
      loading: initialState().get('loading'),
      loaded: true,
      loadError: payload.get('error'),
    }),

  [QUERY_INVITES_RECEIVED_SUCCESS]: (state, { payload }) =>
    state.merge(payload),

  [QUERY_MICROSOFT_FOLDERS]: state =>
    state.merge({
      microsoftFolders: initialState().get('microsoftFolders'),
      microsoftFoldersLoading: true,
      microsoftFoldersError: initialState().get('microsoftFoldersError'),
    }),
  [QUERY_MICROSOFT_FOLDERS_FAIL]: (state, { payload }) =>
    state.merge({
      microsoftFoldersLoading: initialState().get('microsoftFoldersLoading'),
      microsoftFoldersError: payload.get('error'),
    }),
  [QUERY_MICROSOFT_FOLDERS_SUCCESS]: (state, { payload }) =>
    state.merge({
      microsoftFolders: payload.get('microsoftFolders'),
      microsoftFoldersLoading: initialState().get('microsoftFoldersLoading'),
      microsoftFoldersError: initialState().get('microsoftFoldersError'),
    }),

  [MICROSOFT_O_AUTH]: state =>
    state.merge({
      microsoftOAuthError: initialState().get('microsoftOAuthError'),
    }),
  [MICROSOFT_O_AUTH_FAIL]: (state, { payload }) =>
    state.merge({
      microsoftOAuthError: payload.get('error'),
    }),
  [MICROSOFT_O_AUTH_SUCCESS]: state =>
    state.merge({
      microsoftOAuthError: initialState().get('microsoftOAuthError'),
    }),

  [MICROSOFT_O_AUTH_CALLBACK]: state =>
    state.merge({
      microsoftOAuthCallbackError: initialState().get(
        'microsoftOAuthCallbackError'
      ),
    }),
  [MICROSOFT_O_AUTH_CALLBACK_FAIL]: (state, { payload }) =>
    state.merge({
      microsoftOAuthCallbackError: payload.get('error'),
    }),
  [MICROSOFT_O_AUTH_CALLBACK_SUCCESS]: state =>
    state.merge({
      microsoftOAuthCallbackError: initialState().get(
        'microsoftOAuthCallbackError'
      ),
    }),

  [REFRESH_TOKEN]: state =>
    state.merge({
      refreshTokenLoading: true,
      refreshTokenError: initialState().get('refreshTokenError'),
    }),
  [REFRESH_TOKEN_FAIL]: (state, { payload }) =>
    state.merge({
      refreshTokenLoading: initialState().get('refreshTokenLoading'),
      refreshTokenError: payload.get('error'),
    }),
  [REFRESH_TOKEN_SUCCESS]: state =>
    state.merge({
      refreshTokenLoading: initialState().get('refreshTokenLoading'),
      refreshTokenError: initialState().get('refreshTokenError'),
    }),

  [REGISTER]: state =>
    state.merge({
      registering: true,
      registerError: initialState().get('registerError'),
    }),
  [REGISTER_SUCCESS]: (state, { payload }) =>
    state.merge({
      registering: initialState().get('registering'),
      registerError: initialState().get('registerError'),
      registeredUser: payload.get('registeredUser'),
    }),
  [REGISTER_FAIL]: (state, { payload }) =>
    state.merge({
      registering: initialState().get('registering'),
      registerError: payload.get('error'),
    }),

  [REGISTER_DEVICE_TOKEN]: (state, { payload }) =>
    state.merge({
      deviceToken: payload.get('deviceToken'),
      deviceId: payload.get('deviceId'),
      registeringDeviceToken: true,
      registerDeviceTokenError: initialState().get('registerDeviceTokenError'),
    }),
  [REGISTER_DEVICE_TOKEN_SUCCESS]: (state, { payload }) =>
    state.merge({
      registeringDeviceToken: initialState().get('registeringDeviceToken'),
      registerDeviceTokenError: initialState().get('registerDeviceTokenError'),
      deviceTokenUuid: payload.get('uuid'),
    }),
  [REGISTER_DEVICE_TOKEN_FAIL]: (state, { payload }) =>
    state.merge({
      registeringDeviceToken: initialState().get('registeringDeviceToken'),
      registerDeviceTokenError: payload.get('error'),
    }),

  [DELETE_DEVICE_TOKEN_SUCCESS]: state =>
    state.merge({
      deviceToken: initialState().get('deviceToken'),
      deviceTokenUuid: initialState().get('deviceTokenUuid'),
    }),

  [REGISTER_FULL]: state =>
    state.merge({
      registeringFull: true,
      registerFullError: initialState().get('registerFullError'),
      registeredUser: initialState().get('registeredUser'),
    }),
  [REGISTER_FULL_SUCCESS]: (state, { payload }) =>
    state.merge({
      registeringFull: initialState().get('registeringFull'),
      registerFullError: initialState().get('registerFullError'),
      registeredUser: payload.get('registeredUser'),
    }),
  [REGISTER_FULL_FAIL]: (state, { payload }) =>
    state.merge({
      registeringFull: initialState().get('registeringFull'),
      registerFullError: payload.get('error'),
      registeredUser: initialState().get('registeredUser'),
    }),

  [REQUEST_RESET_LINK]: state =>
    state.merge({
      sendingResetLink: true,
      requestResetLinkError: initialState().get('requestResetLinkError'),
    }),
  [REQUEST_RESET_LINK_SUCCESS]: state =>
    state.merge({
      sendingResetLink: initialState().get('sendingResetLink'),
      requestResetLinkError: initialState().get('requestResetLinkError'),
    }),
  [REQUEST_RESET_LINK_FAIL]: (state, { payload }) =>
    state.merge({
      sendingResetLink: initialState().get('sendingResetLink'),
      requestResetLinkError: payload.get('error'),
    }),

  [RESET_PASSWORD]: state =>
    state.merge({
      resettingPassword: true,
      resetPasswordError: initialState().get('resetPasswordError'),
    }),
  [RESET_PASSWORD_SUCCESS]: state =>
    state.merge({
      resettingPassword: initialState().get('resettingPassword'),
      resetPasswordError: initialState().get('resetPasswordError'),
    }),
  [RESET_PASSWORD_FAIL]: (state, { payload }) =>
    state.merge({
      resettingPassword: initialState().get('resettingPassword'),
      resetPasswordError: payload.get('error'),
    }),

  [UPDATE_PROFILE]: state =>
    state.merge({
      updateProfileError: initialState().get('updateProfileError'),
      updateProfileLoading: true,
    }),
  [UPDATE_PROFILE_FAIL]: (state, { payload }) =>
    state.merge({
      updateProfileError: payload.get('error'),
      updateProfileLoading: initialState().get('updateProfileLoading'),
    }),
  [UPDATE_PROFILE_SUCCESS]: (state, { payload }) =>
    state.merge({
      user: payload.get('user'),
      updateProfileLoading: initialState().get('updateProfileLoading'),
    }),

  [organizationsConstants.SOCKET_ORGANIZATION_INVITATION]: (
    state,
    { payload }
  ) => {
    const {
      entities: { invites },
      result: uuid,
    } = normalize(payload.toJS(), inviteSchema)

    const isCurrentUser =
      _.get(invites, [uuid, 'recipient_email']) ===
      state.getIn(['user', 'email'])

    if (!isCurrentUser) return state

    _.set(invites, [uuid, 'token_type'], 'ORG_INVITE')

    return state
      .update('invitesReceived', _invitesReceived =>
        _invitesReceived.merge(invites)
      )
      .update('invitesReceivedUuids', uuids => {
        if (uuids.includes(uuid)) {
          return uuids
        }

        return uuids.push(uuid)
      })
  },
}

export default handleActions(reducers, initialState())
