import AuthPage from 'components/Page/AuthPage'
import AuthCard from 'components/Cards/AuthCard'
import { ResetPasswordFullForm } from 'components/AuthForms'

const ChangePassword = () => (
  <AuthPage>
    <AuthCard>
      <ResetPasswordFullForm />
    </AuthCard>
  </AuthPage>
)

export default ChangePassword
