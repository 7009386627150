import styled from 'styled-components'
import Page from 'components/Page'
import AuthNavBar from 'components/NavBar/AuthNavBar'

const AuthPage = styled(Page).attrs({
  navBar: AuthNavBar,
})`
  padding-left: 15px;
  padding-right: 15px;
  background-color: ${props => props.theme.colors.offWhite};
`

export default AuthPage
