import styled from 'styled-components'
import { CloseIcon } from '../Icons'

export default styled(CloseIcon)`
  color: #c4c4c4;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 21px;
  cursor: pointer;
`
