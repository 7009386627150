import styled from 'styled-components'
import Nav from '../Nav'
import ProfileDropdown from './ProfileDropdown'

const ProfileNav = styled(({ ...props }) => (
  <Nav {...props}>
    <li>
      <ProfileDropdown />
    </li>
  </Nav>
))``

export default ProfileNav
