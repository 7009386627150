import memoize from 'lru-memoize'
import { createValidator, required, minNumber } from 'utility/validation'

const TeamFormValidation = createValidator({
  name: [required],
  members: {
    _array: [minNumber(1, 'member')],
  },
  statuses: {
    _array: [minNumber(1, 'status')],
    initialism: [required],
    title: [required],
  },
})

export default memoize(10)(TeamFormValidation)
