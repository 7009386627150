import React, { useState } from 'react'

import EditModal from 'components/Modal/EditOrganizationMember'
import DeleteModal from 'components/Modal/DeleteOrganizationMember'
import Button from 'components/Buttons'
import { EditIcon } from 'components/Icons'

const EditButton = ({ memberUuid }) => {
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const onDeleteClick = () => {
    setOpenEditModal(false)
    setOpenDeleteModal(true)
  }

  return (
    <>
      <IconButton
        onClick={() => {
          setOpenEditModal(true)
        }}
      />

      <EditModal
        isOpen={openEditModal}
        handleCancel={() => {
          setOpenEditModal(false)
        }}
        memberUuid={memberUuid}
        onDeleteClick={onDeleteClick}
      />

      <DeleteModal
        isOpen={openDeleteModal}
        handleCancel={() => {
          setOpenDeleteModal(false)
        }}
        memberUuid={memberUuid}
      />
    </>
  )
}

export const IconButton = styled(Button).attrs({
  link: true,
  children: <EditIcon />,
})`
  flex: 0 0 auto;
  margin-left: auto;
  padding-top: 0;
  padding-bottom: 0;
  color: inherit;
`

export default EditButton
