import styled from 'styled-components'
import { func, string, bool } from 'prop-types'

import {
  Modal, ModalHeader, ModalBody, ModalClose, ModalTitle, ModalFooter,
} from 'components/Modal'
import Button from 'components/Buttons'
import ButtonRow from 'components/Buttons/ButtonRow'

const PreDeleteModal = ({
  title,
  type,
  handleCancel,
  handleDelete,
  isOpen,
  ...props
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={handleCancel}
    {...props}
  >
    <ModalHeader>
      <ModalTitle>{title}</ModalTitle>
      <ModalClose onClick={handleCancel} />
    </ModalHeader>

    <ModalBody>
Are you sure you want to delete this
      {type}
? This can’t be undone.
    </ModalBody>

    <ModalFooter>
      <ButtonRow>
        <Button
          onClick={handleCancel}
          secondary
          type='button'
        >
          Cancel
        </Button>

        <Button
          onClick={handleDelete}
          type='button'
          primary
        >
          Delete
        </Button>
      </ButtonRow>
    </ModalFooter>
  </Modal>
)

PreDeleteModal.propTypes = {
  title: string.isRequired,
  type: string.isRequired,
  handleCancel: func.isRequired,
  handleDelete: func.isRequired,
  isOpen: bool.isRequired,
}

export default styled(PreDeleteModal)``
