import React, { useState } from 'react'
import { fromJS } from 'immutable'

import useOrganizationMembers from 'hooks/useOrganizationMembers'

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalClose,
  ModalTitle,
  ModalFooter,
} from 'components/Modal'

import Button from 'components/Buttons'
import ButtonRow from 'components/Buttons/ButtonRow'

const DeleteOrganizationMemberModal = ({
  handleCancel,
  memberUuid,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const { deleteOrganizationMembership, memberByUuid } = useOrganizationMembers(
    memberUuid
  )

  const { organization } = memberByUuid.toJS()

  const onDeleteClick = () => {
    return new Promise((resolve, reject) => {
      setLoading(true)

      deleteOrganizationMembership(
        fromJS({ organization, uuid: memberUuid, resolve, reject })
      )
    }).catch(() => {
      setLoading(false)
    })
  }

  return (
    <Modal {...props}>
      <ModalHeader>
        <ModalTitle>Delete Member</ModalTitle>
        <ModalClose onClick={handleCancel} />
      </ModalHeader>

      <ModalBody>
        Are you sure you want to remove this member from your organization?
      </ModalBody>

      <ModalFooter>
        <ButtonRow>
          <Button
            disabled={loading}
            onClick={handleCancel}
            secondary
            type="button"
          >
            Cancel
          </Button>

          <Button
            disabled={loading}
            loadingText="Deleting..."
            loading={loading}
            onClick={onDeleteClick}
            type="button"
            red
          >
            Delete
          </Button>
        </ButtonRow>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteOrganizationMemberModal
