import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { actions as conversationsActions } from 'modules/conversations'

import { getConversationFormOpen } from 'selectors/conversationsSelector'
import { currentTeamIdSelector } from 'selectors/teamsSelector'

import { AddButton } from 'components/AddButton'

const AddConversationButton = props => {
  const dispatch = useDispatch()

  const open = useSelector(getConversationFormOpen)
  const currentTeamUuid = useSelector(currentTeamIdSelector)

  const toggleForm = shouldOpen =>
    dispatch(conversationsActions.toggleConversationForm(shouldOpen))

  useEffect(() => {
    toggleForm(false)
  }, [currentTeamUuid])

  return (
    <AddButton open={open} onAddClick={() => toggleForm(true)} {...props} />
  )
}

export default AddConversationButton
