import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export default styled(NavLink)`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 12px 30px;
  background-color: ${props => props.theme.tabs.tabLinkBg};
  font-size: 15px;
  font-weight: bold;
  color: ${props => props.theme.colors.darkGray}!important;
  text-align: center;
  white-space: nowrap;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: ${props => props.theme.tabs.tabLinkHoverBg};
  }

  margin-left: -1px;
  line-height: 24px;
  border: 1px solid ${props => props.theme.tabs.tabsLinkBorderColor};
  border-radius: 0;
  &:hover {
    border-color: ${props => props.theme.tabs.tabsLinkHoverBorderColor} ${props => props.theme.tabs.tabsLinkHoverBorderColor} ${props => props.theme.tabs.tabsLinkBorderColor};
  }

  &.active {
    &,
    &:hover,
    &:focus {
      color: ${props => props.theme.colors.darkGray};
      background-color: ${props => props.theme.tabs.tabActiveLinkHoverBg};
      border-color: ${props => props.theme.tabs.tabsLinkBorderColor};
      border-bottom-color: transparent;
      cursor: default;
  }
`
