import styled from 'styled-components'
import { Redirect } from 'react-router-dom'

import useAuth from 'hooks/useAuth'

import _Page from 'components/Page'
import HomeNavBar from 'components/HomeNavBar'

import Home from './Home'

const Page = styled(_Page)`
  padding-top: 60px;
  padding-left: ${({ theme }) => theme.page.home.paddingHorizontal};
  padding-right: ${({ theme }) => theme.page.home.paddingHorizontal};
  padding-bottom: 0;
`

export default () => {
  const { user } = useAuth()

  return (
    <Page navBar={HomeNavBar}>
      <Home />
      {!!user && <Redirect strict replace from="/" to="/calls" />}
    </Page>
  )
}
