import styled from 'styled-components'
import { bool, func, string } from 'prop-types'
import { list } from 'react-immutable-proptypes'
import { fromJS } from 'immutable'

import { connect } from 'react-redux'

import {
  getCurrentActiveNonSystemTeamStatusUuids,
  getCompletedStatusUuid,
} from 'selectors/statusesSelector'
import { getStatusFromUuid } from 'selectors/conversationsSelector'

import { actions as conversationsActions } from 'modules/conversations'

import { Dropdown as _Dropdown, Option } from 'components/Dropdown'
import { PreDeleteModalToggle } from 'components/Modals'

import Status from './Status'

const Dropdown = styled(_Dropdown)``

const Completed = styled(Option).attrs({
  children: 'Completed',
})`
  font-weight: 700;
`

const Delete = styled(Option).attrs({
  children: 'Delete',
})`
  font-weight: 700;
`

const mapStateToProps = (state, { conversationUuid: uuid }) => {
  const statuses = getCurrentActiveNonSystemTeamStatusUuids(state)
  const status = getStatusFromUuid(state, { uuid })
  const completedStatusUuid = getCompletedStatusUuid(state)

  return {
    statuses,
    status,
    completedStatusUuid,
  }
}

const mapDispatchToProps = {
  deleteConversation: conversationsActions.deleteConversation,
  getConversation: conversationsActions.getConversation,
  updateStatus: conversationsActions.updateStatus,
}

class StatusDropdown extends React.Component {
  constructor(props) {
    super(props)

    this._dropdownHolder = {}
  }

  handleStatusChange = newStatus => {
    const { updateStatus, conversationUuid } = this.props

    updateStatus(
      fromJS({
        conversation: conversationUuid,
        status: newStatus,
      })
    )

    this._dropdownHolder.closeDropdown()
  }

  handleCompleted = () => {
    const { completedStatusUuid } = this.props
    this.handleStatusChange(completedStatusUuid)
  }

  handleDelete = () => {
    const { deleteConversation, conversationUuid } = this.props
    deleteConversation(conversationUuid)
  }

  render() {
    const {
      completedStatusUuid,
      conversationUuid,
      deleteConversation,
      getConversation,
      noCaret,
      status,
      statuses,
      updateStatus,
      ...props
    } = this.props

    return (
      <div {...props}>
        <Dropdown
          ref={_dropdown => {
            this._dropdownHolder = _dropdown
          }}
          toggle={<Status statusUuid={status} />}
          noCaret={noCaret}
        >
          {statuses &&
            statuses.map(_status => (
              <Option
                active={_status === status}
                className="dropdownItem"
                closeDropdown={this._dropdownHolder.closeDropdown}
                key={_status}
                onSelect={() => {
                  this.handleStatusChange(_status)
                }}
              >
                <Status expanded statusUuid={_status} />
              </Option>
            ))}

          <hr />

          <Completed className="dropdownItem" onSelect={this.handleCompleted} />

          <PreDeleteModalToggle
            component={Delete}
            className="dropdownItem"
            onConfirm={() => {
              this.handleDelete()
              this._dropdownHolder.closeDropdown()
            }}
            title="Delete Conversation?"
            type="conversation"
          />
        </Dropdown>
      </div>
    )
  }
}

StatusDropdown.propTypes = {
  conversationUuid: string.isRequired,
  getConversation: func.isRequired,
  noCaret: bool,
  deleteConversation: func.isRequired,
  statuses: list.isRequired,
  updateStatus: func.isRequired,
}

StatusDropdown.defaultProps = {
  noCaret: false,
}

export default styled(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StatusDropdown)
)`
  position: relative;
  align-self: flex-start;
  .dropdown {
    top: 0;
    margin-top: 0;
  }
`
