import React, { useRef, createRef } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { ModalConsumer } from 'components/Modals'
import _Email from 'components/Email'
import _PhoneNumber from 'components/PhoneNumber'

import { media, onlyShow } from 'utility/styles'

import { getContactByUuid } from 'selectors/contactsSelector'

const InnerContact = ({ uuid, summonContactModal = () => {} }) => {
  let contact = useSelector(state => getContactByUuid(state, { uuid }))
  if (contact) contact = contact.toJS() || {}

  const {
    first_name: firstName,
    last_name: lastName,
    business_name: businessName,
    phone_numbers: phoneIDs = [],
    email_addresses: emailIDs = [],
  } = contact || {}

  const emails = useRef(emailIDs.map(() => createRef()))
  const phones = useRef(phoneIDs.map(() => createRef()))

  const openEditContactModal = e => {
    const shouldNotOpenModal = [...emails.current, ...phones.current].some(
      ({ current: el }) => el && el.contains(e.target)
    )

    if (shouldNotOpenModal) return

    summonContactModal(e, { contactUuid: uuid })
  }

  if (!contact) return null

  return (
    <ContactWrapper onClick={openEditContactModal}>
      <FirstCol>
        <Name>{`${firstName} ${lastName}`}</Name>
        <ContactBusiness>{businessName}</ContactBusiness>
      </FirstCol>

      <ReverseOnMobile>
        <EmailList>
          {emailIDs.map((_uuid, i) => (
            <Email innerRef={emails.current[i]} key={_uuid} uuid={_uuid} />
          ))}
        </EmailList>

        <PhoneList>
          {phoneIDs.map((_uuid, i) => (
            <Phone
              isLink
              innerRef={phones.current[i]}
              phoneNumberUuid={_uuid}
              key={_uuid}
            />
          ))}
        </PhoneList>
      </ReverseOnMobile>
    </ContactWrapper>
  )
}

export const Contact = props => (
  <ModalConsumer>
    {({ contact: { summon = () => {} } = {} }) => (
      <InnerContact summonContactModal={summon} {...props} />
    )}
  </ModalConsumer>
)

const ReverseOnMobile = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  ${media.phone`
    flex-direction: column-reverse;
  `}
`

const Phone = styled(_PhoneNumber)`
  display: flex;
  ${media.phone`
    margin-bottom: 4px;
  `}
`

const Email = styled(_Email)`
  ${onlyShow(1).line}
  display: flex;
  ${media.phone`
    margin-bottom: 4px;
    width: fit-content;
  `}
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const PhoneList = styled.div`
  ${media.phone`
    width: 100%;
  `}
  text-align: right;
  margin-left: auto;
`

const EmailList = styled.div`
  ${media.phone`
    width: 100%;
  `}
  text-align: right;
`

const FirstCol = styled(Col)`
  width: 55%;
  ${media.phone`
    width: 100%;
  `}
`

const ContactBusiness = styled.p`
  font-size: 12px;
  color: ${props => props.theme.page.subheadingColor};
`

const ContactWrapper = styled.div`
  cursor: pointer;
  border-top: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  padding: 12px;
  ${media.phone`
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
  `}
`

const Name = styled.h4`
  font-weight: bold;
  ${media.phone`
    margin: 0;
  `}
`
