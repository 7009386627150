import { useDispatch, useSelector } from 'react-redux'
import { actions as contactsActions } from 'modules/contacts'

import { getExportJobByUuid } from 'selectors/contactsSelector'

const useContactExports = ({ jobUuid } = {}) => {
  const dispatch = useDispatch()

  const exportJob = useSelector(state => getExportJobByUuid(state, jobUuid))

  const createExportJob = values => {
    dispatch(contactsActions.createExportContactsJob(values))
  }

  return {
    exportJob,
    createExportJob,
  }
}

export default useContactExports
