import styled from 'styled-components'
import Icon from './Icon'

const GripIcon = styled(Icon).attrs({
  type: 'grip-lines',
})`
  color: #d8d8d8;
`

export default GripIcon
