import styled from 'styled-components'
import Icon from './Icon'

const AddToTeamIcon = styled(Icon).attrs({
  type: 'add-user',
})`
  border: 1px dashed #9d9d9d;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  display: inline-block;
  &:hover {
    border-color: #fff;
  }
`

export default AddToTeamIcon
