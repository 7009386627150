import styled from 'styled-components'
import { connect } from 'react-redux'
import { object } from 'prop-types'
import { list } from 'react-immutable-proptypes'
import { getCurrentActiveNonSystemTeamStatusUuids } from 'selectors/statusesSelector'
import { Dropdown, Option } from 'components/Dropdown'
import Status from '../Conversation/Status'

const mapStateToProps = state => {
  const statuses = getCurrentActiveNonSystemTeamStatusUuids(state)

  return {
    statuses,
  }
}

const mapDispatchToProps = {}

const AddConversationStatus = ({
  meta,
  input: { value: selectedStatusId, onChange },
  statuses,
  ...props
}) => {
  let _dropdownHolder = {}

  if (!statuses || statuses.isEmpty()) return null

  return (
    <div {...props}>
      <Dropdown
        ref={_dropdown => {
          _dropdownHolder = _dropdown
        }}
        toggle={
          selectedStatusId ? (
            <Status statusUuid={selectedStatusId} />
          ) : (
            <span>Status</span>
          )
        }
        noCaret
      >
        {statuses.map(status => (
          <Option
            active={status === selectedStatusId}
            className="dropdownItem"
            closeDropdown={_dropdownHolder.closeDropdown}
            id={status}
            key={status}
            onSelect={() => {
              onChange(status)
              _dropdownHolder.closeDropdown()
            }}
          >
            <Status expanded statusUuid={status} />
          </Option>
        ))}
      </Dropdown>
    </div>
  )
}

AddConversationStatus.propTypes = {
  statuses: list.isRequired,
  input: object.isRequired,
  meta: object.isRequired,
}

AddConversationStatus.defaultProps = {}

export default styled(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddConversationStatus)
)``
