import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Link as _Link } from 'react-router-dom'

import { GearIcon } from 'components/Icons'
import Triangle from 'components/Triangle'
import useDropdown from 'hooks/useDropdown'
import { currentTeamIdSelector } from 'selectors/teamsSelector'

import ExportContactsButton from './ExportContactsButton'

const Link = styled(_Link)`
  color: inherit;
  display: block;
  padding: 3px 24px 3px 24px;
  font-weight: normal;
  text-transform: none;
  text-align: inherit;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: #434a54;
    background-color: #f5f5f5;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const PointRow = styled(Row)`
  cursor: pointer;
  justify-content: flex-end;
`

const Gear = styled(GearIcon)`
  font-size: 23px;
`

export const ContactsDropdown = () => {
  const currentTeamUuid = useSelector(currentTeamIdSelector)

  const {
    toggleDropdown,
    isOpen: isDropdownOpen,
    Dropdown: _Dropdown,
  } = useDropdown()

  const Dropdown = styled(_Dropdown)`
    padding-left: 0;
    padding-right: 0;
  `

  return (
    <>
      <PointRow onClick={toggleDropdown}>
        <Gear />
        {isDropdownOpen ? <Triangle up /> : <Triangle down />}
        {isDropdownOpen && (
          <Dropdown>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link as={ExportContactsButton}>Export Contacts</Link>
            <Link to={`/contacts/${currentTeamUuid}/import`}>
              Import Contacts
            </Link>
            {/* <Link to={`/contacts/${currentTeamUuid}/microsoft`}>
              Exchange Sync
            </Link> */}
          </Dropdown>
        )}
      </PointRow>
    </>
  )
}
