import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Button from './Button'

const StrippedLink = ({ primary, red, secondary, link, block, ...props }) => (
  <Link {...props} />
)

const ButtonAsLink = styled(Button).attrs({
  as: StrippedLink,
})``

export default ButtonAsLink
