import styled from 'styled-components'

import useOrganizations from 'hooks/useOrganizations'
import useTeams from 'hooks/useTeams'

const NoEntity = ({ org }) => {
  const { currentOrganizationUuid, organizationUuids } = useOrganizations()
  const { currentTeamUuid, teamUuids } = useTeams()
  const entities = org ? organizationUuids : teamUuids
  const currentEntity = org ? currentOrganizationUuid : currentTeamUuid
  const noEntities = entities.isEmpty()

  return (
    <Card>
      <Text>
        It appears you are not currently assigned to{' '}
        {noEntities
          ? org
            ? 'an organization'
            : 'a team'
          : `this ${org ? 'organization' : 'team'}`}
        . Please contact your administrator.
      </Text>
    </Card>
  )
}

const Card = (styled.div || styled.View)`
  padding: 48px;
`

const Text = (styled.h1 || styled.Text)`
  text-align: center;
  margin: 0;
  color: ${props => props.theme.colors.gray};
  font-size: 18px;
  font-weight: normal;
`

export default NoEntity
