import styled from 'styled-components'
import Button from './Button'

export default styled(Button).attrs({
  link: true,
})`
  color: ${({ theme }) => theme.colors.blue}!important;
  font-size: 12px !important;
  font-weight: bold !important;
  letter-spacing: 0.23px !important;
  line-height: 24px !important;
  padding: 0 !important;
`
