import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import {
  func, bool,
} from 'prop-types'
import { email } from 'utility/validation';

import { actions as organizationsActions } from 'modules/organizations'
import { actions as teamsActions } from 'modules/teams'

import {
  getSearchOrganizationMemberUuids,
  getSearchOrganizationMembersError,
  getSearchOrganizationMembersLoading,
} from 'selectors/organizationsSelector'

import {
  getSearchTeamMemberUuids,
  getSearchTeamMembersError,
  getSearchTeamMembersLoading,
} from 'selectors/teamsSelector'

import Spinner from 'components/Spinner'
import { Option, DropdownList } from 'components/Dropdown'
import AddIconButton from 'components/Buttons/AddIconButton'
import Member from 'components/Member'

import NoResultsMessage from './NoResultsMessage'

const Input = (styled.input || styled.TextInput)`
  width: 100%;
  padding: 10px;
  border: 1px solid ${props => props.theme.colors.lightGray};
  margin-bottom: 8px;
`

const MemberListStatic = (styled.div || styled.View)`
  margin-top: 20px;
`

const AddMemberButton = styled(AddIconButton).attrs(() => ({
  children: 'Add',
}))`
  position: absolute;
  top: 6px;
  right: 10px;
  height: 29px;
  padding: 4px 10px!important;
  border-radius: 3px;
  background-color: ${props => props.theme.forms.inputInnerButtonBgColor};
  i {
    font-size: 14px!important;
  }
`

const MemberOption = styled(({
  uuid, handleSelect, className,
}) => (
  <Option
    key={uuid}
    id={uuid}
    onSelect={() => handleSelect(uuid)}
    className={className}
  >
    <Member uuid={uuid} />
  </Option>
))`
  + a {
    margin-top: 16px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #f5f5f5;
  }
`

const MemberSearch = ({
  handleSelect,
  handleAddClick,
  dropdown,
  includeAddOption,
  isTeam = false,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [emailValue, setEmailValue] = useState(false)

  const dispatch = useDispatch()

  const { searchMembers, clearMembers } = {
    team: {
      searchMembers: _keyword => dispatch(teamsActions.searchTeamMembers(_keyword)),
      clearMembers: () => dispatch(teamsActions.searchTeamMembersClear()),
    },
    organization: {
      searchMembers: _keyword => dispatch(organizationsActions.searchOrganizationMembers(_keyword)),
      clearMembers: () => dispatch(organizationsActions.searchOrganizationMembersClear()),
    },
  }[isTeam ? 'team' : 'organization']

  const input = useRef(null)

  const {
    loading,
    error,
    membersList,
  } = {
    team: {
      loading: useSelector(getSearchTeamMembersLoading),
      error: useSelector(getSearchTeamMembersError),
      membersList: useSelector(getSearchTeamMemberUuids),
    },
    organization: {
      loading: useSelector(getSearchOrganizationMembersLoading),
      error: useSelector(getSearchOrganizationMembersError),
      membersList: useSelector(getSearchOrganizationMemberUuids),
    },
  }[isTeam ? 'team' : 'organization']

  const _handleSelect = uuid => {
    clearMembers()
    setKeyword('')
    handleSelect(uuid)
  }

  const _handleAddClick = () => {
    handleAddClick(keyword)
    setOpen(false)
    setKeyword('')
    setEmailValue(false)
  }

  const closeDropdown = target => {
    if (target !== input.current) {
      setOpen(false)
    }
  }

  const toggleOpen = () => {
    setOpen(keyword.length > 2)
  }

  const onFocus = () => {
    toggleOpen()
  }

  const typeAheadCallBack = _.debounce(() => {
    setEmailValue(keyword !== '' && !email(keyword))

    if (keyword.length > 2) {
      setOpen(true)
      searchMembers(keyword)
    } else {
      setOpen(false)
    }
  }, 500)

  useEffect(() => {
    typeAheadCallBack()
  }, [keyword])

  const MemberList = dropdown ? DropdownList : MemberListStatic

  return (
    <div {...props}>
      <Input
        ref={input}
        type='text'
        value={keyword}
        onChange={({ target: { value } }) => {
          setKeyword(value)
        }}
        onFocus={onFocus}
        placeholder='Enter email to add team member'
      />

      {includeAddOption && emailValue && <AddMemberButton onClick={_handleAddClick} />}

      {open
        && (
        <MemberList closeDropdown={dropdown ? closeDropdown : null}>
          {!loading
            && !error
            && membersList.map(uuid => (
              <MemberOption
                key={uuid}
                uuid={uuid}
                handleSelect={_handleSelect}
              />
            ))
          }

          {!loading
            && !error
            && membersList.count() === 0
            && <NoResultsMessage>No results found</NoResultsMessage>
          }

          {loading && <Spinner />}

          {!!error && <div>{error}</div>}
        </MemberList>
        )
      }
    </div>
  )
}

MemberSearch.propTypes = {
  handleSelect: func,
  handleAddClick: func,
  dropdown: bool,
  includeAddOption: bool,
}

MemberSearch.defaultProps = {
  handleSelect: () => {},
  handleAddClick: () => {},
  dropdown: false,
  includeAddOption: false,
}

export default styled(MemberSearch)`
  position: relative;
  ${NoResultsMessage} {
    margin: 0 22px;
  }
`
