import React, { createRef } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { List } from 'immutable'

import {
  getSearchContactsUuids,
  getSearchContactsLoading,
  getSearchContactsError,
} from 'selectors/contactsSelector'

import _ContactName from 'components/ContactName'
import Spinner from 'components/Spinner'
import _AddContactButton from 'components/AddContactButton'
import { ContactModalToggle } from 'components/Modals/Contact'
import _FirstEmail from 'components/FirstEmail'
import { FormError } from 'components/Form'

import _ContactListItem from './ContactListItem'
import DetailsButton from './DetailsButton'
import _FirstPhoneNumber from './FirstPhoneNumber'

const AddContactButton = styled(_AddContactButton)`
  margin: 0 20px;
  border-top: 1px solid #E6E6E6;
  padding-top: 10px!important;
`

const ContactListItem = styled(_ContactListItem)`
  padding: 0 20px;
  cursor: pointer;
`

const ContactName = styled(_ContactName)`
  flex: 1;
  min-width: 0;
  margin-right: 15px;
`

const ContactDetailsColumn = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`

const FirstPhoneNumber = styled(_FirstPhoneNumber)`
  flex: 0 0 130px;
  line-height: 20px;
  font-size: 12px;
`

const FirstEmail = styled(_FirstEmail)`
  flex: 0 0 130px;
  font-size: 12px;
  line-height: 20px;
`

const EditButtonHolder = styled.div`
  margin-left: 15px;
`

const NoResultsMessage = (styled.span || styled.Text)`
  display: block;
  font-style: italic;
  padding: 0 20px;
`

const Container = (styled.div || styled.View)`
  max-height: 200px;
  overflow: auto;
`

const ContactsContainer = styled.div`
  height: 100%;
  overflow: auto;
`

const ContactsSearchList = styled(
  ({
    handleSelect,
    handleFreeClick = () => {},
    showAddContactButton,
    showDetails = false,
    defaultContactUuid = null,
    hasKeyword = false,
    postSave,
    ...props
  }) => {
    const searchContactsLoading = useSelector(getSearchContactsLoading)
    const searchContactsError = useSelector(getSearchContactsError)
    const searchContactsUuids = useSelector(getSearchContactsUuids)

    const emailRefs = searchContactsUuids.map(() => createRef())
    const phoneRefs = searchContactsUuids.map(() => createRef())
    const toggleRefs = searchContactsUuids.map(() => createRef())

    const refClicked = (ref = {}, target) => {
      if (!ref.current) {
        return false
      }

      return ref.current.contains(target)
    }

    const uuids = (!hasKeyword && defaultContactUuid)
      ? List([defaultContactUuid])
      : searchContactsUuids;

    return (
      <Container {...props}>
        <ContactsContainer>
          {searchContactsLoading && <Spinner />}
          {searchContactsError && (
            <FormError>
              {searchContactsError.getIn(['data', 'detail'])}
            </FormError>
          )}
          {!searchContactsLoading &&
            !searchContactsError &&
            uuids.map((contactUuid, index) => (
              <ContactListItem
                key={contactUuid}
                onClick={e => {
                  if (
                    (!showDetails ||
                      (!refClicked(emailRefs.get(index), e.target) &&
                        !refClicked(phoneRefs.get(index), e.target))) &&
                    !refClicked(toggleRefs.get(index), e.target)
                  ) {
                    handleSelect(contactUuid)
                  } else {
                    const modalToggleClicked = refClicked(
                      toggleRefs.get(index),
                      e.target
                    )
                    handleFreeClick(!modalToggleClicked)
                  }
                }}
              >
                <ContactName contactUuid={contactUuid} />

                {!!showDetails && (
                  <ContactDetailsColumn>
                    <FirstPhoneNumber
                      isLink
                      innerRef={phoneRefs.get(index)}
                      contactUuid={contactUuid}
                    />

                    <FirstEmail
                      innerRef={emailRefs.get(index)}
                      contactUuid={contactUuid}
                    />
                  </ContactDetailsColumn>
                )}

                <EditButtonHolder ref={toggleRefs.get(index)}>
                  <ContactModalToggle
                    contactUuid={contactUuid}
                    component={DetailsButton}
                  />
                </EditButtonHolder>
              </ContactListItem>
            ))}

          {!searchContactsLoading &&
            !searchContactsError &&
            uuids.count() === 0 && (
              <NoResultsMessage>No results found</NoResultsMessage>
          )}
        </ContactsContainer>


        {showAddContactButton && (
          <ContactModalToggle
            component={AddContactButton}
            postSave={postSave || handleSelect}
          />
        )}
      </Container>
    )
  }
)`
  display: flex;
  flex-direction: column;
  ${({ showDetails }) =>
    showDetails &&
    css`
      ${ContactListItem} {
        justify-content: flex-start;
        padding-left: 12px;
      }

      ${ContactName} {
        flex: 0 0 150px;
      }
    `}
`

export default ContactsSearchList
