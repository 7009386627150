import React, { useCallback } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import useOrganizationInvites from 'hooks/useOrganizationInvites'
import { keyIn } from 'utility/immutable'
import { onlyShow } from 'utility/styles'

import Button from 'components/Buttons'

const [ACTIVE, DECLINED, REVOKED, ACCEPTED, EXPIRED] = [
  'ACTIVE',
  'DECLINED',
  'REVOKED',
  'ACCEPTED',
  'EXPIRED',
]

const InviteRow = ({ className, email, invites }) => {
  const inviteExpiration = invites
    .map(invite => invite.get('expired_timestamp'))
    .maxBy(timestamp => moment(timestamp).format('X'))

  const statuses = invites.map(invite => invite.get('status'))
  const status = statuses.includes(ACTIVE)
    ? ACTIVE
    : statuses.includes(REVOKED)
    ? REVOKED
    : statuses.includes(DECLINED)
    ? DECLINED
    : statuses.includes(ACCEPTED)
    ? ACCEPTED
    : EXPIRED
  const isActive = status === ACTIVE

  const activeInvites = invites
    .filter(invite => invite.get('status') === ACTIVE)
    .map(invite => invite.filter(keyIn(['uuid', 'organization'])))

  const {
    createOrganizationInvite,
    revokeOrganizationInvite,
  } = useOrganizationInvites()

  const revoke = useCallback(() => {
    activeInvites.forEach(revokeOrganizationInvite)
  }, [activeInvites])

  const resend = useCallback(() => {
    const resendInvite = invites
      .first()
      .filter(keyIn(['uuid', 'organization', 'recipient_email']))
    createOrganizationInvite(resendInvite)
  }, [invites.count()])

  return (
    <Row className={className}>
      <Col left>{email}</Col>

      <Col>
        {status} {!!isActive && 'Expires '}
        {moment(inviteExpiration).fromNow()}
      </Col>

      <Col right>
        <RevokeButton hidden={!isActive} onClick={revoke} />
      </Col>

      <Col right>
        <ResendButton onClick={resend} />
      </Col>
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Col = styled.div`
  ${({ right }) => right && 'margin-left: auto;'}
  ${onlyShow(1).line}
  justify-content: center;
  flex: ${({ right }) => (right ? '0 0 auto' : '1 1 100%')};
  text-align: ${({ right }) => (right ? 'right' : 'left')};
`

const RevokeButton = styled(Button).attrs({
  link: true,
  children: 'Revoke',
})`
  ${({ hidden }) => !!hidden && 'visibility: hidden;'}
`

const ResendButton = styled(Button).attrs({
  link: true,
  children: 'Re-send',
})``

export default InviteRow
