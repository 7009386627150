import _ from 'lodash'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'

import {
  getConversationUuids,
  getConversationsLoading,
  getConversationsHasMore,
} from 'selectors/conversationsSelector'
import { currentTeamIdSelector } from 'selectors/teamsSelector'
import { getConversationFiltersLoaded } from 'selectors/formSelector'
import { actions as conversationsActions } from 'modules/conversations'
import Conversation from './Conversation'
import EndText from './EndText'

const ConversationsList = ({
  limit = 10,
  scrollDisabled = false,
  Component = null,
  printNotes = false,
}) => {
  const dispatch = useDispatch()
  const loading = useRef(false)
  const currentTeamId = useSelector(currentTeamIdSelector)

  const conversationUuids = useSelector(getConversationUuids)
  const conversationsLoading = useSelector(getConversationsLoading)
  const conversationsHasMore = useSelector(getConversationsHasMore)
  const conversationFiltersLoaded = useSelector(getConversationFiltersLoaded)

  const hasMore =
    currentTeamId &&
    conversationsHasMore &&
    !conversationsLoading &&
    !scrollDisabled

  const handleInfiniteLoad = () => {
    if (!loading.current) {
      loading.current = true
      dispatch(
        conversationsActions.queryConversations({
          offset: conversationUuids.size,
          limit,
        })
      )
    }
  }

  useEffect(() => {
    if (loading.current && !conversationsLoading) {
      setTimeout(() => {
        loading.current = false
      }, 1)
    }
  }, [conversationsLoading])

  if (!currentTeamId) return null

  if (!conversationFiltersLoaded) return null

  const ConversationComp = Component || Conversation

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={_.debounce(handleInfiniteLoad, 1)}
      hasMore={hasMore}
    >
      {conversationUuids.map(uuid => (
        <ConversationComp key={uuid} uuid={uuid} printNotes={printNotes} />
      ))}

      {!scrollDisabled && <EndText />}
    </InfiniteScroll>
  )
}

export default ConversationsList
