import { useSelector, useDispatch } from 'react-redux'
import { actions as teamsActions } from 'modules/teams'
import {
  currentTeamIdSelector,
  getQueryAdminTeamUuids,
  getQueryTeamsLoading,
  teamUuidsSelector,
} from 'selectors/teamsSelector'

export default function useTeams() {
  const dispatch = useDispatch()

  const adminTeamUuids = useSelector(getQueryAdminTeamUuids)
  const currentTeamUuid = useSelector(currentTeamIdSelector)
  const teamUuids = useSelector(teamUuidsSelector)
  const teamsLoading = useSelector(getQueryTeamsLoading)

  const currentTeamIsInvalid =
    !!currentTeamUuid && !teamUuids.includes(currentTeamUuid)

  const queryAdminTeams = values => {
    dispatch(teamsActions.queryAdminTeams(values))
  }

  const queryTeams = values => {
    if (!teamsLoading) {
      dispatch(teamsActions.queryTeams(values))
    }
  }

  return {
    adminTeamUuids,
    currentTeamUuid,
    currentTeamIsInvalid,
    teamUuids,
    teamsLoading,
    queryAdminTeams,
    queryTeams,
  }
}
