import styled from 'styled-components'
import { connect } from 'react-redux'
import { string } from 'prop-types'

import { organizationsDataSelector } from 'selectors/organizationsSelector'
import MemberSearch from './MemberSearch'

const mapStateToProps = (state) => {
  const organizationsData = organizationsDataSelector(state)
  return {
    currentOrganizationId: organizationsData.get('currentOrganizationId'),
  }
}

const mapDispatchToProps = {}

const MemberSearchByTeam = ({ currentOrganizationId, ...props }) => (
  currentOrganizationId && <MemberSearch {...props} />
)

MemberSearchByTeam.propTypes = {
  currentOrganizationId: string,
}

MemberSearchByTeam.defaultProps = {
  currentOrganizationId: null,
}

export default styled(connect(mapStateToProps, mapDispatchToProps)(MemberSearchByTeam))``
