import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { withRouter } from 'react-router-dom'
import { fromJS } from 'immutable'

import useTeams from 'hooks/useTeams'
import useOrganizations from 'hooks/useOrganizations'

import {
  currentTeamIdSelector,
  getQueryTeamsLoading,
  teamUuidsSelector,
} from 'selectors/teamsSelector'
import {
  currentOrganizationIdSelector,
  organizationsLoadingSelector,
  organizationUuidsSelector,
} from 'selectors/organizationsSelector'

import { actions as organizationsActions } from 'modules/organizations'
import { actions as teamsActions } from 'modules/teams'

import Page from 'components/Page'
import NoEntity from './NoEntity'

const RedirectPage = withRouter(
  ({ children, className, heading, match, navBar, redirectToOrg = false }) => {
    const dispatch = useDispatch()
    const { currentTeamIsInvalid } = useTeams()
    const { currentOrganizationIsInvalid } = useOrganizations()
    const [mounted, setMounted] = useState(false)
    const { org_id: orgId, team_id: teamId } = useParams()
    const entityId = redirectToOrg ? orgId : teamId
    const entitySelector = redirectToOrg
      ? currentOrganizationIdSelector
      : currentTeamIdSelector
    const entityIdFromStore = useSelector(entitySelector)

    const redirectToEntity = redirectToOrg
      ? organizationsActions.redirectOrganization
      : teamsActions.redirectTeam
    const setCurrentEntity = redirectToOrg
      ? organizationsActions.setCurrentOrganizationId
      : teamsActions.setCurrentTeamId
    const entityUuidsSelector = redirectToOrg
      ? organizationUuidsSelector
      : teamUuidsSelector
    const entityUuids = useSelector(entityUuidsSelector)

    const entityListLoadingSelector = redirectToOrg
      ? organizationsLoadingSelector
      : getQueryTeamsLoading
    const entityListLoading = useSelector(entityListLoadingSelector)

    const entityIdIsInvalid = redirectToOrg
      ? currentOrganizationIsInvalid
      : currentTeamIsInvalid

    useEffect(() => {
      if (!entityId) {
        new Promise((resolve, reject) => {
          dispatch(redirectToEntity(fromJS({ match, resolve, reject })))
        }).then(() => {
          setMounted(true)
        })
      } else {
        if (entityId !== entityIdFromStore) {
          dispatch(setCurrentEntity(entityId))
        }
        setMounted(true)
      }
    }, [entityId])

    useEffect(() => {
      if (mounted) {
        if (!entityId && entityUuids.size > 0) {
          dispatch(redirectToEntity(fromJS({ match })))
        }
      }
    }, [entityUuids.size])

    return (
      <Page className={className} heading={heading} navBar={navBar}>
        {entityIdIsInvalid ? (
          <NoEntity org={redirectToOrg} />
        ) : mounted && !entityListLoading && entityIdFromStore ? (
          children
        ) : mounted && !entityListLoading && entityUuids.isEmpty() ? (
          <NoEntity org={redirectToOrg} />
        ) : null}
      </Page>
    )
  }
)

export default RedirectPage
