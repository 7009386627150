import styled from 'styled-components'
import { connect } from 'react-redux'
import { bool, string } from 'prop-types'

import {
  getFullNameFromContactUuid,
  getBussinessNameFromContactUuid,
} from 'selectors/contactsSelector'
import Theme from 'components/Theme'

const Container = (styled.div || styled.View)``

const FullName = (styled.span || styled.Text)`
  color: ${Theme.fullNameColor};
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: ${24 / 18};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const BusinessName = (styled.span || styled.Text)`
  color: ${Theme.businessNameColor};
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: ${18 / 12};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const mapStateToProps = (state, ownProps) => {
  const { contactUuid: uuid } = ownProps

  const fullName = getFullNameFromContactUuid(state, { uuid })
  const businessName = getBussinessNameFromContactUuid(state, { uuid })

  return {
    fullName,
    businessName,
  }
}

const mapDispatchToProps = {}

const ContactName = ({
  fullName,
  businessName,
  contactUuid,
  omitBusiness,
  ...props
}) => (
  <Container {...props}>
    <FullName>{fullName}</FullName>
    {!omitBusiness && <BusinessName>{businessName}</BusinessName>}
  </Container>
)

ContactName.propTypes = {
  fullName: string,
  businessName: string,
  contactUuid: string.isRequired,
  omitBusiness: bool,
}

ContactName.defaultProps = {
  fullName: null,
  businessName: null,
  omitBusiness: false,
}

export default styled(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContactName)
)`
  max-width: 100%;
  cursor: pointer;
`
