import React from 'react'
import styled, { css } from 'styled-components'

const Base = styled.div`
  width: 0px;
  height: 0px;
  ${({
    l,
    r,
    b,
    t,
  }) => css`
    border-width: 5px;
    border-left-color: ${l};
    border-right-color: ${r};
    border-bottom-color: ${b};
    border-top-color: ${t};
  `}
`

export const Triangle = ({
  up, down, right, left, color = 'black', ...props
}) => {
  let l = 'transparent'
  let r = 'transparent'
  let b = color
  let t = 'transparent'
  if (down) {
    t = color
    b = 'transparent'
  } else if (right) {
    b = 'transparent'
    l = color
  } else if (left) {
    b = 'transparent'
    r = color
  }
  return <Base l={l} r={r} t={t} b={b} {...props} />
}

export default Triangle
