import styled from 'styled-components'
import { Form as ReactFinalForm } from 'react-final-form'
import { fromJS } from 'immutable'

import { FloatingLabelFinalField } from 'components/FloatingLabelInput'
import Spinner from 'components/Spinner'
import { FormRow, Form as _Form, FormError } from 'components/Form'
import useAuth from 'hooks/useAuth'

import validate from './RegisterFullFormValidation'

import Title from '../Title'
import Link from '../Link'
import Button from '../Button'

const InlineLink = styled(Link)`
  display: inline;
`

const Form = styled(_Form)`
`

const RegisterFullForm = ({
  error,
  handleSubmit,
  invalid,
  links,
  pristine,
  submitting,
  title,
  token,
}) => {
  const { registeringFull, registerFullError } = useAuth()
  const registerFullErrorText = registerFullError
    ? registerFullError.getIn(['data', 'detail']) || 'There was an error registering your account.'
    : null

  return (
    <Form
      onSubmit={handleSubmit}
    >
      {title && <Title>{title}</Title>}

      <FormRow>
        <FloatingLabelFinalField
          id='email'
          disabled
          value={token.email}
          name='email'
          type='text'
          placeholder='Email'
        />
      </FormRow>

      <FormRow>
        <FloatingLabelFinalField
          id='password'
          name='password'
          type='password'
          placeholder='Create password'
        />
      </FormRow>

      <FormRow>
        <FloatingLabelFinalField
          id='password_confirm'
          name='password_confirm'
          type='password'
          placeholder='Confirm password'
        />
      </FormRow>

      {!!registerFullErrorText && (
        <FormError>{registerFullErrorText}</FormError>
      )}

      {!!error && !pristine && (
        <FormError>{error}</FormError>
      )}

      <FormRow half>
        <Button
          primary
          disabled={submitting || invalid || pristine}
          type='submit'
        >
          Start
        </Button>
      </FormRow>

      {registeringFull && <Spinner />}

      {links && links.length > 0 && links.map((link, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <Link key={key} to={link.path}>
          {link.label}
        </Link>
      ))}
    </Form>
  )
}

const WrappedRegisterFullForm = props => {
  const { registerFull, registration: registerToken } = useAuth()

  if (!registerToken.isValid || registerToken.isExpired) {
    const message = registerToken.isValid
      ? 'is invalid'
      : 'has expired'
    return (
      <>
        <h2>
          Sorry, this activation token&nbsp;
          {message}
          .
        </h2>
        <p>
          Please&nbsp;
          <InlineLink to='/signup'>re-register</InlineLink>
          .
        </p>
      </>
    )
  }

  const initialValues = {
    email: registerToken.email,
    password: null,
    password_confirm: null,
    entitlement_token: registerToken.token,
  }

  return (
    <ReactFinalForm
      initialValues={initialValues}
      onSubmit={values => {
        registerFull(fromJS(values))
      }}
      validate={validate}
    >
      {_props => (
        <RegisterFullForm
          token={registerToken}
          {...props}
          {..._props}
        />
      )}

    </ReactFinalForm>
  )
}

export default WrappedRegisterFullForm
