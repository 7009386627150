import styled, { css } from 'styled-components'
import _ from 'lodash'
import { any, array, bool, func, string, oneOfType } from 'prop-types'
import cn from 'classnames'

const Container = (styled.div || styled.View)``
const Input = (styled.input || styled.TextInput)``
const Label = (styled.label || styled.Text)``
const Span = (styled.span || styled.Text)``

const FloatingLabelInput = ({
  autoComplete,
  className,
  containerClassName,
  errorMsg,
  hideErrorText,
  id,
  innerRef,
  inputClassName,
  isDisabled,
  noFloat,
  onChange,
  pattern,
  placeholder,
  touched,
  type,
  value,
  ...props
}) => {
  const hasValue = !!value
  const hasValueOrTouched = hasValue || touched

  const inputClasses = cn(
    'FLInput',
    inputClassName,
    { FLValid: hasValueOrTouched && !errorMsg },
    { FLInvalid: hasValueOrTouched && errorMsg }
  )

  const errMsgClasses = cn(
    { FLErrorMsg: errorMsg },
    { FLErrorShow: errorMsg && hasValueOrTouched }
  )

  return (
    <Container className={cn(className, containerClassName)}>
      <Input
        autoComplete={(!!autoComplete).toString()}
        className={inputClasses}
        disabled={isDisabled}
        id={id}
        onChange={event => _.attempt(onChange, event)}
        type={type}
        value={value || ''}
        placeholder={noFloat ? placeholder : ''}
        ref={innerRef}
        {...props}
      />

      {!noFloat && (
        <Label className="FLInputLabel" htmlFor={id}>
          {placeholder}
        </Label>
      )}

      <Span className="FLInputBar" />

      {!hideErrorText && !!errorMsg && (
        <Span className={errMsgClasses}>
          {errorMsg.map
            ? errorMsg.map((message, index) => (
                <Span
                  key={`_${Math.random()
                    .toString(36)
                    .substr(2, 9)}`}
                >
                  {message}
                </Span>
              ))
            : errorMsg}
        </Span>
      )}
    </Container>
  )
}

FloatingLabelInput.propTypes = {
  placeholder: string.isRequired,
  autoComplete: bool,
  className: any,
  containerClassName: any,
  errorMsg: oneOfType([array, string]),
  id: string.isRequired,
  inputClassName: any,
  isDisabled: bool,
  onChange: func,
  pattern: any,
  touched: bool,
  type: string,
  value: string,
}

FloatingLabelInput.defaultProps = {
  autoComplete: false,
  className: null,
  containerClassName: null,
  errorMsg: null,
  inputClassName: null,
  isDisabled: false,
  onChange: () => {},
  pattern: null,
  touched: false,
  type: 'text',
  value: null,
}

export default styled(FloatingLabelInput)`
  display: flex;
  flex-direction: column;
  ${({ hideErrorText }) =>
    !hideErrorText
      ? css`
          padding-bottom: 20px;
        `
      : ''}
  position: relative;

  input:not(:focus):not(.FLValid):not(.FLInvalid) {
    color: transparent;
  }

  input,
  label,
  .FLErrorMsg {
    -webkit-font-smoothing: antialiased;
    text-shadow: none;
  }

  input {
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    border-radius: 0;
    display: flex;
    font-size: 100%;
    line-height: 25px;
  }

  .FLInputLabel {
    color: #b2b2b2;
    font-weight: normal;
    opacity: 0.75;
    order: 1;
    padding-left: 2px;
    pointer-events: none;
    text-transform: uppercase;
    transform-origin: left top;
    transform: scale(1) translate3d(0, 22px, 0);
    transition: 200ms ease all;
  }

  input:focus + label,
  input.FLValid + label,
  .FLInvalid + label {
    color: #25263d;
    opacity: 1;
    transform: scale(0.8) translate3d(0, 5px, 0);
  }

  .FLInput:active,
  .FLInput:focus,
  .FLInputLabel {
    outline: 0;
  }

  .FLInput {
    border: 0;
    border-bottom: 1px solid #d8d8d8;
    color: #000;
    flex: 1 1 auto;
    order: 2;
  }

  .FLInputBar {
    display: block;
    order: 3;
    top: 0;
  }

  .FLInputBar::after,
  .FLInputBar::before {
    background-color: #25263d;
    bottom: 0;
    content: '';
    height: 2px;
    position: absolute;
    transition: 200ms ease all;
    width: 0;
  }

  .FLInputBar::before {
    left: 50%;
  }

  .FLInputBar::after {
    right: 50%;
  }

  .FLInput:focus ~ .FLInputBar::after,
  .FLInput:focus ~ .FLInputBar::before,
  .FLInvalid ~ .FLInputBar::after,
  .FLInvalid ~ .FLInputBar::before {
    width: 50%;
  }

  .FLInputBar,
  .FLErrorMsg {
    position: relative;
    width: inherit;
  }

  .FLErrorMsg {
    bottom: 0;
    display: none;
    font-size: 13px;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90%;
    word-break: break-all;
    word-wrap: break-word;
  }

  .FLErrorShow {
    display: inline-block;
  }

  .FLInvalid ~ .FLInputBar::after,
  .FLInvalid ~ .FLInputBar::before {
    background: #d9534f;
  }

  .FLInput.FLInvalid + label,
  .FLErrorMsg {
    color: #d9534f;
  }
`
