import styled from 'styled-components'
import moment from 'moment'

import useOrganizationInvoices from 'hooks/useOrganizationInvoices'

const HistoryRow = ({ uuid }) => {
  const { organizationInvoiceByUuid } = useOrganizationInvoices(uuid)

  const {
    created,
    amount_due,
    stripe_hosted_invoice_page_url,
  } = organizationInvoiceByUuid.toJS()

  return (
    <Row>
      <Col>{!!created && moment(created).format('MMM D, YYYY')}</Col>
      <Col>{amount_due}</Col>
      <Col right>
        <a
          href={stripe_hosted_invoice_page_url}
          rel="noopener noreferrer"
          target="_blank"
        >
          Receipt
        </a>
      </Col>
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  padding: 16px 18px;
  border-bottom: 1px solid #e3e3e3;
`

const Col = styled.div`
  flex: 1 1 100%;
  ${({ right }) => right && 'text-align: right;'}
`

export default HistoryRow
