import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { DumbTabs } from 'components/Tabs'
import { currentTeamSelector } from 'selectors/teamsSelector'

import BackLink from '../components/BackLink'
import Form from './Form'
import ExportHelpContent from './ExportHelpContent'

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  margin-bottom: 5px;
  font-size: 24px;
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 22px;
`

const Header = styled.div`
  margin-top: 16px;
  margin-bottom: 12px;
`

const Graf = styled.p`
  margin-bottom: 24px;
`

const ContactsPage = () => {
  const team = useSelector(currentTeamSelector)

  if (!team) return null

  return (
    <>
      <Header>
        <Col>
          <BackLink>
            &lt; Contacts
          </BackLink>

          <Title>{`Import Contacts For ${team.get('name')}`}</Title>
        </Col>
      </Header>

      <Graf>
        {
          'You can easily import your contacts into PhoneSlate from Outlook, Gmail, \
          Address Book, and many other systems. Your PhoneSlate contacts are stored in a \
          secure cloud server. We will never sell or share your data in any way.'
        }
      </Graf>

      <DumbTabs
        tabs={ExportHelpContent.map(({ name, Content }) => {
          const render = () => (
            <>
              <Content />

              <Form />
            </>
          )

          return { name, render }
        })}
        bordered
      />
    </>
  )
}

export default ContactsPage
