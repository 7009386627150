import _ from 'lodash'
import { string, object } from 'prop-types'
import { Field } from 'redux-form/immutable'
import FloatingLabelInput from './FloatingLabelInput'

export const Input = ({
  input,
  meta: { touched, error, submitError },
  ...props
}) => {
  const errors = _.compact(_.flatten([error, submitError]))

  return (
    <FloatingLabelInput
      className="field-input"
      errorMsg={(!!touched && !_.isEmpty(errors) && errors) || null}
      touched={touched}
      {...input}
      {...props}
    />
  )
}

Input.propTypes = {
  input: object.isRequired,
  meta: object.isRequired,
  type: string.isRequired,
}

Input.defaultProps = {}

const FloatingLabelField = props => <Field {...props} component={Input} />

export default FloatingLabelField
