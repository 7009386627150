import React, { useState } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { fromJS } from 'immutable'
import { FORM_ERROR } from 'final-form'

import useOrganizations from 'hooks/useOrganizations'

import { Form as _Form, FormError } from 'components/Form'
import Button from 'components/Buttons'
import Spinner from 'components/Spinner'

const CreditCardForm = ({ onSuccess, onFail }) => {
  const stripe = useStripe()
  const elements = useElements()
  const {
    activateCurrentOrganizationSubscription,
    createOrganizationPaymentMethod,
    currentOrganizationPaymentMethod,
    currentOrganizationStripeCancelAtPeriodEnd,
    currentOrganizationStripeSubscriptionStatus,
    currentOrganizationUuid,
  } = useOrganizations()

  const [cardError, setCardError] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault()
    setSubmitting(true)
    setCardError(null)

    const cardElement = elements.getElement(CardElement)
    const { token, error } = await stripe.createToken(cardElement)

    if (token) {
      return new Promise((resolve, reject) => {
        createOrganizationPaymentMethod(
          fromJS({
            token: token.id,
            organization: currentOrganizationUuid,
            resolve,
            reject,
          })
        )
      })
        .then(
          () =>
            new Promise((resolve, reject) => {
              activateCurrentOrganizationSubscription(
                fromJS({ resolve, reject })
              )
            })
        )
        .catch(_error => {
          setCardError(
            _.get(_error, FORM_ERROR) ||
              'There was an error activating this subscription.'
          )
        })
        .finally(() => {
          setEditMode(false)
          setSubmitting(false)
        })
    }

    if (error) {
      setCardError(error.message)
      setSubmitting(false)
    }
  }

  if (currentOrganizationPaymentMethod && !editMode) {
    return (
      <>
        <Graf>
          Card on file: XXXX-XXXX-XXXX-{currentOrganizationPaymentMethod}
        </Graf>

        {!!cardError && <FormError>{cardError}</FormError>}

        <ButtonRow>
          <RedButton onClick={() => setEditMode(true)}>
            Update
            {!!currentOrganizationStripeCancelAtPeriodEnd &&
              ' & Activate Subscription'}
          </RedButton>
        </ButtonRow>
      </>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <CardElementContainer>
        {!stripe && <Spinner />}
        <CardElement />
      </CardElementContainer>

      {!!cardError && <FormError>{cardError}</FormError>}

      <ButtonRow>
        {!!currentOrganizationPaymentMethod && (
          <GrayButton onClick={() => setEditMode(false)}>Cancel</GrayButton>
        )}
        <RedButton
          type="submit"
          disabled={!stripe || submitting}
          loading={submitting}
          loadingText="Saving..."
        >
          Save
        </RedButton>
      </ButtonRow>
    </Form>
  )
}

const CardElementContainer = styled.div`
  max-width: 400px;
  margin: 13px 0 16px;
`

const Form = styled(_Form)``

const Graf = styled.p`
  margin-bottom: 36px;
`

const ButtonRow = styled.div`
  display: flex;
  margin-bottom: 54px;
`

const RedButton = styled(Button).attrs({
  red: true,
})`
  min-width: 148px;
`

const GrayButton = styled(RedButton).attrs({
  secondary: true,
})`
  margin-right: 16px;
`

export default CreditCardForm
