import React, { useEffect, useState } from 'react'
import { fromJS } from 'immutable'

import useOrganizations from 'hooks/useOrganizations'
import useOrganizationMembers from 'hooks/useOrganizationMembers'
import useTeams from 'hooks/useTeams'

import _Member from 'components/Member'
import Spinner from 'components/Spinner'

import EditButton, { IconButton } from './EditButton'

const UsersList = () => {
  const [loaded, setLoaded] = useState(false)
  const { currentOrganizationUuid } = useOrganizations()
  const { currentOrganizationMemberUuids } = useOrganizationMembers()
  const { queryAdminTeams } = useTeams()

  useEffect(() => {
    new Promise((resolve, reject) => {
      queryAdminTeams(
        fromJS({ organization: currentOrganizationUuid, resolve, reject })
      )
    }).then(() => {
      setLoaded(true)
    })
  }, [])

  if (!loaded) return <Spinner />

  return currentOrganizationMemberUuids.map(memberUuid => (
    <UserRow key={memberUuid} memberUuid={memberUuid} />
  ))
}

const UserRow = ({ memberUuid }) => {
  return (
    <Row>
      <Member uuid={memberUuid} />

      <EditButton memberUuid={memberUuid} />
    </Row>
  )
}

const Member = styled(_Member)`
  flex: 1 1 100%;
  min-width: 0;
`

const Row = styled.div`
  border-top: 1px solid #e3e3e3;
  padding: 15px 5px;
  display: flex;

  ${IconButton} {
    visibility: hidden;
  }

  &:hover {
    ${IconButton} {
      visibility: visible;
    }
  }
`

export default UsersList
