import { ToastContainer } from 'react-toastify'
import {} from 'react-toastify/dist/ReactToastify.css'

import { CloseIcon } from 'components/Icons'

const ToastCloseIcon = ({ closeToast, ...props }) => (
  <CloseIcon
    onClick={closeToast}
    {...props}
  />
)

export const WrappedToastContainer = ({ className, ...rest }) => (
  <div className={className}>
    <ToastContainer {...rest} />
  </div>
)

export default styled(WrappedToastContainer).attrs({
  autoClose: 5000,
  closeButton: <ToastCloseIcon />,
  hideProgressBar: true,
})`
  .Toastify__toast {
    border-radius: 5px;
    background-color: #25263D;
    opacity: 0.8;
    color: #fff;
    font-size: 12px;
    line-height: 15px;
    padding: 15px 18px;

    &:hover {
      opacity: 1;
    }
  }

  .Toastify__toast-container--top-right {
    top: 64px;
  }
  .Toastify__toast-container {}
  .Toastify__toast--error {}
  .Toastify__toast--warning {}
  .Toastify__toast--success {}
  .Toastify__toast-body {}
  .Toastify__progress-bar {}
`
