import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { fromJS } from 'immutable'

import { actions as conversationsActions } from 'modules/conversations'

import {
  getLastCallTimeFromUuid,
  getLastCallUuidFromUuid,
} from 'selectors/conversationsSelector'

import _TimePicker from 'components/TimePicker'

const DATE_FORMAT = moment.HTML5_FMT.TIME

const TimePicker = ({ conversationUuid, ...props }) => {
  const dispatch = useDispatch()
  const timer = useRef(null)

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  const callTime = useSelector(state =>
    getLastCallTimeFromUuid(state, { uuid: conversationUuid })
  )

  const callUuid = useSelector(state =>
    getLastCallUuidFromUuid(state, { uuid: conversationUuid })
  )

  const [value, setValue] = useState(moment(callTime).format(DATE_FORMAT))

  const onChange = newTime => {
    setValue(newTime)

    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      dispatch(
        conversationsActions.updateCall(
          fromJS({
            conversationUuid,
            time: moment(newTime, DATE_FORMAT).clone(),
            uuid: callUuid,
          })
        )
      )
    }, 2000)
  }

  return <StyledTimePicker value={value} onChange={onChange} {...props} />
}

const StyledTimePicker = styled(_TimePicker)`
  border: 1px solid transparent;
  background-color: transparent;
  outline: none !important;
  appearance: none;
  display: block;
  background-image: none;
  border-radius: 3px;
  padding: 4px;

  &:focus,
  &:hover {
    border-color: #cbcbcb;
    background-color: #fff;
  }
`

export default TimePicker
