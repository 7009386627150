import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import {
  getCurrentOrganizationMember,
  adminOrgsSelector,
} from 'selectors/organizationsSelector'

import NavBar from 'components/NavBar/OrgPageNavBar'
import RedirectToOrgPage from 'components/Page/RedirectToOrgPage'
import { media } from 'utility/styles'

import OrganizationAdminSettingsPage from './OrganizationAdminSettingsPage'
import TeamAdminSettingsPage from './TeamAdminSettingsPage'

const Col = styled.div`
  ${props =>
    props.hideOnMobile &&
    media.phone`
    display: none;
  `}
  display: flex;
  flex-direction: column;
`
const Title = styled.h1`
  text-transform: uppercase;
  font-size: 26px;
`

const Header = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  justify-content: space-between;
  ${media.phone`
    margin: 0px;
    padding: 12px;
    background: rgb(244, 244, 244);
  `}
`

const SettingsPage = () => {
  const currentOrganizationMember = useSelector(getCurrentOrganizationMember)
  const isOrgAdmin = useSelector(adminOrgsSelector)

  return (
    <RedirectToOrgPage navBar={NavBar}>
      <Header>
        <Col hideOnMobile>
          <Title>Settings</Title>
        </Col>
      </Header>

      {currentOrganizationMember &&
        (isOrgAdmin ? (
          <OrganizationAdminSettingsPage />
        ) : (
          <TeamAdminSettingsPage />
        ))}
    </RedirectToOrgPage>
  )
}

export default SettingsPage
