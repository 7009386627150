import styled from 'styled-components'

const Collaboration = () => (
  <div>
    <h1>Collaboration with PhoneSlate</h1>
    <p>PhoneSlate lets you log and manage calls as a team, on any device.</p>

    <h2>Fits Any Team</h2>
    <p>
      Every organization is different. That’s why we designed PhoneSlate’s team
      collaboration features to be as flexible as possible. Whether you’re
      working on your own or as part of a large team, PhoneSlate can make sure
      your business never misses an important call.
    </p>

    <h2>Your Team</h2>
    <p>
      Your organization can have as many teams as you’d like, and users can be
      on multiple teams.
    </p>

    <h2>Your Call Sheet</h2>
    <p>
      In PhoneSlate, each user has their own call sheet. This is where you log
      calls that you make or need to take action on. Your teammates can view and
      modify your call sheet, and vice versa.
    </p>

    <h2>Managing Calls for Teammates</h2>
    <p>
      If you’re on a coworker’s PhoneSlate team, you can access their contacts,
      add calls to their call sheet, and edit or update their calls.
    </p>

    <h2>Producing Summaries and Printouts</h2>
    <p>
      If you have access to multiple call sheets—a receptionist who takes calls
      for several bosses, for example—you can view all the calls for your
      company or filter by teammate. Create summaries and to-do lists for each
      person in your office and share them as an email or a printout.
    </p>

    <h2>Managing Access</h2>
    <p>
      If your team changes, it’s easy for organization admins or team admins to
      add and remove users from both the organization and individual teams.
    </p>
  </div>
)

Collaboration.propTypes = {}

export default styled(Collaboration)`
  max-width: 1200px;
`
