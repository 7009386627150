import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Form as ReactFinalForm, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { fromJS } from 'immutable'

import useAuthInvites from 'hooks/useAuthInvites'
import useOrganizationInvites from 'hooks/useOrganizationInvites'

import { Form, CheckboxField } from 'components/Form'
import Button from 'components/Buttons'

const InviteListForm = ({
  handleSubmit,
  hideCreateButton,
  values,
  ...props
}) => {
  const { push } = useHistory()
  const numberSelected = _.filter(values.invites, { selected: true }).length

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <ThCol collapse>
          <Shim />
        </ThCol>
        <ThCol>Organization</ThCol>
        <ThCol>User</ThCol>
      </Row>

      <FieldArray name="invites">
        {({ fields, ...rest }) =>
          fields.map((name, index) => {
            const invite = fields.value[index]

            return (
              <Row key={invite.uuid}>
                <Col collapse>
                  <Field
                    name={`${name}.selected`}
                    id={`invite-form-${name}-selected`}
                    component={CheckboxField}
                    inputName={`${name}.selected`}
                    labelText=""
                  />
                </Col>

                <Col>{invite.organization_name}</Col>
                <Col>{invite.requestor_email}</Col>
              </Row>
            )
          })
        }
      </FieldArray>

      <ButtonRow>
        {!!numberSelected && (
          <AcceptButton type="submit">
            Accept {numberSelected} Invite{numberSelected > 1 && 's'}
          </AcceptButton>
        )}

        {!hideCreateButton && (
          <NewOrganizationButton
            onClick={() => {
              push('/organizations/create')
            }}
          >
            Create New Organization
          </NewOrganizationButton>
        )}
      </ButtonRow>
    </Form>
  )
}

const WrappedForm = ({ showEmptyListMessage, preventRedirect, ...props }) => {
  const { replace } = useHistory()
  const { acceptOrganizationInvite } = useOrganizationInvites()
  const {
    queryInvitesReceived,
    activeOrganizationInvitesReceived,
  } = useAuthInvites()

  useEffect(() => {
    if (activeOrganizationInvitesReceived.isEmpty()) {
      queryInvitesReceived()
    }
  }, [activeOrganizationInvitesReceived.isEmpty()])

  const onSubmit = values => {
    Promise.all(
      _.filter(values.invites, { selected: true }).map(
        ({ uuid, organization }) =>
          new Promise((resolve, reject) => {
            acceptOrganizationInvite(
              fromJS({ uuid, organization, resolve, reject })
            )
          })
      )
    ).finally(() => {
      if (!preventRedirect) replace('/calls')
    })
  }

  if (activeOrganizationInvitesReceived.isEmpty()) {
    return showEmptyListMessage ? (
      <NoResultsMessage>No invites.</NoResultsMessage>
    ) : null
  }

  const initialValues = {
    invites: activeOrganizationInvitesReceived
      .toList()
      .toJS()
      .map(invite => ({ ...invite, selected: false })),
  }

  return (
    <ReactFinalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
    >
      {_props => <InviteListForm {...props} {..._props} />}
    </ReactFinalForm>
  )
}

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 3px;
  border-bottom: 1px solid #d8d8d8;

  color: #787878;
  font-size: 14px;
`

const Col = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  flex: ${({ collapse }) => (collapse ? '0 0 auto' : '1 1 100%')};
`

const ThCol = styled(Col)`
  text-transform: uppercase;
  color: #9d9d9d;
  font-size: 10px;
  letter-spacing: 0.26px;
`

const Shim = styled.span`
  width: 30px;
  display: inline-block;
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`

const NewOrganizationButton = styled(Button).attrs({
  secondary: true,
})``

const AcceptButton = styled(Button).attrs({
  red: true,
})`
  margin-right: 24px;
`

const NoResultsMessage = styled.span`
  font-style: italic;
`

export default WrappedForm
