import styled from 'styled-components'

export default (styled.h1 || styled.Text)`
  margin-top: 0;
  margin-bottom: 14px;

  letter-spacing: 0.6px;
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.6875;
  color: ${props => props.theme.colors.headerText};
  text-align: center;
`
