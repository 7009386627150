import React, { useState } from 'react'
import styled from 'styled-components'
import { Form as _Form, FormError } from 'components/Form'
import _Button from 'components/Buttons'
import { FloatingLabelFinalField as _FloatingLabelFinalField } from 'components/FloatingLabelInput'
import { PasswordConfirmModal } from 'components/Modal'

import Subhed from 'components/Subhed'

const PersonalInfoForm = ({
  errors,
  form,
  handleSubmit,
  hasValidationErrors,
  invalid,
  pristine,
  submitError,
  submitting,
}) => {
  const [showModal, setShowModal] = useState(false)
  const prePasswordInvalid = !_(errors)
    .omit('password')
    .isEmpty()
  const modalDisabled = submitting || prePasswordInvalid || pristine

  return (
    <Form onSubmit={handleSubmit}>
      <Subhed>Personal Info</Subhed>

      <Row>
        <FloatingLabelFinalField
          id="email"
          name="email"
          type="email"
          placeholder="Email"
          disabled
        />

        <FloatingLabelFinalField
          id="display_name"
          name="display_name"
          type="text"
          placeholder="Display Name"
          right
        />
      </Row>

      <Subhed>Password</Subhed>

      <Row>
        <FloatingLabelFinalField
          id="new_password"
          name="new_password"
          type="password"
          placeholder="Password"
        />

        <FloatingLabelFinalField
          id="new_password_confirm"
          name="new_password_confirm"
          type="password"
          placeholder="Confirm Password"
          right
        />
      </Row>

      <ButtonRow>
        <CancelButton onClick={form.reset} disabled={submitting || pristine} />
        <SaveButton
          disabled={modalDisabled}
          onClick={() => setShowModal(true)}
        />
      </ButtonRow>

      {showModal && (
        <PasswordConfirmModal
          isOpen={showModal}
          invalid={hasValidationErrors}
          handleCancel={() => setShowModal(false)}
          handleConfirm={() =>
            handleSubmit().then(_errors => {
              if (_.isEmpty(_errors)) setShowModal(false)
              return _errors
            })
          }
          submitting={submitting}
          loadingText="Saving..."
          submitError={submitError}
        >
          <Row>
            <FloatingLabelFinalField
              autoFocus
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              hideErrorText
              right
            />
          </Row>

          {submitError && <FormError>{submitError}</FormError>}
        </PasswordConfirmModal>
      )}
    </Form>
  )
}

const Form = styled(_Form)`
  margin-bottom: 50px;
`

const Row = styled.div`
  display: flex;
  max-width: 400px;
`

const ButtonRow = styled(Row)`
  max-width: 310px;
  margin-top: 54px;
`

const FloatingLabelFinalField = styled(_FloatingLabelFinalField)`
  flex: 1 1 100%;
  margin-right: ${({ right }) => (right ? '0' : '30')}px;
`

const Button = styled(_Button)`
  flex: 1 1 100%;
`

const CancelButton = styled(Button).attrs({
  children: 'Cancel',
  secondary: true,
})`
  margin-right: 16px;
`

const SaveButton = styled(Button).attrs({
  red: true,
  children: 'Save',
})``

export default PersonalInfoForm
