import styled from 'styled-components'
import { any, bool, func } from 'prop-types'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'

const PLACEHOLDER = 'Conversation date'

const propTypes = {
  autoFocus: bool,
  handleChange: func,
  value: any,
}

const defaultProps = {
  autoFocus: false,
  handleChange: () => {},
  value: null,
}

class DatePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: props.value,
      focused: props.autoFocus,
    }

    this.onDateChange = this.onDateChange.bind(this)
    this.onFocusChange = this.onFocusChange.bind(this)
  }

  componentDidUpdate({ value: prevValue }) {
    const { value } = this.props
    if (value !== prevValue) {
      this.setState(() => ({ // eslint-disable-line react/no-did-update-set-state
        date: value,
      }));
    }
  }

  onDateChange(date) {
    const { handleChange } = this.props
    this.setState({ date });
    handleChange(date)
  }

  onFocusChange({ focused }) {
    this.setState({ focused })
  }

  render() {
    const { focused, date } = this.state

    const {
      autoFocus, handleChange, openDirection, ...props
    } = this.props

    return (
      <div {...props}>
        <SingleDatePicker
          date={date ? moment(date) : date}
          displayFormat='M/D/YYYY'
          focused={focused}
          onDateChange={this.onDateChange}
          onFocusChange={this.onFocusChange}
          openDirection={openDirection}
          placeholder={PLACEHOLDER}
          enableOutsideDays
          isOutsideRange={() => false}
          noBorder
          small
        />
      </div>
    )
  }
}

DatePicker.propTypes = propTypes
DatePicker.defaultProps = defaultProps

export default styled(DatePicker)``
