import styled from 'styled-components'
import { object, bool, string, any } from 'prop-types'

const DefaultLabel = (styled.label || styled.Text)`
  font-size: 14px;
  letter-spacing: 0.26px;
  line-height: 17px;
  white-space: nowrap;
`

const CheckboxField = ({
  input: { value, onChange },
  input,
  disabled,
  labelText,
  inputName,
  Label,
  reverse,
  className,
}) => (
  <div className={className}>
    <input
      type="checkbox"
      disabled={disabled}
      checked={reverse ? !value : !!value}
      name={inputName}
      onChange={() => {}}
    />
    <Label htmlFor={inputName} onClick={() => onChange(!value)}>
      {labelText}
    </Label>
  </div>
)

CheckboxField.propTypes = {
  input: object.isRequired,
  className: string.isRequired,
  inputName: string.isRequired,
  Label: any,
  labelText: string,
  disabled: bool,
  reverse: bool,
}

CheckboxField.defaultProps = {
  Label: DefaultLabel,
  labelText: null,
  disabled: false,
  reverse: false,
}

export default styled(CheckboxField)`
  display: flex;
  flex-direction: row;
  input {
    display: none;
  }
  label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: normal;
    &:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      min-width: 20px;
      height: 20px;
      background: white;
      border: 1px solid #cccccc;
      pointer-events: none;
    }
    &:hover:before {
    }
    &:focus:before {
    }
    &:after {
      display: none;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-family: 'icons';
      font-smoothing: antialiased;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      line-height: 1;
      text-decoration: inherit;
      text-rendering: optimizeLegibility;
      text-transform: none;
      position: absolute;
      content: '\f102';
      font-size: 16px;
      line-height: 25px;
      top: 0;
      left: 2px;
    }
  }

  input:checked + label:after {
    display: inline-block;
  }

  input:disabled + label {
    color: #b8b8b8;
    cursor: not-allowed;
    pointer-events: none;
  }

  input:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
`
