import styled, { css } from 'styled-components'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { getFullNameFromContact } from 'utility/contacts'

import { getConversationByUuid } from 'selectors/conversationsSelector'
import { getContactWithEmailAddressesAndPhoneNumbersByUuid } from 'selectors/contactsSelector'
import { fetchMemberFromListByUserUuid } from 'selectors/teamsSelector'
import { getStatusByUuid } from 'selectors/statusesSelector'
import { getSelectedOrFirstPhoneNumberFromUuid } from '../../../../redux/selectors/conversationsSelector'

const DATE_FORMAT = 'ddd MMM D'
const TIME_FORMAT = 'h:mm a'

const mapStateToProps = (state, { uuid }) => {
  const conversation = getConversationByUuid(state, { uuid })
  const owner = fetchMemberFromListByUserUuid(state, {
    uuid: conversation.get('owner'),
  })
  const status = getStatusByUuid(state, { uuid: conversation.get('status') })
  const contact = getContactWithEmailAddressesAndPhoneNumbersByUuid(state, {
    uuid: conversation.get('contact'),
  })
  const selectedOrFirstPhoneNumberUuid = getSelectedOrFirstPhoneNumberFromUuid(
    state,
    {
      uuid,
    }
  )

  return {
    conversation,
    status,
    owner,
    contact,
    selectedOrFirstPhoneNumberUuid,
  }
}

const mapDispatchToProps = {}

const PrintConversation = ({
  conversation,
  status,
  owner,
  contact,
  printNotes,
  selectedOrFirstPhoneNumberUuid,
}) => {
  const callMoment = moment(conversation.getIn(['last_call_time']))
  const callDate = callMoment.format(DATE_FORMAT)
  const callTime = callMoment.format(TIME_FORMAT)
  const selectedPhone = contact
    .get('phone_numbers')
    .find(phone => phone.get('uuid') === selectedOrFirstPhoneNumberUuid)

  return (
    <>
      <PrintRow>
        <PrintCell bold width="60px">
          {status && status.get('initialism')}
        </PrintCell>
        <PrintCell width="180px">{owner && owner.get('short_name')}</PrintCell>
        <CallTime>
          <span>{callDate}</span>
          <span>{callTime}</span>
        </CallTime>
        <PrintCell bold width="140px">
          {getFullNameFromContact(contact)}
        </PrintCell>
        <PrintCell width="140px">{contact.get('business_name')}</PrintCell>
        <PrintCell width="140px">
          {selectedPhone &&
            `${selectedPhone.get('phone_type')} ${selectedPhone.get(
              'phone_number'
            )}`}
        </PrintCell>
        <PrintCell flex>{conversation.get('subject')}</PrintCell>
      </PrintRow>
      {printNotes && conversation.get('message') && (
        <NotesRow>
          <PrintCell flex>{conversation.get('message')}</PrintCell>
        </NotesRow>
      )}
    </>
  )
}

PrintConversation.propTypes = {
  conversation: object.isRequired,
  status: object,
  owner: object,
}

PrintConversation.defaultProps = {
  status: null,
  owner: null,
}

const PrintRow = (styled.div || styled.View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 15px;
  border-top: 1px solid black;
  padding: 5px 0;
  > div:first-of-type {
    padding-left: 0;
  }
`

const PrintCell = styled(({ width, bold, right, flex, ...props }) => (
  <div {...props} />
))`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${props => props.width || 'auto'};
  padding-left: 14px;

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
  ${({ right }) =>
    right &&
    css`
      text-align: right;
    `}
  ${({ flex }) =>
    flex &&
    css`
      flex-grow: 1;
      flex-shrink: 1;
    `}
`

const CallTime = styled(PrintCell)`
  flex: 0 0 136px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const NotesRow = styled(PrintRow)`
  margin: 0 15px;
  padding-bottom: 10px;
  border-top: 1px dashed black;
`

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintConversation)
