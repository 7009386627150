import _ from 'lodash'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS } from 'immutable'
import { Form as ReactFinalForm } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import { createOrUpdateContactFromForm } from 'modules/contacts'

import { getContactWithEmailAddressesAndPhoneNumbersByUuid } from 'selectors/contactsSelector'
import { currentTeamIdSelector } from 'selectors/teamsSelector'

import Button from 'components/Buttons'
import DeleteButton from 'components/Buttons/BlankIconButtonDelete'
import { FloatingLabelFinalField } from 'components/FloatingLabelInput'
import CloseButton from 'components/CloseButton'
import { FormRow, Form as _Form, FormError } from 'components/Form'
import { DeleteContactModalToggle } from 'components/Modals'

import { media } from 'utility/styles'
import { keyIn } from 'utility/immutable'

import PhoneNumbersArray from './PhoneNumbersArray'
import EmailsArray from './EmailsArray'
import validate from './ContactFormValidation'

const MobileHeaderContainer = (styled.div || styled.View)`
  display: none;
  ${media.phone`
    background: rgb(28, 28, 46);
    display: flex;
    height: 53px; /* TODO: for some reason there's a 1px line above this... */
    justify-content: space-between;
    color: white;
    align-items: center;
    padding: 12px;
  `}
`

const MobileHeader = ({ title, onClose }) => (
  <MobileHeaderContainer>
    <h3 style={{ margin: 0 }}>{title}</h3>
    <CloseButton handleClick={onClose} />
  </MobileHeaderContainer>
)

const Form = styled(_Form)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 40px;
  max-height: 80vh;
  overflow-y: auto;
  ${media.phone`
    padding: 16px;
    height: calc(100vh - 52px); /* 52px is height of nav */
    max-height: inherit;
  `}
`

const ContactForm = ({
  closeModal,
  contactUuid,
  error,
  form,
  handleSubmit,
  invalid,
  pristine,
  submitError,
  submitting,
}) => {
  const closeModalResetForm = () => {
    closeModal()
    form.reset()
  }

  return (
    <Form
      onSubmit={event => {
        return handleSubmit(event).then(errors => {
          if (_.isEmpty(errors)) {
            form.reset()

            // Completely successful submission
          }
        })
      }}
    >
      <MobileHeader
        title={`${contactUuid ? 'Edit' : 'Add'} Contact`}
        onClose={closeModalResetForm}
      />

      <CloseButton handleClick={closeModalResetForm} />

      <FormRow>
        <FloatingLabelFinalField
          id="first_name"
          name="first_name"
          type="text"
          placeholder="First name"
        />
      </FormRow>

      <FormRow>
        <FloatingLabelFinalField
          id="last_name"
          name="last_name"
          type="text"
          placeholder="Last name"
        />
      </FormRow>

      <FormRow>
        <FloatingLabelFinalField
          id="business_name"
          name="business_name"
          type="text"
          placeholder="Business name"
        />
      </FormRow>

      <PhoneNumbersArray />

      <EmailsArray />

      {!!contactUuid && (
        <DeleteContactModalToggle
          component={DeleteButton}
          contactUuid={contactUuid}
          preDelete={closeModal}
          style={{ margin: '12px 0' }}
        >
          Delete Contact
        </DeleteContactModalToggle>
      )}

      <FormRow half>
        <Button secondary onClick={closeModalResetForm} type="button">
          Cancel
        </Button>

        <Button
          primary
          disabled={submitting || invalid || pristine}
          type="submit"
        >
          Save
        </Button>
      </FormRow>

      {!!submitError && <FormError>{submitError}</FormError>}

      {!!error && !pristine && <FormError>{error}</FormError>}
    </Form>
  )
}

const WrappedContactForm = ({ postSave, ...props }) => {
  const dispatch = useDispatch()

  const { contactUuid, closeModal } = props
  const initialContactValues = useSelector(state =>
    getContactWithEmailAddressesAndPhoneNumbersByUuid(state, {
      uuid: contactUuid,
    })
  )
  const currentTeamUuid = useSelector(currentTeamIdSelector)

  const emptyFormValues = {
    uuid: null,
    first_name: null,
    last_name: null,
    business_name: null,
    team: currentTeamUuid,
    phone_numbers: [],
    email_addresses: [],
  }

  let initialValues = contactUuid
    ? initialContactValues.filter(keyIn(Object.keys(emptyFormValues))).toJS()
    : emptyFormValues

  const onSubmit = (values, { initialize }) => {
    return new Promise((resolve, reject) => {
      let contact = { ...values }

      if (!contact.uuid) {
        contact = _.pickBy(contact)
      }

      dispatch(
        createOrUpdateContactFromForm(
          fromJS({
            contact,
            initial: initialValues,
            resolve,
            reject,
          })
        )
      )
    }).then(
      ({
        contact: { uuid: newContactUuid = null } = {},
        contact = {},
        errors = {},
      }) => {
        if (!_.isEmpty(errors)) {
          return _.mapValues(errors, error => {
            if (!_.isArray(error)) return error

            return _.first(error)
          })
        }

        if (newContactUuid) {
          postSave(newContactUuid)
        }

        initialValues = _.pick(contact, Object.keys(emptyFormValues))
        initialize(initialValues)

        closeModal()

        return errors
      }
    )
  }

  return (
    <ReactFinalForm
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
      validate={validate}
    >
      {_props => <ContactForm {...props} {..._props} />}
    </ReactFinalForm>
  )
}

export default WrappedContactForm
