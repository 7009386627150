import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import styled, { css } from 'styled-components'
import cx from 'classnames'
import { bool, func, any, oneOfType, node } from 'prop-types'

import { _component } from 'utility/proptypes'

import Caret from 'components/Caret'

const Toggle = (styled.a || styled.Button)`
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
`

export const DropdownList = styled(
  ({ children, closeDropdown, right, ...props }) => {
    const dropdownListRef = useRef()

    const handleOutsideClick = event => {
      if (!dropdownListRef.current.contains(event.target)) {
        closeDropdown(event.target)
      }
    }

    useEffect(() => {
      document
        .getElementById('app-container')
        .addEventListener('click', handleOutsideClick, false)
      return () => {
        document
          .getElementById('app-container')
          .removeEventListener('click', handleOutsideClick, false)
      }
    }, [])

    return (
      <div ref={dropdownListRef} {...props}>
        {children}
      </div>
    )
  }
)`
  position: absolute;
  top: 100%;

  ${({ right }) =>
    right &&
    css`
      right: 0;
    `}

  background-color: #fff;
  border-radius: 3px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 14px;
  padding: 20px 0 30px;
  margin-top: -5px;
  width: 285px;
  z-index: 7;

  a {
    color: #434a54;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: #434a54;
    }
  }

  .dropdownItem:hover {
    background-color: #f5f5f5;
  }
`

export const Option = styled(
  ({ active, children, closeDropdown, div, id, onSelect, ...props }) => {
    const onClick = () => {
      if (typeof onSelect === 'function') onSelect(id)
      if (typeof closeDropdown === 'function') closeDropdown()
    }

    const OptionElement = div
      ? _props => <div {..._props} />
      : _props => <a {..._props} /> // eslint-disable-line jsx-a11y/anchor-has-content

    return (
      <OptionElement
        onClick={() => onClick()}
        onKeyPress={console.log}
        {...props}
      >
        {children}
      </OptionElement>
    )
  }
)`
  color: inherit;
  display: block;
  position: relative;
  padding: 3px 42px 3px 24px;
  cursor: pointer;
  ${({ div }) =>
    div &&
    css`
      cursor: default;
    `}
  ${({ active }) =>
    active &&
    css`
      &:before {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-family: 'icons';
        font-smoothing: antialiased;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        line-height: 1;
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        position: absolute;
        content: '\f102';
        font-size: 10px;
        color: #e9573f;
        line-height: 25px;
        top: 0;
        right: 20px;
      }
    `}
  + div {
    margin-top: 16px;
  }
`

export const Title = (styled.h2 || styled.Text)`
  padding: 3px 20px;
  color: #afafaf;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
  text-transform: uppercase;
  font-family: Lato;
`

const DropdownHolder = styled(
  forwardRef(
    (
      {
        children,
        className,
        List = DropdownList,
        noCaret,
        right,
        toggle,
        ...props
      },
      ref
    ) => {
      const [open, setOpen] = useState(false)

      const closeDropdown = () => setOpen(false)

      useImperativeHandle(ref, () => ({
        closeDropdown,
      }))

      return (
        <>
          <Toggle
            onClick={() => {
              setOpen(prevValue => !prevValue)
            }}
            {...props}
            className="toggle"
          >
            {toggle}

            {!noCaret && <Caret className="caret" />}
          </Toggle>

          {open && (
            <List
              className={cx(className, 'dropdown')}
              closeDropdown={closeDropdown}
              right={!!right}
            >
              {children}
            </List>
          )}
        </>
      )
    }
  )
)``

DropdownHolder.propTypes = {
  children: any,
  List: oneOfType([func, node, _component]),
  noCaret: bool,
  right: bool,
  toggle: any,
}

DropdownHolder.defaultProps = {
  children: null,
  List: DropdownList,
  noCaret: false,
  right: false,
  toggle: null,
}

export const Dropdown = DropdownHolder
