import { ModalConsumer } from '..'

const ContactModalToggle = ({
  contactUuid = null,
  postSave = () => {},
  component: Component,
  ...props
}) => {
  return (
    <ModalConsumer>
      {({ contact }) => (
        <Component
          onClick={e => {
            contact.summon(e, { contactUuid, postSave })
          }}
          {...props}
        />
      )}
    </ModalConsumer>
  )
}

export default ContactModalToggle
