import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { fromJS } from 'immutable'
import { useHistory } from 'react-router-dom'
import { FORM_ERROR } from 'final-form'
import { FormError as _FormError } from 'components/Form'
import Spinner from 'components/Spinner'

import useAuth from 'hooks/useAuth'
import useOrganizationInvites from 'hooks/useOrganizationInvites'

import AuthCard from 'components/Cards/AuthCard'
import _Button from 'components/Buttons'

const Confirm = () => {
  const { registration: inviteToken } = useAuth()
  const { token_uuid: uuid, org: organization } = inviteToken

  const {
    acceptOrganizationInvite,
    declineOrganizationInvite,
    organizationInviteByUuid,
    getOrganizationInvite,
  } = useOrganizationInvites({
    uuid,
  })

  const { replace } = useHistory()

  const [loading, setLoading] = useState(true)
  const [disableButtons, setDisableButtons] = useState(false)
  const [getError, setGetError] = useState(null)
  const [submitError, setSubmitError] = useState(null)

  useEffect(() => {
    new Promise((resolve, reject) => {
      getOrganizationInvite(fromJS({ uuid, organization, resolve, reject }))
    })
      .then(() => {})
      .catch(({ errors }) => {
        setGetError(errors[FORM_ERROR])
      })
      .finally(() => {
        setLoading(false)
      })
  }, [uuid, organization])

  const updateInvite = action => {
    setDisableButtons(true)
    setSubmitError(null)
    return new Promise((resolve, reject) => {
      action(fromJS({ uuid, organization, resolve, reject }))
    })
      .then(() => {
        replace('/account')
      })
      .catch(({ errors }) => {
        setDisableButtons(false)
        setSubmitError(errors[FORM_ERROR])
      })
  }

  const acceptInvite = () => updateInvite(acceptOrganizationInvite)

  const declineInvite = () => updateInvite(declineOrganizationInvite)

  if (!inviteToken.isValid || inviteToken.isExpired) {
    const message = inviteToken.isValid ? 'is invalid' : 'has expired'
    return (
      <span>
        Sorry, this invite token&nbsp;
        {message}.
      </span>
    )
  }

  if (loading) return <Spinner />

  if (getError) return <FormError>{getError}</FormError>

  if (organizationInviteByUuid) {
    const status = organizationInviteByUuid.get('status')
    if (status !== 'ACTIVE') {
      const message =
        status === 'EXPIRED' ? 'has expired' : `has already been ${status}`
      return (
        <span>
          Sorry, this invite&nbsp;
          {message}.
        </span>
      )
    }
  }

  const [requestorEmail, organizationName] = [
    organizationInviteByUuid.get('requestor_email'),
    organizationInviteByUuid.get('organization_name'),
  ]

  return (
    <>
      <Title>Confirm your invitation</Title>

      <Graf>
        {requestorEmail} invited you to join {organizationName}.
      </Graf>

      <Container>
        <DeclineButton disabled={disableButtons} onClick={declineInvite} />
        <AcceptButton disabled={disableButtons} onClick={acceptInvite} />
      </Container>

      {!!submitError && <FormError>{submitError}</FormError>}
    </>
  )
}

const ConfirmWrapper = () => (
  <AuthCard>
    <Confirm />
  </AuthCard>
)

const Title = styled.h1`
  color: #1e1e1e;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0.68px;
  line-height: 1.5;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0;
`

const Container = styled.div`
  display: flex;
  margin-top: 24px;
`

const Button = styled(_Button)`
  flex: 1 1 100%;
`

const AcceptButton = styled(Button).attrs({
  children: 'Accept',
  primary: true,
})`
  margin-left: 20px;
`

const DeclineButton = styled(Button).attrs({
  children: 'Decline',
  red: true,
})``

const FormError = styled(_FormError)`
  margin-top: 24px;
`

const Graf = styled.p``

export default ConfirmWrapper
