import _ from 'lodash'
import {
  all,
  put,
  fork,
  call,
  take,
  takeEvery,
  takeLatest,
  select,
  delay,
  race,
} from 'redux-saga/effects'
import { SubmissionError } from 'redux-form'
import { generatePath } from 'react-router'
import { replace, getLocation } from 'connected-react-router/immutable'
import { fromJS } from 'immutable'

import {
  constants as teamsConstants,
  actions as teamsActions,
} from 'modules/teams'
import {
  actions as organizationsActions,
  constants as organizationsConstants,
} from 'modules/organizations'
import {
  currentTeamIdSelector,
  currentTeamSelector,
  firstTeamIdSelector,
  getCurrentTeamMember,
  getGetTeamLoadingList,
  getMemberByUuid,
  getTeamWithMembersAndStatusesByUuid,
  teamsListSelector,
  teamUuidsSelector,
} from 'selectors/teamsSelector'
import { currentOrganizationIdSelector } from 'selectors/organizationsSelector'
import { authUserUuidSelector } from 'selectors/authSelector'
import { pickAlteredKeys } from 'utility/helpers'
import devicestorage from 'utility/devicestorage'

import {
  addMembership as addMembershipApi,
  addStatus as addStatusApi,
  createTeam as createTeamApi,
  deleteTeam as deleteTeamApi,
  deleteMembership as deleteMembershipApi,
  getTeam as getTeamApi,
  queryAdminTeams as queryAdminTeamsApi,
  queryMembers as queryMembersApi,
  queryTeams as queryTeamsApi,
  updateMembership as updateMembershipApi,
  updateStatus as updateStatusApi,
  updateTeam as updateTeamApi,
} from 'api/teams'

function segmentChildProperties(values, initialValues) {
  const [rest, addedValues] = _.partition(values, 'uuid')
  const deletedValues = _.filter(
    initialValues,
    ({ uuid }) => !_.some(rest, { uuid })
  )

  const updatedValues = _.map(
    _.differenceWith(rest, initialValues, _.isEqual),
    obj => {
      const initialObj = _.find(initialValues, { uuid: obj.uuid })
      return pickAlteredKeys(obj, initialObj, ['uuid', 'team'])
    }
  )

  return [addedValues, deletedValues, updatedValues]
}

function* getTeam({ payload: uuid }) {
  try {
    const {
      entities: { members, statuses, teams },
    } = yield call(getTeamApi, uuid)

    const getTeamLoadingList = yield select(getGetTeamLoadingList)

    yield put({
      type: teamsConstants.GET_TEAM_SUCCESS,
      payload: fromJS({
        teams,
        members,
        statuses,
        getTeamLoadingList: getTeamLoadingList.filter(u => u !== uuid),
      }),
    })
  } catch (error) {
    const getTeamLoadingList = yield select(getGetTeamLoadingList)

    yield put({
      type: teamsConstants.GET_TEAM_FAIL,
      payload: fromJS({
        uuid,
        getTeamLoadingList: getTeamLoadingList.filter(u => u !== uuid),
        error,
      }),
    })
  }
}

function* createTeam({ payload }) {
  const {
    resolve,
    reject,
    team: { name = 'New Team', organization: organizationUuid = null } = {},
  } = payload.toJS()

  try {
    const organization = yield organizationUuid ||
      select(currentOrganizationIdSelector)

    const { entities, result: newTeamUuid } = yield call(createTeamApi, {
      organization,
      name,
    })

    yield put({
      type: teamsConstants.CREATE_TEAM_SUCCESS,
      payload: fromJS({
        ...entities,
        newTeamUuid,
      }),
    })

    if (resolve) resolve(newTeamUuid)
  } catch (createTeamError) {
    yield put({
      type: teamsConstants.CREATE_TEAM_FAIL,
      payload: fromJS({ createTeamError }),
    })
    if (reject) reject(createTeamError)
  }
}

function* deleteTeam({ payload }) {
  const { uuid, resolve, reject } = payload.toJS()

  try {
    // TODO: remove the race condition and the delay
    // https://app.asana.com/0/484373866847088/1153926715523801
    yield race({
      deleteTeam: call(deleteTeamApi, uuid),
      timeout: delay(1500),
    })

    yield put({
      type: teamsConstants.DELETE_TEAM_SUCCESS,
      payload: fromJS({}),
    })

    if (resolve) resolve(uuid)
  } catch (error) {
    yield put({
      type: teamsConstants.DELETE_TEAM_FAIL,
      payload: fromJS({ error }),
    })

    if (reject) reject(error)
  }
}

function* queryTeams({ payload }) {
  const { resolve, reject } = (payload || fromJS({})).toJS()

  try {
    const { entities, result: teamUuids } = yield call(queryTeamsApi)

    yield put({
      type: teamsConstants.QUERY_TEAMS_SUCCESS,
      payload: fromJS({ ...entities, teamUuids }),
    })

    if (resolve) resolve()
  } catch (error) {
    yield put({
      type: teamsConstants.QUERY_TEAMS_FAIL,
      payload: fromJS({ error }),
    })

    if (reject) reject(fromJS({ error }))
  }
}

function* queryAdminTeams({ payload }) {
  const { organization, resolve, reject } = payload.toJS()

  try {
    const { entities, result: adminTeamUuids } = yield call(
      queryAdminTeamsApi,
      { organization_uuid: organization }
    )

    yield put({
      type: teamsConstants.QUERY_ADMIN_TEAMS_SUCCESS,
      payload: fromJS({ ...entities, adminTeamUuids }),
    })

    if (resolve) resolve()
  } catch (error) {
    yield put({
      type: teamsConstants.QUERY_ADMIN_TEAMS_FAIL,
      payload: fromJS({ error }),
    })

    if (reject) reject(fromJS({ error }))
  }
}

function* redirectTeam({ payload }) {
  const {
    match: { path },
    resolve,
    reject,
  } = payload.toJS()

  try {
    yield put(teamsActions.queryTeams())

    yield take([
      teamsConstants.QUERY_TEAMS_SUCCESS,
      teamsConstants.QUERY_TEAMS_FAIL,
    ])

    let teamIdFromStorage = yield devicestorage.get(
      __CONFIG__.localStorageKeys.teamUuid
    )

    if (teamIdFromStorage) {
      const teamUuids = yield select(teamUuidsSelector)
      if (!teamUuids.includes(teamIdFromStorage)) {
        teamIdFromStorage = null
      }
    }

    const teamId = yield teamIdFromStorage || select(firstTeamIdSelector)

    if (teamId) {
      const newPath = generatePath(path, { team_id: teamId })
      if (newPath) yield put(replace(newPath))
    } else {
      yield put(teamsActions.setCurrentTeamId(null))
    }

    yield put({ type: teamsConstants.REDIRECT_TEAM_SUCCESS })

    if (resolve) resolve()
  } catch (error) {
    if (reject) reject(error)
  }
}

function* updateTeam({ payload }) {
  const {
    team: { members, statuses, ...team },
    initial: {
      members: membersInitial,
      statuses: statusesInitial,
      ...initialTeam
    },
    resolve,
    reject,
  } = payload.toJS()

  try {
    const childCalls = [
      segmentChildProperties(members, membersInitial),
      segmentChildProperties(statuses, statusesInitial),
    ]

    const [
      [addedMembers, deletedMembers, updatedMembers],
      [addedStatuses, deletedStatuses, updatedStatuses],
    ] = childCalls

    const childCallResponses = yield all(
      _.flatten([
        addedMembers.map(member =>
          put(teamsActions.addMembership(fromJS(member)))
        ),
        deletedMembers.map(member =>
          put(teamsActions.deleteMembership(fromJS(member)))
        ),
        updatedMembers.map(member =>
          put(teamsActions.updateMembership(fromJS(member)))
        ),
        addedStatuses.map(status =>
          put(teamsActions.addStatus(fromJS(status)))
        ),
        deletedStatuses.map(status =>
          put(teamsActions.deleteStatus(fromJS(status)))
        ),
        updatedStatuses.map(status =>
          put(teamsActions.updateStatus(fromJS(status)))
        ),
      ])
    )

    for (let i = 0; i < childCallResponses.length; i += 1) {
      yield take([
        teamsConstants.ADD_MEMBERSHIP_SUCCESS,
        teamsConstants.ADD_MEMBERSHIP_FAIL,
        teamsConstants.UPDATE_MEMBERSHIP_SUCCESS,
        teamsConstants.UPDATE_MEMBERSHIP_FAIL,
        teamsConstants.DELETE_MEMBERSHIP_SUCCESS,
        teamsConstants.DELETE_MEMBERSHIP_FAIL,
        teamsConstants.ADD_STATUS_SUCCESS,
        teamsConstants.ADD_STATUS_FAIL,
        teamsConstants.UPDATE_STATUS_SUCCESS,
        teamsConstants.UPDATE_STATUS_FAIL,
        teamsConstants.DELETE_STATUS_SUCCESS,
        teamsConstants.DELETE_STATUS_FAIL,
      ])
    }

    const isTeamUpdated = !_.isEqual(team, initialTeam)

    const { entities } = yield isTeamUpdated
      ? call(updateTeamApi, team.uuid, team)
      : call(getTeamApi, team.uuid)

    yield put({
      type: teamsConstants.UPDATE_TEAM_SUCCESS,
      payload: fromJS({ ...entities }),
    })

    const newFullTeam = yield select(state =>
      getTeamWithMembersAndStatusesByUuid(state, { teamUuid: team.uuid })
    )

    resolve(newFullTeam)
  } catch (error) {
    yield put({
      type: teamsConstants.UPDATE_TEAM_FAIL,
      payload: fromJS({ error }),
    })

    reject(
      new SubmissionError({ _error: 'There was an error udpating the team.' })
    )
  }
}

function* deleteStatus({ payload }) {
  try {
    const { team, uuid } = payload.toJS()

    const { entities } = yield call(updateStatusApi, team, uuid, {
      is_removed: true,
    })

    yield put({
      type: teamsConstants.DELETE_STATUS_SUCCESS,
      payload: fromJS({ ...entities }),
    })
  } catch (deleteStatusError) {
    yield put({
      type: teamsConstants.DELETE_STATUS_FAIL,
      payload: fromJS({ deleteStatusError }),
    })
  }
}

function* updateStatus({ payload }) {
  try {
    const { team, uuid, ...status } = payload.toJS()

    const { entities } = yield call(updateStatusApi, team, uuid, status)

    yield put({
      type: teamsConstants.UPDATE_STATUS_SUCCESS,
      payload: fromJS({ ...entities }),
    })
  } catch (deleteStatusError) {
    yield put({
      type: teamsConstants.UPDATE_STATUS_FAIL,
      payload: fromJS({ deleteStatusError }),
    })
  }
}

function* addStatus({ payload }) {
  try {
    const { team, ...status } = payload.toJS()

    const { entities } = yield call(addStatusApi, team, status)

    yield put({
      type: teamsConstants.ADD_STATUS_SUCCESS,
      payload: fromJS({ ...entities }),
    })
  } catch (error) {
    yield put({
      type: teamsConstants.ADD_STATUS_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* addMembership({ payload }) {
  try {
    const member = payload.toJS()
    const { team: teamUuid } = member

    const { entities } = yield call(addMembershipApi, teamUuid, member)

    yield put({
      type: teamsConstants.ADD_MEMBERSHIP_SUCCESS,
      payload: fromJS({ ...entities }),
    })
  } catch (error) {
    yield put({
      type: teamsConstants.ADD_MEMBERSHIP_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* updateMembership({ payload }) {
  try {
    const member = payload.toJS()
    const { team: teamUuid, uuid: memberUuid } = member

    const { entities } = yield call(
      updateMembershipApi,
      teamUuid,
      memberUuid,
      member
    )

    yield put({
      type: teamsConstants.UPDATE_MEMBERSHIP_SUCCESS,
      payload: fromJS({ ...entities }),
    })
  } catch (error) {
    yield put({
      type: teamsConstants.UPDATE_MEMBERSHIP_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* deleteMembership({ payload }) {
  const member = payload.toJS()
  const { team: teamUuid, uuid: memberUuid, resolve, reject } = member
  try {
    yield call(deleteMembershipApi, teamUuid, memberUuid)

    yield put({
      type: teamsConstants.DELETE_MEMBERSHIP_SUCCESS,
    })

    if (resolve) resolve()
  } catch (error) {
    yield put({
      type: teamsConstants.DELETE_MEMBERSHIP_FAIL,
      payload: fromJS({ error }),
    })

    if (reject) reject()
  }
}

function* setCurrentTeamId({ payload }) {
  if (payload) {
    yield put(teamsActions.getTeam(payload))

    const { fail } = yield race({
      success: take(teamsConstants.GET_TEAM_SUCCESS),
      fail: take(teamsConstants.GET_TEAM_FAIL),
    })

    if (fail) {
      yield put(teamsActions.queryTeams())

      yield race({
        success: take(teamsConstants.QUERY_TEAMS_SUCCESS),
        fail: take(teamsConstants.QUERY_TEAMS_FAIL),
      })
    }

    const currentTeam = yield select(currentTeamSelector)

    if (currentTeam) {
      const currentOrganizationUuid = currentTeam.get('organization')

      yield put(
        organizationsActions.setCurrentOrganizationId(currentOrganizationUuid)
      )
    }
  }

  yield devicestorage.set(__CONFIG__.localStorageKeys.teamUuid, payload)
}

function* searchTeamMembers({ payload: keyword }) {
  try {
    const currentTeamId = yield select(currentTeamIdSelector)

    const { entities, result: searchTeamMembersList } = yield call(
      queryMembersApi,
      currentTeamId,
      keyword
    )

    yield put({
      type: teamsConstants.SEARCH_TEAM_MEMBERS_SUCCESS,
      payload: fromJS({ ...entities, searchTeamMembersList }),
    })
  } catch (searchTeamMembersError) {
    yield put({
      type: teamsConstants.SEARCH_TEAM_MEMBERS_FAIL,
      payload: fromJS({ searchTeamMembersError }),
    })
  }
}

function* socketTeam() {
  const organization = yield select(currentOrganizationIdSelector)
  yield put(teamsActions.queryAdminTeams(fromJS({ organization })))
}

function* socketDeleteOrganization({ payload }) {
  const { uuid: organizationUuid } = payload.toJS()
  const teams = yield select(teamsListSelector)

  const deleteTeamUuids = teams
    .filter(team => team.get('organization') === organizationUuid)
    .map(team => team.get('uuid'))
    .toArray()

  yield all(
    deleteTeamUuids.map(uuid =>
      put(teamsActions.socketDeleteTeam(fromJS({ uuid })))
    )
  )
}

function* socketTeamMembership({ payload }) {
  const { user } = payload.toJS()
  const userUuid = yield select(authUserUuidSelector)
  if (user === userUuid) {
    yield put(teamsActions.queryTeams())
  }
}

function* socketDeleteTeamMembership({ payload }) {
  const currentTeamMembership = yield select(getCurrentTeamMember)
  if (!currentTeamMembership) {
    const { pathname } = yield select(getLocation)
    const pathRoot = pathname.split('/')[1]
    if (['calls', 'contacts'].includes(pathRoot)) {
      yield put(replace(`/${pathRoot}`))
      yield put(teamsActions.setCurrentTeamId(null))
    }
  }

  const currentUserUuid = yield select(authUserUuidSelector)
  const membership = yield select(state =>
    getMemberByUuid(state, { uuid: payload.get('uuid') })
  )

  if (currentUserUuid && membership) {
    if (currentUserUuid === membership.get('user')) {
      yield delay(1000)
      yield put(
        teamsActions.socketRemoveTeamFromTeamUuids(membership.get('team'))
      )
    }
  }
}

function* watchTeams() {
  yield takeEvery(teamsConstants.GET_TEAM, getTeam)
  yield takeEvery(teamsConstants.CREATE_TEAM, createTeam)
  yield takeEvery(teamsConstants.DELETE_TEAM, deleteTeam)
  yield takeEvery(teamsConstants.QUERY_ADMIN_TEAMS, queryAdminTeams)
  yield takeLatest(teamsConstants.QUERY_TEAMS, queryTeams)
  yield takeLatest(teamsConstants.REDIRECT_TEAM, redirectTeam)
  yield takeLatest(teamsConstants.SET_CURRENT_TEAM_ID, setCurrentTeamId)
  yield takeEvery(teamsConstants.UPDATE_TEAM, updateTeam)
  yield takeEvery(teamsConstants.DELETE_STATUS, deleteStatus)
  yield takeEvery(teamsConstants.UPDATE_STATUS, updateStatus)
  yield takeEvery(teamsConstants.ADD_STATUS, addStatus)
  yield takeEvery(teamsConstants.DELETE_MEMBERSHIP, deleteMembership)
  yield takeEvery(teamsConstants.UPDATE_MEMBERSHIP, updateMembership)
  yield takeEvery(teamsConstants.ADD_MEMBERSHIP, addMembership)
  yield takeLatest(teamsConstants.SEARCH_TEAM_MEMBERS, searchTeamMembers)
  yield takeLatest(teamsConstants.SOCKET_TEAM, socketTeam)
  yield takeEvery(
    organizationsConstants.SOCKET_DELETE_ORGANIZATION,
    socketDeleteOrganization
  )
  yield takeEvery(
    teamsConstants.SOCKET_DELETE_TEAM_MEMBERSHIP,
    socketDeleteTeamMembership
  )
  yield takeEvery(teamsConstants.SOCKET_TEAM_MEMBERSHIP, socketTeamMembership)
}

export const teamsSaga = [fork(watchTeams)]
