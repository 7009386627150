import styled from 'styled-components'

const Collaboration = () => (
  <div>
    <h1>Sharing Calls with PhoneSlate</h1>
    <p>Create custom call sheets and share them however you want.</p>

    <h2>Filter and Sort</h2>
    <p>
      You can filter and sort your call sheet to only show calls from this week,
      calls you need to return, calls for a particular coworker, or just about
      anything else.
    </p>

    <h2>Send as an Email</h2>
    <p>
      Send your filtered call sheet to a coworker as an easy-to-read, formatted
      email with a single click.
    </p>

    <h2>Print It Out</h2>
    <p>
      Want to see it on paper? PhoneSlate will create a neat, printable call
      sheet and spare you the hassle of messing with margins and layout.
    </p>
  </div>
)

Collaboration.propTypes = {}

export default styled(Collaboration)``
