import styled from 'styled-components'
import { connect } from 'react-redux'
import { string } from 'prop-types'
import { map } from 'react-immutable-proptypes'
import moment from 'moment'
import { fromJS } from 'immutable'

import { getShortNameFromUserUuid } from 'selectors/teamsSelector'
import { getCallByUuid } from 'selectors/conversationsSelector'

import Initialism from './Initialism'

const mapStateToProps = (state, { callUuid: uuid }) => {
  const call = getCallByUuid(state, { uuid }) || fromJS({})
  const creatorShortName = getShortNameFromUserUuid(
    state,
    { uuid: call.get('creator') },
  )

  return {
    call,
    creatorShortName,
  }
}

const mapDispatchToProps = {}

const Call = styled(({
  callUuid, creatorShortName, call, ...props
}) => {
  const dateFormat = 'ddd MMM D h:mmA'
  const callTime = moment(call.get('call_time')).local().format(dateFormat || '')

  return (
    <div {...props}>
      <Initialism statusUuid={call.get('status')} />
      <CallTime>{callTime}</CallTime>
      <CallCreator>
        by&nbsp;
        {creatorShortName}
      </CallCreator>
    </div>
  )
})``

Call.propTypes = {
  call: map.isRequired,
  callUuid: string.isRequired,
  creatorShortName: string.isRequired,
}

const CallTime = (styled.span || styled.Text)`
  display: inline-block;
  padding-left: 10px;
`

const CallCreator = (styled.span || styled.Text)`
  display: inline-block;
  padding-left: 10px;
  color: ${props => props.theme.gray};
`

export default connect(mapStateToProps, mapDispatchToProps)(Call)
