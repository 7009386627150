import styled, { css } from 'styled-components'
import Button from 'components/Buttons'

export default (styled.div || styled.View)`
  display: flex;
  justify-content: space-between;
  margin-left: -7px;
  margin-right: -7px;
  > * {
    flex: 1;
    margin-left: 7px;
    margin-right: 7px;
  }
  ${props => ((props.alignLeft || props.alignRight) && css`
    ${Button} {
      width: ${props.theme.buttons.defaultWidth};
      max-width: ${props.theme.buttons.defaultWidth};
    }
  `)}
  ${({ alignLeft }) => alignLeft && css`
    justify-content: flex-start;
  `}
  ${({ alignRight }) => alignRight && css`
    justify-content: flex-end;
  `}
`
