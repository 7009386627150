import { createAction, handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import { sizes } from 'utility/styles'

const SCREEN_RESIZE = 'app/ui/SCREEN_RESIZE'

export const constants = {
  SCREEN_RESIZE,
}

// ------------------------------------
// Actions
// ------------------------------------
export const screenResize = createAction(SCREEN_RESIZE)

export const actions = {
  screenResize,
}

const isMobile = width => width < sizes.tablet

export const initialState = () =>
  fromJS({
    isMobile: window ? isMobile(window.innerWidth) : true,
  })

export const reducers = {
  [SCREEN_RESIZE]: (state, { payload }) =>
    state.merge({
      isMobile: isMobile(payload),
    }),
}

export default handleActions(reducers, initialState())
