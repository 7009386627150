import _ from 'lodash'
import memoize from 'lru-memoize'
import { required } from 'utility/validation'

const OrganizationCreateFormValidator = data => {
  const errors = {}

  _.forEach(['organization.name', 'team.name'], key => {
    _.set(errors, key, _.compact([required(_.get(data, key))]))
  })

  return errors
}

export default memoize(10)(OrganizationCreateFormValidator)
