import styled from 'styled-components'
import Nav from '../Nav'
import TeamDropdown from './TeamDropdown'
// import AddToTeamDropdown from './AddToTeamDropdown'

const TeamNav = styled(({ ...props }) => (
  <Nav {...props}>
    <li>
      <TeamDropdown />
    </li>
    {/* <li>
      <AddToTeamDropdown />
    </li> */}
  </Nav>
))``

export default TeamNav
