import { combineReducers } from 'redux-immutable'
import { connectRouter } from 'connected-react-router/immutable'
import { reducer as form } from 'redux-form/immutable'

import auth from './modules/auth'
import contacts from './modules/contacts'
import conversations from './modules/conversations'
import organizations from './modules/organizations'
import teams from './modules/teams'
import ui from './modules/ui'

export default history =>
  combineReducers({
    auth,
    contacts,
    conversations,
    form,
    organizations,
    router: connectRouter(history),
    teams,
    ui,
  })
