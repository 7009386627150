import styled from 'styled-components'
import Card from 'components/Cards/Card'
import InnerCard from 'components/Cards/InnerCard'

import { media } from 'utility/styles'

const AuthCard = styled(Card)`
  max-width: 415px;

  ${InnerCard} {
    padding: 32px 54px 45px;
  }

  ${media.tablet`
    max-width: none;
  `}
`

export default AuthCard
