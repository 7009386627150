import { handleActions, createAction } from 'redux-actions'
import { fromJS } from 'immutable'
import { normalize } from 'normalizr'
import _ from 'lodash'
import { schema as contactsSchema } from 'api/contacts'
import { constants as conversationsConstants } from 'modules/conversations'
import { rekeyObjectToCamelcase } from 'utility/helpers'

const EXPORT_JOB_COMPLETED = 'app/contacts/EXPORT_JOB_COMPLETED'
const CLEAR_TEAM_CONTACTS = 'app/contacts/CLEAR_TEAM_CONTACTS'
const CONTACTS_LOCK = 'app/contacts/CONTACTS_LOCK'
const CONTACTS_UNLOCK = 'app/contacts/CONTACTS_UNLOCK'
const CREATE_CONTACT = 'app/contacts/CREATE_CONTACT'
const CREATE_CONTACT_FAIL = 'app/contacts/CREATE_CONTACT_FAIL'
const CREATE_CONTACT_SUCCESS = 'app/contacts/CREATE_CONTACT_SUCCESS'
const DELETE_CONTACT = 'app/contacts/DELETE_CONTACT'
const DELETE_CONTACT_FAIL = 'app/contacts/DELETE_CONTACT_FAIL'
const DELETE_CONTACT_SUCCESS = 'app/contacts/DELETE_CONTACT_SUCCESS'
const DELETE_ALL_IMPORTED_CONTACTS =
  'app/congtacts/DELETE_ALL_IMPORTED_CONTACTS'
const DELETE_ALL_IMPORTED_CONTACTS_FAIL =
  'app/congtacts/DELETE_ALL_IMPORTED_CONTACTS_FAIL'
const DELETE_ALL_IMPORTED_CONTACTS_SUCCESS =
  'app/congtacts/DELETE_ALL_IMPORTED_CONTACTS_SUCCESS'
const CREATE_EMAIL = 'app/contacts/CREATE_EMAIL'
const CREATE_EMAIL_FAIL = 'app/contacts/CREATE_EMAIL_FAIL'
const CREATE_EMAIL_SUCCESS = 'app/contacts/CREATE_EMAIL_SUCCESS'
const CREATE_OR_UPDATE_CONTACT_FROM_FORM =
  'app/contacts/CREATE_OR_UPDATE_CONTACT_FROM_FORM'
const CREATE_PHONE = 'app/contacts/CREATE_PHONE'
const CREATE_PHONE_FAIL = 'app/contacts/CREATE_PHONE_FAIL'
const CREATE_PHONE_SUCCESS = 'app/contacts/CREATE_PHONE_SUCCESS'
const DELETE_EMAIL = 'app/contacts/DELETE_EMAIL'
const DELETE_EMAIL_FAIL = 'app/contacts/DELETE_EMAIL_FAIL'
const DELETE_EMAIL_SUCCESS = 'app/contacts/DELETE_EMAIL_SUCCESS'
const DELETE_PHONE = 'app/contacts/DELETE_PHONE'
const DELETE_PHONE_FAIL = 'app/contacts/DELETE_PHONE_FAIL'
const DELETE_PHONE_SUCCESS = 'app/contacts/DELETE_PHONE_SUCCESS'
const CREATE_EXPORT_CONTACTS_JOB = 'app/contacts/CREATE_EXPORT_CONTACTS_JOB'
const GET_EXPORT_JOB = 'app/contacts/GET_EXPORT_JOB'
const GET_CONTACT = 'app/contacts/GET_CONTACT'
const GET_CONTACT_FAIL = 'app/contacts/GET_CONTACT_FAIL'
const GET_CONTACT_SUCCESS = 'app/contacts/GET_CONTACT_SUCCESS'
const POPULAR_CONTACTS = 'app/contacts/POPULAR_CONTACTS'
const POPULAR_CONTACTS_FAIL = 'app/contacts/POPULAR_CONTACTS_FAIL'
const POPULAR_CONTACTS_SUCCESS = 'app/contacts/POPULAR_CONTACTS_SUCCESS'
const QUERY_CONTACTS = 'app/contacts/QUERY_CONTACTS'
const QUERY_CONTACTS_FAIL = 'app/contacts/QUERY_CONTACTS_FAIL'
const QUERY_CONTACTS_SUCCESS = 'app/contacts/QUERY_CONTACTS_SUCCESS'
const QUERY_CONTACTS_IMPORTS = 'app/contacts/QUERY_CONTACTS_IMPORTS'
const SEARCH_CONTACTS = 'app/contacts/SEARCH_CONTACTS'
const SEARCH_CONTACTS_CLEAR = 'app/contacts/SEARCH_CONTACTS_CLEAR'
const SEARCH_CONTACTS_FAIL = 'app/contacts/SEARCH_CONTACTS_FAIL'
const SEARCH_CONTACTS_SUCCESS = 'app/contacts/SEARCH_CONTACTS_SUCCESS'
const SET_NEW_CONTACT_FORM_POST_SAVE_TARGET =
  'app/contacts/SET_NEW_CONTACT_FORM_POST_SAVE_TARGET'
const SET_UPDATE_CONTACT_ID = 'app/contacts/SET_UPDATE_CONTACT_ID'
const TOGGLE_NEW_CONTACT_FORM = 'app/contacts/TOGGLE_NEW_CONTACT_FORM'
const UPDATE_CONTACT = 'app/contacts/UPDATE_CONTACT'
const UPDATE_CONTACT_FAIL = 'app/contacts/UPDATE_CONTACT_FAIL'
const UPDATE_CONTACT_LOADED = 'app/contacts/UPDATE_CONTACT_LOADED'
const UPDATE_CONTACT_SUCCESS = 'app/contacts/UPDATE_CONTACT_SUCCESS'
const UPDATE_EMAIL = 'app/contacts/UPDATE_EMAIL'
const UPDATE_EMAIL_FAIL = 'app/contacts/UPDATE_EMAIL_FAIL'
const UPDATE_EMAIL_SUCCESS = 'app/contacts/UPDATE_EMAIL_SUCCESS'
const UPDATE_PHONE = 'app/contacts/UPDATE_PHONE'
const UPDATE_PHONE_FAIL = 'app/contacts/UPDATE_PHONE_FAIL'
const UPDATE_PHONE_SUCCESS = 'app/contacts/UPDATE_PHONE_SUCCESS'
const UPLOAD_CONTACTS_IMPORT = 'app/contacts/UPLOAD_CONTACTS_IMPORT'
const UPLOAD_CONTACTS_IMPORT_SUCCESS =
  'app/contacts/UPLOAD_CONTACTS_IMPORT_SUCCESS'
const UPLOAD_CONTACTS_IMPORT_FAIL = 'app/contacts/UPLOAD_CONTACTS_IMPORT_FAIL'

const SOCKET_CONTACT = 'app/contacts/SOCKET_CONTACT'
const SOCKET_DELETE_CONTACT = 'app/contacts/SOCKET_DELETE_CONTACT'
const SOCKET_EMAIL = 'app/contacts/SOCKET_EMAIL'
const SOCKET_DELETE_EMAIL = 'app/contacts/SOCKET_DELETE_EMAIL'
const SOCKET_PHONE = 'app/contacts/SOCKET_PHONE'
const SOCKET_DELETE_PHONE = 'app/contacts/SOCKET_DELETE_PHONE'

export const constants = {
  EXPORT_JOB_COMPLETED,
  CONTACTS_LOCK,
  CONTACTS_UNLOCK,
  CREATE_CONTACT_FAIL,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT,
  CREATE_EMAIL_FAIL,
  CREATE_EMAIL_SUCCESS,
  CREATE_EMAIL,
  CREATE_OR_UPDATE_CONTACT_FROM_FORM,
  CREATE_PHONE_FAIL,
  CREATE_PHONE_SUCCESS,
  CREATE_PHONE,
  DELETE_ALL_IMPORTED_CONTACTS_FAIL,
  DELETE_ALL_IMPORTED_CONTACTS_SUCCESS,
  DELETE_ALL_IMPORTED_CONTACTS,
  DELETE_CONTACT_FAIL,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT,
  DELETE_EMAIL_FAIL,
  DELETE_EMAIL_SUCCESS,
  DELETE_EMAIL,
  DELETE_PHONE_FAIL,
  DELETE_PHONE_SUCCESS,
  DELETE_PHONE,
  CREATE_EXPORT_CONTACTS_JOB,
  GET_CONTACT_FAIL,
  GET_CONTACT_SUCCESS,
  GET_CONTACT,
  GET_EXPORT_JOB,
  POPULAR_CONTACTS_FAIL,
  POPULAR_CONTACTS_SUCCESS,
  POPULAR_CONTACTS,
  QUERY_CONTACTS_FAIL,
  QUERY_CONTACTS_IMPORTS,
  QUERY_CONTACTS_SUCCESS,
  QUERY_CONTACTS,
  SEARCH_CONTACTS_CLEAR,
  SEARCH_CONTACTS_FAIL,
  SEARCH_CONTACTS_SUCCESS,
  SEARCH_CONTACTS,
  SET_NEW_CONTACT_FORM_POST_SAVE_TARGET,
  SET_UPDATE_CONTACT_ID,
  TOGGLE_NEW_CONTACT_FORM,
  UPDATE_CONTACT_FAIL,
  UPDATE_CONTACT_LOADED,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT,
  UPDATE_EMAIL_FAIL,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL,
  UPDATE_PHONE_FAIL,
  UPDATE_PHONE_SUCCESS,
  UPDATE_PHONE,
  UPLOAD_CONTACTS_IMPORT_FAIL,
  UPLOAD_CONTACTS_IMPORT_SUCCESS,
  UPLOAD_CONTACTS_IMPORT,
  SOCKET_CONTACT,
  SOCKET_DELETE_CONTACT,
  SOCKET_EMAIL,
  SOCKET_DELETE_EMAIL,
  SOCKET_PHONE,
  SOCKET_DELETE_PHONE,
}

// ------------------------------------
// Actions
// ------------------------------------
export const clearTeamContacts = createAction(CLEAR_TEAM_CONTACTS)
export const contactsLock = createAction(CONTACTS_LOCK)
export const contactsUnlock = createAction(CONTACTS_UNLOCK)
export const createContact = createAction(CREATE_CONTACT)
export const createOrUpdateContactFromForm = createAction(
  CREATE_OR_UPDATE_CONTACT_FROM_FORM
)
export const deleteContact = createAction(DELETE_CONTACT)
export const deleteAllImportedContacts = createAction(
  DELETE_ALL_IMPORTED_CONTACTS
)
export const createEmailAddress = createAction(CREATE_EMAIL)
export const createPhoneNumber = createAction(CREATE_PHONE)
export const deleteEmailAddress = createAction(DELETE_EMAIL)
export const deletePhoneNumber = createAction(DELETE_PHONE)
export const createExportContactsJob = createAction(CREATE_EXPORT_CONTACTS_JOB)
export const exportJobCompleted = createAction(EXPORT_JOB_COMPLETED)
export const getExportJob = createAction(GET_EXPORT_JOB)
export const getContact = createAction(GET_CONTACT)
export const queryContacts = createAction(QUERY_CONTACTS)
export const queryContactsImports = createAction(QUERY_CONTACTS_IMPORTS)
export const searchContacts = createAction(SEARCH_CONTACTS, keyword => ({
  keyword,
  limit: 50,
}))
export const searchContactsBig = createAction(SEARCH_CONTACTS, keyword => ({
  keyword,
  limit: 50,
}))
export const searchContactsClear = createAction(SEARCH_CONTACTS_CLEAR)
export const setNewContactFormPostSaveTarget = createAction(
  SET_NEW_CONTACT_FORM_POST_SAVE_TARGET
)
export const setUpdateContactId = createAction(SET_UPDATE_CONTACT_ID)
export const socketContact = createAction(SOCKET_CONTACT)
export const socketDeleteContact = createAction(SOCKET_DELETE_CONTACT)
export const socketDeleteEmail = createAction(SOCKET_DELETE_EMAIL)
export const socketDeletePhone = createAction(SOCKET_DELETE_PHONE)
export const socketEmail = createAction(SOCKET_EMAIL)
export const socketPhone = createAction(SOCKET_PHONE)
export const toggleNewContactForm = createAction(TOGGLE_NEW_CONTACT_FORM)
export const updateContact = createAction(UPDATE_CONTACT)
export const updateEmailAddress = createAction(UPDATE_EMAIL)
export const updatePhoneNumber = createAction(UPDATE_PHONE)
export const uploadContactsImport = createAction(UPLOAD_CONTACTS_IMPORT)

export const actions = {
  clearTeamContacts,
  contactsLock,
  contactsUnlock,
  createContact,
  createEmailAddress,
  createOrUpdateContactFromForm,
  createPhoneNumber,
  deleteAllImportedContacts,
  deleteContact,
  deleteEmailAddress,
  deletePhoneNumber,
  createExportContactsJob,
  exportJobCompleted,
  getContact,
  getExportJob,
  queryContacts,
  queryContactsImports,
  searchContacts,
  searchContactsBig,
  searchContactsClear,
  setNewContactFormPostSaveTarget,
  setUpdateContactId,
  socketContact,
  socketDeleteContact,
  socketDeleteEmail,
  socketDeletePhone,
  socketEmail,
  socketPhone,
  toggleNewContactForm,
  updateContact,
  updateEmailAddress,
  updatePhoneNumber,
  uploadContactsImport,
}

export const initialState = () =>
  fromJS({
    contactDetailsError: null,
    contactDetailsLoading: false,

    teamContacts: [],
    teamContactsLoading: false,
    teamContactsError: null,
    teamContactsCount: 0,

    contacts: {},
    phoneNumbers: {},
    emailAddresses: {},
    contactsError: null,

    createContactError: null,
    createContactLoading: false,

    deleteAllImportedContactsError: null,
    deleteAllImportedContactsLoading: false,

    deleteContactError: null,
    deleteContactLoading: false,

    updateContactError: null,
    updateContactId: null,
    updateContactLoading: false,

    newContactFormOpen: false,
    newContactFormPostSaveTarget: null,

    popularContacts: null,
    popularContactsError: undefined,
    popularContactsLoading: false,

    searchContactsUuids: [],
    searchContactsError: null,
    searchContactsLoading: false,

    uploadContactsResults: null,
    uploadContactsLoading: false,
    uploadContactsError: null,

    contactsLockedTeamUuids: [],

    exportJobs: {},
  })

export const reducers = {
  [CLEAR_TEAM_CONTACTS]: state =>
    state.merge({
      teamContacts: initialState().get('teamContacts'),
      teamContactsCount: initialState().get('teamContactsCount'),
    }),

  [SEARCH_CONTACTS]: state => {
    return state.merge({
      searchContactsLoading: true,
      searchContactsError: initialState().get('searchContactsError'),
    })
  },
  [SEARCH_CONTACTS_SUCCESS]: (state, { payload }) => {
    return state.merge({
      searchContactsLoading: initialState().get('searchContactsLoading'),
      searchContactsError: initialState().get('searchContactsError'),
      searchContactsUuids: payload.get('searchContactsUuids'),
      contacts: state.get('contacts').merge(payload.get('contacts')),
      phoneNumbers: state
        .get('phoneNumbers')
        .merge(payload.get('phoneNumbers')),
      emailAddresses: state
        .get('emailAddresses')
        .merge(payload.get('emailAddresses')),
    })
  },
  [SEARCH_CONTACTS_FAIL]: (state, { payload }) => {
    return state.merge({
      searchContactsLoading: initialState().get('searchContactsLoading'),
      searchContactsError: payload.get('error'),
    })
  },
  [SEARCH_CONTACTS_CLEAR]: state => {
    return state.merge({
      searchContactsUuids: initialState().get('searchContactsUuids'),
    })
  },

  [conversationsConstants.QUERY_CONVERSATIONS_SUCCESS]: (state, { payload }) =>
    state.merge({
      contacts: state.get('contacts').merge(payload.get('contacts')),
      phoneNumbers: state
        .get('phoneNumbers')
        .merge(payload.get('phoneNumbers')),
      emailAddresses: state
        .get('emailAddresses')
        .merge(payload.get('emailAddresses')),
    }),

  [conversationsConstants.GET_CONVERSATION_SUCCESS]: (state, { payload }) =>
    state.merge({
      contacts: state.get('contacts').merge(payload.get('contacts')),
      phoneNumbers: state
        .get('phoneNumbers')
        .merge(payload.get('phoneNumbers')),
      emailAddresses: state
        .get('emailAddresses')
        .merge(payload.get('emailAddresses')),
    }),

  [POPULAR_CONTACTS]: state => {
    return state.merge({
      popularContactsLoading: true,
      popularContactsError: initialState().get('popularContactsLoading'),
    })
  },
  [POPULAR_CONTACTS_SUCCESS]: (state, { payload }) => {
    return state.merge({
      popularContactsLoading: initialState().get('popularContactsLoading'),
      popularContactsError: initialState().get('popularContactsLoading'),
      popularContacts: payload.get('popularContacts'),
    })
  },
  [POPULAR_CONTACTS_FAIL]: (state, { payload }) => {
    return state.merge({
      popularContactsLoading: initialState().get('popularContactsLoading'),
      popularContactsError: payload.get('error'),
    })
  },

  [GET_CONTACT]: state => {
    return state.merge({
      contactDetailsLoading: true,
      contactDetailsError: initialState().get('contactDetailsError'),
    })
  },
  [GET_CONTACT_SUCCESS]: (state, { payload }) => {
    return state.merge({
      contactDetailsLoading: initialState().get('contactDetailsLoading'),
      contactDetailsError: initialState().get('contactDetailsError'),
      contacts: state.get('contacts').merge(payload.get('contacts')),
      phoneNumbers: state
        .get('phoneNumbers')
        .merge(payload.get('phoneNumbers')),
      emailAddresses: state
        .get('emailAddresses')
        .merge(payload.get('emailAddresses')),
    })
  },
  [GET_CONTACT_FAIL]: (state, { payload }) => {
    return state.merge({
      contactDetailsLoading: initialState().get('contactDetailsLoading'),
      contactDetailsError: payload.get('error'),
    })
  },

  [QUERY_CONTACTS]: state => {
    return state.merge({
      teamContactsLoading: true,
      teamContactsError: initialState().get('teamContactsError'),
    })
  },
  [QUERY_CONTACTS_SUCCESS]: (state, { payload }) => {
    return state.merge({
      contacts: state.get('contacts').merge(payload.get('contacts')),
      phoneNumbers: state
        .get('phoneNumbers')
        .merge(payload.get('phone_numbers')),
      emailAddresses: state
        .get('emailAddresses')
        .merge(payload.get('email_addresses')),
      teamContactsLoading: initialState().get('teamContactsLoading'),
      teamContactsError: initialState().get('teamContactsError'),
      teamContacts: state
        .get('teamContacts')
        .concat(payload.get('teamContacts')),
      teamContactsCount: payload.get('teamContactsCount'),
    })
  },
  [QUERY_CONTACTS_FAIL]: (state, { payload }) => {
    return state.merge({
      teamContactsLoading: initialState().get('teamContactsLoading'),
      teamContactsError: payload.get('error'),
    })
  },

  [CREATE_CONTACT]: state => {
    return state.merge({
      createContactLoading: true,
      createContactError: initialState().get('createContactError'),
    })
  },
  [CREATE_CONTACT_SUCCESS]: (state, { payload }) =>
    state.merge({
      createContactLoading: initialState().get('createContactLoading'),
      createContactError: initialState().get('createContactError'),
    }),
  [CREATE_CONTACT_FAIL]: (state, { payload }) => {
    return state.merge({
      createContactLoading: initialState().get('createContactLoading'),
      createContactError: payload.get('error'),
    })
  },

  [DELETE_CONTACT]: state => {
    return state.merge({
      deleteContactLoading: true,
      deleteContactError: initialState().get('deleteContactError'),
    })
  },
  [DELETE_CONTACT_SUCCESS]: (state, { payload: contactUuid }) => {
    return state.merge({
      deleteContactLoading: initialState().get('deleteContactLoading'),
      deleteContactError: initialState().get('deleteContactError'),
    })
  },
  [DELETE_CONTACT_FAIL]: (state, { payload }) => {
    return state.merge({
      deleteContactLoading: initialState().get('createContactLoading'),
      deleteContactError: payload.get('error'),
    })
  },

  [DELETE_ALL_IMPORTED_CONTACTS]: state => {
    return state.merge({
      deleteAllImportedContactsLoading: true,
      deleteAllImportedContactsError: initialState().get(
        'deleteAllImportedContactsError'
      ),
    })
  },
  [DELETE_ALL_IMPORTED_CONTACTS_SUCCESS]: state => {
    return state.merge({
      deleteAllImportedContactsLoading: initialState().get(
        'deleteAllImportedContactsLoading'
      ),
      deleteAllImportedContactsError: initialState().get(
        'deleteAllImportedContactsError'
      ),
      contacts: initialState().get('contacts'),
    })
  },
  [DELETE_ALL_IMPORTED_CONTACTS_FAIL]: (state, { payload }) => {
    return state.merge({
      deleteAllImportedContactsLoading: initialState().get(
        'deleteAllImportedContactsLoading'
      ),
      deleteAllImportedContactsError: payload.get('error'),
    })
  },

  [UPDATE_CONTACT_LOADED]: state => {
    return state.merge({
      updateContactLoading: initialState().get('updateContactLoading'),
    })
  },

  [UPDATE_CONTACT]: state => {
    return state.merge({
      updateContactLoading: true,
      updateContactError: initialState().get('updateContactError'),
    })
  },
  [UPDATE_CONTACT_SUCCESS]: (state, { payload }) => {
    return state.merge({
      updateContactLoading: initialState().get('updateContactLoading'),
      updateContactError: initialState().get('updateContactError'),
    })
  },
  [UPDATE_CONTACT_FAIL]: (state, { payload }) => {
    return state.merge({
      updateContactLoading: initialState().get('updateContactLoading'),
      updateContactError: payload.get('error'),
    })
  },

  [TOGGLE_NEW_CONTACT_FORM]: (state, { payload }) => {
    return state.merge({
      newContactFormOpen: payload,
    })
  },

  [UPLOAD_CONTACTS_IMPORT]: state => {
    return state.merge({
      uploadContactsResults: initialState().get('uploadContactsLoading'),
      uploadContactsLoading: true,
      uploadContactsError: initialState().get('uploadContactsError'),
    })
  },
  [UPLOAD_CONTACTS_IMPORT_SUCCESS]: (state, { payload }) => {
    return state.merge({
      uploadContactsResults: payload,
      uploadContactsLoading: initialState().get('uploadContactsLoading'),
      uploadContactsError: initialState().get('uploadContactsError'),
    })
  },
  [UPLOAD_CONTACTS_IMPORT_FAIL]: (state, { payload }) => {
    return state.merge({
      uploadContactsResults: initialState().get('uploadContactsResults'),
      uploadContactsLoading: initialState().get('uploadContactsLoading'),
      uploadContactsError: payload.get('error'),
    })
  },

  [CONTACTS_LOCK]: (state, { payload }) =>
    state.merge({
      contactsLockedTeamUuids: state
        .get('contactsLockedTeamUuids')
        .push(payload),
    }),
  [CONTACTS_UNLOCK]: (state, { payload }) =>
    state.merge({
      contactsLockedTeamUuids: state
        .get('contactsLockedTeamUuids')
        .filterNot(uuid => uuid === payload),
    }),

  [GET_EXPORT_JOB]: (state, { payload }) =>
    state.update('exportJobs', exportJobs => exportJobs.merge(payload)),

  [SOCKET_CONTACT]: (state, { payload }) => {
    const { entities } = normalize(payload.toJS(), contactsSchema.contact)
    const camelCaseEntities = rekeyObjectToCamelcase(entities)
    return state.mergeDeep(camelCaseEntities)
  },
  [SOCKET_DELETE_CONTACT]: (state, { payload }) =>
    state.merge({
      teamContacts: state
        .get('teamContacts')
        .filterNot(uuid => uuid === payload.get('uuid')),
      searchContactsUuids: state
        .get('searchContactsUuids')
        .filterNot(uuid => uuid === payload.get('uuid')),
    }),
  [SOCKET_EMAIL]: (state, { payload }) => {
    const { entities } = normalize(payload.toJS(), contactsSchema.emailAddress)
    const camelCaseEntities = rekeyObjectToCamelcase(entities)
    const contactUuid = payload.get('contact')
    const contact = state.getIn(['contacts', contactUuid])

    if (contact) {
      const uuid = payload.get('uuid')
      const emailExists = contact.get('email_addresses').includes(uuid)
      if (!emailExists) {
        _.set(
          camelCaseEntities,
          ['contacts', contactUuid, 'email_addresses'],
          contact.get('email_addresses').push(uuid)
        )
      }
    }

    return state.mergeDeep(camelCaseEntities)
  },
  [SOCKET_DELETE_EMAIL]: (state, { payload }) => {
    const uuid = payload.get('uuid')
    const contact = state
      .get('contacts')
      .find((value, key) => value.get('email_addresses').includes(uuid))

    if (!contact) return state

    return state.setIn(
      ['contacts', contact.get('uuid'), 'email_addresses'],
      contact
        .get('email_addresses')
        .filterNot(phone_uuid => phone_uuid === uuid)
    )
  },
  [SOCKET_PHONE]: (state, { payload }) => {
    const { entities } = normalize(payload.toJS(), contactsSchema.phoneNumber)
    const camelCaseEntities = rekeyObjectToCamelcase(entities)
    const contactUuid = payload.get('contact')
    const contact = state.getIn(['contacts', contactUuid])

    if (contact) {
      const uuid = payload.get('uuid')
      const phoneExists = contact.get('phone_numbers').includes(uuid)
      if (!phoneExists) {
        _.set(
          camelCaseEntities,
          ['contacts', contactUuid, 'phone_numbers'],
          contact.get('phone_numbers').push(uuid)
        )
      }
    }

    return state.mergeDeep(camelCaseEntities)
  },
  [SOCKET_DELETE_PHONE]: (state, { payload }) => {
    const uuid = payload.get('uuid')
    const contact = state
      .get('contacts')
      .find((value, key) => value.get('phone_numbers').includes(uuid))

    if (!contact) return state

    return state.setIn(
      ['contacts', contact.get('uuid'), 'phone_numbers'],
      contact.get('phone_numbers').filterNot(phone_uuid => phone_uuid === uuid)
    )
  },
}

export default handleActions(reducers, initialState())
