import _ from 'lodash'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useQueryParam } from 'use-query-params'
import { fromJS } from 'immutable'
import useAuth from 'hooks/useAuth'
import { authUserSelector } from 'selectors/authSelector'
import Spinner from 'components/Spinner'


const Microsoft = () => {
  const user = useSelector(authUserSelector)
  const { microsoftOAuthCallback } = useAuth()
  const mounted = useRef(false)
  const [code] = useQueryParam('code')
  const [state] = useQueryParam('state')
  const [sessionState] = useQueryParam('session_state')

  useEffect(() => {
    console.log({
      user,
      code,
      state,
      sessionState,
    })
    if (!mounted.current && _.every([code, state, sessionState])) {
      microsoftOAuthCallback(fromJS({ code, state, session_state: sessionState }))
      mounted.current = true
    }
  }, [user, code, state, sessionState])

  if (!user) return <Spinner />

  return (
    <>
      <h1>Microsoft Callback</h1>
      <Spinner />
      <p>Authenticating your Microsoft account...</p>
    </>
  )
}

export default Microsoft
