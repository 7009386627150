import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'

import { FloatingLabelFinalField as _FloatingLabelField } from 'components/FloatingLabelInput'

import AddConversationTimePickerSafari from './AddConversationTimePickerSafari'

const FloatingLabelField = styled(_FloatingLabelField).attrs(() => ({
  noFloat: true,
  hideErrorText: true,
}))``

const AddConversationTimePicker = styled(props => {
  const inputRef = useRef()
  const mounted = useRef(false)
  const [isCompatible, setIsCompatible] = useState(true)

  useEffect(() => {
    if (!mounted.current) {
      setIsCompatible(_.get(inputRef, 'current.type') === 'time')
      mounted.current = true
    }
  }, [inputRef.current])

  if (!isCompatible) return <AddConversationTimePickerSafari {...props} />

  return (
    <FloatingLabelField
      innerRef={inputRef}
      format={time => {
        if (!time) return ''
        return time
      }}
      parse={time => {
        if (!time) return null
        return time
      }}
      id="new-conversation-form-time"
      type="time"
      placeholder="Time"
      required
      {...props}
    />
  )
})`
  input {
    color: inherit !important;
  }
`

export default AddConversationTimePicker
