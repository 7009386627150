import React from 'react'
import { SearchIcon } from 'components/Icons'
import styled, { css } from 'styled-components'
import { media } from 'utility/styles'

const shared = css`
  height: 40px;
  width: 305.42px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 50px/50px;
  position: relative;
  overflow: hidden;
  box-shadow: none;
`

const Input = styled.input`
  ${shared}
  border: none;
  &:focus {
    outline: none;
  }
`

const RoundInput = ({
  left,
  right,
  id = `_${Math.random()
    .toString(36)
    .substr(2, 9)}`,
  ...props
}) => (
  <Label htmlFor={id}>
    {left && left}
    <Input id={id} className="input" autoComplete="off" {...props} />
    {right && right}
  </Label>
)

const Label = styled.label`
  ${shared}
  border: 1px solid #CCCCCC;
  &:focus-within {
    background-color: white;
    box-shadow: 0 0 3pt 1pt cornflowerblue;
  }
  margin-right: 12px;
  ${media.tablet`
    flex: 1;
  `}
`

export const ContactSearchInput = ({
  onEnter,
  value,
  onClear,
  onChange,
  ...props
}) => (
  <RoundInput
    onChange={onChange}
    left={
      <span style={{ color: '#CCCCCC', fontSize: 18, margin: '0 10px 0 16px' }}>
        <SearchIcon />
      </span>
    }
    {...props}
  />
)
