const colors = {
  blue: '#2A74CA',
  darkGray: '#434A54',
  gray: '#9d9d9d',
  grayAlt: '#9b9b9b',
  green: '#417505',
  headerText: '#1e1e1e',
  lighterGray: '#e6e6e6',
  lightGray: '#d7d7d7',
  offWhite: '#f6f6f6',
  orange: '#f5a623',
  red: '#e9573f',
  warningRed: '#d9534f', // using the Floating Label warning color; mocks differ slightly
  white: '#ffffff',
}

const statusColors = {
  red: '#d0021b',
  orange: '#ef6800',
  yellow: '#ffbe00',
  green: colors.green,
  lightGreen: '#8ac44a',
  purple: '#663ab7',
  darkBlue: '#253798',
  blue: '#048bf8',
  teal: '#0bcdc9',
  magenta: '#ea1f62',
  black: '#000000',
  gray: '#8d8d8d',
  lightGray: '#bababa',
  slate: '#607d8b',
  brown: '#795548',
}

const fonts = {
  main: "'Lato', sans-seriff",
}

export default {
  fonts,
  colors,
  statusColors,
  page: {
    headingColor: '#333333',
    subheadingColor: '#737373',
    headingMarginVertical: '30px',
    paddingHorizontal: '30px',
    paddingVertical: '48px',
    home: {
      paddingHorizontal: 0,
    },
  },
  nav: {
    marginHorizontal: '30px',
  },
  tabs: {
    tabLinkBg: colors.offWhite,
    tabLinkHoverBg: '#e9e9e9',
    tabDiabledLinkColor: colors.grayAlt,
    tabsLinkBorderColor: colors.lighterGray,
    tabsLinkHoverBorderColor: colors.lighterGray,
    tabActiveLinkHoverBg: colors.white,
    tabsActiveLinkHoverBorderColor: colors.lighterGray,
  },
  table: {
    titleColor: colors.darkGray,
    lightColor: colors.gray,
    rowBorderColor: colors.lighterGray,
    rowHoverBackground: colors.offWhite,
    editButtonColor: colors.grayAlt,
    headerColor: '#b2b2b2',
    highlightColor: colors.orange,
  },
  forms: {
    inputHeight: '42px',
    inputBorder: '1px solid #d8d8d8',
    sortHandleColor: '#ccd1d9',
    sortItemBorderColor: '#e1e2e2',
    colorSwatchBorderColor: '#979797',
    inputInnerButtonBgColor: colors.offWhite,
    formInfoColor: colors.grayAlt,
    formNoticeOkBg: '#dcefc8',
    formNoticeErrorBg: '#f5ebe9',
  },
  buttons: {
    defaultWidth: '146px',
    buttonLightColor: colors.grayAlt,
  },
  cursorDisabled: 'not-allowed',
  layers: {
    overlay: 900, // addcallform
    overlayPopup: 1000, // addcallform datepicker
    overlayModal: 1100, // contactform
    modalAbove: 1110, // drag-and-drop sortable elements in modal
  },
  breakpoints: {
    xsMax: '767px',
    smMax: '991px',
  },
}
