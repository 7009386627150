import styled from 'styled-components'
import { string, any } from 'prop-types'
import _Button from './Button'
import { Icon } from '../Icons'

const IconButton = ({ type, children, color, ...props }) => (
  <Button {...props}>
    <Icon type={type} />
    {children}
  </Button>
)

IconButton.propTypes = {
  type: string.isRequired,
  children: any.isRequired,
}

const Button = styled(_Button)`
  &,
  &.btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid ${props => props.theme.colors.lightGray};
    color: ${props => props.theme.colors.darkGray};
    font-size: 12px;
    ${Icon} {
      margin-right: 8px;
      font-size: 20px;
      color: ${props => props.color || props.theme.colors.warningRed};
    }
  }
`

export default styled(IconButton)``
