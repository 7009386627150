import React, { useRef } from 'react'
import styled, { css } from 'styled-components'

import { Dropdown, Option as _Option, DropdownList } from 'components/Dropdown'

const ColorDot = styled.div`
  width: 34px;
  height: 34px;
  display: inline-block;
  border-radius: 50%;
  background-color: #${({ color }) => color || '000000'};
  cursor: pointer;
`

const Option = styled(_Option)`
  display: inline-block;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid transparent;
  font-size: 0;
  margin: 2px;

  &:hover {
    border-color: #979797;
  }

  ${({ active }) => active && css`
    border-color: #979797;

    &:before {
      display: none;
    }
  `}
`

const StatusColorDropdown = styled(({
  input: { value: selectedColor, onChange }, meta, ...props
}) => {
  const _dropdownHolder = useRef(null)
  const colorOptions = __CONFIG__.statuses.colors

  return (
    <div {...props}>
      <Dropdown
        ref={_dropdownHolder}
        toggle={<ColorDot color={selectedColor} />}
        right
      >
        {colorOptions.map(colorOption => (
          <Option
            active={colorOption === selectedColor}
            id={colorOption}
            key={colorOption}
            onSelect={() => {
              onChange(colorOption)
              _dropdownHolder.current.closeDropdown()
            }}
          >
            <ColorDot color={colorOption} />
          </Option>
        ))}
      </Dropdown>
    </div>
  )
})`
  position: relative;

  ${DropdownList} {
    padding: 14px;
    width: 278px;
  }
`

export default StatusColorDropdown
