import { css } from 'styled-components'

export const sizes = {
  desktop: 1024,
  tablet: 768,
  phone: 480,
}

/**
 * Iterate through the sizes and create a media template
 */
export const media = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)}
    }
  `
  return accumulator
}, {})

/**
 * Only shows X number of lines specified
 */
export const onlyShow = num => ({
  line: css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `,
  lines: css`
    display: -webkit-box;
    -webkit-line-clamp: ${num};
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,
})
