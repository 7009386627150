import { Form as ReactFinalForm } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import { fromJS } from 'immutable'
import _ from 'lodash'

import useOrganizations from 'hooks/useOrganizations'

import { FloatingLabelFinalField } from 'components/FloatingLabelInput'
import { FormRow, Form as _Form, FormError } from 'components/Form'
import Button from 'components/Buttons'

import validate from './validate'

const OrganizationCreateForm = ({
  error,
  handleSubmit,
  invalid,
  pristine,
  submitError,
  submitting,
}) => {
  const {
    createOrganizationLoading,
    createOrganizationError,
  } = useOrganizations()
  const createOrganizationErrorText = createOrganizationError
    ? createOrganizationError.getIn(['data', 'detail']) ||
      createOrganizationError.get('message')
    : null

  return (
    <Form onSubmit={handleSubmit}>
      <Title>Create A Team</Title>

      <p>
        You don&apos;t belong to any organizations yet. If you&apos;re setting
        up PhoneSlate for yourself or your company, create an organization and
        team now. Otherwise, ask your organization admin to invite you to an
        existing PhoneSlate organization.
      </p>

      <FormRow>
        <FloatingLabelFinalField
          id="organization_name"
          name="organization.name"
          type="text"
          placeholder="Organization name"
        />
      </FormRow>

      <FormRow>
        <FloatingLabelFinalField
          id="team_name"
          name="team.name"
          type="text"
          placeholder="Team name"
        />
      </FormRow>

      {!!(createOrganizationErrorText || submitError) && (
        <FormError>{submitError || createOrganizationErrorText}</FormError>
      )}

      {!!error && !pristine && <FormError>{error}</FormError>}

      <FormRow half>
        <Button
          red
          disabled={
            submitting || invalid || pristine || createOrganizationLoading
          }
          loading={submitting || createOrganizationLoading}
          loadingText="Creating Team"
          onClick={handleSubmit}
          type="submit"
        >
          Create Team
        </Button>
      </FormRow>
    </Form>
  )
}

const OrganizationCreateFormWrapper = props => {
  const { createOrganization } = useOrganizations()
  const { replace } = useHistory()

  return (
    <ReactFinalForm
      onSubmit={values =>
        new Promise((resolve, reject) => {
          createOrganization(fromJS({ ...values, resolve, reject }))
        })
          .then(() => {
            replace('/')
          })
          .catch(({ errors }) => {
            if (!_.isEmpty(errors)) {
              return _.mapValues(errors, error => {
                if (!_.isArray(error)) return error

                return _.first(error)
              })
            }
          })
      }
      validate={validate}
    >
      {_props => <OrganizationCreateForm {...props} {..._props} />}
    </ReactFinalForm>
  )
}

const Form = styled(_Form)``
const Title = styled.h1`
  color: #1e1e1e;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0.68px;
  line-height: 1.5;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0;
`

export default OrganizationCreateFormWrapper
