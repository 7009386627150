import { useEffect } from 'react'
import usePortal from 'react-useportal'

const Dropdown = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  padding: 20px;
  display: flex;
  flex-direction: column;
`

export const useDropdown = ({
  width = 200, height = 400, onOpen, ...config
} = {}) => {
  const {
    isOpen, togglePortal, Portal, ref: targetRef, portalRef,
  } = usePortal({
    onOpen({ portal, targetEl: clickedEl, event }) {
      const {
        top, bottom, left, right,
      } = clickedEl.current.getBoundingClientRect()
      let l = left
      let t = top + clickedEl.current.clientHeight
      const outRight = window.innerWidth < left + width
      const outBottom = window.innerHeight < top + portal.current.clientHeight
      const outTop = false
      const outLeft = false
      if (outRight) {
        l = window.innerWidth - (right - left + width)
      } else if (outLeft) { /* very uncommon, implement later */ }
      if (outBottom) {
        t = window.innerHeight - (bottom - top + height)
      } else if (outTop) { /* very uncommon, implement later */ }
      // eslint-disable-next-line no-param-reassign
      portal.current.style.cssText = `
        width: ${width}px;
        position: absolute;
        top: ${t}px;
        left: ${l}px;
        background: #ffff;
        cursor: pointer;
      `
      if (onOpen) onOpen({ portal, targetEl: clickedEl, event })
    },
    ...config,
  })

  const onResize = ({ target: screen }) => {
    if (targetRef.current) {
      const { left, right } = targetRef.current.getBoundingClientRect()
      const l = screen.innerWidth - (right - left + width)
      portalRef.current.style.left = `${l}px`
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  })

  return {
    Dropdown: props => <Portal><Dropdown {...props} /></Portal>,
    toggleDropdown: togglePortal,
    isOpen,
  }
}

export default useDropdown
