import { useSelector, useDispatch } from 'react-redux'
import { actions as organizationsActions } from 'modules/organizations'
import {
  currentOrganizationInvoiceUuidsSelector,
  organizationInvoiceByUuidSelector,
  queryOrganizationInvoicesLoadingSelector,
} from 'selectors/organizationsSelector'

export default function useOrganizationInvoices(uuid = null) {
  const dispatch = useDispatch()

  const currentOrganizationInvoiceUuids = useSelector(
    currentOrganizationInvoiceUuidsSelector
  )
  const organizationInvoiceByUuid = useSelector(state =>
    organizationInvoiceByUuidSelector(state, { uuid })
  )
  const queryOrganizationInvoicesLoading = useSelector(
    queryOrganizationInvoicesLoadingSelector
  )

  const queryOrganizationInvoices = values => {
    if (!queryOrganizationInvoicesLoading) {
      dispatch(organizationsActions.queryOrganizationInvoices(values))
    }
  }

  return {
    currentOrganizationInvoiceUuids,
    organizationInvoiceByUuid,
    queryOrganizationInvoices,
    queryOrganizationInvoicesLoading,
  }
}
