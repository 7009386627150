import styled from 'styled-components'

import { media } from 'utility/styles'

export default (styled.div || styled.View)`
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  background-color: #ffffff;
  padding: 58px;

  ${media.tablet`
    box-shadow: none;
    border-radius: 0;
    height: 100%;
  `}
`
