import styled from 'styled-components'
import { Icon } from '../Icons'

const AddIcon = styled(Icon).attrs(() => ({
  type: 'plus',
}))`
  color: ${props => props.theme.colors.white};
  font-size: 28px;
`

export default AddIcon
