import { object, string } from 'prop-types'
import styled, { css } from 'styled-components'

const TextInputField = ({
  input, meta, className, ...props
}) => (
  <InputRow className={className}>
    <Input {...input} {...props} type='text' />
    {meta.touched && meta.error
    && <ErrorMsg>{meta.error}</ErrorMsg>}
  </InputRow>
)

TextInputField.propTypes = {
  input: object.isRequired,
  meta: object.isRequired,
  className: string,
}

TextInputField.defaultProps = {
  className: null,
}

const InputRow = (styled.div || styled.View)`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 20px;
`

const Input = (styled.input || styled.TextInput)`
  width: 100%;
  height: ${props => props.theme.forms.inputHeight};
  border: ${props => props.theme.forms.inputBorder};
  padding: 10px;
`

const ErrorMsg = (styled.span || styled.Text)`
  color: ${props => props.theme.colors.warningRed};
  bottom: 0;
  display: inline-block;
  font-size: 13px;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  word-break: break-all;
  word-wrap: break-word;
`

export default styled(TextInputField)`
  ${props => props.meta.error && css`
    ${Input} {
      border-color: ${props.theme.colors.warningRed};
    }
  `}
`
