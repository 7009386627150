import { Switch } from 'react-router-dom'
import Route from 'components/Route'
import RedirectToTeamPage from 'components/Page/RedirectToTeamPage'
import NavBar from 'components/NavBar/DefaultNavBar'

import ContactsList from './List'
import ContactsImport from './Import'
import Sync from './Microsoft/Sync'

const ContactsPage = () => (
  <RedirectToTeamPage navBar={NavBar}>
    <Switch>
      <Route
        exact
        path="/contacts/:team_id/microsoft"
        component={Sync}
        loginRequired
      />
      <Route
        exact
        path="/contacts/:team_id/import"
        component={ContactsImport}
        loginRequired
      />
      <Route
        exact
        path="/contacts/:team_id?"
        component={ContactsList}
        loginRequired
      />
    </Switch>
  </RedirectToTeamPage>
)

export default ContactsPage
