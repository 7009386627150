import CookieConsent from 'react-cookie-consent'
import CTAButton from 'components/Buttons/CTAButton'

const CookieButton = styled(CTAButton)`
  flex: 0 0 auto;
  font-size: inherit;
  line-height: 1.3;
  margin: 15px;
`

const PhoneSlateCookieConsent = () => (
  <CookieConsent ButtonComponent={CookieButton} disableButtonStyles>
    This website uses cookies to enhance the user experience.
  </CookieConsent>
)

export default PhoneSlateCookieConsent
