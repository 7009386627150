import _ from 'lodash'
import { createSelector } from 'reselect'
import { fromJS } from 'immutable'

import { getDateAndTime } from 'utility/helpers'
import { diffMap } from 'utility/immutable'

import { getCurrentTeamMembersUserUuids } from 'selectors/teamsSelector'
import { getCurrentTeamStatusUuids } from 'selectors/statusesSelector'

const passProps = (state, props) => props

export const formDataSelector = createSelector(
  state => state.get('form'),
  formState => formState
)

export const formSubmissionSuccessful = createSelector(
  formDataSelector,
  passProps,
  (formData, { form }) => {
    return _.get(formData, [form, 'submitSucceeded'])
  }
)

export const addConversationFormInitialValuesSelector = createSelector(
  getCurrentTeamStatusUuids,
  getCurrentTeamMembersUserUuids,
  (statuses, members) => {
    const conversationFormInitialValues = fromJS({
      owner: (members && members.first()) || null,
      selected_phone_number: null,
      contact: null,
      message: '',
      subject: '',
      call: {
        status: (statuses && statuses.first()) || null,
        ...getDateAndTime(),
      },
    })

    return conversationFormInitialValues
  }
)

export const getFormByName = createSelector(
  formDataSelector,
  passProps,
  (formData, { form }) => formData.get(form)
)

export const getFormValues = createSelector(
  formDataSelector,
  passProps,
  (formData, { form }) => formData.getIn([form, 'values'])
)

export const getFormInitialValues = createSelector(
  formDataSelector,
  passProps,
  (formData, { form }) => formData.getIn([form, 'initial'])
)

/**
 *
 * Conversation Filters
 *
 */

export const getConversationFiltersForm = createSelector(
  state => state,
  state => getFormByName(state, { form: 'conversationFilters' })
)

export const getConversationFiltersFormValues = createSelector(
  getConversationFiltersForm,
  form => (form && form.get('values')) || fromJS({})
)

export const getConversationFiltersFormInitialValues = createSelector(
  getConversationFiltersForm,
  form => (form && form.get('initial')) || fromJS({})
)

export const getConversationFiltersLoaded = createSelector(
  getConversationFiltersForm,
  form => !!form
)

export const getEmptyConversationFiltersValues = createSelector(
  state => state,
  () =>
    fromJS({
      sort: 'newest',
      callsheets: [],
      statuses: [],
      show_completed: false,
      last_call_time: [null, null],
      keyword: '',
      contact_uuid: null,
    })
)

export const getActiveConversationFilters = createSelector(
  getConversationFiltersFormValues,
  getEmptyConversationFiltersValues,
  (filters, emptyValues) => diffMap(filters, emptyValues)
)
