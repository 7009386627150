import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import { fromJS } from 'immutable'

import { actions as conversationsActions } from 'modules/conversations'

import {
  getLastCallTimeFromUuid,
  getLastCallUuidFromUuid,
} from 'selectors/conversationsSelector'

import TimePickerSafari from './TimePickerSafari'

const Input = styled.input`
  border: 1px solid transparent;
  background-color: transparent;
  outline: none !important;
  appearance: none;
  display: block;
  width: 100%;
  background-image: none;
  border-radius: 3px;
  padding: 4px;

  &:focus,
  &:hover {
    border-color: #cbcbcb;
    background-color: #fff;
  }
`

const DATE_FORMAT = moment.HTML5_FMT.TIME

const TimePicker = ({ conversationUuid, ...props }) => {
  const dispatch = useDispatch()
  const inputRef = useRef()
  const mounted = useRef(false)
  const [isCompatible, setIsCompatible] = useState(true)

  const callTime = useSelector(state =>
    getLastCallTimeFromUuid(state, { uuid: conversationUuid })
  )

  const callUuid = useSelector(state =>
    getLastCallUuidFromUuid(state, { uuid: conversationUuid })
  )

  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(callTime ? moment(callTime).format(DATE_FORMAT) : '')
  }, [callTime])

  useEffect(() => {
    if (!mounted.current) {
      setIsCompatible(_.get(inputRef, 'current.type') === 'time')
      mounted.current = true
    }
  }, [inputRef.current])

  const onChange = ({ target: { value: _value } }) => {
    setValue(_value)
  }

  const onBlur = () => {
    dispatch(
      conversationsActions.updateCall(
        fromJS({
          conversationUuid,
          time: moment(value, DATE_FORMAT).clone(),
          uuid: callUuid,
        })
      )
    )
  }

  if (!isCompatible) {
    return <TimePickerSafari conversationUuid={conversationUuid} {...props} />
  }

  return (
    <div {...props}>
      <Input
        ref={inputRef}
        placeholder="Time"
        type="time"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        required
      />
    </div>
  )
}

export default TimePicker
