import styled from 'styled-components'

import { media } from 'utility/styles'

import theme from './theme'

export default (styled.div || styled.View)`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${theme.filterBg};
  border-right: 1px solid ${theme.filterBorderColor};
  flex: 0 0 160px;
  .toggle { height: 100%; }

  ${media.desktop`
    flex: 1;
    border-bottom: 1px solid ${theme.filterBorderColor};
  `}
`
