import { schema } from 'normalizr'

const idAttribute = 'uuid'

export const member = new schema.Entity('members', undefined, { idAttribute })
export const team = new schema.Entity('teams', undefined, { idAttribute })
export const invite = new schema.Entity('invites', undefined, { idAttribute })
export const invoice = new schema.Entity('invoices', undefined, { idAttribute })

export const organization = new schema.Entity(
  'organizations',
  {
    members: [member],
    teams: [team],
  },
  { idAttribute }
)
