import { createAction, handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

const SOCKET_CONNECTED = 'app/auth/SOCKET_CONNECTED'
const SOCKET_DISCONNECTED = 'app/auth/SOCKET_DISCONNECTED'

export const constants = {
  SOCKET_CONNECTED,
  SOCKET_DISCONNECTED,
}

// ------------------------------------
// Actions
// ------------------------------------
export const socketConnected = createAction(SOCKET_CONNECTED)

export const socketDisconnected = createAction(SOCKET_DISCONNECTED)

export const actions = {
  socketConnected,
  socketDisconnected,
}

export const initialState = () =>
  fromJS({
    socketConnected: false,
    rooms: [],
  })

export const reducers = {
  [SOCKET_CONNECTED]: state =>
    state.merge({
      socketConnected: true,
    }),
  [SOCKET_DISCONNECTED]: state =>
    state.merge({
      socketConnected: initialState.get('socketConnected'),
    }),
}

export default handleActions(reducers, initialState())
