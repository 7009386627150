import { RegisterFullForm } from 'components/AuthForms'
import AuthPage from 'components/Page/AuthPage'
import AuthCard from 'components/Cards/AuthCard'

const FORM_TITLE = 'Sign Up'

const Activate = ({
  className, registrationToken, title, staticContext, ...props
}) => (
  <AuthPage {...props}>
    <AuthCard>
      <RegisterFullForm title={FORM_TITLE} />
    </AuthCard>
  </AuthPage>
)

export default Activate
