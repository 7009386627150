import styled from 'styled-components'

import {
  string, arrayOf, shape, func,
} from 'prop-types'
import Route from 'components/Route'
import { Redirect, Switch } from 'react-router-dom'
import TabsList from './TabsList'
import TabItem from './TabItem'
import TabLink from './TabLink'
import TabContent from './TabContent'

const Tabs = styled(props => {
  const { className, tabs = [] } = props

  return (
    <>
      <TabsList className={className}>
        {tabs.map((tab, key) => (
          <TabItem key={key} disabled>
            <TabLink
              to={tab.path}
              replace
              activeClassName='active'
            >
              {tab.name}
            </TabLink>
          </TabItem>
        ))}
      </TabsList>

      <TabContent>
        <Switch>
          {tabs.map((tab, key) => (
            <Route
              key={key}
              path={tab.path}
              component={tab.component}
            />
          ))}
          <Redirect replace to={tabs[0].path} />
        </Switch>
      </TabContent>
    </>
  )
})``

Tabs.propTypes = {
  tabs: arrayOf(shape({
    name: string.isRequired,
    path: string.isRequired,
    component: func.isRequired,
  })).isRequired,
  className: string,
}

Tabs.defaultProps = {
  className: '',
}

export default Tabs
