import { createSelector } from 'reselect'

import { getPhoneNumberUuidsFromContactUuid } from 'selectors/contactsSelector'

const passProps = (state, props) => props

export const conversationsDataSelector = createSelector(
  state => state.get('conversations'),
  conversationsState => conversationsState
)

export const getConversations = createSelector(
  conversationsDataSelector,
  conversationsData => conversationsData.get('conversations')
)

export const getConversationUuids = createSelector(
  conversationsDataSelector,
  conversationsData => conversationsData.get('conversationUuids')
)

export const getConversationByUuid = createSelector(
  getConversations,
  passProps,
  (conversationUuids, { uuid }) => conversationUuids.get(uuid)
)

export const isGetConversationLoadingSelector = createSelector(
  conversationsDataSelector,
  passProps,
  (conversationsData, props) => {
    return !!conversationsData
      .get('getConversationLoadingList')
      .find(_uuid => _uuid === props.uuid)
  }
)

export const getConversationsCount = createSelector(
  conversationsDataSelector,
  contactsData => contactsData.get('conversationsCount')
)

export const getConversationsLength = createSelector(
  getConversationUuids,
  uuids => uuids.count()
)

export const getConversationsHasMore = createSelector(
  getConversationsCount,
  getConversationsLength,
  (count, length) => count > length
)

/**
 *
 * CALLS
 */
export const getAllCalls = createSelector(
  conversationsDataSelector,
  conversationsData => conversationsData.get('calls')
)

export const getCallByUuid = createSelector(
  getAllCalls,
  passProps,
  (calls, { uuid }) => calls.get(uuid)
)

export const getCallsByConversationUuid = createSelector(
  getConversationByUuid,
  conversation => (conversation && conversation.get('calls')) || null
)

export const getConversationsLoading = createSelector(
  conversationsDataSelector,
  conversationsData => conversationsData.get('conversationsLoading')
)

/**
 *
 * CONTACT
 */
export const getConversationContactUuidFromUuid = createSelector(
  getConversationByUuid,
  conversation => (conversation && conversation.get('contact')) || null
)

/**
 *
 * ALL PHONE NUMBERS
 */
export const getAllPhoneNumbersFromUuid = createSelector(
  getConversationContactUuidFromUuid,
  state => state,
  (contactUuid, state) =>
    getPhoneNumberUuidsFromContactUuid(state, { uuid: contactUuid })
)

/**
 *
 * SELECTED PHONE NUMBER
 */
export const getSelectedPhoneNumberFromUuid = createSelector(
  getConversationByUuid,
  conversation =>
    (conversation && conversation.get('selected_phone_number')) || null
)

/**
 *
 * FIRST PHONE NUMBER
 */
export const getFirstPhoneNumberFromUuid = createSelector(
  getAllPhoneNumbersFromUuid,
  phonenumbers => phonenumbers.first()
)

/**
 *
 * SELECTED OR FIRST PHONE NUMBER
 */
export const getSelectedOrFirstPhoneNumberFromUuid = createSelector(
  getSelectedPhoneNumberFromUuid,
  getFirstPhoneNumberFromUuid,
  (selectedPhoneNumber, firstPhoneNumber) =>
    selectedPhoneNumber || firstPhoneNumber
)

/**
 *
 * HAS DETAILS
 */
export const getHasDetailsFromUuid = createSelector(
  getConversationByUuid,
  conversation => (conversation && conversation.get('_has_details')) || null
)

/**
 *
 * SUBJECT
 */
export const getSubjectFromUuid = createSelector(
  getConversationByUuid,
  conversation => (conversation && conversation.get('subject')) || null
)

/**
 *
 * STATUS
 */
export const getStatusFromUuid = createSelector(
  getConversationByUuid,
  conversation => (conversation && conversation.get('status')) || null
)

/**
 *
 * LAST CALL TIME
 */
export const getLastCallTimeFromUuid = createSelector(
  getConversationByUuid,
  conversation => (conversation && conversation.get('last_call_time')) || null
)

/**
 *
 * LAST CALL ID
 */
export const getLastCallUuidFromUuid = createSelector(
  getConversationByUuid,
  conversation => (conversation && conversation.get('last_call_id')) || null
)

/**
 *
 * MESSAGE
 */
export const getMessageFromUuid = createSelector(
  getConversationByUuid,
  conversation => (conversation && conversation.get('message')) || null
)

/**
 *
 * OWNER
 */
export const getOwnerUuidFromUuid = createSelector(
  getConversationByUuid,
  conversation => (conversation && conversation.get('owner')) || null
)

export const getUpdateCallLoadingList = createSelector(
  conversationsDataSelector,
  conversationsData => conversationsData.get('updateCallLoadingList')
)

export const getConversationFormOpen = createSelector(
  conversationsDataSelector,
  conversationData => conversationData.get('conversationFormOpen')
)
