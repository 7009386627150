import jsonwebtoken from 'jsonwebtoken'
// import jsrsasign from 'jsrsasign'
import moment from 'moment'

export function isValidToken(token, jwtPublicKey) {
  if (!(token && jwtPublicKey)) return false

  try {
    // const pubKeyObj = jsrsasign.KEYUTIL.getKey(jwtPublicKey)
    // const isValid = jsrsasign.jws.JWS.verifyJWT(token, pubKeyObj, {
    //   alg: ['RS256'],
    // })

    const isValid = jsonwebtoken.verify(token, jwtPublicKey, {
      algorithms: ['RS256'],
    })

    if (!isValid && window && window._rollbar) {
      window._rollbar.error('isValidToken: Invalid JWT token', {
        token,
        jwtPublicKey,
      })
    }

    return !!isValid
  } catch (error) {
    console.log(error)
    if (window && window._rollbar) {
      window._rollbar.error('isValidToken: error', {
        error,
        token,
        jwtPublicKey,
      })
    }

    return false
  }
}

export function decodeToken(token) {
  if (!token) return {}
  // const a = token.split('.')
  // const decodedToken = jsrsasign.KJUR.jws.JWS.readSafeJSONString(
  //   jsrsasign.b64utoutf8(a[1])
  // )
  const decodedToken = jsonwebtoken.decode(token)
  return decodedToken
}

export function isExpired(token, buffer = 0) {
  const { exp } = decodeToken(token)
  return moment()
    .add(buffer, 'ms')
    .isAfter(moment(exp, 'X'))
}

export function expiresIn(token) {
  const { exp } = decodeToken(token)
  return moment(exp, 'X').diff(moment())
}
