import styled, { css } from 'styled-components'

export default (styled.div || styled.View)`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.right ? 'flex-end' : 'flex-start')};
  margin-top: 10px;
  min-height: 26px;
  &:first-of-type {
    margin-top: 0;
  }

  .field-input {
    flex: 1;
  }

  ${props =>
    props.half &&
    css`
      > * {
        flex: 1 1 50%;
        + * {
          margin-left: ${props.doubleMargin ? '30px' : '15px'};
        }
      }
      ${props.alignLeft &&
        css`
          > * {
            flex: 0 1 auto;
          }
        `}
    `}
`
