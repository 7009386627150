import styled from 'styled-components'
import { darken } from 'polished'
import { Icon } from '../Icons'

export default styled(({ children, type, ...props }) => (
  <Button type="button" {...props}>
    <Icon type={type} />
    {children}
  </Button>
))``

const Button = (styled.button || styled.Button)`
  display: inline-flex;
  color: ${props => props.theme.buttons.buttonLightColor};
  padding: 4px 2px;
  border: none;
  line-height: 21px;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  text-transform: uppercase;

  &,
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  ${Icon} {
    font-size: 21px;
    margin-right: 6px;
    color: ${props => props.color || props.theme.colors.warningRed};
  }

  &:hover {
    ${Icon} {
      color: ${props =>
        darken(0.1, props.color || props.theme.colors.warningRed)};
    }
  }
`
