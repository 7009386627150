import _ from 'lodash'
import { Set, Iterable } from 'immutable'

export const not = predicate => !predicate.apply(this, arguments) // eslint-disable-line no-undef

export const keyIn = (keys) => {
  const keySet = Set(keys)
  return (v, k) => keySet.has(k)
}

export const diffMap = (values, defaultValues) => values.filter((value, key) => {
  const initValue = defaultValues.get(key)

  if (Iterable.isIterable(value)) {
    return !value.equals(initValue)
  }

  return !_.isEqual(value, initValue)
})
