import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Link as _Link } from 'react-router-dom'
import { Dropdown, Title } from 'components/Dropdown'
import NavLink from 'components/NavLink'

import {
  organizationsDataSelector,
  isAdminSelector,
} from 'selectors/organizationsSelector'
import { currentTeamSelector } from 'selectors/teamsSelector'
import useAuth from 'hooks/useAuth'
import { onlyShow } from 'utility/styles'

import _LogoutLink from './LogoutLink'

const LinkCss = `
  color: inherit;
  display: block;
  padding: 3px 24px 3px 24px;
`

const Link = styled(_Link)`
  ${LinkCss}
`

const DumbLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  display: block;
  padding: 3px 24px 3px 24px;
  cursor: pointer;

  &.muted {
    padding: 3px 20px;
    color: #afafaf !important;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    text-transform: uppercase;
    font-family: Lato;
  }
`

const LogoutLink = styled(_LogoutLink)`
  ${LinkCss}
  width: 100%;
  text-align: left;
  text-transform: none;
  font-weight: normal;
  color: #434a54;
  transition: none;
`

const ProfileDropdown = styled(({ ...props }) => {
  const { user } = useAuth()
  const { isAdmin, currentTeam, currentOrgId } = useSelector(state => {
    const organizationsData = organizationsDataSelector(state)
    return {
      currentOrgId: organizationsData.get('currentOrganizationId'),
      isAdmin: isAdminSelector(state),
      currentTeam: currentTeamSelector(state),
    }
  })
  if (!user) return <NavLink to="/login">Login</NavLink>

  let dropdownHolder = {}

  const DropdownLink = _props => (
    <Link
      onClick={dropdownHolder.closeDropdown}
      className="dropdownItem"
      {..._props}
    />
  )

  // TODO: Need to sort out link behavior. Right now, currentTeamId can be in different
  // org than currentOrg. Which to prioritize for best user experience?
  let settingsOrgId = null
  if (currentOrgId) {
    settingsOrgId = currentOrgId
  } else if (currentTeam) {
    settingsOrgId = currentTeam.get('organization')
  }

  return (
    <Dropdown
      ref={_dropdown => {
        dropdownHolder = _dropdown
      }}
      toggle={<Name>{user && (user.display_name || user.email)}</Name>}
      onSelect={() => {}}
      right
      {...props}
    >
      <DropdownLink to="/account">Account</DropdownLink>
      {isAdmin && (
        <DropdownLink to={`/settings${settingsOrgId && `/${settingsOrgId}`}`}>
          Settings
        </DropdownLink>
      )}
      <DumbLink
        className="dropdownItem"
        href="https://phoneslate.zendesk.com/hc/en-us/articles/360046847894-PhoneSlate-FAQ"
      >
        FAQ
      </DumbLink>
      {false && <DropdownLink to="/tutorial">Tutorial</DropdownLink>}
      <LogoutLink />
      <Title>Bug? Feature request? Email us!</Title>
      <DumbLink
        className="dropdownItem muted"
        href="mailto:support@phoneslate.com"
      >
        support@phoneslate.com
      </DumbLink>
      <DumbLink
        className="dropdownItem muted"
        href="https://app.termly.io/document/privacy-policy/f5582ae9-bf0e-44c1-b588-0390c7e2f887"
      >
        Privacy Policy
      </DumbLink>
      <DumbLink
        className="dropdownItem muted"
        href="https://app.termly.io/document/terms-of-use-for-saas/1046dca2-10cb-4b0b-8f24-b9488ff623a5"
      >
        Terms & Conditions
      </DumbLink>
    </Dropdown>
  )
})``

const Name = styled.span`
  ${onlyShow(1).line}
`

export default ProfileDropdown
