import _ from 'lodash'
import ApiClient from 'components/ApiClient'
import {
  organization as organizationSchema,
  member as memberSchema,
  invite as inviteSchema,
  invoice as invoiceSchema,
} from '../schemas/organizations'

const client = new ApiClient()
const API_ENDPOINT = __CONFIG__.apiPath.organizations

export const createOrganization = data =>
  client.post(API_ENDPOINT.base, { data, schema: organizationSchema })

export const deleteOrganization = uuid =>
  client.delete(_.replace(API_ENDPOINT.get, ':uuid', uuid), {
    schema: organizationSchema,
  })

export const getOrganization = uuid =>
  client.get(_.replace(API_ENDPOINT.get, ':uuid', uuid), {
    schema: organizationSchema,
  })

export const queryOrganizations = () =>
  client.get(API_ENDPOINT.base, { schema: [organizationSchema], isArray: true })

export const updateOrganization = (uuid, data) =>
  client.patch(_.replace(API_ENDPOINT.get, ':uuid', uuid), {
    data,
    schema: organizationSchema,
  })

export const queryMembers = (organizationUuid, keyword) =>
  client.get(
    _.replace(API_ENDPOINT.members.base, ':organizationUuid', organizationUuid),
    {
      schema: [memberSchema],
      params: { search: keyword },
      isArray: true,
    }
  )

export const queryOrganizationMembers = organizationUuid =>
  client.get(
    _.replace(API_ENDPOINT.members.base, ':organizationUuid', organizationUuid),
    {
      schema: [memberSchema],
      params: { limit: 100 },
      isArray: true,
    }
  )

export const createInvite = (organizationUuid, data) =>
  client.post(
    _.replace(API_ENDPOINT.invites.base, ':organizationUuid', organizationUuid),
    { data, schema: inviteSchema }
  )

export const queryInvites = organizationUuid =>
  client.get(
    _.replace(API_ENDPOINT.invites.base, ':organizationUuid', organizationUuid),
    { schema: [inviteSchema], params: { limit: 100 }, isArray: true }
  )

export const getInvite = (organizationUuid, inviteUuid) =>
  client.get(
    _.replace(
      API_ENDPOINT.invites.get,
      /:organizationUuid|:uuid/g,
      match =>
        ({ ':organizationUuid': organizationUuid, ':uuid': inviteUuid }[match])
    ),
    { schema: inviteSchema }
  )

export const queryInvoices = organizationUuid =>
  client.get(
    _.replace(
      API_ENDPOINT.invoices.base,
      ':organizationUuid',
      organizationUuid
    ),
    { schema: [invoiceSchema], params: { limit: 100 }, isArray: true }
  )

export const deleteMembership = (organizationUuid, membershipUuid) =>
  client.delete(
    _.replace(
      API_ENDPOINT.members.get,
      /:organizationUuid|:uuid/g,
      match =>
        ({ ':organizationUuid': organizationUuid, ':uuid': membershipUuid }[
          match
        ])
    )
  )

export const deleteInvite = (organizationUuid, inviteUuid) =>
  client.delete(
    _.replace(
      API_ENDPOINT.invites.get,
      /:organizationUuid|:uuid/g,
      match =>
        ({ ':organizationUuid': organizationUuid, ':uuid': inviteUuid }[match])
    ),
    { schema: inviteSchema }
  )

export const updateInvite = (organizationUuid, inviteUuid, data) =>
  client.put(
    _.replace(
      API_ENDPOINT.invites.get,
      /:organizationUuid|:uuid/g,
      match =>
        ({ ':organizationUuid': organizationUuid, ':uuid': inviteUuid }[match])
    ),
    { data, schema: inviteSchema }
  )

export const updateMembership = (organizationUuid, membershipUuid, data) =>
  client.put(
    _.replace(
      API_ENDPOINT.members.get,
      /:organizationUuid|:uuid/g,
      match =>
        ({ ':organizationUuid': organizationUuid, ':uuid': membershipUuid }[
          match
        ])
    ),
    { data, schema: memberSchema }
  )

export const createOrganizationPaymentMethod = (
  organizationUuid,
  attachments
) =>
  client.post(
    _.replace(
      API_ENDPOINT.paymentMethod.base,
      ':organizationUuid',
      organizationUuid
    ),
    { attachments }
  )

export const deleteOrganizationPaymentMethod = organizationUuid =>
  client.delete(
    _.replace(
      API_ENDPOINT.paymentMethod.base,
      ':organizationUuid',
      organizationUuid
    ),
    {}
  )
