import { Tabs } from 'components/Tabs'
import { useRouteMatch } from 'react-router-dom'

import Payment from './Payment'
import Teams from './Teams'
import Users from './Users'

const OrganizationAdminSettingsPage = () => {
  const {
    params: { org_id: orgId },
  } = useRouteMatch()

  if (!orgId) return null

  return (
    <Tabs
      tabs={[
        {
          name: 'Users',
          path: `/settings/${orgId}/users`,
          component: Users,
        },
        {
          name: 'Teams',
          path: `/settings/${orgId}/teams`,
          component: Teams,
        },
        {
          name: 'Payment',
          path: `/settings/${orgId}/payment`,
          component: Payment,
        },
      ]}
    />
  )
}

export default OrganizationAdminSettingsPage
