import styled from 'styled-components'

export default (styled.div || styled.View)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;

  &:hover {
    background-color: #f6f6f6;
  }

  .contactname__name { font-size: 14px;}
`
