import { useDispatch, useSelector } from 'react-redux'
import useModal from 'use-react-modal'
import { useHistory } from 'react-router-dom'
import { fromJS } from 'immutable'

import { actions as teamsActions } from 'modules/teams'

import {
  getCreateTeamLoading,
  getCreateTeamError,
} from 'selectors/teamsSelector'
import { currentOrganizationIdSelector } from 'selectors/organizationsSelector'

import { AddButton } from 'components/AddButton'
import Button from 'components/Buttons'
import ButtonRow from 'components/Buttons/ButtonRow'
import { FormError } from 'components/Form'

const AddTeamButton = () => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  const currentOrganizationUuid = useSelector(currentOrganizationIdSelector)
  const createTeamLoading = useSelector(getCreateTeamLoading)
  const createTeamError = useSelector(getCreateTeamError)

  const { openModal, isOpen, Modal, closeModal } = useModal()

  const createNewTeam = () => {
    return new Promise((resolve, reject) => {
      dispatch(
        teamsActions.createTeam(
          fromJS({
            resolve,
            reject,
          })
        )
      )
    }).then(newTeamUuid => {
      closeModal()
      push(`/settings/${currentOrganizationUuid}/teams/${newTeamUuid}`)
    })
  }

  return (
    <>
      <AddButton onClick={openModal} />

      {isOpen && (
        <Modal>
          <Col>
            <Title>Create a new team?</Title>

            <ButtonRow>
              <Button onClick={closeModal} secondary type="button">
                Cancel
              </Button>

              <Button
                onClick={createNewTeam}
                disabled={createTeamLoading}
                type="button"
                primary
              >
                {createTeamLoading ? 'Creating...' : 'Create'}
              </Button>
            </ButtonRow>

            {createTeamError && (
              <FormError>
                {createTeamError.message || createTeamError}
              </FormError>
            )}
          </Col>
        </Modal>
      )}
    </>
  )
}

const Col = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 24px;
  width: 358px;
`

const Title = styled.h2`
  color: #535353;
  font-size: 21px;
  letter-spacing: 0.39px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 24px;
`

export default AddTeamButton
