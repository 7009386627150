import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { string, func } from 'prop-types'
import { reduxForm, Field } from 'redux-form/immutable'
import { withRouter } from 'react-router'
import queryString from 'query-string'

import { media } from 'utility/styles'
import devicestorage from 'utility/devicestorage'

import { CheckboxField } from 'components/Form'
import {
  Modal as _Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
  ModalTitle,
  ModalFooter,
} from 'components/Modal'
import Button from 'components/Buttons'

import { getEmptyConversationFiltersValues } from 'selectors/formSelector'
import { currentTeamIdSelector } from 'selectors/teamsSelector'
import useUi from 'hooks/useUi'

import theme from './theme'
import FilterItem from './FilterItem'
import FilterLabel from './FilterLabel'
import SortField from './SortField'
import CallsheetField from './CallsheetField'
import StatusField from './StatusField'
import DatesField from './DatesField'
import KeywordField from './KeywordField'
import PrintFilters from './PrintFilters'
import _FilterButton from './FilterButton'
import FilterToggle from './FilterToggle'

const mapStateToProps = (state, { location: { search = '' } = {} }) => {
  const emptyForm = getEmptyConversationFiltersValues(state).toJS()
  const queryObject = queryString.parse(search, {
    arrayFormat: 'bracket',
    parseBooleans: true,
    parseNumbers: true,
  })
  const currentTeamUuid = currentTeamIdSelector(state)

  return {
    emptyForm,
    queryObject,
    currentTeamUuid,
  }
}

const FilterItemExpand = styled(FilterItem)`
  flex: 1 1 auto;

  ${media.desktop`
    order: 1;
    flex-basis: 100%;
  `}

  ${media.tablet`
    order: 0;
    border-bottom: 0;
    border-right: 0;

    ${KeywordField} {
      padding: 0;
    }
  `}
`

const FilterItemCheckbox = styled(FilterItem)`
  flex-direction: row;
  align-items: center;
  padding: ${theme.filterItemPadding};
  > span {
    white-space: normal;
  }
`

const CheckboxLabel = styled(FilterLabel)`
  &:after {
    color: ${theme.filterLabelHoverColor};
  }
`

const ContactField = (styled.input || styled.TextInput)`
  display: none;
`

const FilterButton = styled(_FilterButton)`
  margin-left: 18px;
`

const Modal = styled(_Modal).attrs({ styles: { overlay: { zIndex: 9999 } } })`
  max-width: none;
  height: 100%;
  margin: 0;
  border: 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;

  ${ModalHeader} {
    background-color: #25263d;
    color: #fff;
  }

  ${ModalBody} {
    flex: 1;
    padding-left: 0;
    padding-right: 0;
  }

  ${ModalFooter} {
    padding: 0;
  }

  ${FilterItem}, ${FilterToggle} {
    background-color: transparent;
  }

  ${FilterItem} {
    border-right: 0;
  }
`

const mapDispatchToProps = {}

const mapToForm = {
  form: 'conversationFilters',
}

const ConversationsListFilterBar = ({
  className,
  currentTeamUuid,
  emptyForm,
  handleSubmit,
  initialize,
  queryObject,
  print = false,
  location: { pathname = '' } = {},
  history: { replace = () => {} } = {},
}) => {
  const mounted = useRef(false)
  const [initialized, setInitialized] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const { isMobile } = useUi()

  const initializeForm = async () => {
    setInitialized(false)
    const filterSets =
      (await devicestorage.get(__CONFIG__.localStorageKeys.filterSets)) || {}
    const filterSet = _.get(filterSets, currentTeamUuid, {})
    const querySet = _.pick(queryObject, _.keys(emptyForm))
    const useQuerySet = !_.isEmpty(querySet)

    const initialValues = _.assign(
      emptyForm,
      useQuerySet ? querySet : filterSet
    )

    if (print) initialValues.limit = 100
    initialize(fromJS(initialValues))
    setInitialized(true)
  }

  useEffect(() => {
    initializeForm()
  }, [currentTeamUuid])

  useEffect(() => {
    if (!print) {
      const search = queryString.stringify(
        _.omit(queryObject, _.keys(emptyForm)),
        { arrayFormat: 'bracket', parseBooleans: true, parseNumbers: true }
      )

      replace({
        pathname,
        search,
      })
    }

    mounted.current = true
  }, [])

  if (!initialized) return null

  if (print) return <PrintFilters />

  const Keyword = (
    <FilterItemExpand>
      <Field name="contact_uuid" component={ContactField} />

      <Field name="keyword" component={KeywordField} />
    </FilterItemExpand>
  )

  const Sort = (
    <FilterItem>
      <Field name="sort" component={SortField} />
    </FilterItem>
  )

  const Callsheet = (
    <FilterItem>
      <Field name="callsheets" component={CallsheetField} type="select-multi" />
    </FilterItem>
  )

  const Dates = (
    <FilterItem>
      <Field name="last_call_time" component={DatesField} />
    </FilterItem>
  )

  const Status = (
    <FilterItem>
      <Field name="statuses" component={StatusField} type="select-multi" />
    </FilterItem>
  )

  const Completed = (
    <FilterItemCheckbox>
      <Field
        name="show_completed"
        inputName="show_completed-input"
        labelText="Show Completed"
        component={CheckboxField}
        Label={CheckboxLabel}
      />
    </FilterItemCheckbox>
  )

  return (
    <form className={className} onSubmit={handleSubmit(() => {})}>
      {isMobile ? (
        <>
          {Keyword}

          <FilterButton onClick={() => setModalOpen(true)} />

          <Modal isOpen={modalOpen}>
            <ModalHeader>
              <ModalTitle>Sort and Filter Calls</ModalTitle>
              <ModalClose onClick={() => setModalOpen(false)} />
            </ModalHeader>

            <ModalBody>
              {Sort}

              {Callsheet}

              {Dates}

              {Status}

              {Completed}
            </ModalBody>

            <ModalFooter>
              <Button primary red block onClick={() => setModalOpen(false)}>
                Apply
              </Button>
            </ModalFooter>
          </Modal>
        </>
      ) : (
        <>
          {Callsheet}

          {Status}

          {Completed}

          {Dates}

          {Keyword}

          {Sort}
        </>
      )}
    </form>
  )
}

ConversationsListFilterBar.propTypes = {
  className: string,
  handleSubmit: func.isRequired,
}

ConversationsListFilterBar.defaultProps = {
  className: '',
}

export default styled(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(reduxForm(mapToForm)(ConversationsListFilterBar))
  )
)`
  align-items: stretch;
  justify-content: flex-start;
  border-bottom: 1px solid ${theme.filterBarBorderColor};
  display: flex;

  ${media.desktop`
    flex-wrap: wrap;
  `}

  ${media.tablet`
    flex-wrap: nowrap;
    background-color: #f6f6f6;
    padding: 8px 15px;
  `}
`
