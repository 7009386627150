import { useSelector, useDispatch } from 'react-redux'
import { actions as organizationsActions } from 'modules/organizations'
import {
  activateCurrentOrganizationSubscriptionLoadingSelector,
  adminOrgsSelector,
  cancelCurrentOrganizationSubscriptionLoadingSelector,
  createOrganizationErrorSelector,
  createOrganizationLoadingSelector,
  currentOrganizationHasActiveSubscriptionSelector,
  currentOrganizationIdSelector,
  currentOrganizationPaymentMethodSelector,
  currentOrganizationSeatsSelector,
  currentOrganizationSelector,
  currentOrganizationStripeCancelAtPeriodEndSelector,
  currentOrganizationStripeSubscriptionStatusSelector,
  currentOrganizationSubscriptionExpirationDateSelector,
  organizationsLoadingSelector,
  organizationUuidsSelector,
} from 'selectors/organizationsSelector'

export default function useOrganizations() {
  const dispatch = useDispatch()

  const activateCurrentOrganizationSubscriptionLoading = useSelector(
    activateCurrentOrganizationSubscriptionLoadingSelector
  )
  const cancelCurrentOrganizationSubscriptionLoading = useSelector(
    cancelCurrentOrganizationSubscriptionLoadingSelector
  )
  const createOrganizationError = useSelector(createOrganizationErrorSelector)
  const createOrganizationLoading = useSelector(
    createOrganizationLoadingSelector
  )
  const currentOrganization = useSelector(currentOrganizationSelector)
  const currentOrganizationHasActiveSubscription = useSelector(
    currentOrganizationHasActiveSubscriptionSelector
  )
  const currentOrganizationPaymentMethod = useSelector(
    currentOrganizationPaymentMethodSelector
  )
  const currentOrganizationSeats = useSelector(currentOrganizationSeatsSelector)
  const currentOrganizationStripeCancelAtPeriodEnd = useSelector(
    currentOrganizationStripeCancelAtPeriodEndSelector
  )
  const currentOrganizationStripeSubscriptionStatus = useSelector(
    currentOrganizationStripeSubscriptionStatusSelector
  )
  const currentOrganizationSubscriptionExpirationDate = useSelector(
    currentOrganizationSubscriptionExpirationDateSelector
  )
  const currentOrganizationUuid = useSelector(currentOrganizationIdSelector)
  const isOrgAdmin = useSelector(adminOrgsSelector)
  const organizationsLoading = useSelector(organizationsLoadingSelector)
  const organizationUuids = useSelector(organizationUuidsSelector)

  const currentOrganizationIsInvalid =
    !!currentOrganizationUuid &&
    !organizationUuids.includes(currentOrganizationUuid)

  const activateCurrentOrganizationSubscription = values => {
    if (!activateCurrentOrganizationSubscriptionLoading) {
      dispatch(
        organizationsActions.activateCurrentOrganizationSubscription(values)
      )
    }
  }

  const cancelCurrentOrganizationSubscription = values => {
    if (!cancelCurrentOrganizationSubscriptionLoading) {
      dispatch(
        organizationsActions.cancelCurrentOrganizationSubscription(values)
      )
    }
  }

  const createOrganization = values => {
    if (!createOrganizationLoading) {
      dispatch(organizationsActions.createOrganization(values))
    }
  }

  const createOrganizationPaymentMethod = values => {
    dispatch(organizationsActions.createOrganizationPaymentMethod(values))
  }

  const queryOrganizations = values => {
    if (!organizationsLoading) {
      dispatch(organizationsActions.queryOrganizations(values))
    }
  }

  const getOrganization = values => {
    dispatch(organizationsActions.getOrganization(values))
  }

  const redirectInactiveOrganization = values => {
    dispatch(organizationsActions.redirectInactiveOrganization(values))
  }

  return {
    activateCurrentOrganizationSubscription,
    cancelCurrentOrganizationSubscription,
    createOrganization,
    createOrganizationError,
    createOrganizationLoading,
    createOrganizationPaymentMethod,
    currentOrganization,
    currentOrganizationHasActiveSubscription,
    currentOrganizationIsInvalid,
    currentOrganizationPaymentMethod,
    currentOrganizationSeats,
    currentOrganizationStripeCancelAtPeriodEnd,
    currentOrganizationStripeSubscriptionStatus,
    currentOrganizationSubscriptionExpirationDate,
    currentOrganizationUuid,
    getOrganization,
    isOrgAdmin,
    organizationsLoading,
    organizationUuids,
    queryOrganizations,
    redirectInactiveOrganization,
  }
}
