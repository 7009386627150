import styled from 'styled-components'

export default (styled.h1 || styled.Text)`
  font-size: 21px;
  font-weight: 500;
  letter-spacing: .39px;
  line-height: 1.14;
  text-transform: uppercase;
  margin-top: 0;
  padding-right: 15px;
`
