import { createSelector } from 'reselect'
import { fromJS, List, Map } from 'immutable'
import { keyIn } from 'utility/immutable'

import { authUserUuidSelector } from './authSelector'
import {
  adminTeamsSelector,
  getMemberByUuid as getTeamMemberByUuid,
  memberListSelector as teamMembersListSelector,
  getQueryAdminTeamsAndMembers,
  getTeamsAndMembers,
} from './teamsSelector'

const passProps = (_state, props) => props

const otherMembers = (members, userUuid) =>
  members.filter(member => member.get('user') !== userUuid)
const targetMember = (members, userUuid) =>
  members.find(member => member.get('user') === userUuid)
const isOnlyMember = (members, userUuid) =>
  otherMembers(members, userUuid).isEmpty()

const isOnlyOrganizationAdmin = (members, userUuid) =>
  otherMembers(members, userUuid)
    .filter(member => member.get('is_org_admin'))
    .isEmpty() &&
  !members
    .filter(
      member => member.get('user') === userUuid && member.get('is_org_admin')
    )
    .isEmpty()

const isOnlyTeamAdmin = (teamsAndMembers, organizationUuid, userUuid) =>
  !!teamsAndMembers.find(
    team =>
      team.get('organization') === organizationUuid &&
      team
        .get('members')
        .find(
          member =>
            member.get('user') === userUuid && member.get('is_team_admin')
        ) &&
      !team
        .get('members')
        .find(
          member =>
            member.get('user') !== userUuid && member.get('is_team_admin')
        )
  )

const isPaymentMethodUser = (members, userUuid) => {
  const target = targetMember(members, userUuid)
  return target?.get('is_payment_method_user')
}


export const organizationsDataSelector = createSelector(
  state => state.get('organizations'),
  organizationsState => organizationsState
)

export const organizationsSelector = createSelector(
  organizationsDataSelector,
  organizationsData =>
    organizationsData && organizationsData.get('organizations')
)

export const getOrganizationByUuid = createSelector(
  organizationsSelector,
  passProps,
  (organizations, { uuid }) => organizations.get(uuid)
)

export const getOrganizationNameByUuid = createSelector(
  getOrganizationByUuid,
  organization => organization && organization.get('name')
)

export const organizationUuidsSelector = createSelector(
  organizationsDataSelector,
  organizationsData =>
    organizationsData && organizationsData.get('organizationUuids')
)

export const organizationsLoadingSelector = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('organizationsLoading')
)

export const createOrganizationLoadingSelector = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('createOrganizationLoading')
)

export const createOrganizationErrorSelector = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('createOrganizationError')
)

export const activateCurrentOrganizationSubscriptionLoadingSelector = createSelector(
  organizationsDataSelector,
  organizationData =>
    organizationData.get('activateCurrentOrganizationSubscriptionLoading')
)

export const cancelCurrentOrganizationSubscriptionLoadingSelector = createSelector(
  organizationsDataSelector,
  organizationData =>
    organizationData.get('cancelCurrentOrganizationSubscriptionLoading')
)

export const firstOrganizationIdSelector = createSelector(
  organizationUuidsSelector,
  organizationUuids => (organizationUuids && organizationUuids.first()) || null
)

export const currentOrganizationIdSelector = createSelector(
  organizationsDataSelector,
  organizationsData => organizationsData.get('currentOrganizationId')
)

export const currentOrganizationSelector = createSelector(
  organizationsDataSelector,
  currentOrganizationIdSelector,
  (organizationsData, currentOrganizationId) => {
    if (!(organizationsData && currentOrganizationId)) return null
    return (
      organizationsData.getIn(['organizations', currentOrganizationId]) || null
    )
  }
)

export const currentOrganizationSeatsSelector = createSelector(
  currentOrganizationSelector,
  currentOrganization => currentOrganization && currentOrganization.get('seats')
)

export const currentOrganizationPaymentMethodSelector = createSelector(
  currentOrganizationSelector,
  currentOrganization =>
    currentOrganization &&
    currentOrganization.get('stripe_payment_method_last_4')
)

export const currentOrganizationStripeSubscriptionStatusSelector = createSelector(
  currentOrganizationSelector,
  currentOrganization =>
    currentOrganization && currentOrganization.get('stripe_subscription_status')
)

export const currentOrganizationStripeCancelAtPeriodEndSelector = createSelector(
  currentOrganizationSelector,
  currentOrganization =>
    currentOrganization &&
    currentOrganization.get('subscription_cancel_at_period_end')
)

export const currentOrganizationHasActiveSubscriptionSelector = createSelector(
  currentOrganizationSelector,
  currentOrganization =>
    currentOrganization && currentOrganization.get('has_active_subscription')
)

export const currentOrganizationSubscriptionExpirationDateSelector = createSelector(
  currentOrganizationSelector,
  currentOrganization =>
    currentOrganization &&
    currentOrganization.get('subscription_expiration_date')
)

export const memberEditSelector = createSelector(
  organizationsDataSelector,
  currentOrganizationSelector,
  (organizationsData, currentOrganization) => {
    const memberEditId = organizationsData.get('memberEditId')
    const members =
      (currentOrganization && currentOrganization.get('members')) || List([])
    const memberEdit = members.find(m => m.get('uuid') === memberEditId)

    const emptyFormValues = {
      id: null,
      email: null,
      display_name: null,
      is_org_admin: null,
      hide_callsheet: null,
      is_active: null,
    }

    const memberEditInitialValues = memberEditId
      ? memberEdit.filter(keyIn(Object.keys(emptyFormValues)))
      : Map(emptyFormValues)

    return fromJS({
      memberEdit,
      memberEditInitialValues,
    })
  }
)

/* ** MEMBERS ** */
export const getMembers = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('members')
)

export const getMemberByUuid = createSelector(
  getMembers,
  passProps,
  (members, { uuid }) => {
    if (!(members && uuid)) return null
    return members.get(uuid) || null
  }
)

export const getUserUuidByMemberUuid = createSelector(
  getMemberByUuid,
  member => (member ? member.get('user') : null)
)

export const getOrganizationOrTeamMemberByUuid = createSelector(
  getTeamMemberByUuid,
  getMemberByUuid,
  (teamMember, organizationMember) => teamMember || organizationMember
)

export const getOrganizationAndTeamMembers = createSelector(
  getMembers,
  teamMembersListSelector,
  (organizationMembers, teamMembers) => organizationMembers.merge(teamMembers)
)

export const getCurrentUserMemberByEntityUuid = createSelector(
  authUserUuidSelector,
  getOrganizationAndTeamMembers,
  passProps,
  (currentUserUuid, members, { uuid }) =>
    members.find(
      member =>
        member.get('user') === currentUserUuid &&
        (member.get('team') === uuid || member.get('organization') === uuid)
    )
)

export const getCurrentOrganizationMemberUuids = createSelector(
  currentOrganizationSelector,
  currentOrganization =>
    (currentOrganization && currentOrganization.get('members')) || null
)

export const getCurrentOrganizationMembers = createSelector(
  getCurrentOrganizationMemberUuids,
  getMembers,
  (uuids, members) => (uuids || fromJS([])).map(uuid => members.get(uuid))
)

export const getOrganizationWithMembersByUuid = createSelector(
  getOrganizationByUuid,
  getMembers,
  (organization, members) => {
    if (!(organization && members)) return fromJS({})

    return organization.update('members', memberUuids =>
      memberUuids.map(memberUuid => members.get(memberUuid))
    )
  }
)

export const getCurrentOrganizationWithMembers = createSelector(
  currentOrganizationSelector,
  getMembers,
  (organization, members) => {
    if (!(organization && members)) return fromJS({})

    return organization.update('members', memberUuids =>
      memberUuids.map(memberUuid => members.get(memberUuid))
    )
  }
)

export const getCurrentOrganizationMember = createSelector(
  getCurrentOrganizationMembers,
  authUserUuidSelector,
  (organizationMemberships, currentUserUuid) => {
    if (!(organizationMemberships && currentUserUuid)) return false

    return organizationMemberships.find(
      membership => membership.get('user') === currentUserUuid
    )
  }
)

const userCanLeaveOrganization = (organization, userUuid, teamsAndMembers) => {
  const members =
    (organization &&
      organization.get('members').filter(member => member.get('is_active'))) ||
    fromJS([])

  if (
    isOnlyMember(members, userUuid) ||
    isOnlyOrganizationAdmin(members, userUuid) ||
    isOnlyTeamAdmin(teamsAndMembers, organization.get('uuid'), userUuid) ||
    isPaymentMethodUser(members, userUuid)
  ) {
    return false
  }

  return true
}

export const currentUserCanLeaveOrganizationByUuid = createSelector(
  getOrganizationWithMembersByUuid,
  authUserUuidSelector,
  getTeamsAndMembers,
  userCanLeaveOrganization
)

export const memberCanLeaveCurrentOrganizationByUuid = createSelector(
  getCurrentOrganizationWithMembers,
  getUserUuidByMemberUuid,
  getQueryAdminTeamsAndMembers,
  userCanLeaveOrganization
)

export const adminOrgsSelector = createSelector(
  getCurrentOrganizationMember,
  organizationMembership => {
    if (!organizationMembership) return false

    return !!organizationMembership.get('is_org_admin')
  }
)

export const isAdminSelector = createSelector(
  adminOrgsSelector,
  adminTeamsSelector,
  (isOrgAdmin, isTeamAdmin) => isOrgAdmin || isTeamAdmin
)

export const canRemoveOrgAdminPrivilegeByCurrentOrgUuidSelector = createSelector(
  getCurrentOrganizationMembers,
  members => members.filter(member => member.get('is_org_admin')).count() > 1
)

export const canRemoveOrganizationMember = createSelector()

/* ** SEARCH ** */
export const getSearchOrganizationMemberUuids = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('searchOrganizationMembersList')
)

export const getSearchOrganizationMembersLoading = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('searchOrganizationMembersLoading')
)

export const getSearchOrganizationMembersError = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('searchOrganizationMembersError')
)

/* Query Organization Members */
export const getQueryOrganizationMembersUuids = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('queryOrganizationMembersUuids')
)

export const getQueryOrganizationMembers = createSelector(
  getMembers,
  getQueryOrganizationMembersUuids,
  (members, uuids) => members.filter(keyIn(uuids.toJS()))
)

export const getQueryOrganizationMembersLoading = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('queryOrganizationMembersLoading')
)

export const getQueryOrganizationMembersError = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('queryOrganizationMembersError')
)

/* Query Organization Invites */
export const queryOrganizationInvitesLoadingSelector = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('queryOrganizationInvitesLoading')
)

export const queryOrganizationInvitesErrorSelector = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('queryOrganizationInvitesError')
)

/* Organization Invites */
export const organizationInviteUuidsSelector = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('inviteUuids')
)

export const organizationInvitesSelector = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('invites')
)

export const organizationInviteByUuidSelector = createSelector(
  organizationInvitesSelector,
  passProps,
  (invites, { uuid }) => invites.get(uuid)
)

export const organizationUuidsFilteredForSettingsSelector = createSelector(
  organizationInvitesSelector,
  organizationInviteUuidsSelector,
  getCurrentOrganizationMembers,
  (_invites, _uuids, _members) => {
    const uuids = _uuids.toJS()
    const invites = _invites.filter(keyIn(uuids)).toJS()
    const memberEmails = _members.map(member => member.get('email'))

    const groupedInvites = _(invites)
      .groupBy('recipient_email')
      .filter((v, email) => {
        return !memberEmails.includes(email)
      })
      .value()

    return fromJS(groupedInvites)
  }
)

/* Organization Invoices */
export const currentOrganizationInvoiceUuidsSelector = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('invoiceUuids')
)

export const currentOrganizationInvoicesSelector = createSelector(
  organizationsDataSelector,
  currentOrganizationInvoiceUuidsSelector,
  (organizationData, uuids) =>
    organizationData.get('invoices').filter(keyIn(uuids.toJS()))
)

export const organizationInvoicesSelector = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('invoices')
)

export const queryOrganizationInvoicesLoadingSelector = createSelector(
  organizationsDataSelector,
  organizationData => organizationData.get('queryOrganizationInvoicesLoading')
)

export const organizationInvoiceByUuidSelector = createSelector(
  organizationInvoicesSelector,
  passProps,
  (invoices, { uuid }) => invoices.get(uuid)
)
