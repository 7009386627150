import styled from 'styled-components'
import Button from 'components/Buttons'

export default styled(Button).attrs(() => ({
  block: true,
  primary: true,
  type: 'submit',
}))`
  margin-top: 50px;
  margin-bottom: 20px;
`
