import { all } from 'redux-saga/effects'

import { authSaga } from './authSaga'
import { contactsSaga } from './contactsSaga'
import { conversationsSaga } from './conversationsSaga'
import { organizationsSaga } from './organizationsSaga'
import { socketSaga } from './socketSaga'
import { teamsSaga } from './teamsSaga'
import { requestMonitor } from './requestMonitor'
import { uiSaga } from './uiSaga'

export default function* sagas() {
  yield all([
    ...authSaga,
    ...contactsSaga,
    ...conversationsSaga,
    ...organizationsSaga,
    ...socketSaga,
    ...teamsSaga,
    ...requestMonitor,
    ...uiSaga,
  ])
}
