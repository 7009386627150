import _ from 'lodash'
import styled, { css, keyframes } from 'styled-components'

const LoadingSpinner = styled(({
  small, medium, large, text, ...props
}) => (
  <div {...props}>
    <Inner>
      <FadingCircle>
        {Array.from(Array(12).keys()).map((val, index) => (
          <div key={index} className={`sk-circle${index + 1} sk-circle`} /> // eslint-disable-line react/no-array-index-key
        ))}
      </FadingCircle>
      {text && (
        <Text small={small} medium={medium} large={large}>
          {text}
        </Text>
      )}
    </Inner>
  </div>
))``

const skCircleFadeDelay = keyframes`
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
`

const FadingCircle = (styled.div || styled.View)`
  width: 40px;
  height: 40px;
  ${({ small }) => small && css`
    width: 40px;
    height: 40px;
  `}
  ${({ medium }) => medium && css`
    width: 70px;
    height: 70px;
  `}
  ${({ large }) => large && css`
    width: 100px;
    height: 100px;
  `}
  position: relative;

  .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #e9573f;
    border-radius: 100%;
    animation: ${skCircleFadeDelay} 1.2s infinite ease-in-out both;
  }
  .sk-circle2 {
    transform: rotate(30deg);
  }
  .sk-circle3 {
    transform: rotate(60deg);
  }
  .sk-circle4 {
    transform: rotate(90deg);
  }
  .sk-circle5 {
    transform: rotate(120deg);
  }
  .sk-circle6 {
    transform: rotate(150deg);
  }
  .sk-circle7 {
    transform: rotate(180deg);
  }
  .sk-circle8 {
    transform: rotate(210deg);
  }
  .sk-circle9 {
    transform: rotate(240deg);
  }
  .sk-circle10 {
    transform: rotate(270deg);
  }
  .sk-circle11 {
    transform: rotate(300deg);
  }
  .sk-circle12 {
    transform: rotate(330deg);
  }
  .sk-circle2:before {
    animation-delay: -1.1s;
  }
  .sk-circle3:before {
    animation-delay: -1s;
  }
  .sk-circle4:before {
    animation-delay: -0.9s;
  }
  .sk-circle5:before {
    animation-delay: -0.8s;
  }
  .sk-circle6:before {
    animation-delay: -0.7s;
  }
  .sk-circle7:before {
    animation-delay: -0.6s;
  }
  .sk-circle8:before {
    animation-delay: -0.5s;
  }
  .sk-circle9:before {
    animation-delay: -0.4s;
  }
  .sk-circle10:before {
    animation-delay: -0.3s;
  }
  .sk-circle11:before {
    animation-delay: -0.2s;
  }
  .sk-circle12:before {
    animation-delay: -0.1s;
  }
`

const Inner = (styled.div || styled.View)`
  display:         flex;
  align-items:     center;
  justify-content: center;
  flex-direction:  column;
`

const Text = styled.text`
  color: ${props => _.get(props, 'theme.colors.gray') || 'lightgray'};

  ${({ small }) => small && css`
    margin-top: 5px;
    font-size: 12px;
  `}
  ${({ medium }) => medium && css``}
  ${({ large }) => large && css``}
`

export default LoadingSpinner
