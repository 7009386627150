import styled from 'styled-components'
import { connect } from 'react-redux'
import { getCallsByConversationUuid } from 'selectors/conversationsSelector'
import Call from './Call'

const mapStateToProps = (state, { conversationUuid: uuid }) => {
  const calls = getCallsByConversationUuid(state, { uuid })

  return {
    calls,
  }
}

const mapDispatchToProps = {}

const ConversationHistory = styled(({ calls }) => (
  <div>
    <HistoryHeader>Conversation History</HistoryHeader>
    {calls.size >= 2
      ? calls.shift().map(callUuid => <Call key={callUuid} callUuid={callUuid} />)
      : <NoCalls>No earlier calls.</NoCalls>
    }
  </div>
))``

const NoCalls = (styled.span || styled.Text)`
  font-size: 14px;
  color: ${props => props.theme.gray};
`

const HistoryHeader = (styled.h3 || styled.Text)`
  font-size: 12px;
  text-transform: uppercase;
  padding-bottom: 8px;
  border-bottom: 1px solid ${props => props.theme.lightGray};
  color: ${props => props.theme.gray};
`

export default connect(mapStateToProps, mapDispatchToProps)(ConversationHistory)
