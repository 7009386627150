import styled from 'styled-components'
import { CircleExclamIcon } from 'components/Icons'

export default styled(({ children, ...props }) => (
  <span {...props}>
    <CircleExclamIcon />
    {children}
  </span>
))`
  display: block;
  margin: 10px 0px;
  color: ${props => props.theme.colors.warningRed};
  i {
    padding-right: 8px;
  }
`
