import { ModalConsumer } from '..'

const DeleteContactModalToggle = ({
  contactUuid = null,
  preDelete = () => {},
  postDelete = () => {},
  component: Component,
  ...props
}) => {
  return (
    <ModalConsumer>
      {({ deleteContact }) => (
        <Component
          onClick={e => {
            deleteContact.summon(e, { contactUuid, preDelete, postDelete })
          }}
          {...props}
        />
      )}
    </ModalConsumer>
  )
}

export default DeleteContactModalToggle
