import { useSelector } from 'react-redux'
import { Field } from 'redux-form/immutable'
import { fromJS } from 'immutable'

import { getOrganizationAndTeamMembers } from 'selectors/organizationsSelector'

import { CheckboxField } from 'components/Form'
import BlankIconButtonDelete from 'components/Buttons/BlankIconButtonDelete'
import { MemberSearch } from 'components/SearchList'

import { onlyShow } from 'utility/styles'
import { keyIn } from 'utility/immutable'

const Container = styled.div`
  border-bottom: 1px solid #e3e3e3;
  flex: 1;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e3e3e3;
  padding: 15px 5px;
`

const Col = styled.div`
  flex: 0 0 auto;
`

const NameCol = styled.div`
  flex: 0 0 160px;
  padding-right: 10px;
  ${onlyShow(1).line}
`

const ButtonCol = styled.div`
  flex: 0 0 auto;
  padding-left: 10px;
`

const Title = styled.h2`
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: 18px;
`

const MemberFields = ({
  className,
  fields,
  teamUuid,
  meta: { error },
  hideTitle,
  hideMemberDetails,
  initialValues = fromJS({ members: [] }),
  isTeam = false,
}) => {
  const oganizationAndTeamMembers = useSelector(getOrganizationAndTeamMembers)

  const handleNewMemberSelect = memberUuid => {
    const existingMembers = fields.getAll().map(member => member.get('user'))
    const newMember = oganizationAndTeamMembers.get(memberUuid)

    if (existingMembers.includes(newMember.get('user'))) return

    const initialMember = initialValues
      .get('members')
      .find(member => member.get('user') === newMember.get('user'))

    if (initialMember) {
      fields.push(initialMember.set('team', teamUuid))
    } else {
      fields.push(
        newMember
          .set('team', teamUuid)
          .filter(keyIn(['short_name', 'user', 'team']))
      )
    }
  }

  return (
    <>
      {!hideTitle && <Title>Team Members</Title>}

      <Container className={className}>
        <MemberSearch
          handleSelect={handleNewMemberSelect}
          handleAddClick={console.log}
          dropdown
          includeAddOption
          isTeam={isTeam}
        />

        {fields.map((member, index) => {
          const memberObject = fields.get(index)
          const name = memberObject.get('short_name')
          const uuid = memberObject.get('uuid')

          return (
            <Row key={uuid || index}>
              <NameCol>{name}</NameCol>

              {!hideMemberDetails && (
                <Col>
                  <Field
                    name={`${member}.is_team_admin`}
                    id={`${index}_is_team_admin`}
                    component={CheckboxField}
                    type="checkbox"
                    inputName="is_org_admin-input"
                    labelText="Team Admin"
                  />
                </Col>
              )}

              <ButtonCol>
                <BlankIconButtonDelete
                  type="button"
                  title="Remove Member"
                  onClick={() => fields.remove(index)}
                />
              </ButtonCol>
            </Row>
          )
        })}

        {error && (
          <div>
            <span>{error}</span>
          </div>
        )}
      </Container>
    </>
  )
}

export default MemberFields
