import styled from 'styled-components'
import { Form as ReactFinalForm } from 'react-final-form'
import { fromJS } from 'immutable'

import { FloatingLabelFinalField } from 'components/FloatingLabelInput'
import Spinner from 'components/Spinner'
import { FormRow, Form as _Form, FormError } from 'components/Form'
import CardMessage from 'components/Cards/CardMessage'
import useAuth from 'hooks/useAuth'

import validate from './ResetPasswordFullFormValidation'

import Title from '../Title'
import Link from '../Link'
import Button from '../Button'

const InlineLink = styled(Link)`
  display: inline;
`

const Form = styled(_Form)`
`

const ResetPasswordFullForm = ({
  handleSubmit,
  invalid,
  pristine,
  submitting,
  submitError,
  submitErrors,
  submitSucceeded,
  links,
}) => {
  if (submitSucceeded) {
    return (
      <>
        <Title>Success!</Title>

        <CardMessage>
          Your password has been reset. You can now
          {' '}
          <Link to='/login'>log in</Link>
          {' '}
          with your new password.
        </CardMessage>
      </>
    )
  }

  return (
    <Form
      onSubmit={handleSubmit}
    >
      <Title>Create New Password</Title>

      <FormRow>
        <FloatingLabelFinalField
          id='password'
          name='password'
          type='password'
          placeholder='New password'
        />
      </FormRow>

      <FormRow>
        <FloatingLabelFinalField
          id='password_confirm'
          name='password_confirm'
          type='password'
          placeholder='Confirm password'
        />
      </FormRow>

      {!!submitError && !pristine && (
        <FormError>{submitError}</FormError>
      )}

      <FormRow half>
        <Button
          primary
          disabled={submitting || invalid || pristine || submitError || submitErrors}
          type='submit'
        >
          Reset
        </Button>
      </FormRow>

      {links && links.length > 0
        && links.map((link) => (
          <Link
            to={link.path}
          >
            {link.label}
          </Link>
        ))
      }

      {submitting && <Spinner />}
    </Form>
  )
}

const WrappedResetPasswordFullForm = props => {
  const { resetPassword, registration: resetPasswordToken } = useAuth()

  if (!resetPasswordToken.isValid || resetPasswordToken.isExpired) {
    const message = resetPasswordToken.isValid
      ? 'is invalid'
      : 'has expired'
    return (
      <>
        <Title>Sorry!</Title>

        <h2>
          Sorry, this password reset token&nbsp;
          {message}
          .
        </h2>

        <p>
          Please request a new&nbsp;
          <InlineLink to='/reset_password'>password reset</InlineLink>
          .
        </p>
      </>
    )
  }

  const initialValues = {
    email: resetPasswordToken.email,
    password: null,
    password_confirm: null,
    entitlement_token: resetPasswordToken.token,
  }

  return (
    <ReactFinalForm
      initialValues={initialValues}
      onSubmit={values => new Promise((resolve) => {
        resetPassword(fromJS({
          ...values,
          resolve,
        }))
      })}
      validate={validate}
    >
      {_props => (
        <ResetPasswordFullForm
          token={resetPasswordToken}
          {...props}
          {..._props}
        />
      )}

    </ReactFinalForm>
  )
}

export default WrappedResetPasswordFullForm
