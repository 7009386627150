import { useSelector } from 'react-redux'

import {
  getConversationsHasMore,
  getConversationsLoading,
  getConversationsLength,
} from 'selectors/conversationsSelector'

import _Spinner from 'components/Spinner'

const EndText = () => {
  const conversationsHasMore = useSelector(getConversationsHasMore)
  const conversationsLoading = useSelector(getConversationsLoading)
  const conversationsLength = useSelector(getConversationsLength)

  if (conversationsLoading) return <Spinner />

  if (conversationsHasMore) return null

  const endText = `${conversationsLength} conversation${
    conversationsLength !== 1 ? 's' : ''
  } shown`
  return <Span>{endText}</Span>
}

const Spinner = styled(_Spinner)`
  padding: 10px 0px;
`

const Span = (styled.span || styled.Text)`
  display: block;
  padding: 15px 0px;
  text-align: center;
  font-weight: 600;
  color: ${props => props.theme.colors.gray};
`

export default EndText
