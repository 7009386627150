import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import RedirectToOrgPage from 'components/Page/RedirectToOrgPage'
import NavBar from 'components/NavBar/OrgPageNavBar'

import useOrganizations from 'hooks/useOrganizations'

const Inactive = () => {
  const {
    // isOrgAdmin,
    currentOrganizationUuid,
    currentOrganizationHasActiveSubscription,
  } = useOrganizations()
  const { org_id: orgId } = useParams()
  const { replace } = useHistory()

  useEffect(() => {
    if (currentOrganizationUuid === orgId) {
      // if (isOrgAdmin) {
      //   replace(`/settings/${orgId}/payment`)
      // }
    }
  }, [currentOrganizationUuid, currentOrganizationHasActiveSubscription])

  return (
    <RedirectToOrgPage navBar={NavBar}>
      <Card>
        <Text>
          This organization is currently inactive. Please contact your
          administrator.
        </Text>
      </Card>
    </RedirectToOrgPage>
  )
}

const Card = (styled.div || styled.View)`
  padding: 48px;
`

const Text = (styled.h1 || styled.Text)`
  text-align: center;
  margin: 0;
  color: ${props => props.theme.colors.gray};
  font-size: 18px;
  font-weight: normal;
`

export default Inactive
