import React, { useEffect } from 'react'
import { fromJS } from 'immutable'

import useOrganizations from 'hooks/useOrganizations'

import Spinner from 'components/Spinner'

import AccountSummary from './AccountSummary'
import CreditCard from './CreditCard'
import Deactivate from './Deactivate'
import History from './History'

const Payment = () => {
  const {
    currentOrganization,
    currentOrganizationUuid,
    getOrganization,
  } = useOrganizations()

  const organizationLoaded =
    currentOrganization && currentOrganization.get('_hasDetails')

  useEffect(() => {
    if (!organizationLoaded && currentOrganizationUuid) {
      getOrganization(fromJS({ uuid: currentOrganizationUuid }))
    }
  }, [currentOrganizationUuid, organizationLoaded])

  if (!currentOrganizationUuid || !organizationLoaded) return <Spinner />

  return (
    <>
      <CreditCard />

      <AccountSummary />

      <Deactivate />

      <History />
    </>
  )
}

export default Payment
