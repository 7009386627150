import _ from 'lodash'
import ApiClient from 'components/ApiClient'
import { call as callSchema } from '../schemas/conversations'

const client = new ApiClient()
const API_ENDPOINT = __CONFIG__.apiPath.calls

export const createCall = data => client.post(
  API_ENDPOINT.base,
  { data, schema: callSchema },
)

export const deleteCall = uuid => client.delete(
  _.replace(API_ENDPOINT.get, ':uuid', uuid),
  { schema: callSchema },
)

export const getCall = uuid => client.get(
  _.replace(API_ENDPOINT.get, ':uuid', uuid),
  { schema: callSchema },
)

export const queryCalls = () => client.get(
  API_ENDPOINT.base,
  { schema: [callSchema] },
)

export const updateCall = (uuid, data) => client.patch(
  _.replace(API_ENDPOINT.get, ':uuid', uuid),
  { data, schema: callSchema },
)
