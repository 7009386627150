import styled, { css } from 'styled-components'
import { darken } from 'polished'

const generateButtonCss = (color, border, background, extraCss) => css`
  color: ${color};
  background-color: ${background};
  border-color: ${border};

  &:focus,
  &.focus {
    color: ${color};
    background-color: ${darken(0.1, background)};
    border-color: ${darken(0.25, border)};
  }

  &:hover {
    color: ${color};
    background-color: ${darken(0.1, background)};
    border-color: ${darken(0.12, border)};
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: ${color};
    background-color: ${darken(0.1, background)};
    background-image: none;
    border-color: ${darken(0.12, border)};

    &:hover,
    &:focus,
    &.focus {
      color: ${color};
      background-color: ${darken(0.17, background)};
      border-color: ${darken(0.25, border)};
    }
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: ${background};
      border-color: ${border};
    }
  }

  ${extraCss}
`

const primaryBackground = '#007bff'
const primaryBorder = '#007bff'
const primaryColor = '#ffffff'
const redColor = '#ffffff'
const redBorder = '#e9573f'
const redBackground = '#e9573f'
const secondaryColor = '#ffffff'
const secondaryBorder = '#6c757d'
const secondaryBackground = '#6c757d'

const styles = {
  primary: generateButtonCss(primaryColor, primaryBorder, primaryBackground),
  red: generateButtonCss(redColor, redBorder, redBackground),
  secondary: generateButtonCss(
    secondaryColor,
    secondaryBorder,
    secondaryBackground
  ),
  block: css`
    display: block;
    width: 100%;
  `,
  link: css`
    font-weight: 400;
    color: ${primaryBackground};
    text-decoration: none;

    &:hover {
      color: #0056b3;
      text-decoration: underline;
    }

    &:focus,
    &.focus {
      text-decoration: underline;
      box-shadow: none;
    }

    &:disabled,
    &.disabled {
      color: #6c757d;
      pointer-events: none;
    }
  `,
}

const Button = styled(
  ({
    block,
    children,
    defaultWidth,
    link,
    loading,
    loadingText = 'Loading...',
    primary,
    red,
    secondary,
    type = 'button',
    ...props
  }) => (
    <button // eslint-disable-line react/button-has-type
      type={type}
      {...props}
    >
      {loading ? loadingText : children}
    </button>
  )
)`
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;


  text-transform: uppercase;
  padding: 12px;
  min-width: ${props =>
    props.defaultWidth ? props.theme.buttons.defaultWidth : 'auto'};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.26px;
  line-height: 1.25;
  box-shadow: none!important;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  &:hover {
    color: #212529;
    text-decoration: none;
  }

  &:focus, &.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
    opacity: 0.65;
  }

  ${({ primary }) => primary && styles.primary}
  ${({ red }) => red && styles.red}
  ${({ secondary }) => secondary && styles.secondary}
  ${({ block }) => block && styles.block}
  ${({ link }) => link && styles.link}
`

export default Button
