import _TimePicker from 'components/TimePicker'
import { Field } from 'react-final-form'

const AddConversationTimePickerSafari = props => {
  return (
    <Field
      {...props}
      render={({ input: { value, onChange }, ..._props }) => (
        <TimePicker value={value} onChange={onChange} {...props} />
      )}
    />
  )
}

const TimePicker = styled(_TimePicker)`
  border-bottom: 1px solid #d8d8d8;
`

export default AddConversationTimePickerSafari
