import styled from 'styled-components'

import BlankButton from 'components/Buttons/BlankButton'

import TableIcon from './TableIcon'

const TableButton = styled(({ type, ...props }) => (
  <BlankButton type='button' {...props}>
    <TableIcon type={type} />
  </BlankButton>
))``

export default styled(TableButton)``
