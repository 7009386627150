import React, { createContext, useRef } from 'react'

import ContactModal from './Contact'
import DeleteContactModal from './DeleteContact'
import EmailConversationsModal from './EmailConversations'
import PreDeleteModal from './PreDelete'

const ModalContext = createContext({
  contact: {},
  deleteContact: {},
  emailConversations: {},
  preDelete: {},
})

const ModalProvider = ({ children }) => {
  const contactModalRef = useRef({})
  const contactSummon = (e, formProps) => {
    contactModalRef.current.summon(e, formProps)
  }

  const deleteContactModalRef = useRef({})
  const deleteContactSummon = (e, formProps) => {
    deleteContactModalRef.current.summon(e, formProps)
  }

  const emailConversationsModalRef = useRef({})
  const emailConversationsSummon = (e, formProps) => {
    emailConversationsModalRef.current.summon(e, formProps)
  }

  const preDeleteModalRef = useRef({})
  const preDeleteSummon = (e, formProps) => {
    preDeleteModalRef.current.summon(e, formProps)
  }

  return (
    <ModalContext.Provider
      value={{
        contact: { summon: contactSummon },
        deleteContact: { summon: deleteContactSummon },
        emailConversations: { summon: emailConversationsSummon },
        preDelete: { summon: preDeleteSummon },
      }}
    >
      {children}

      <ContactModal ref={contactModalRef} />
      <DeleteContactModal ref={deleteContactModalRef} />
      <EmailConversationsModal ref={emailConversationsModalRef} />
      <PreDeleteModal ref={preDeleteModalRef} />
    </ModalContext.Provider>
  )
}

export const ModalConsumer = ModalContext.Consumer
export default ModalProvider
