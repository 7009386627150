import styled, { css } from 'styled-components'

import TableEditButton from './TableEditButton'
import TableEditLink from './TableEditLink'

export default (styled.div || styled.View)`
  display: flex;
  flex-direction: row;
  align-items: flex-center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.table.rowBorderColor};

  &:hover {
    background-color: ${props => props.theme.table.rowHoverBackground};
  }

  ${TableEditButton}, ${TableEditLink} {
    cursor: pointer;
    visibility: hidden;
    pointer-events: none;
  }

  &:hover {
    ${TableEditButton}, ${TableEditLink} {
      visibility: visible;
      pointer-events: auto;
    }
  }

  ${({ header }) => header && css`
    font-size: 12px;
    text-transform: uppercase;
    color: ${props => props.theme.table.headerColor};
    &:hover {
      background-color: inherit;
    }
  `}
`
