import BackLink from './BackLink'
import TeamForm from './TeamForm'

const TeamFormHolder = () => {
  return (
    <>
      <div>
        <BackLink>&lt; All Teams</BackLink>
      </div>

      <TeamForm />
    </>
  )
}

export default TeamFormHolder
