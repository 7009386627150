import Helmet from 'react-helmet'

const HOST = 'https://phoneslate.com'
const ASSETS_PATH = 'assets'

const MetaTags = () => (
  <Helmet>
    <title>PhoneSlate - a simple, powerful call log app</title>
    <meta name="title" content="PhoneSlate - a simple, powerful call log app" />
    <meta
      name="description"
      content="PhoneSlate is an enterprise-ready call log app for Mac, PC, iOS and Android that makes it easy for teams and individuals to log and organize phone calls, manage contacts, and share phone sheets."
    />

    <meta property="og:type" content="website" />
    <meta property="og:url" content={HOST} />
    <meta
      property="og:title"
      content="PhoneSlate - a simple, powerful call log app"
    />
    <meta
      property="og:description"
      content="PhoneSlate is an enterprise-ready call log app for Mac, PC, iOS and Android that makes it easy for teams and individuals to log and organize phone calls, manage contacts, and share phone sheets."
    />
    <meta property="og:image" content={`${HOST}/${ASSETS_PATH}/meta.png`} />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://" />
    <meta
      property="twitter:title"
      content="PhoneSlate - a simple, powerful call log app"
    />
    <meta
      property="twitter:description"
      content="PhoneSlate is an enterprise-ready call log app for Mac, PC, iOS and Android that makes it easy for teams and individuals to log and organize phone calls, manage contacts, and share phone sheets."
    />
    <meta
      property="twitter:image"
      content={`${HOST}/${ASSETS_PATH}/meta.png`}
    />
  </Helmet>
)

export default MetaTags
