import _ from 'lodash'
import { createSelector } from 'reselect'
import queryString from 'query-string'

import { decodeToken, isValidToken, isExpired } from 'utility/token'
import { routerDataSelector } from './routerSelector'

export const authDataSelector = createSelector(
  state => state.get('auth'),
  authData => authData
)

export const authLoadedSelector = createSelector(
  authDataSelector,
  authData => authData.get('loaded')
)

export const authLoggingInSelector = createSelector(
  authDataSelector,
  authData => authData.get('loggingIn')
)

export const authLoginErrorSelector = createSelector(
  authDataSelector,
  authData => authData.get('loginError')
)

export const authRegisteringSelector = createSelector(
  authDataSelector,
  authData => authData.get('registering')
)

export const authRegisterErrorSelector = createSelector(
  authDataSelector,
  authData => authData.get('registerError')
)

export const authRegisteringFullSelector = createSelector(
  authDataSelector,
  authData => authData.get('registeringFull')
)

export const authRegisterFullErrorSelector = createSelector(
  authDataSelector,
  authData => authData.get('registerFullError')
)

export const authSendingResetLinkSelector = createSelector(
  authDataSelector,
  authData => authData.get('authSendingResetLinkSelector')
)

export const authRequestResetLinkError = createSelector(
  authDataSelector,
  authData => authData.get('requestResetLinkError')
)

export const authResettingPasswordSelector = createSelector(
  authDataSelector,
  authData => authData.get('resettingPassword')
)

export const authResetPasswordErrorSelector = createSelector(
  authDataSelector,
  authData => authData.get('resetPasswordError')
)

export const authUserSelector = createSelector(
  authDataSelector,
  authData => authData.get('user')
)

export const authUserUuidSelector = createSelector(
  authUserSelector,
  user => (user && user.get('uuid')) || null
)

export const authJwtPublicKeySelector = createSelector(
  authDataSelector,
  authData => authData.get('jwtPublicKey')
)

export const deviceTokenSelector = createSelector(
  authDataSelector,
  authData => authData.get('deviceToken')
)

export const deviceIdSelector = createSelector(
  authDataSelector,
  authData => authData.get('deviceId')
)

export const updateProfileLoadingSelector = createSelector(
  authDataSelector,
  authData => authData.get('updateProfileLoading')
)

export const tokenSelector = createSelector(
  routerDataSelector,
  routerData => {
    const search = routerData.getIn(['location', 'search'], '')
    return queryString.parse(search).token
  }
)

export const microsoftFoldersSelector = createSelector(
  authDataSelector,
  authData => authData.get('microsoftFolders')
)

export const microsoftFoldersLoadingSelector = createSelector(
  authDataSelector,
  authData => authData.get('microsoftFoldersLoading')
)

export const tokenDecoded = createSelector(
  tokenSelector,
  authJwtPublicKeySelector,
  (token, jwtPublicKey) => {
    const decoded = { tokenValid: false, tokenExpired: false }

    try {
      const tokenValid = isValidToken(token, jwtPublicKey)
      const tokenExpired = isExpired(token)
      _.assign(decoded, decodeToken(token), { tokenExpired, tokenValid })
      return decoded
    } catch (err) {
      console.log({ err })
      return _.assign(decoded, { tokenValid: false })
    }
  }
)

// Invites
export const invitesReceivedSelector = createSelector(
  authDataSelector,
  authData => authData.get('invitesReceived')
)

export const inviteReceivedByUuidSelector = createSelector(
  invitesReceivedSelector,
  (state, uuid) => uuid,
  (invitesReceived, uuid) => invitesReceived.get(uuid)
)

export const activeOrganizationInvitesReceivedSelector = createSelector(
  invitesReceivedSelector,
  invites =>
    invites.filter(
      invite =>
        invite.get('token_type') === 'ORG_INVITE' &&
        invite.get('status') === 'ACTIVE'
    )
)
