import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { bool } from 'prop-types'
import RedirectToTeamPage from 'components/Page/RedirectToTeamPage'
import { actions as teamsActions } from 'modules/teams'

import ConversationsList from '../ConversationsList'
import FilterBar from '../FilterBar'
import PrintConversation from './PrintConversation'

const PrintPage = styled(props => {
  const [printNotes, setPrintNotes] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(teamsActions.queryTeams())
  }, [dispatch])

  return (
    <RedirectToTeamPage {...props}>
      <PrintHeader>
        <div>
          <h3>PhoneSlate</h3>
          <FilterBar print />
        </div>
        <PrintControls>
          <label htmlFor="notes_checkbox">
            <input
              id="notes_checkbox"
              type="checkbox"
              checked={printNotes}
              onChange={() => setPrintNotes(!printNotes)}
            />{' '}
            Include call notes
          </label>
        </PrintControls>
      </PrintHeader>

      <ConversationsList
        Component={PrintConversation}
        scrollDisabled
        printNotes={printNotes}
      />
    </RedirectToTeamPage>
  )
})`
  padding: 10px;
  @media print {
    padding: 0mm;
    margin: 0mm;
  }
`

const PrintHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const PrintControls = styled.div`
  padding: 10px;
  @media print {
    display: none;
  }
`

PrintPage.propTypes = {
  conversationsHasMore: bool,
}

PrintPage.defaultProps = {
  currentTeamId: null,
  conversationsHasMore: false,
}

export default PrintPage
