import styled from 'styled-components'
// import { Link } from 'react-router-dom'
import cx from 'classnames'
import CTAButton from 'components/Buttons/CTAButton'
import {
  ContactsIcon,
  GiftIcon,
  GlobeIcon,
  ListIcon,
  LockIcon,
  MobileIcon,
  PrintIcon,
  UsersIcon,
} from 'components/Icons'

import styles from 'style/index.scss'
import { media } from 'utility/styles'

import contactsImg from './phoneslate_contacts.png'
import heroImg from './phoneslate_hero.png'
import logoImg from './logo-sm@2x.png'
import securityImg from './phoneslate_security.png'
import sharingImg from './phoneslate_sharing.png'
import teamsImg from './phoneslate_teams.png'

// import foxImg from './logo-fox@2x.png'
// import dreamworksImg from './logo-dreamworks@2x.png'
// import wbImg from './logo-wb@2x.png'
// import huluImg from './logo-hulu@2x.png'
// import sonyImg from './logo-sony@2x.png'
// import nbcImg from './logo-nbc@2x.png'
// import bbcImg from './logo-bbc@2x.png'

// import exchangeImg from './logo-exchange@2x.png'
// import gmailImg from './logo-gmail@2x.png'
// import excelImg from './logo-excel@2x.png'
// import filemakerImg from './logo-filemaker@2x.png'

const CURRENT_YEAR = new Date().getFullYear()

const StyledLink = styled('a')`
  &,
  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.colors.red};
  }
`

const FooterLink = styled(StyledLink).attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})``

const OffsetAnchor = styled('a')`
  display: block;
  position: relative;
  visibility: hidden;
  top: -100px;
`

const Footer = styled('div')`
  background-color: black;
  padding: 30px;
  display: flex;

  ${media.tablet`
    flex-direction: column;
    align-items: flex-end;
  `}
`

const FooterLeft = styled('span')`
  display: block;
  color: white;
`

const FooterRight = styled(FooterLeft)`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${media.tablet`
    margin-left: 0;
  `}
`

const Home = ({ className }) => {
  // const foxStyle = { height: '33px' }
  // const dreamworksStyle = { height: '48px' }
  // const wbStyle = { height: '57px' }
  // const huluStyle = { height: '31px' }
  // const sonyStyle = { height: '18px' }
  // const nbcStyle = { height: '49px' }
  // const bbcStyle = { height: '36px' }

  // const exchangeStyle = { height: '33px' }
  // const gmailStyle = { height: '39px' }
  // const excelStyle = { height: '63px' }
  // const filemakerStyle = { height: '43px' }

  const SectionRow = (styled.div || styled.View)`
    display: flex;
    align-items: center;

    @media(max-width: ${props => props.theme.breakpoints.smMax}) {
      flex-direction: column;
      .col-mobile-top {
        order: -1;
      }
    }
  `

  const Container = (styled.div || styled.View)`
    width: 100%;
    max-width: 1200px;
    padding-right: calc(60px / 2);
    padding-left: calc(60px / 2);
    margin-right: auto;
    margin-left: auto;
  `

  const Section = styled.section`
    padding: 55px 0px;
    overflow-x: hidden;

    h1 {
      margin-bottom: 23px;
      font-size: 24px;
      font-weight: 300;
      line-height: 26px;
    }
    p {
      margin-bottom: 14px;
    }
    .access-img-wrap {
      display: flex;

      ${media.tablet`
        display: block;
      `}

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
        margin: auto;
      }
    }
    @media (max-width: ${props => props.theme.breakpoints.smMax}) {
      padding: 45px 0;

      img {
        max-width: 100%;
      }
    }
    @media (max-width: ${props => props.theme.breakpoints.xsMax}) {
      padding: 35px 0;
    }
  `

  const SectionHeader = (styled.h3 || styled.Text)`
    color: #${({ white }) => (white ? 'ffffff' : '333333')};
    font-size: 24px;
    font-weight: 500;
    margin-top: 15px;
    text-transform: uppercase;
  `

  const FeatSection = styled(Section)`
    text-align: center;
    background-color: #2E303F;
    color: #ffffff;

    video {
      width: 100%;
      margin: 40px 0;
      background-color: black;
      }
    }

    ul {
      margin-bottom: 50px;
    }
  `

  const Price = (styled.span || styled.Text)`
    font-size: 44px;
    font-weight: 500;
    line-height: 52px;
    margin: 9px 0 4px 0;
  `

  const PerUser = (styled.span || styled.Text)`
    font-size: 14px;
    font-weight: 300;
    line-height: 11px;
  `

  const IconList = styled.ul`
    list-style: none;
    text-align: center;
    padding-left: 0;
    li {
      padding-bottom: 22px;
    }

    i {
      color: ${({ theme }) => theme.colors.grayAlt};
      font-size: 2rem;
      margin-right: 1.5rem;
      vertical-align: middle;
      position: relative;
      top: -4px;
    }
  `

  const PricingSection = styled(Section)`
    margin-top: 55px;
    text-align: center;
    background-color: #f5f5f5;

    .tiers {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 90px;
      > div {
        padding: 20px 30px;
        border-left: 1px solid #d5d5d5;
        &:last-of-type {
          border-right: 1px solid #d5d5d5;
        }
      }
      h2 {
        margin: 0 0 9px 0;
        color: #333333;
        font-size: 24px;
        font-weight: 300;
        line-height: 29px;
      }
      span {
        display: block;
      }
      a {
        font-weight: 500;
      }
    }
    .testimonials {
      display: flex;
      flex-direction: row;
      justify-content: center;

      margin-top: 50px;

      color: #2e303f;
      > div {
        max-width: 300px;
        margin: 40px;

        text-align: left;
      }
    }

    @media (max-width: ${props => props.theme.breakpoints.smMax}) {
      .tiers {
        flex-direction: column;
        > div {
          border-left: 0px;
          border-bottom: 1px solid #d5d5d5;
          &:first-of-type {
            border-top: 1px solid #d5d5d5;
          }
          &:last-of-type {
            border-right: 0px;
            border-bottom: 0px;
          }
        }
      }
      .testimonials {
        flex-direction: column;
        > div {
          max-width: none;
          margin: 10px 0px;
        }
      }
    }
  `

  const Hero = (styled.div || styled.View)`
    padding-top: 60px;
    padding-bottom: 50px;

    color: #ffffff;
    background-color: #25263D;
    h2 {
      color: #D8D8D8;
    }
    p + button {
      margin-top: 36px;
    }
    .hero-img-wrap {
      display: flex;

      ${media.tablet`
        display: block;
      `}

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
        margin: auto;
      }
    }

    ${media.tablet`
      ${CTAButton} {
        display: block;
        margin-top: 20px;
      }
    `}
  `

  // const Logos = (styled.div || styled.View)`
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   padding: 15px 0;
  //   background-color: #F5F5F5;
  //   margin-bottom: 55px;
  //   overflow-x: hidden;
  //
  //   > img {
  //     flex: 0 0 auto;
  //     margin: 0 15px;
  //   }
  //   @media(max-width: ${props => props.theme.breakpoints.smMax}) {
  //     flex-wrap: wrap;
  //     > img {
  //       margin: 15px;
  //     }
  //   }
  // `

  return (
    <div className={className}>
      <Hero>
        <Container>
          <SectionRow className={styles.row}>
            <div className={styles['col-md-8']}>
              <div className="hero-img-wrap">
                <img
                  src={heroImg}
                  width="1524"
                  height="838"
                  alt="PhoneSlate screen"
                  style={{ maxWidth: '1524px' }} // eslint-disable-line react-native/no-inline-styles
                />
              </div>
            </div>

            <div className={styles['col-md-4']}>
              <h2>
                A simple, powerful call log app for desktop and mobile devices.
              </h2>

              <p>
                PhoneSlate is a call log app that makes it easy for teams and
                individuals to log and organize phone calls, and share call
                sheets. Share access with bosses, assistants, and other
                collaborators so no one misses an important call, no matter
                where they are.
              </p>

              <CTAButton to="/signup">Try It Free - No Credit Card</CTAButton>
            </div>
          </SectionRow>
        </Container>
      </Hero>

      {/* <Logos>
        <img src={foxImg} style={foxStyle} alt="Fox" />
        <img src={dreamworksImg} style={dreamworksStyle} alt="DreamWorks" />
        <img src={wbImg} style={wbStyle} alt="Warner Brothers" />
        <img src={huluImg} style={huluStyle} alt="Hulu" />
        <img src={sonyImg} style={sonyStyle} alt="Sony" />
        <img src={nbcImg} style={nbcStyle} alt="NBC Universal" />
        <img src={bbcImg} style={bbcStyle} alt="BBC" />
      </Logos> */}

      <Section>
        <Container>
          <SectionRow className={styles.row}>
            <div className={styles['col-md-6']}>
              <h1>Works with your existing contacts</h1>

              <p>
                You don&apos;t have to build a contacts list from scratch.
                Import contacts from Google, Outlook, Apple Contacts, or your
                own CSV.
              </p>

              {/* <StyledLink
                href="https://phoneslate.zendesk.com/hc/en-us/articles/360046847894-PhoneSlate-FAQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </StyledLink> */}
            </div>

            <div className={cx(styles['col-md-6'], 'col-mobile-top')}>
              <div className="access-img-wrap">
                <img
                  src={contactsImg}
                  width="590"
                  height="382"
                  alt="PhoneSlate contacts"
                  style={{ maxWidth: '295px' }} // eslint-disable-line react-native/no-inline-styles
                />
              </div>
            </div>
          </SectionRow>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionRow className={styles.row}>
            <div className={styles['col-md-6']}>
              <div className="access-img-wrap">
                <img
                  src={sharingImg}
                  width="864"
                  height="504"
                  alt="PhoneSlate sharing"
                  style={{ maxWidth: '864px' }} // eslint-disable-line react-native/no-inline-styles
                />
              </div>
            </div>

            <div className={styles['col-md-6']}>
              <h1>Share and access call sheets any way you like</h1>

              <p>
                Access PhoneSlate through your browser or with the dedicated iOS
                app. Send call sheets in the body of an email, or sort and
                filter your call sheet and PhoneSlate will create a printable
                version for you.
              </p>

              {/* <StyledLink
                href="https://phoneslate.zendesk.com/hc/en-us/articles/360046847894-PhoneSlate-FAQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </StyledLink> */}
            </div>
          </SectionRow>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionRow className={styles.row}>
            <div className={styles['col-md-6']}>
              <h1>Built for any organization</h1>

              <p>
                PhoneSlate fits your organization, whatever the size and
                structure. Set up unlimited teams with call and contacts
                sharing, let teams manage themselves, or manage everything from
                the top.
              </p>

              {/* <StyledLink
                href="https://phoneslate.zendesk.com/hc/en-us/articles/360046847894-PhoneSlate-FAQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </StyledLink> */}
            </div>

            <div className={cx(styles['col-md-6'], 'col-mobile-top')}>
              <div className="access-img-wrap">
                <img
                  width="562"
                  height="562"
                  style={{ maxWidth: '281px' }} // eslint-disable-line react-native/no-inline-styles
                  src={teamsImg}
                  alt="PhoneSlate tablet"
                />
              </div>
            </div>
          </SectionRow>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionRow className={styles.row}>
            <div className={styles['col-md-6']}>
              <div className="access-img-wrap">
                <img
                  src={securityImg}
                  width="600"
                  height="600"
                  alt="PhoneSlate security"
                  style={{ maxWidth: '300px' }} // eslint-disable-line react-native/no-inline-styles
                />
              </div>
            </div>

            <div className={styles['col-md-6']}>
              <h1>Keep it all safe</h1>

              <p>
                We store your information in an encrypted cloud server that uses
                the same SSL security the big banks use. We’ll never sell or
                share your data in any way. No exceptions.
              </p>
            </div>
          </SectionRow>
        </Container>
      </Section>

      <PricingSection>
        <Container>
          <OffsetAnchor id="pricing" />

          <img src={logoImg} alt="PhoneSlate" className="logo-sm" />

          <SectionHeader>Pricing</SectionHeader>

          <p>The first two weeks are free, then our pricing is simple:</p>

          <div className="tiers">
            <div>
              <h2>1-50 Users</h2>
              <Price>$10</Price>
              <PerUser>per user/month</PerUser>
            </div>
            <div>
              <h2>50+ Users</h2>
              <h2>
                <StyledLink
                  href="mailto:support@phoneslate.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </StyledLink>
              </h2>
            </div>
          </div>

          <CTAButton to="/signup">Try It Free - No Credit Card</CTAButton>

          {/* <div className="testimonials">
            <div>
              <p>
                <i>
                  As a digital media and branded entertainment executive, having
                  full, cross-device functionality of all phone activity is
                  extremely important. Phoneslate provides an excellent
                  solution.
                </i>
              </p>
              <p className="attribution">- Executive, Fox Digital Media</p>
            </div>
            <div>
              <p>
                <i>
                  As a digital media and branded entertainment executive, having
                  full, cross-device functionality of all phone activity is
                  extremely important. Phoneslate provides an excellent
                  solution.
                </i>
              </p>
              <p className="attribution">- Assistant, Elevate Entertainment</p>
            </div>
          </div> */}
        </Container>
      </PricingSection>

      <FeatSection>
        <Container>
          <OffsetAnchor id="features" />

          <img src={logoImg} alt="PhoneSlate" className="logo-sm" />

          <SectionHeader white>Features</SectionHeader>

          <div className={styles.row}>
            <div
              className={cx(
                styles['col-lg-6'],
                styles['col-lg-offset-3'],
                styles['col-md-8'],
                styles['col-md-offset-2']
              )}
            >
              {/* <video>
                <track kind="captions" />
              </video> */}
              <IconList>
                <li>
                  <ContactsIcon />
                  Sync contacts with Microsoft Exchange, or import contacts from
                  Outlook, FileMaker, InEntertainment, and more (COMING SOON!)
                </li>
                <li>
                  <LockIcon />
                  Enterprise-grade security
                </li>
                <li>
                  <GlobeIcon />
                  No installation - PhoneSlate works immediately in your browser
                </li>
                <li>
                  <MobileIcon />
                  Access PhoneSlate via mobile web or with the iOS app
                </li>
                <li>
                  <PrintIcon />
                  Create clear, printable call sheets with a single click
                </li>
                <li>
                  <UsersIcon />
                  Manage and share call logs with multiple collaborators
                </li>
                <li>
                  <ListIcon />
                  Conversation threading lets you see your entire call history
                  with a contact
                </li>
                <li>
                  <GiftIcon />
                  Free two-week trial with no credit card and no commitment,
                  then fair pricing
                </li>
              </IconList>
              <CTAButton to="/signup">Try It Free - No Credit Card</CTAButton>
            </div>
          </div>
        </Container>
      </FeatSection>

      <Footer>
        <FooterLeft>© {CURRENT_YEAR} PhoneSlate, LLC</FooterLeft>

        <FooterRight>
          <FooterLink href="https://app.termly.io/document/privacy-policy/f5582ae9-bf0e-44c1-b588-0390c7e2f887">
            Privacy Policy
          </FooterLink>
          <FooterLink href="https://app.termly.io/document/terms-of-use-for-saas/1046dca2-10cb-4b0b-8f24-b9488ff623a5">
            Terms and Conditions
          </FooterLink>
          <span>
            Contact us at{' '}
            <FooterLink href="mailto:support@phoneslate.com">
              support@phoneslate.com
            </FooterLink>
          </span>
        </FooterRight>
      </Footer>
    </div>
  )
}

Home.propTypes = {}

export default styled(Home)`
  font-size: 16px;

  h2 {
    font-size: 24px;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
  }

  .logo-sm {
    width: 29px;
  }
`
