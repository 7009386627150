const get = async key => {
  const item = localStorage.getItem(key)
  try {
    const parsedItem = JSON.parse(item)
    return parsedItem
  } catch (e) {
    return item
  }
}

const set = async (key, item) => localStorage.setItem(key, JSON.stringify(item))

const remove = async key => localStorage.removeItem(key)

export default {
  get,
  set,
  remove,
}
