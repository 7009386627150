import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS } from 'immutable'

import { EditIcon } from 'components/Icons'
import Spinner from 'components/Spinner'
import Link from 'components/Link'
import { onlyShow } from 'utility/styles'

import {
  currentOrganizationIdSelector,
  adminOrgsSelector,
} from 'selectors/organizationsSelector'
import {
  getQueryAdminTeamUuids,
  getTeamWithMemberByUuid,
  getQueryAdminTeamsLoading,
} from 'selectors/teamsSelector'
import { actions as teamsActions } from 'modules/teams'

import AddTeamButton from '../components/AddTeamButton'

const Row = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #e3e3e3;
  padding: 16px 18px;
  ${({ isEven }) =>
    isEven &&
    css`
      background-color: #f6f6f6;
    `}
`

const Col = styled.div`
  flex: 1 1 100%;
  font-size: 12px;
  line-height: 14px;
  color: #9b9b9b;
`

const NameCol = styled(Col)`
  flex: 0 0 160px;
  color: #434a54;
  font-size: 14px;
  font-weight: bold;
  padding-right: 10px;
  ${onlyShow(1).line}
`

const ButtonCol = styled(Col)`
  flex: 0 0 auto;
  padding-left: 10px;
`

const EditButton = styled(({ to, className }) => (
  <Link to={to} className={className} replace>
    <EditIcon />
  </Link>
))`
  color: inherit;
`

const TeamRow = ({ teamUuid, isEven }) => {
  const organization = useSelector(currentOrganizationIdSelector)
  const team = useSelector(state =>
    getTeamWithMemberByUuid(state, { teamUuid })
  )

  const [hovered, setHovered] = useState(false)

  if (!team) return null

  const { name, members } = team.toJS()

  return (
    <Row
      isEven={isEven}
      onMouseOver={() => setHovered(true)}
      onFocus={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onBlur={() => setHovered(false)}
    >
      <NameCol>{name}</NameCol>

      <Col>
        {members.map(({ short_name: memberName }) => memberName).join(', ')}
      </Col>

      <ButtonCol>
        {!!hovered && (
          <EditButton to={`/settings/${organization}/teams/${teamUuid}`} />
        )}
      </ButtonCol>
    </Row>
  )
}

const Container = styled.div`
  border-bottom: 1px solid #e3e3e3;
`

const NoTeamsMessage = styled.h2.attrs(() => ({
  children: 'No teams found',
}))`
  font-size: 22px;
`

const TeamsList = () => {
  const organization = useSelector(currentOrganizationIdSelector)
  const teamUuids = useSelector(getQueryAdminTeamUuids)
  const queryAdminTeamsLoading = useSelector(getQueryAdminTeamsLoading)
  const isOrgAdmin = useSelector(adminOrgsSelector)
  const dispatch = useDispatch()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if (organization) {
      dispatch(teamsActions.queryAdminTeams(fromJS({ organization })))
      setMounted(true)
    }
  }, [organization, dispatch])

  if (!(organization && teamUuids)) return null

  const isEmpty = teamUuids.isEmpty()

  if (queryAdminTeamsLoading) return <Spinner />

  return (
    <>
      <Container>
        {teamUuids.map((teamUuid, index) => (
          <TeamRow isEven={!!(index % 2)} key={teamUuid} teamUuid={teamUuid} />
        ))}
      </Container>

      {isEmpty && !queryAdminTeamsLoading && mounted && <NoTeamsMessage />}

      {!!isOrgAdmin && <AddTeamButton />}
    </>
  )
}

export default TeamsList
