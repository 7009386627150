import styled from 'styled-components'
import { func, string } from 'prop-types'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { actions as conversationsActions } from 'modules/conversations'
import {
  getLastCallTimeFromUuid,
  getLastCallUuidFromUuid,
} from 'selectors/conversationsSelector'

import DateComponent from '../DatePicker'

const mapStateToProps = (state, { conversationUuid: uuid }) => {
  const callTime = getLastCallTimeFromUuid(state, { uuid })
  const callUuid = getLastCallUuidFromUuid(state, { uuid })

  return {
    callUuid,
    callTime,
  }
}

const mapDispatchToProps = {
  updateCall: conversationsActions.updateCall,
}

const propTypes = {
  callUuid: string,
  callTime: string,
  conversationUuid: string.isRequired,
  updateCall: func.isRequired,
}

const defaultProps = {
  callUuid: null,
  callTime: null,
}

class DatePicker extends React.Component {
  handleChange = date => {
    const { updateCall, callUuid, conversationUuid } = this.props

    updateCall(
      fromJS({
        uuid: callUuid,
        conversationUuid,
        date,
      })
    )
  }

  render() {
    const {
      callTime,
      callUuid,
      conversationUuid,
      updateCall,
      ...props
    } = this.props

    return (
      <div {...props}>
        <DateComponent handleChange={this.handleChange} value={callTime} />
      </div>
    )
  }
}

DatePicker.propTypes = propTypes

DatePicker.defaultProps = defaultProps

export default styled(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DatePicker)
)`
  .SingleDatePickerInput {
    border: 1px solid transparent;
    background-color: transparent;
    outline: none !important;
    -webkit-appearance: none;
    background-image: none;
    border-radius: 3px;

    &:focus,
    &:hover {
      border-color: #cbcbcb;
      background-color: #fff;
    }
  }

  .DateInput_input {
    font-weigth: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    padding: 4px;
  }

  .DateInput,
  .DateInput_input,
  .DateInput_input__focused {
    background-color: transparent;
    border: 0;
  }
`
