import _ from 'lodash'
import { FORM_ERROR } from 'final-form'
import {
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
  all,
} from 'redux-saga/effects'
import { actionTypes as reduxFormActionTypes } from 'redux-form/immutable'
import { fromJS } from 'immutable'
import moment from 'moment'
import { normalize } from 'normalizr'

import {
  createCall as createCallApi,
  updateCall as updateCallApi,
} from 'api/calls'
import {
  createConversation as createConversationApi,
  deleteConversation as deleteConversationApi,
  emailConversations as emailConversationsApi,
  getConversation as getConversationApi,
  queryConversations as queryConversationsApi,
  updateConversation as updateConversationApi,
  schema as conversationsSchema,
} from 'api/conversations'

import { constants as teamsContants } from 'modules/teams'
import {
  actions as contactsActions,
  constants as contactsConstants,
} from 'modules/contacts'
import {
  constants as conversationsConstants,
  actions as conversationsActions,
} from 'modules/conversations'

import { currentTeamIdSelector } from 'selectors/teamsSelector'
import {
  getActiveConversationFilters,
  getEmptyConversationFiltersValues,
} from 'selectors/formSelector'
import { getContactByUuid } from 'selectors/contactsSelector'
import {
  conversationsDataSelector,
  getConversationByUuid,
  getConversations,
  getConversationUuids,
  getLastCallTimeFromUuid,
  getUpdateCallLoadingList,
} from 'selectors/conversationsSelector'

import devicestorage from 'utility/devicestorage'

function* getConversation({ payload }) {
  let response
  const uuid = payload.get('uuid')
  const conversationData = yield select(conversationsDataSelector)
  const getConversationLoadingList = conversationData
    .get('getConversationLoadingList')
    .filter(_uuid => _uuid !== uuid)

  try {
    response = yield call(getConversationApi, uuid)

    const {
      entities: {
        conversations = {},
        contacts = {},
        calls = {},
        phone_numbers: phoneNumbers = {},
        email_addresses: emailAddresses = {},
      },
    } = response

    _.set(conversations, [uuid, '_has_details'], true)

    yield put({
      type: conversationsConstants.GET_CONVERSATION_SUCCESS,
      payload: fromJS({
        getConversationLoadingList,
        conversations,
        calls,
        contacts,
        phoneNumbers,
        emailAddresses,
      }),
    })
  } catch (error) {
    yield put({
      type: conversationsConstants.GET_CONVERSATION_FAIL,
      payload: fromJS({
        getConversationLoadingList,
        error,
      }),
    })
  }

  return fromJS(response)
}

function* createCall({ payload }) {
  let response
  const { form, ..._call } = payload.toJS()

  try {
    response = yield call(createCallApi, _call)
    yield put({
      type: conversationsConstants.CREATE_CALL_SUCCESS,
    })
  } catch (error) {
    yield put({
      type: conversationsConstants.CREATE_CALL_FAIL,
      payload: fromJS({ error }),
    })

    throw error
  }

  return fromJS(response)
}

function* createConversation({ payload }) {
  const {
    conversation,
    call: { status, date, time },
    resolve,
  } = payload.toJS()

  try {
    const team = yield select(currentTeamIdSelector)
    const { result: conversationUuid } = yield call(createConversationApi, {
      team,
      ...conversation,
    })

    const momentTime = moment(time, moment.HTML5_FMT.TIME)
    const combinedDate = moment(date)
      .set({
        hour: momentTime.get('hour'),
        minute: momentTime.get('minute'),
        second: momentTime.get('second'),
      })
      .utc()
      .format()

    yield call(
      createCall,
      conversationsActions.createCall(
        fromJS({
          status,
          call_time: combinedDate,
          conversation: conversationUuid,
        })
      )
    )

    yield put({
      type: conversationsConstants.CREATE_CONVERSATION_SUCCESS,
    })

    resolve({ conversationUuid, errors: {} })
  } catch (error) {
    yield put({
      type: conversationsConstants.CREATE_CONVERSATION_SUCCESS,
      payload: fromJS({ error }),
    })

    resolve({
      errors: {
        ..._.get(error, 'response.data', {}),
        [FORM_ERROR]: 'There was an error submitting this conversation.',
      },
    })
  }
}

function* updateCall({ payload }) {
  const { conversationUuid, date, time, uuid, ..._call } = payload.toJS()
  const updateCallLoadingList = yield select(getUpdateCallLoadingList)
  const newUpdateCallLoadingList = updateCallLoadingList.filter(u => u !== uuid)

  try {
    if (date || time) {
      const callTime = yield select(getLastCallTimeFromUuid, {
        uuid: conversationUuid,
      })
      const newCallTime = moment(callTime)

      if (date) {
        newCallTime.set({
          year: date.get('year'),
          month: date.get('month'),
          date: date.get('date'),
        })
      }

      if (time) {
        newCallTime.set({
          hour: time.get('hour'),
          minute: time.get('minute'),
          second: time.get('second'),
        })
      }

      _call.call_time = newCallTime.utc().format()
    }

    yield call(updateCallApi, uuid, { ..._call })
  } catch (error) {
    yield put({
      type: conversationsConstants.UPDATE_CALL_FAIL,
      payload: fromJS({
        updateCallLoadingList: newUpdateCallLoadingList,
        error,
      }),
    })
  }
}

function* deleteConversation({ payload: uuid }) {
  try {
    yield call(deleteConversationApi, uuid)
    yield put({
      type: conversationsConstants.DELETE_CONVERSATION_SUCCESS,
      payload: uuid,
    })
  } catch (error) {
    yield put({
      type: conversationsConstants.DELETE_CONVERSATION_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* queryConversations({
  payload: { limit = 10, offset = 0, replace = false },
}) {
  try {
    const teamUuid = yield select(currentTeamIdSelector)
    const activeConversationFilters = yield select(getActiveConversationFilters)
    const queryParams = activeConversationFilters.toJS()

    const {
      entities: {
        conversations = {},
        contacts = {},
        phone_numbers: phoneNumbers = {},
        email_addresses: emailAddresses = {},
      },
      result: conversationUuids,
      count: conversationsCount,
    } = yield call(queryConversationsApi, {
      ...queryParams,
      team_uuid: teamUuid,
      limit,
      offset,
    })

    yield put({
      type: conversationsConstants.QUERY_CONVERSATIONS_SUCCESS,
      payload: fromJS({
        conversations,
        conversationUuids,
        conversationsCount,
        contacts,
        phoneNumbers,
        emailAddresses,
        replace,
      }),
    })
  } catch (error) {
    yield put({
      type: conversationsConstants.QUERY_CONVERSATIONS_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* getQueryConversationUuids({
  limit = 1000,
  offset = 0,
  fields = 'uuid',
} = {}) {
  let newUuids

  try {
    const teamUuid = yield select(currentTeamIdSelector)
    const activeConversationFilters = yield select(getActiveConversationFilters)
    const queryParams = activeConversationFilters.toJS()
    if (fields) queryParams.fields = fields

    newUuids = yield call(queryConversationsApi, {
      ...queryParams,
      team_uuid: teamUuid,
      limit,
      offset,
    })
  } catch (error) {
    console.log(error)
  }

  return newUuids
}

function* setQueryConversationUuids(newUuids) {
  try {
    const conversationUuids = yield select(getConversationUuids)
    const conversations = yield select(getConversations)
    const {
      result: conversationUuidsOnly,
      count: conversationsCount,
    } = newUuids

    const newConversationUuids = conversationUuidsOnly.slice(
      0,
      conversationUuids.size
    )
    const unfetchedConversations = []
    newConversationUuids.forEach(uuid => {
      if (!conversations.has(uuid)) {
        unfetchedConversations.push(uuid)
      }
    })

    yield all(
      unfetchedConversations.map(uuid => {
        return call(
          getConversation,
          conversationsActions.getConversation(fromJS({ uuid }))
        )
      })
    )

    yield put({
      type: conversationsConstants.QUERY_CONVERSATIONS_UUIDS_SUCCESS,
      payload: fromJS({
        conversationUuids: newConversationUuids,
        conversationsCount,
      }),
    })
  } catch (error) {
    console.log(error)
  }
}

function* queryConversationUuids(params = {}) {
  const uuids = yield call(getQueryConversationUuids, params)
  yield call(setQueryConversationUuids, uuids)
}

function* socketCall({ type, payload }) {
  try {
    const _call = payload.toJS()
    const { uuid, conversation } = _call
    const calls = { [uuid]: _call }

    const newUuids = yield call(getQueryConversationUuids)
    const { result: conversationUuidsOnly } = newUuids

    if (conversationUuidsOnly.includes(conversation)) {
      const conversationInState = yield select(getConversationByUuid, {
        uuid: conversation,
      })

      if (conversationInState) {
        yield put(
          conversationsActions.getConversation(fromJS({ uuid: conversation }))
        )
      }
    }

    yield put({
      type: `${type}_SUCCESS`,
      payload: fromJS({ calls }),
    })

    if (newUuids) {
      yield call(setQueryConversationUuids, newUuids)
    }
  } catch (error) {
    yield put({
      type: `${type}_FAIL`,
      payload: fromJS({ error }),
    })
  }
}

function* socketUpdateConversation({ payload }) {
  const conversation = payload.toJS()
  const { uuid, team, contact: contactUuid } = conversation
  const currentTeamUuid = yield select(currentTeamIdSelector)

  if (!_.isEqual(currentTeamUuid, team)) return

  const newUuids = yield call(getQueryConversationUuids)
  const { result: conversationUuidsOnly } = newUuids

  if (conversationUuidsOnly.includes(uuid)) {
    const contact = yield select(getContactByUuid, { uuid: contactUuid })

    if (!contact) {
      yield put(contactsActions.getContact(contactUuid))
    }
  }

  const { entities } = normalize(conversation, conversationsSchema.conversation)

  yield put({
    type: conversationsConstants.SOCKET_UPDATE_CONVERSATION_SUCCESS,
    payload: fromJS({ ...entities }),
  })

  if (newUuids) {
    yield call(setQueryConversationUuids, newUuids)
  }
}

function* updateConversation({ payload }) {
  const { uuid, ...patch } = payload.toJS()

  try {
    const { entities } = yield call(updateConversationApi, uuid, patch)

    yield put({
      type: conversationsConstants.UPDATE_CONVERSATION_SUCCESS,
      payload: fromJS({
        ...entities,
        uuid,
      }),
    })
  } catch (error) {
    yield put({
      type: conversationsConstants.UPDATE_CONVERSATION_FAIL,
      payload: fromJS({
        error,
        uuid,
      }),
    })
  }
}

function* updateStatus({ payload }) {
  const uuid = payload.get('conversation')
  const lastCallTime = yield select(getLastCallTimeFromUuid, { uuid })
  const callTimeMoment = moment.max(moment(lastCallTime), moment())

  yield put(
    conversationsActions.createCall(
      payload.set('call_time', callTimeMoment.utc().format())
    )
  )
}

function* emailConversations({ payload }) {
  const { members, resolve } = payload.toJS()

  try {
    const currentTeamUuid = yield select(currentTeamIdSelector)
    const filters = yield select(getActiveConversationFilters)
    const params = filters.toJS()

    yield all(
      members.map(({ user }) =>
        call(emailConversationsApi, currentTeamUuid, {
          params,
          data: { recipient_user: user },
        })
      )
    )

    resolve({ errors: {} })
  } catch (error) {
    resolve({
      errors: {
        ..._.get(error, 'response.data', {}),
        _error: 'There was an error sending this email.',
      },
    })
  }
}

function* onFormChange({ meta: { form, field = null }, payload }) {
  if (form === 'conversationFilters') {
    try {
      let limit = 10
      if (!field) {
        limit = payload.get('limit') || limit
      }
      yield put(conversationsActions.clearConversations())
      yield put(conversationsActions.queryConversations({ limit }))

      if (field && !['keyword', 'contact_uuid'].includes(field)) {
        const currentTeamUuid = yield select(currentTeamIdSelector)
        const emptyForm = yield select(getEmptyConversationFiltersValues)
        const payloadJS = payload.toJS ? payload.toJS() : payload

        let filterSets = yield devicestorage.get(
          __CONFIG__.localStorageKeys.filterSets
        )
        filterSets = filterSets || {}

        if (_.isEqual(payloadJS, emptyForm.toJS()[field])) {
          _.unset(filterSets, [currentTeamUuid, field])
        } else {
          _.set(filterSets, [currentTeamUuid, field], payloadJS)
        }

        yield devicestorage.set(
          __CONFIG__.localStorageKeys.filterSets,
          filterSets
        )
      }
    } catch (error) {
      console.log(error)
    }
  }
}

function* onSetCurrentTeamId() {
  yield put(conversationsActions.clearConversations())
}

function* watchConversations() {
  yield takeEvery(conversationsConstants.CREATE_CALL, createCall)
  yield takeEvery(
    conversationsConstants.CREATE_CONVERSATION,
    createConversation
  )
  yield takeEvery(conversationsConstants.UPDATE_CALL, updateCall)
  yield takeEvery(
    conversationsConstants.UPDATE_CONVERSATION,
    updateConversation
  )
  yield takeEvery(
    conversationsConstants.DELETE_CONVERSATION,
    deleteConversation
  )
  yield takeEvery(conversationsConstants.GET_CONVERSATION, getConversation)
  yield takeLatest(
    conversationsConstants.QUERY_CONVERSATIONS,
    queryConversations
  )
  yield takeEvery(
    conversationsConstants.EMAIL_CONVERSATIONS,
    emailConversations
  )
  yield takeEvery(conversationsConstants.UPDATE_STATUS, updateStatus)
  yield takeLatest(reduxFormActionTypes.CHANGE, onFormChange)
  yield takeLatest(reduxFormActionTypes.INITIALIZE, onFormChange)

  yield takeLatest(teamsContants.SET_CURRENT_TEAM_ID, onSetCurrentTeamId)

  yield takeEvery(
    conversationsConstants.SOCKET_DELETE_CONVERSATION,
    queryConversationUuids
  )
  yield takeEvery(
    conversationsConstants.SOCKET_UPDATE_CONVERSATION,
    socketUpdateConversation
  )
  yield takeEvery(
    [
      conversationsConstants.SOCKET_CREATE_CALL,
      conversationsConstants.SOCKET_UPDATE_CALL,
    ],
    socketCall
  )
  yield takeLatest(
    contactsConstants.SOCKET_DELETE_CONTACT,
    queryConversationUuids
  )
}

export const conversationsSaga = [fork(watchConversations)]
