import React, { useRef } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { object } from 'prop-types'
import {
  getCurrentTeamMembers,
  getShortNameFromUserUuid,
} from 'selectors/teamsSelector'
import { Dropdown, Option } from 'components/Dropdown'

const MemberDropdown = ({
  meta,
  input: { value: selectedMemberUuid, onChange },
  ...props
}) => {
  const members = useSelector(getCurrentTeamMembers)
  const selectedMemberShortName = useSelector(state =>
    getShortNameFromUserUuid(state, { uuid: selectedMemberUuid })
  )
  const dropdownHolder = useRef({})

  if (!members || members.isEmpty()) return null

  return (
    <Container {...props}>
      <Dropdown
        ref={dropdownHolder}
        toggle={
          selectedMemberShortName ? (
            <span>{selectedMemberShortName}</span>
          ) : (
            <span>Select team member</span>
          )
        }
        noCaret
      >
        {members.map(member => {
          const userUuid = member.get('user')
          const shortName = member.get('short_name')

          return (
            <Option
              active={userUuid === selectedMemberUuid}
              className="dropdownItem"
              closeDropdown={dropdownHolder.current.closeDropdown}
              key={userUuid}
              onSelect={() => {
                onChange(userUuid)
                dropdownHolder.current.closeDropdown()
              }}
            >
              {shortName}
            </Option>
          )
        })}
      </Dropdown>
    </Container>
  )
}

const Container = styled.div`
  .dropdown {
    max-height: 330px;
    overflow: auto;
  }
`

MemberDropdown.propTypes = {
  input: object.isRequired,
  meta: object.isRequired,
}

export default styled(MemberDropdown)``
