import PageHeading from 'components/Page/PageHeading'
import OrgsAndTeams from './OrgsAndTeams'
import PersonalInfo from './PersonalInfo'
import Invites from './Invites'

const Account = () => {
  return (
    <>
      <PageHeading>My Account</PageHeading>

      <PersonalInfo />

      <OrgsAndTeams />

      <Invites />
    </>
  )
}

export default Account
