import React from 'react'
import { useDispatch } from 'react-redux'
import { bool, string } from 'prop-types'

import {
  setNewContactFormPostSaveTarget as _setNewContactFormPostSaveTarget,
  toggleNewContactForm,
} from 'modules/contacts'

import Button from 'components/Buttons/BlueActionButton'

const BUTTON_LABEL = 'Add New Contact'

const AddContactButton = ({
  isNewConversation,
  conversationUuid,
  ...props
}) => {
  const dispatch = useDispatch()

  const openForm = () => dispatch(toggleNewContactForm(true))
  const setNewContactFormPostSaveTarget = target => (
    dispatch(_setNewContactFormPostSaveTarget(target))
  )

  const onClick = () => {
    openForm()

    const postSaveTarget = isNewConversation
      ? 'addConversation'
      : conversationUuid || null

    setNewContactFormPostSaveTarget(postSaveTarget)
  }

  return (
    <Button onClick={onClick} {...props}>
      {BUTTON_LABEL}
    </Button>
  )
}

AddContactButton.propTypes = {
  conversationUuid: string,
  isNewConversation: bool,
}

AddContactButton.defaultProps = {
  conversationUuid: null,
  isNewConversation: false,
}

export default AddContactButton
