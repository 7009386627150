import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import useAuth from 'hooks/useAuth'

import { currentTeamSelector } from 'selectors/teamsSelector'

import _Button from 'components/Buttons'

import BackLink from '../../components/BackLink'
import FoldersList from './FoldersList'


const Sync = () => {
  const team = useSelector(currentTeamSelector)
  const {
    microsoftFolders,
    microsoftOAuth,
    queryMicrosoftFolders,
    microsoftFoldersLoading,
  } = useAuth()
  const mounted = useRef(false)
  const foldersLoaded = mounted.current && !microsoftFoldersLoading

  console.log({
    mounted,
    foldersLoaded,
    microsoftFolders,
  })

  useEffect(() => {
    if (!mounted.current) {
      queryMicrosoftFolders()
      mounted.current = true
    }
  }, [])

  if (!foldersLoaded) return null
  if (!team) return null

  const hasFolders = microsoftFolders.size > 0

  return (
    <>
      <Header>
        <Col>
          <BackLink>
            &lt; Contacts
          </BackLink>

          <Title>Sync Contacts With Microsoft Exchange Server</Title>
        </Col>
      </Header>

      {!!hasFolders && <FoldersList />}

      {!hasFolders && (
        <>
          <Graf>
            Your contacts are not currently synced with Microsoft Exchange Server.
          </Graf>

          <Button onClick={microsoftOAuth} />
        </>
      )}
    </>
  )
}

const Button = styled(_Button).attrs({
  red: true,
  primary: true,
  children: 'Authorize Microsoft',
})``

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  margin-bottom: 5px;
  font-size: 24px;
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 22px;
  text-transform: uppercase;
`

const Header = styled.div`
  margin-top: 16px;
  margin-bottom: 12px;
`

const Graf = styled.p`
  margin-bottom: 24px;
`

export default Sync
