import styled from 'styled-components'
import { connect } from 'react-redux'
import { getEmailAddressFromEmailAddressUuid } from 'selectors/contactsSelector'

const mapStateToProps = (state, { emailAddressUuid: uuid }) => {
  const email = getEmailAddressFromEmailAddressUuid(state, { uuid })
  return {
    email,
  }
}

const ContactEmail = ({ emailAddressUuid, email, ...props }) => (
  <a
    href={`mailto:${email}`}
    {...props}
  >
    {email}
  </a>
)

export default styled(connect(
  mapStateToProps,
  {}
)(ContactEmail))`
  display: block;
  line-height: 18px;
`
