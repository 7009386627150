import styled from 'styled-components'
import { Form as ReactFinalForm } from 'react-final-form'
import { fromJS } from 'immutable'
import { BooleanParam, useQueryParam } from 'use-query-params'

import { FloatingLabelFinalField } from 'components/FloatingLabelInput'
import Spinner from 'components/Spinner'
import { FormRow, Form as _Form, FormError } from 'components/Form'
import CardMessage from 'components/Cards/CardMessage'
import useAuth from 'hooks/useAuth'

import validate from './ResetPasswordFormValidation'

import Title from '../Title'
import Link from '../Link'
import Button from '../Button'

const Form = styled(_Form)``

const ResetPasswordForm = ({
  handleSubmit,
  invalid,
  links,
  pristine,
  submitting,
  submitError,
  submitErrors,
  submitSucceeded,
  title = 'Reset Your Password',
}) => {
  const [forceReset] = useQueryParam('force_reset', BooleanParam)
  return (
    <Form onSubmit={handleSubmit}>
      {title && <Title>{title}</Title>}
      <CardMessage>
        {!submitSucceeded
          ? forceReset
            ? 'Your account password must be reset to comply with security rules.'
            : 'Enter your email address and we’ll send you a link to reset your password.'
          : "Thanks! We've sent you an email with a link to reset your password."}
      </CardMessage>
      <>
        {!submitSucceeded && (
          <>
            <FormRow>
              <FloatingLabelFinalField
                id="email"
                name="email"
                type="text"
                placeholder="Email"
              />
            </FormRow>

            {!!submitError && !pristine && <FormError>{submitError}</FormError>}

            <FormRow half>
              <Button
                primary
                disabled={
                  submitting ||
                  invalid ||
                  pristine ||
                  submitError ||
                  submitErrors
                }
                onClick={handleSubmit}
                type="submit"
              >
                Send Link
              </Button>
            </FormRow>
          </>
        )}

        {links &&
          links.length > 0 &&
          links.map((link, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <Link key={key} to={link.path}>
              {link.label}
            </Link>
          ))}
      </>

      {submitting && <Spinner />}
    </Form>
  )
}

const WrappedResetPasswordForm = props => {
  const { requestResetLink } = useAuth()

  const initialValues = {
    recipient_email: null,
  }

  return (
    <ReactFinalForm
      initialValues={initialValues}
      onSubmit={values =>
        new Promise(resolve => {
          requestResetLink(
            fromJS({
              ...values,
              resolve,
            })
          )
        })
      }
      validate={validate}
    >
      {_props => <ResetPasswordForm {...props} {..._props} />}
    </ReactFinalForm>
  )
}

export default WrappedResetPasswordForm
