import styled from 'styled-components'
import Theme from 'components/Theme'

export default (styled.h1 || styled.Text)`
  margin-top: ${Theme.page.headingMarginVertical};
  margin-bottom: ${Theme.page.headingMarginVertical};
  color: ${Theme.page.headingColor};
  font-size: 26px;
  letter-spacing: 2px;
  text-transform: uppercase;
`
