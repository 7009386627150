import styled from 'styled-components'
import { func, string } from 'prop-types'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'

import {
  getSelectedPhoneNumberFromUuid,
  getSelectedOrFirstPhoneNumberFromUuid,
  getAllPhoneNumbersFromUuid,
} from 'selectors/conversationsSelector'

import { actions as conversationsActions } from 'modules/conversations'

import { Dropdown as _Dropdown, Option } from 'components/Dropdown'
import PhoneNumber from 'components/PhoneNumber'

const Dropdown = styled(_Dropdown)`
  margin-top: 0;
  top: 0;
`

const mapStateToProps = (state, ownProps) => {
  const { conversationUuid: uuid } = ownProps
  const selectedPhoneNumberUuid = getSelectedPhoneNumberFromUuid(state, {
    uuid,
  })
  const selectedOrFirstPhoneNumberUuid = getSelectedOrFirstPhoneNumberFromUuid(
    state,
    {
      uuid,
    }
  )
  const phoneNumbers = getAllPhoneNumbersFromUuid(state, { uuid })

  return {
    phoneNumbers,
    selectedPhoneNumberUuid,
    selectedOrFirstPhoneNumberUuid,
  }
}

const mapDispatchToProps = {
  updateConversation: conversationsActions.updateConversation,
}

class PhoneDropdown extends React.Component {
  handlePhoneSelect = newPhoneNumberUuid => {
    const { updateConversation, conversationUuid } = this.props

    updateConversation(
      fromJS({
        uuid: conversationUuid,
        selected_phone_number: newPhoneNumberUuid,
      })
    )
  }

  render() {
    const {
      conversationUuid,
      phoneNumbers,
      selectedPhoneNumberUuid,
      selectedOrFirstPhoneNumberUuid,
      updateConversation,
      ...props
    } = this.props

    if (!phoneNumbers || phoneNumbers.count() === 0) return null

    return (
      <div {...props}>
        <Dropdown
          ref={_dropdown => {
            this._dropdownHolder = _dropdown
          }}
          toggle={
            <PhoneNumber phoneNumberUuid={selectedOrFirstPhoneNumberUuid} />
          }
          noCaret
        >
          {phoneNumbers.map(phoneNumberUuid => (
            <Option
              active={phoneNumberUuid === selectedPhoneNumberUuid}
              className="dropdownItem"
              id={phoneNumberUuid}
              key={phoneNumberUuid}
              onSelect={() => {
                this.handlePhoneSelect(phoneNumberUuid)
                this._dropdownHolder.closeDropdown()
              }}
            >
              <PhoneNumber phoneNumberUuid={phoneNumberUuid} />
            </Option>
          ))}
        </Dropdown>
      </div>
    )
  }
}

PhoneDropdown.propTypes = {
  conversationUuid: string.isRequired,
  updateConversation: func.isRequired,
}

PhoneDropdown.defaultProps = {}

export default styled(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PhoneDropdown)
)`
  position: relative;
  .dropdown {
    top: 0;
    margin-top: 0;
  }
`
