import _ from 'lodash'
import axios from 'axios'
import {
  getAuthToken,
  deleteAuthToken,
  getApiHostAddress,
} from 'utility/cookie'
import { normalize } from 'normalizr'

if (__CONFIG__.env === 'test') {
  const httpAdapter = require('axios/lib/adapters/http')
  axios.defaults.host = __CONFIG__.testHost
  axios.defaults.adapter = httpAdapter
}

const methods = ['get', 'post', 'put', 'patch', 'delete']

const formatUrl = async path => {
  const adjustedPath = path[0] !== '/' ? `/${path}` : path

  if (path.indexOf('http') === 0) {
    return path
  }

  const apiHostAddress = await getApiHostAddress()

  return apiHostAddress + adjustedPath
}

class _ApiClient {
  constructor() {
    // request available as param
    methods.forEach(method => {
      this[method] = async (
        path,
        {
          params,
          data,
          headers,
          attachments,
          handleProgress,
          omitToken,
          schema,
          isArray,
        } = {}
      ) => {
        const requestConfig = {
          method,
          url: await formatUrl(path),
        }

        if (params) {
          requestConfig.params = { ...params }
        }

        if (headers) {
          requestConfig.headers = { ...headers }
        }

        if (attachments) {
          if (attachments && typeof attachments === 'object') {
            const _data = new FormData()
            Object.keys(attachments).forEach(c => {
              _data.append(c, attachments[c])
            })
            requestConfig.data = _data
          }
        }

        if (data && !attachments) {
          requestConfig.data = { ...data }
        }

        if (handleProgress) {
          requestConfig.onUploadProgress = handleProgress
        }

        if (!omitToken) {
          const accessToken = await getAuthToken()
          if (!accessToken) {
            const error = {
              message: 'No token provided.',
              response: {
                status: 401,
              },
            }
            await deleteAuthToken()
            throw error
          }
          _.set(requestConfig, 'headers.Authorization', `Bearer ${accessToken}`)
        }

        try {
          const res = await axios({ ...requestConfig })
          const key = isArray ? 'data.results' : 'data'
          let payload = _.get(res, key)
          if (schema) payload = normalize(payload, schema)
          if (isArray) {
            payload.next = res.data.next
            payload.count = res.data.count
          }
          return payload
        } catch (error) {
          if (window && window._rollbar) {
            window._rollbar.error(error)
          }
          const errorObject = _.omit(error.response || error, [
            'config',
            'headers',
            'request',
            'toJSON',
          ])
          errorObject.message = error.message
          throw errorObject
        }
      }
    })
  }
}

const ApiClient = _ApiClient

export default ApiClient
