import _ from 'lodash'
import { Field } from 'react-final-form'

const FileField = styled(({ name, ...props }) => (
  <Field name={name}>
    {({ input: { value, onChange, ...input } }) => {
      const handleChange = ({ target }) => {
        onChange(_.get(target, 'files.0'))
      }

      return <input {...input} type='file' onChange={handleChange} {...props} />
    }}
  </Field>
))`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

export default FileField
