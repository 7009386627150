import styled from 'styled-components'
import { Link } from 'react-router-dom'
import TableEditIcon from './TableEditIcon'

const TableEditLink = styled(({ ...props }) => (
  <Link {...props}>
    <TableEditIcon />
  </Link>
))``

export default TableEditLink
