export default {
  checkboxBg: '#fff',
  checkboxBorderColor: '#ccc',
  defaultDropdownHoverBg: '#f5f5f5',
  dropdownHeaderColor: '#afafaf',
  filterBarBorderColor: '#eaeaea',
  filterBg: '#f6f6f6',
  filterBorderColor: '#e6e6e6',
  filterBoxShadowColor: 'rgba(0, 0, 0, 0.5)',
  filterButtonColor: '#afafaf',
  filterButtonHoverColor: '#5e5e5e',
  filterCallsheetCheckmarkColor: '#000',
  filterCallsheetColor: '#434a54',
  filterCallsheetLiHoverBg: '#f7f2f1',
  filterCaretColor: '#9B9B9B',
  filterHoverBg: '#ebebeb',
  filterItemPadding: '17px 24px 13px 17px',
  filterLabelColor: '#c0c0c0',
  filterLabelHoverColor: '#737373',
}
