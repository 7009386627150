import { useDispatch } from 'react-redux'
import cn from 'classnames'

import { actions as authActions } from 'modules/auth'
import Button from 'components/Buttons'

const LogoutLink = ({ className }) => {
  const dispatch = useDispatch()

  return (
    <Button
      className={cn('dropdownItem', className)}
      onClick={() => dispatch(authActions.logout())}
    >
      Sign Out
    </Button>
  )
}

export default LogoutLink
