import { fromJS } from 'immutable'
import { createSelector } from 'reselect'
import { currentTeamSelector, statusesDataSelector } from './teamsSelector'

const passProps = (state, props) => props

export const getStatusByUuid = createSelector(
  statusesDataSelector,
  passProps,
  (statuses, { uuid }) => statuses.get(uuid)
)

export const getCurrentTeamStatusUuids = createSelector(
  state => state,
  currentTeamSelector,
  (state, currentTeam) =>
    (currentTeam &&
      currentTeam
        .get('statuses')
        .sortBy(uuid => getStatusByUuid(state, { uuid }).get('order'))) ||
    fromJS([])
)

export const getCurrentTeamStatuses = createSelector(
  state => state,
  getCurrentTeamStatusUuids,
  (state, uuids) => uuids && uuids.map(uuid => getStatusByUuid(state, { uuid }))
)

export const getCurrentActiveTeamStatusUuids = createSelector(
  getCurrentTeamStatuses,
  statuses =>
    statuses
      .filterNot(status => status.get('is_removed'))
      .map(status => status.get('uuid'))
)

export const getCurrentActiveNonSystemTeamStatuses = createSelector(
  getCurrentTeamStatuses,
  statuses =>
    statuses.filterNot(
      status => status.get('is_removed') || status.get('system')
    )
)

export const getCurrentActiveNonSystemTeamStatusUuids = createSelector(
  getCurrentTeamStatuses,
  statuses =>
    statuses
      .filterNot(status => status.get('is_removed') || status.get('system'))
      .map(status => status.get('uuid'))
)

export const getCompletedStatus = createSelector(
  getCurrentTeamStatuses,
  statuses => {
    const completedStatus = statuses.find(status => {
      return status.get('system') && status.get('initialism') === 'CMP'
    })

    if (!completedStatus) return null

    return completedStatus
  }
)

export const getCompletedStatusUuid = createSelector(
  getCompletedStatus,
  completedStatus => (completedStatus ? completedStatus.get('uuid') : null)
)

export const getCurrentActiveTeamStatusUuidsExcludingComplete = createSelector(
  getCurrentActiveTeamStatusUuids,
  getCompletedStatusUuid,
  (uuids, completedUuid) => uuids.filterNot(uuid => uuid === completedUuid)
)

export const getColorFromUuid = createSelector(
  getStatusByUuid,
  status => status && status.get('color')
)

export const getInitialismFromUuid = createSelector(
  getStatusByUuid,
  status => (status && status.get('initialism')) || ''
)

export const getTitleFromUuid = createSelector(
  getStatusByUuid,
  status => (status && status.get('title')) || ''
)

export const getOrderFromUuid = createSelector(
  getStatusByUuid,
  status => (status && status.get('order')) || ''
)
