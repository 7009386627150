import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import { keyIn } from 'utility/immutable'

import { authUserSelector } from './authSelector'

const passProps = (state, props) => props

export const teamsDataSelector = createSelector(
  state => state.get('teams'),
  teamsState => teamsState
)

export const statusesDataSelector = createSelector(
  teamsDataSelector,
  teamsData => teamsData.get('statuses')
)

export const teamsListSelector = createSelector(
  teamsDataSelector,
  teamsData => teamsData.get('teams')
)

export const teamUuidsSelector = createSelector(
  teamsDataSelector,
  teamsData => teamsData.get('teamUuids')
)

export const memberTeamsListSelector = createSelector(
  teamUuidsSelector,
  teamsListSelector,
  (uuids, teams) => uuids.map(uuid => teams.get(uuid))
)

export const memberListSelector = createSelector(
  teamsDataSelector,
  teamsData => teamsData.get('members')
)

export const currentTeamIdSelector = createSelector(
  teamsDataSelector,
  teamsData => teamsData.get('currentTeamId')
)

export const getQueryAdminTeamUuids = createSelector(
  teamsDataSelector,
  teamsData => teamsData.get('adminTeamUuids')
)

export const getTeamsAndMembers = createSelector(
  teamUuidsSelector,
  teamsListSelector,
  memberListSelector,
  (adminTeamUuids, teams, members) => {
    if (!(adminTeamUuids && teams && members)) return fromJS([])

    return adminTeamUuids
      .toList()
      .map(uuid =>
        teams
          .get(uuid)
          .update('members', memberUuids =>
            memberUuids.map(memberUuid => members.get(memberUuid))
          )
      )
  }
)

export const getQueryAdminTeamsAndMembers = createSelector(
  getQueryAdminTeamUuids,
  teamsListSelector,
  memberListSelector,
  (adminTeamUuids, teams, members) => {
    if (!(adminTeamUuids && teams && members)) return fromJS([])

    return adminTeamUuids
      .toList()
      .map(uuid =>
        teams
          .get(uuid)
          .update('members', memberUuids =>
            memberUuids.map(memberUuid => members.get(memberUuid))
          )
      )
  }
)

export const getQueryTeamsLoading = createSelector(
  teamsDataSelector,
  teamsData => teamsData.get('queryTeamsLoading')
)

export const getQueryAdminTeamsLoading = createSelector(
  teamsDataSelector,
  teamsData => teamsData.get('queryAdminTeamsLoading')
)

export const getCreateTeamLoading = createSelector(
  teamsDataSelector,
  teamsData => teamsData.get('createTeamLoading')
)

export const getDeleteTeamLoading = createSelector(
  teamsDataSelector,
  teamsData => teamsData.get('deleteTeamLoading')
)

export const getCreateTeamError = createSelector(
  teamsDataSelector,
  teamsData => teamsData.get('createTeamError')
)

export const getDeleteTeamError = createSelector(
  teamsDataSelector,
  teamsData => teamsData.get('deleteTeamError')
)

export const currentTeamSelector = createSelector(
  teamsListSelector,
  currentTeamIdSelector,
  (teamsList, currentTeamId) => teamsList.get(currentTeamId) || null
)

export const firstTeamIdSelector = createSelector(
  teamsDataSelector,
  teams => teams.getIn(['teamUuids', 0])
)

export const getTeamByUuid = createSelector(
  teamsListSelector,
  passProps,
  (teams, { teamUuid }) => {
    if (!(teams && teamUuid)) return null

    return teams.get(teamUuid)
  }
)

export const getTeamNameByUuid = createSelector(
  getTeamByUuid,
  team => team && team.get('name')
)

export const getGetTeamLoadingList = createSelector(
  teamsDataSelector,
  teamsData => (teamsData && teamsData.get('getTeamLoadingList')) || null
)

export const getTeamUpdateLoading = createSelector(
  teamsDataSelector,
  teamsData => (teamsData && teamsData.get('teamUpdateLoading')) || false
)

/**
 *
 * Members
 */

export const getCurrentTeamMemberUuids = createSelector(
  currentTeamSelector,
  team => (team && team.get('members')) || fromJS([])
)

export const getTeamWithMemberByUuid = createSelector(
  getTeamByUuid,
  memberListSelector,
  (team, members) => {
    if (!(team && members)) return null

    const teamMembers = members.filter(keyIn(team.get('members'))).toList()

    return team.set('members', teamMembers)
  }
)

export const getTeamWithMembersAndStatusesByUuid = createSelector(
  getTeamByUuid,
  memberListSelector,
  statusesDataSelector,
  (team, members, statuses) => {
    if (!(team && members)) return null

    const teamMembers = members.filter(keyIn(team.get('members'))).toList()
    const teamStatuses = statuses
      .filter(keyIn(team.get('statuses')))
      .toList()
      .sortBy(status => status.get('order'))

    return team.set('members', teamMembers).set('statuses', teamStatuses)
  }
)

export const getMemberByUuid = createSelector(
  memberListSelector,
  passProps,
  (members, { uuid }) => members.get(uuid)
)

export const fetchMemberFromListByUserUuid = createSelector(
  memberListSelector,
  passProps,
  (members, { uuid }) => members.find(member => member.get('user') === uuid)
)

export const getCurrentTeamMembers = createSelector(
  getCurrentTeamMemberUuids,
  memberListSelector,
  (uuids, members) => uuids && uuids.map(uuid => members.get(uuid))
)

export const getCurrentTeamMember = createSelector(
  getCurrentTeamMembers,
  authUserSelector,
  (teamMemberships, currentUser) => {
    if (!(teamMemberships && currentUser)) return false

    return teamMemberships.find(
      membership => membership.get('user') === currentUser.get('uuid')
    )
  }
)

export const currentUserCanLeaveTeamByUuid = createSelector(
  getTeamWithMemberByUuid,
  authUserSelector,
  (team, currentUser) => {
    const members = (team && team.get('members')) || fromJS([])
    const otherMembers = members.filter(
      member => member.get('user') !== currentUser.get('uuid')
    )

    const isOnlyMember = otherMembers.isEmpty()

    if (isOnlyMember) return false

    const isOnlyTeamAdmin =
      otherMembers.filter(member => member.get('is_team_admin')).isEmpty() &&
      !members
        .filter(
          member =>
            member.get('user') === currentUser.get('uuid') &&
            member.get('is_team_admin')
        )
        .isEmpty()

    return !isOnlyTeamAdmin
  }
)

export const getCurrentTeamMembersUserUuids = createSelector(
  getCurrentTeamMembers,
  members => members.map(member => member.get('user'))
)

export const getShortNameFromUserUuid = createSelector(
  fetchMemberFromListByUserUuid,
  member => member && member.get('short_name')
)

export const getShortNameFromUuid = createSelector(
  getMemberByUuid,
  member => member && member.get('short_name')
)

export const getDisplayNameFromUserUuid = createSelector(
  fetchMemberFromListByUserUuid,
  member => member && member.get('display_name')
)

export const adminTeamsSelector = createSelector(
  getCurrentTeamMember,
  teamMember => {
    if (!teamMember) return false

    return !!teamMember.get('is_team_admin')
  }
)

/* ** SEARCH ** */
export const getSearchTeamMemberUuids = createSelector(
  teamsDataSelector,
  organizationData => organizationData.get('searchTeamMembersList')
)

export const getSearchTeamMembersLoading = createSelector(
  teamsDataSelector,
  organizationData => organizationData.get('searchTeamMembersLoading')
)

export const getSearchTeamMembersError = createSelector(
  teamsDataSelector,
  organizationData => organizationData.get('searchTeamMembersError')
)
