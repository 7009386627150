import styled from 'styled-components'
import NavBar from 'components/NavBar/DefaultNavBar'
import RedirectToTeamPage from 'components/Page/RedirectToTeamPage'

import AddConversationButton from './AddConversationButton'
import ConversationsList from './ConversationsList'
import ConversationsListFilterBar from './FilterBar'
import AddConversationForm from './AddConversationForm'
import ConversationDeleteModal from './ConversationDeleteModal'

const ConversationsPage = props => (
  <RedirectToTeamPage navBar={NavBar} {...props}>
    <ConversationsListFilterBar />

    <ConversationsList />

    <AddConversationButton>
      <AddConversationForm />
    </AddConversationButton>

    <ConversationDeleteModal />
  </RedirectToTeamPage>
)

export default styled(ConversationsPage)`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
`
