import _ from 'lodash'
import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  retry,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { replace } from 'connected-react-router/immutable'
import { fromJS } from 'immutable'
import { FORM_ERROR } from 'final-form'

import {
  constants as authConstants,
  actions as authActions,
} from 'modules/auth'
import {
  getAuthToken,
  setAccessToken,
  setRefreshToken,
  deleteAuthToken,
  setEnterpriseServerAddress,
  deleteEnterpriseServerAddress,
} from 'utility/cookie'
import { isValidToken, isExpired } from 'utility/token'
import { keyIn } from 'utility/immutable'
import devicestorage from 'utility/devicestorage'
import {
  addDevice as addDeviceApi,
  queryInvitesReceived as queryInvitesReceivedApi,
  queryDevices as queryDevicesApi,
  deleteDevice as deleteDeviceApi,
  getJwtPublicKey as getJwtPublicKeyApi,
  load as loadApi,
  login as loginApi,
  queryMicrosoftFolders as queryMicrosoftFoldersApi,
  microsoftOAuth as microsoftOAuthApi,
  microsoftCallback as microsoftCallbackApi,
  refreshToken as refreshTokenApi,
  register as registerApi,
  registerFull as registerFullApi,
  resetLink as resetLinkApi,
  resetPass as resetPassApi,
  updateProfile as updateProfileApi,
} from 'api/auth'
import { routerFromSelector } from 'selectors/routerSelector'
import {
  authJwtPublicKeySelector,
  authUserSelector,
  deviceIdSelector,
  deviceTokenSelector,
} from 'selectors/authSelector'

function* getJwtPublicKey() {
  try {
    const { jwt_public_key: jwtPublicKey } = yield call(getJwtPublicKeyApi)

    yield put({
      type: authConstants.GET_JWT_PUBLIC_KEY_SUCCESS,
      payload: fromJS({ jwtPublicKey }),
    })
  } catch (error) {
    yield put({
      type: authConstants.GET_JWT_PUBLIC_KEY_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* removeExistingDevices() {
  try {
    const deviceId = yield select(deviceIdSelector)
    const deviceToken = yield select(deviceTokenSelector)

    if (deviceId || deviceToken) {
      const devices = yield call(queryDevicesApi)
      const matchingDevices = _.filter(
        devices,
        device =>
          device.device_id === deviceId || device.device_token === deviceToken
      )

      yield all(
        matchingDevices.map(device => call(deleteDeviceApi, device.uuid))
      )
    }
  } catch (error) {
    console.log(error)
    yield put({
      type: authConstants.DELETE_DEVICE_TOKEN_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* registerDeviceToken(action) {
  try {
    const { deviceToken, name, deviceId, deviceType } = action.payload.toJS()
    const user = yield select(authUserSelector)
    if (!user) {
      yield take(authConstants.LOAD_SUCCESS)
    }

    yield call(removeExistingDevices)

    const payload = yield call(addDeviceApi, {
      device_token: deviceToken,
      device_id: deviceId,
      device_type: deviceType,
      name,
    })

    yield put({
      type: authConstants.REGISTER_DEVICE_TOKEN_SUCCESS,
      payload: fromJS(payload),
    })
  } catch (error) {
    yield put({
      type: authConstants.REGISTER_DEVICE_TOKEN_FAIL,
      payload: fromJS({ error }),
    })
  }

  yield take(authConstants.LOGOUT_SUCCESS)

  yield put(action)
}

function* logout() {
  yield call(removeExistingDevices)

  yield all([
    call(deleteAuthToken),
    call(deleteEnterpriseServerAddress),
    devicestorage.remove('teamUuid'),
    devicestorage.remove('filterSets'),
    put({ type: 'RESET_APP' }),
  ])

  yield put({
    type: authConstants.LOGOUT_SUCCESS,
  })
}

function* getUser() {
  try {
    const user = yield call(loadApi)
    yield put({
      type: authConstants.LOAD_SUCCESS,
      payload: fromJS({ user }),
    })
  } catch (error) {
    const status = _.get(error, 'status')
    yield put({
      type: authConstants.LOAD_FAIL,
      payload: fromJS({ error }),
    })
    if (status === 401) yield call(logout)
  }
}

function* saveTokens(tokensObject) {
  const setTokenMethods = {
    access: setAccessToken,
    refresh: setRefreshToken,
  }

  yield all(
    _.map(tokensObject, (token, tokenType) =>
      call(setTokenMethods[tokenType], token)
    )
  )
}

function* validateTokens(tokensObject) {
  const jwtPublicKey = yield select(authJwtPublicKeySelector)
  return _.every(
    _.map(tokensObject, token => isValidToken(token, jwtPublicKey))
  )
}

function* redirectAfterAuthentication() {
  const redirect = { pathname: '/calls' }
  const from = yield select(routerFromSelector)

  if (
    from &&
    !['/organizations/create', '/invites/list'].includes(from.get('pathname'))
  ) {
    _.assign(redirect, from.toJS())
  }

  yield put(
    replace({
      ...redirect,
      state: {},
      key: '',
    })
  )
}

function* login(credentials) {
  let tokens = { access: null, refresh: null }

  try {
    tokens = yield call(loginApi, credentials.toJS())

    // Do we need this validity check?
    const isValid = yield call(validateTokens, tokens)
    if (!isValid) {
      const jwtPublicKey = yield select(authJwtPublicKeySelector)
      if (window && window._rollbar) {
        window._rollbar.error('Invalid token', { ...tokens, jwtPublicKey })
      }
      tokens = { access: null, refresh: null }
      const error = {
        data: {
          detail: 'Invalid token provided.',
        },
        status: 400,
        statusText: 'Unauthorized',
      }
      throw error
    }

    yield call(saveTokens, tokens)

    yield put({ type: authConstants.LOGIN_SUCCESS })
  } catch (error) {
    const { data: { non_field_errors = [] } = {}, status } = error
    if (
      status === 400 &&
      non_field_errors.includes('Password reset is required')
    ) {
      yield put(replace('/reset_password?force_reset=1'))
    }

    yield put({
      type: authConstants.LOGIN_FAIL,
      payload: fromJS({ error }),
    })
  }

  return tokens
}

function* loginFromForm({ payload: immutablePayload }) {
  const errors = {}
  const {
    enterpriseServerAddress,
    resolve,
    ...credentials
  } = immutablePayload.toJS()

  if (enterpriseServerAddress) {
    yield call(setEnterpriseServerAddress, enterpriseServerAddress)
  } else {
    yield call(deleteEnterpriseServerAddress)
  }

  try {
    // Duplicate code from getJwtPublicKey
    const { jwt_public_key: jwtPublicKey } = yield call(getJwtPublicKeyApi)

    yield put({
      type: authConstants.GET_JWT_PUBLIC_KEY_SUCCESS,
      payload: fromJS({ jwtPublicKey }),
    })
  } catch (jwtError) {
    errors[FORM_ERROR] = _.get(
      jwtError,
      'data.detail',
      _.get(jwtError, 'message', jwtError)
    )
  }

  if (!_.isEmpty(errors)) return resolve(errors)

  try {
    yield put(authActions.login(fromJS(credentials)))
  } catch (loginError) {
    // Will this ever catch anything?
    errors[FORM_ERROR] = _.get(
      loginError,
      'data.detail',
      _.get(loginError, 'data', loginError)
    )
  }
  return resolve(errors)
}

function* exchangeRefreshToken() {
  let tokens = { access: null, refresh: null }
  const storedRefreshToken = yield call(getAuthToken, 'apiRefreshToken')

  try {
    tokens = yield retry(3, 1000, refreshTokenApi)

    tokens.refresh = tokens.refresh || storedRefreshToken

    const isValid = yield call(validateTokens, tokens)
    if (!isValid) {
      tokens = { access: null, refresh: null }
      const error = {
        data: {
          detail: 'Invalid token provided.',
        },
        status: 400,
        statusText: 'Unauthorized',
      }
      throw error
    }

    yield call(saveTokens, tokens)

    yield put({ type: authConstants.REFRESH_TOKEN_SUCCESS })
  } catch (error) {
    yield put({
      type: authConstants.REFRESH_TOKEN_FAIL,
      payload: fromJS({ error }),
    })

    // if (error.message === 'Network Error') {
    //   tokens.isNetworkError = true
    // }

    if (window && window._rollbar) {
      window._rollbar.error('Error during exchange refresh token', {
        error,
        tokens,
      })
    }
  }

  return tokens
}

function* register(action) {
  const recipientEmail = action.payload.get('recipient_email')

  try {
    const { user: registeredUser } = yield call(registerApi, {
      recipient_email: recipientEmail,
    })

    yield put({
      type: authConstants.REGISTER_SUCCESS,
      payload: fromJS({ registeredUser }),
    })

    yield put(replace('/register_confirm'))
  } catch (error) {
    yield put({
      type: authConstants.REGISTER_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* registerFull(action) {
  const user = action.payload.filter(
    keyIn(['email', 'password', 'password_confirm', 'entitlement_token'])
  )

  try {
    const { user: registeredUser } = yield call(registerFullApi, user.toJS())
    yield put({
      type: authConstants.REGISTER_FULL_SUCCESS,
      payload: fromJS({ registeredUser }),
    })

    yield put(authActions.login(user.filter(keyIn(['email', 'password']))))
  } catch (error) {
    yield put({
      type: authConstants.REGISTER_FULL_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* resetLink(action) {
  const errors = {}
  const { email, resolve } = action.payload.toJS()

  try {
    yield call(resetLinkApi, { recipient_email: (email || '').toLowerCase() })
    yield put({ type: authConstants.REQUEST_RESET_LINK_SUCCESS })
  } catch (error) {
    const data = _.get(error, 'data')
    if (_.isObject(data)) {
      errors[FORM_ERROR] = _.get(data, 'detail')
      _.assign(errors, _.omit('detail'))
    }

    yield put({
      type: authConstants.REQUEST_RESET_LINK_FAIL,
      payload: fromJS({ error }),
    })
  }

  resolve(errors)
}

function* resetPassword(action) {
  const errors = {}
  const {
    email,
    password,
    password_confirm,
    entitlement_token,
    resolve,
  } = action.payload.toJS()

  try {
    yield call(resetPassApi, { password, password_confirm, entitlement_token })
    yield put({
      type: authConstants.RESET_PASSWORD_SUCCESS,
    })
    yield put(authActions.login(fromJS({ email, password })))
  } catch (error) {
    const data = _.get(error, 'data')
    if (_.isObject(data)) {
      errors[FORM_ERROR] = _.get(data, 'detail')
      _.assign(errors, _.omit('detail'))
    }

    yield put({
      type: authConstants.RESET_PASSWORD_FAIL,
      payload: fromJS({ error }),
    })
  }

  resolve(errors)
}

function* authFlowSaga() {
  // Get the public key and the two tokens
  yield call(getJwtPublicKey)
  let refreshToken = yield call(getAuthToken, 'apiRefreshToken')
  let accessToken = yield call(getAuthToken)
  let tokenFromStorage = !!refreshToken
  let tokenFromLogin = false
  let userSignedOut

  const loadFailAction = {
    type: authConstants.LOAD_FAIL,
    payload: fromJS({
      error: {
        data: {
          detail: 'No token stored.',
        },
        status: 400,
        statusText: 'Unauthorized',
      },
    }),
  }

  function* logoutAndClearTokens() {
    userSignedOut = true
    refreshToken = null
    accessToken = null
    yield call(logout)
  }

  function* timeComparison(token) {
    yield delay(5000)
    return yield isExpired(token, 5000)
  }

  // start the loop
  // this will run the entirety of the session
  while (true) {
    // no refresh token === no go
    // send a fail, wait for login
    if (!refreshToken) {
      yield put(loadFailAction)

      const { payload: credentials } = yield take(authConstants.LOGIN)
      const { refresh, access } = yield call(login, credentials)
      refreshToken = refresh
      accessToken = access
      userSignedOut = false
      tokenFromLogin = true
    }

    // still no refresh token?!?! try again
    if (!refreshToken) continue

    // we at least have a refresh token
    while (!userSignedOut) {
      const isValidRefresh = yield call(validateTokens, {
        refresh: refreshToken,
      })

      if (!isValidRefresh) {
        if (window && window._rollbar) {
          window._rollbar.error('Invalid refresh token', { refreshToken })
        }

        yield call(logoutAndClearTokens)
        continue
      }

      if (!accessToken || tokenFromStorage) {
        tokenFromStorage = false
        const { refresh, access } = yield call(exchangeRefreshToken)

        if (!_.every([refresh, access])) {
          if (window && window._rollbar) {
            window._rollbar.error('Failed to exchange refresh token', {
              refresh,
              access,
            })
          }
          yield call(logoutAndClearTokens)
          yield put(loadFailAction)
          continue
        }

        refreshToken = refresh
        accessToken = access
      }

      const user = yield select(authUserSelector)
      if (!user) {
        yield call(getUser)
      }

      if (tokenFromLogin) {
        yield call(redirectAfterAuthentication)
        tokenFromLogin = false
      }

      const { expired, signout } = yield race({
        expired: timeComparison(accessToken),
        signout: take(authConstants.LOGOUT),
      })

      if (expired) {
        const { access, refresh, isNetworkError } = yield call(
          exchangeRefreshToken
        )

        if (isNetworkError) {
          yield delay(10000)
          continue
        }

        accessToken = access
        refreshToken = refresh

        if (!_.every([accessToken, refreshToken])) {
          if (window && window._rollbar) {
            window._rollbar.error(
              'Failed to exchange refresh token after access token expiration',
              {
                accessToken,
                refreshToken,
              }
            )
          }
          yield call(logoutAndClearTokens)
        }
      }

      if (signout) {
        yield call(logoutAndClearTokens)
      }
    }
  }
}

function* queryMicrosoftFolders() {
  try {
    const { results: microsoftFolders } = yield call(queryMicrosoftFoldersApi)

    yield put({
      type: authConstants.QUERY_MICROSOFT_FOLDERS_SUCCESS,
      payload: fromJS({ microsoftFolders }),
    })
  } catch (error) {
    yield put({
      type: authConstants.QUERY_MICROSOFT_FOLDERS_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* microsoftOAuth() {
  try {
    const { signin_url: signinUrl } = yield call(microsoftOAuthApi)

    yield put({
      type: authConstants.MICROSOFT_O_AUTH_SUCCESS,
    })

    window.location.href = signinUrl
  } catch (error) {
    yield put({
      type: authConstants.MICROSOFT_O_AUTH_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* microsoftOAuthCallback({ payload }) {
  try {
    const { return_url: returnURL } = yield call(
      microsoftCallbackApi,
      payload.toJS()
    )

    yield put({
      type: authConstants.MICROSOFT_O_AUTH_CALLBACK_SUCCESS,
    })

    if (returnURL) {
      window.location.href = returnURL
    }
  } catch (error) {
    console.log({ error })
    yield put({
      type: authConstants.MICROSOFT_O_AUTH_CALLBACK_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* queryInvitesReceived({ payload = fromJS({}) }) {
  const { resolve, reject } = payload.toJS()

  try {
    const {
      entities: { invites: invitesReceived = {} },
      result: invitesReceivedUuids = [],
    } = yield call(queryInvitesReceivedApi)

    yield put({
      type: authConstants.QUERY_INVITES_RECEIVED_SUCCESS,
      payload: fromJS({ invitesReceived, invitesReceivedUuids }),
    })

    if (resolve) resolve({ errors: {} })
  } catch (error) {
    yield put({
      type: authConstants.QUERY_INVITES_RECEIVED_FAIL,
      payload: fromJS({ error }),
    })

    if (reject) {
      reject({
        errors: {
          ..._.get(error, 'data', {}),
          [FORM_ERROR]: 'There was an error updating this invite.',
        },
      })
    }
  }
}

function* updateProfile({ payload = fromJS({}) }) {
  const {
    display_name,
    password,
    new_password,
    new_password_confirm,
    resolve,
    reject,
  } = payload.toJS()

  try {
    const data = { display_name, password }
    if (new_password && new_password_confirm) {
      _.assign(data, { new_password, new_password_confirm })
    }
    const user = yield call(updateProfileApi, data)

    yield put({
      type: authConstants.UPDATE_PROFILE_SUCCESS,
      payload: fromJS({ user }),
    })

    if (resolve) resolve({})
  } catch (error) {
    yield put({
      type: authConstants.UPDATE_PROFILE_FAIL,
      payload: fromJS({ error }),
    })

    if (reject) {
      const fieldErrors = _.get(error, 'data', {})
      const errorsObject = _.isObject(fieldErrors) ? { ...fieldErrors } : {}
      if (errorsObject.non_field_errors) {
        errorsObject[FORM_ERROR] = _.join(errorsObject.non_field_errors, ' ')
        delete errorsObject.non_field_errors
      }

      reject({
        [FORM_ERROR]: 'There was an error updating your account.',
        ...errorsObject,
      })
    }
  }
}

function storageListener() {
  return eventChannel(emitter => {
    if (!window || !window.addEventListener) return () => {}

    const onStorage = ({ key, oldValue, newValue }) => {
      const tokenKey = __CONFIG__.localStorageKeys.apiAccessToken

      if (tokenKey === key) {
        if (!oldValue && newValue) {
          emitter(authActions.load())
        }

        if (oldValue && !newValue) {
          emitter(authActions.logout())
        }
      }
    }

    window.addEventListener('storage', onStorage)
    return () => {
      window.removeEvenListener('storage', onStorage)
    }
  })
}

function* watchAll() {
  yield takeLatest(authConstants.GET_JWT_PUBLIC_KEY, getJwtPublicKey)
  yield takeLatest(authConstants.LOAD, authFlowSaga)
  yield takeLatest(authConstants.LOGIN_FROM_FORM, loginFromForm)
  yield takeLatest(authConstants.QUERY_MICROSOFT_FOLDERS, queryMicrosoftFolders)
  yield takeLatest(authConstants.MICROSOFT_O_AUTH, microsoftOAuth)
  yield takeLatest(
    authConstants.MICROSOFT_O_AUTH_CALLBACK,
    microsoftOAuthCallback
  )
  yield takeLatest(authConstants.REFRESH_TOKEN, exchangeRefreshToken)
  yield takeLatest(authConstants.REGISTER, register)
  yield takeLatest(authConstants.REGISTER_DEVICE_TOKEN, registerDeviceToken)
  yield takeLatest(authConstants.REGISTER_FULL, registerFull)
  yield takeLatest(authConstants.RESET_PASSWORD, resetPassword)
  yield takeLatest(authConstants.REQUEST_RESET_LINK, resetLink)
  yield takeLatest(authConstants.QUERY_INVITES_RECEIVED, queryInvitesReceived)
  yield takeEvery(authConstants.UPDATE_PROFILE, updateProfile)
  const channel = yield call(storageListener)
  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

export const authSaga = [fork(watchAll)]
