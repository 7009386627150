import _ from 'lodash'
import ApiClient from 'components/ApiClient'
import * as schema from '../schemas/conversations'

const client = new ApiClient()
const API_ENDPOINT = __CONFIG__.apiPath.conversations

export const createConversation = data =>
  client.post(API_ENDPOINT.base, { data, schema: schema.conversation })

export const deleteConversation = uuid =>
  client.delete(_.replace(API_ENDPOINT.get, ':uuid', uuid), {})

export const getConversation = uuid =>
  client.get(_.replace(API_ENDPOINT.get, ':uuid', uuid), {
    schema: schema.conversation,
  })

export const emailConversations = (teamUuid, { data, params }) =>
  client.post(_.replace(API_ENDPOINT.email, ':teamUuid', teamUuid), {
    data,
    params,
  })

export const queryConversations = params =>
  client.get(API_ENDPOINT.base, {
    params,
    schema: [schema.conversation],
    isArray: true,
  })

export const updateConversation = (uuid, data) =>
  client.patch(_.replace(API_ENDPOINT.get, ':uuid', uuid), {
    data,
    schema: schema.conversation,
  })

export { schema }
