import styled from 'styled-components'
import Theme from 'components/Theme'

export default styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-bottom: 0px;
  padding-left: 0;
  border-bottom: 1px solid ${Theme.tabs.tabsLinkBorderColor};
`
