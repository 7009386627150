import styled from 'styled-components'
import { bool } from 'prop-types'

import NavBar from 'components/NavBar'
import Collapse from 'components/NavBar/Collapse'
import BrandLink from 'components/NavBar/BrandLink'
import _TeamNav from 'components/TeamNav/TeamNav'
import _ProfileNav from 'components/ProfileNav/ProfileNav'
import _CallsNav from 'components/CallsNav/CallsNav'
import _ContactsNav from 'components/ContactsNav'

const DefaultNavBar = styled(({ ...props }) => (
  <NavBar {...props}>
    <BrandLink marketing={false} />

    <Collapse>
      <TeamNav />
      <CallsNav />
      <ContactsNav />
      <ProfileNav />
    </Collapse>
  </NavBar>
))``

DefaultNavBar.propTypes = {
  marketing: bool,
}

DefaultNavBar.defaultProps = {
  marketing: false,
}

const TeamNav = styled(_TeamNav)`
  margin-left: 15px;
  margin-right: auto;
`

const CallsNav = styled(_CallsNav)`
  margin-left: ${props => props.theme.nav.marginHorizontal};
  flex: 0 0 auto;
`

const ContactsNav = styled(_ContactsNav)`
  margin-left: ${props => props.theme.nav.marginHorizontal};
  flex: 0 0 auto;
`

const ProfileNav = styled(_ProfileNav)`
  margin-left: ${props => props.theme.nav.marginHorizontal};
  flex: 0 0 auto;
  max-width: 200px;
`

export default DefaultNavBar
