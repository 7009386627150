import styled from 'styled-components'
import { connect } from 'react-redux'
import { object, func } from 'prop-types'
import { list } from 'react-immutable-proptypes'

import {
  getCurrentTeamMembersUserUuids,
  getShortNameFromUserUuid as _getShortNameFromUserUuid,
} from 'selectors/teamsSelector'

import MultiDropdownField from './MultiDropdownField'

const mapStateToProps = state => {
  const members = getCurrentTeamMembersUserUuids(state)
  const getShortNameFromUserUuid = uuid =>
    _getShortNameFromUserUuid(state, { uuid })
  return {
    getShortNameFromUserUuid,
    members,
  }
}

const mapDispatchToProps = {}

const CallsheetField = ({
  getShortNameFromUserUuid,
  members,
  meta,
  ...props
}) => (
  <MultiDropdownField
    options={members}
    label="Call Sheet"
    iconType="user"
    optionText={getShortNameFromUserUuid}
    {...props}
  />
)

CallsheetField.propTypes = {
  getShortNameFromUserUuid: func.isRequired,
  members: list.isRequired,
  meta: object.isRequired,
}

CallsheetField.defaultProps = {}

export default styled(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CallsheetField)
)``
