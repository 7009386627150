import { createSelector } from 'reselect'
import { fromJS } from 'immutable'

const conversationFiltersSelector = createSelector(
  state => state.getIn(['form', 'conversationFilters', 'values']) || fromJS({}),
  conversationFiltersData => conversationFiltersData,
)

export const conversationSortSelector = createSelector(
  conversationFiltersSelector,
  conversationFiltersData => conversationFiltersData.get('sort'),
)

export const getContactIdFilter = createSelector(
  conversationFiltersSelector,
  conversationFiltersData => conversationFiltersData.get('contact_uuid'),
)
