import styled from 'styled-components'
import { getFormValues } from 'redux-form/immutable'
import { Map, List } from 'immutable'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

import { getInitialismFromUuid } from 'selectors/statusesSelector'
import { getShortNameFromUserUuid } from 'selectors/teamsSelector'
import { getConversationsHasMore } from 'selectors/conversationsSelector'

const DATE_FORMAT = 'ddd MMM D'

const mapStateToProps = state => {
  const filters = getFormValues('conversationFilters')(state)
  const shortNames = filters
    .get('callsheets')
    .map(uuid => getShortNameFromUserUuid(state, { uuid }))
  const statusInitialisms = filters
    .get('statuses')
    .map(uuid => getInitialismFromUuid(state, { uuid }))
  const conversationsHasMore = getConversationsHasMore(state)
  return {
    filters,
    shortNames,
    statusInitialisms,
    conversationsHasMore,
  }
}

const mapDispatchToProps = {}

const PrintFilters = ({
  filters,
  shortNames,
  statusInitialisms,
  conversationsHasMore,
}) => {
  const getDates = () => {
    const datesFilter = filters.get('last_call_time') || List()
    return datesFilter.size
      ? datesFilter
          .map(d => (d ? moment(d).format(DATE_FORMAT) : '---'))
          .join(' to ')
      : 'All Dates'
  }

  return (
    <>
      <Filters>
        {!!filters && (
          <>
            <div>
              <Bold>Showing calls for: </Bold>
              {shortNames.join(', ') || 'All'}
            </div>

            <div>
              <Bold>Statuses: </Bold>
              {statusInitialisms.join(', ') || 'All'}
            </div>

            <div>
              <Bold>Dates: </Bold>
              {getDates()}
            </div>
          </>
        )}
      </Filters>

      {!!conversationsHasMore && (
        <LimitNotice>
          {`Showing the first ${filters.get('limit')} calls.`}
        </LimitNotice>
      )}
    </>
  )
}

PrintFilters.propTypes = {
  filters: object,
  shortNames: object,
  statusInitialisms: object,
}

PrintFilters.defaultProps = {
  filters: Map(),
  shortNames: Map(),
  statusInitialisms: Map(),
}

const Filters = (styled.div || styled.View)`
  padding-bottom: 8px;
`

const Bold = (styled.span || styled.Text)`
  font-weight: 600;
`

const LimitNotice = (styled.div || styled.View)`
  font-style: italic;
  padding-bottom: 8px;
`

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintFilters)
