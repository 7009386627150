import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { string } from 'prop-types'
import {
  getPhoneNumberFromPhoneNumberUuid,
  getPhoneTypeFromPhoneNumberUuid,
} from 'selectors/contactsSelector'

const Type = (styled.span || styled.Text)`
  color: #000;
  margin-right: 4px;
`

const Number = styled.a.attrs(({ phoneNumber, isLink }) => {
  if (isLink) {
    return {
      href: `tel:${phoneNumber}`,
      children: phoneNumber,
    }
  }

  return {
    as: 'span',
    children: phoneNumber,
  }
})`
  color: ${({ theme }) => theme.colors.blue};
`

const PhoneNumber = styled(
  ({ phoneNumberUuid, innerRef, isLink, ...props }) => {
    const phoneType = useSelector(state =>
      getPhoneTypeFromPhoneNumberUuid(state, { uuid: phoneNumberUuid })
    )
    const phoneNumber = useSelector(state =>
      getPhoneNumberFromPhoneNumberUuid(state, { uuid: phoneNumberUuid })
    )

    return (
      <div ref={innerRef} {...props}>
        <Type>{phoneType}</Type>
        <Number isLink={isLink} phoneNumber={phoneNumber} />
      </div>
    )
  }
)`
  max-width: 100%;
  font-size: 12px;
  line-height: 1.25;
  display: inline-block;
`

PhoneNumber.propTypes = {
  phoneNumberUuid: string.isRequired,
}

PhoneNumber.defaultProps = {}

export default PhoneNumber
