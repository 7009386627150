import _ from 'lodash'
import { CalendarIcon } from 'components/Icons'
import { Dropdown, Option } from 'components/Dropdown'
import { DateRangePicker } from 'react-dates'

import styled, { css } from 'styled-components'
import { object } from 'prop-types'
import { fromJS } from 'immutable'
import moment from 'moment'

import { media } from 'utility/styles'

import FilterToggle from './FilterToggle'

const DATE_OPTIONS = {
  all: {
    label: 'All dates',
    getValues: () => [null, null],
  },
  today: {
    label: 'Today',
    getValues: () => [
      moment()
        .startOf('day')
        .format(),
      moment()
        .endOf('day')
        .format(),
    ],
  },
  yesterday: {
    label: 'Yesterday',
    getValues: () => [
      moment()
        .subtract(1, 'days')
        .startOf('day')
        .format(),
      moment()
        .subtract(1, 'days')
        .endOf('day')
        .format(),
    ],
  },
  tomorrow: {
    label: 'Tomorrow',
    getValues: () => [
      moment()
        .add(1, 'days')
        .startOf('day')
        .format(),
      moment()
        .add(1, 'days')
        .endOf('day')
        .format(),
    ],
  },
  this_week: {
    label: 'This week',
    getValues: () => [
      moment()
        .startOf('week')
        .format(),
      moment()
        .endOf('week')
        .format(),
    ],
  },
  last_week: {
    label: 'Last week',
    getValues: () => [
      moment()
        .subtract(1, 'week')
        .startOf('week')
        .format(),
      moment()
        .subtract(1, 'week')
        .endOf('week')
        .format(),
    ],
  },
  next_week: {
    label: 'Next week',
    getValues: () => [
      moment()
        .add(1, 'week')
        .startOf('week')
        .format(),
      moment()
        .add(1, 'week')
        .endOf('week')
        .format(),
    ],
  },
}

const CUSTOM_DATE_KEY = 'custom'
const FORMAT = 'M/D'

const CustomRangeOption = styled(Option)`
  position: relative;
  margin-top: 10px !important;
  ${({ active }) =>
    active &&
    css`
      &:before {
        line-height: 43px;
      }
    `}
`

class DatesField extends React.Component {
  static propTypes = {
    input: object.isRequired,
    meta: object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      selected: DATE_OPTIONS.all.label,
      focusedInput: null,
    }

    this._dropdownHolder = {}
  }

  getToggleText = () => {
    const {
      input: { value: selectedDates },
    } = this.props
    const [start, end] = selectedDates.toJS()

    const format = string => (string && moment(string).format(FORMAT)) || '?'

    const toggleText =
      start || end ? `${format(start)} - ${format(end)}` : 'All'
    return toggleText
  }

  parse = selected => {
    const dates = DATE_OPTIONS[selected].getValues()
    return fromJS(dates)
  }

  render() {
    const {
      input: { value: selectedDates, onChange },
      meta,
      ...props
    } = this.props
    const [start, end] = selectedDates.toJS()
    const { selected, focusedInput } = this.state

    return (
      <div {...props}>
        <Dropdown
          ref={_dropdown => {
            this._dropdownHolder = _dropdown
          }}
          toggle={
            <FilterToggle title="Dates">
              <span>
                <CalendarIcon />
                {this.getToggleText(selectedDates)}
              </span>
            </FilterToggle>
          }
          noCaret
        >
          {_.keys(DATE_OPTIONS).map((datesValue, index) => (
            <Option
              active={selected === DATE_OPTIONS[datesValue].label}
              className="dropdownItem"
              closeDropdown={this._dropdownHolder.closeDropdown}
              key={index}
              onSelect={() => {
                onChange(this.parse(datesValue))
                this.setState({ selected: DATE_OPTIONS[datesValue].label })
                this._dropdownHolder.closeDropdown()
              }}
            >
              {DATE_OPTIONS[datesValue].label}
            </Option>
          ))}

          <CustomRangeOption active={selected === CUSTOM_DATE_KEY} div>
            <DateRangePicker
              enableOutsideDays
              isOutsideRange={() => false}
              startDate={
                selected === CUSTOM_DATE_KEY && start ? moment(start) : null
              }
              startDateId="FilterBarCustomStartDate"
              endDate={selected === CUSTOM_DATE_KEY && end ? moment(end) : null}
              endDateId="FilterBarCustomEndDate"
              onDatesChange={({ startDate, endDate }) => {
                onChange(
                  fromJS([
                    (startDate && startDate.startOf('day').format()) ||
                      (endDate && endDate.startOf('day').format()) ||
                      null,
                    (endDate && endDate.endOf('day').format()) ||
                      (startDate && startDate.endOf('day').format()) ||
                      null,
                  ])
                )
                this.setState({ selected: CUSTOM_DATE_KEY })
              }}
              focusedInput={focusedInput}
              onFocusChange={_focusedInput => {
                if (_focusedInput !== focusedInput) {
                  this.setState({ focusedInput: _focusedInput })
                }
              }}
            />
          </CustomRangeOption>
        </Dropdown>
      </div>
    )
  }
}

export default styled(DatesField)`
  height: 100%;

  .dropdown {
    width: 260px;
  }

  .DateRangePickerInput {
    display: flex;
    align-items: center;
    .DateInput_input {
      font-size: 11px;
    }

    .DateInput {
      width: auto;
    }
  }
`
