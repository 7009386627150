import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import cx from 'classnames'

import { GripIcon } from 'components/Icons'

const Handle = React.forwardRef((props, ref) => (
  <div className="rowHandle" ref={ref}>
    <GripIcon />
  </div>
))

const DraggableRow = styled(({ children, className, id, index, moveRow }) => {
  const ref = useRef(null)

  const [, drop] = useDrop({
    accept: 'row',
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex // eslint-disable-line no-param-reassign
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'row', id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  preview(drop(ref))

  return (
    <div className={cx(className, { dragging: isDragging })} ref={ref}>
      <Handle ref={drag} />
      {children}
    </div>
  )
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e3e3e3;
  padding: 15px 5px;

  &.dragging {
    opacity: 0;
    input {
      display: none;
    }
  }

  .rowHandle {
    margin-right: 30px;
    cursor: grab;
  }
`

export default DraggableRow
