import styled from 'styled-components'
import _ from 'lodash'
import React, { forwardRef, useImperativeHandle } from 'react'
import useModal from 'use-react-modal'

import Form from './Form'

const EmailConversationsModal = styled(
  forwardRef(({ className, ...props }, ref) => {
    const { openModal, isOpen, Modal, closeModal } = useModal()

    const summon = (e, _props) => {
      _.result(_props, 'preSave')
      openModal(e)
    }

    useImperativeHandle(ref, () => ({
      summon,
    }))

    if (!isOpen) return null

    return (
      <Modal {...props}>
        <Col>
          <h2>Send current call sheet via email</h2>

          <p>
            Choose team member(s) below to email a link to the current call
            sheet.
          </p>

          <Form closeModal={closeModal} />
        </Col>
      </Modal>
    )
  })
)``

const Col = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 16px;
  width: 600px;
`

export default EmailConversationsModal
