import { createAction, handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

const CLEAR_CONVERSATIONS = 'app/conversations/CLEAR_CONVERSATIONS'
const CREATE_CALL = 'app/conversations/CREATE_CALL'
const CREATE_CALL_FAIL = 'app/conversations/CREATE_CALL_FAIL'
const CREATE_CALL_SUCCESS = 'app/conversations/CREATE_CALL_SUCCESS'
const CREATE_CONVERSATION = 'app/conversations/CREATE_CONVERSATION'
const CREATE_CONVERSATION_FAIL = 'app/conversations/CREATE_CONVERSATION_FAIL'
const CREATE_CONVERSATION_SUCCESS =
  'app/conversations/CREATE_CONVERSATION_SUCCESS'
const DELETE_CONVERSATION = 'app/conversations/DELETE_CONVERSATION'
const DELETE_CONVERSATION_FAIL = 'app/conversations/DELETE_CONVERSATION_FAIL'
const DELETE_CONVERSATION_SUCCESS =
  'app/conversations/DELETE_CONVERSATION_SUCCESS'
const EMAIL_CONVERSATIONS = 'app/conversations/EMAIL_CONVERSATIONS'
const GET_CONVERSATION = 'app/conversations/GET_CONVERSATION'
const GET_CONVERSATION_FAIL = 'app/conversations/GET_CONVERSATION_FAIL'
const GET_CONVERSATION_SUCCESS = 'app/conversations/GET_CONVERSATION_SUCCESS'
const LOAD_FILTERED_CONVERSATIONS =
  'app/conversations/LOAD_FILTERED_CONVERSATIONS'
const PRE_DELETE_CONVERSATION = 'app/conversations/PRE_DELETE_CONVERSATION'
const PRE_DELETE_CONVERSATION_CLEAR =
  'app/conversations/PRE_DELETE_CONVERSATION_CLEAR'
const QUERY_CONVERSATIONS = 'app/conversations/QUERY_CONVERSATIONS'
const QUERY_CONVERSATIONS_FAIL = 'app/conversations/QUERY_CONVERSATIONS_FAIL'
const QUERY_CONVERSATIONS_SUCCESS =
  'app/conversations/QUERY_CONVERSATIONS_SUCCESS'
const QUERY_CONVERSATIONS_UUIDS_SUCCESS =
  'app/conversations/QUERY_CONVERSATIONS_UUIDS_SUCCESS'
const TOGGLE_CONVERSATION_FORM = 'app/conversations/TOGGLE_CONVERSATION_FORM'
const TOGGLE_SHARE_MODAL = 'app/conversations/TOGGLE_SHARE_MODAL'
const UPDATE_CALL = 'app/conversations/UPDATE_CALL'
const UPDATE_CALL_FAIL = 'app/conversations/UPDATE_CALL_FAIL'
const UPDATE_CALL_SUCCESS = 'app/conversations/UPDATE_CALL_SUCCESS'
const UPDATE_CONVERSATION = 'app/conversations/UPDATE_CONVERSATION'
const UPDATE_CONVERSATION_FAIL = 'app/conversations/UPDATE_CONVERSATION_FAIL'
const UPDATE_CONVERSATION_SUCCESS =
  'app/conversations/UPDATE_CONVERSATION_SUCCESS'
const UPDATE_STATUS = 'app/conversations/UPDATE_STATUS'

const SOCKET_DELETE_CONVERSATION =
  'app/conversations/SOCKET_DELETE_CONVERSATION'
const SOCKET_UPDATE_CONVERSATION =
  'app/conversations/SOCKET_UPDATE_CONVERSATION'
const SOCKET_UPDATE_CONVERSATION_SUCCESS =
  'app/conversations/SOCKET_UPDATE_CONVERSATION_SUCCESS'
const SOCKET_UPDATE_CONVERSATION_FAIL =
  'app/conversations/SOCKET_UPDATE_CONVERSATION_FAIL'

const SOCKET_CREATE_CALL = 'app/conversations/SOCKET_CREATE_CALL'
const SOCKET_CREATE_CALL_SUCCESS =
  'app/conversations/SOCKET_CREATE_CALL_SUCCESS'
const SOCKET_CREATE_CALL_FAIL = 'app/conversations/SOCKET_CREATE_CALL_FAIL'

const SOCKET_UPDATE_CALL = 'app/conversations/SOCKET_UPDATE_CALL'
const SOCKET_UPDATE_CALL_SUCCESS =
  'app/conversations/SOCKET_UPDATE_CALL_SUCCESS'
const SOCKET_UPDATE_CALL_FAIL = 'app/conversations/SOCKET_UPDATE_CALL_FAIL'

export const constants = {
  CLEAR_CONVERSATIONS,
  CREATE_CALL_FAIL,
  CREATE_CALL_SUCCESS,
  CREATE_CALL,
  CREATE_CONVERSATION_FAIL,
  CREATE_CONVERSATION_SUCCESS,
  CREATE_CONVERSATION,
  DELETE_CONVERSATION_FAIL,
  DELETE_CONVERSATION_SUCCESS,
  DELETE_CONVERSATION,
  EMAIL_CONVERSATIONS,
  GET_CONVERSATION_FAIL,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATION,
  LOAD_FILTERED_CONVERSATIONS,
  PRE_DELETE_CONVERSATION_CLEAR,
  PRE_DELETE_CONVERSATION,
  QUERY_CONVERSATIONS_UUIDS_SUCCESS,
  QUERY_CONVERSATIONS_FAIL,
  QUERY_CONVERSATIONS_SUCCESS,
  QUERY_CONVERSATIONS,
  SOCKET_CREATE_CALL_FAIL,
  SOCKET_CREATE_CALL_SUCCESS,
  SOCKET_CREATE_CALL,
  SOCKET_DELETE_CONVERSATION,
  SOCKET_UPDATE_CALL_FAIL,
  SOCKET_UPDATE_CALL_SUCCESS,
  SOCKET_UPDATE_CALL,
  SOCKET_UPDATE_CONVERSATION_FAIL,
  SOCKET_UPDATE_CONVERSATION_SUCCESS,
  SOCKET_UPDATE_CONVERSATION,
  TOGGLE_CONVERSATION_FORM,
  TOGGLE_SHARE_MODAL,
  UPDATE_CALL_FAIL,
  UPDATE_CALL_SUCCESS,
  UPDATE_CALL,
  UPDATE_CONVERSATION_FAIL,
  UPDATE_CONVERSATION_SUCCESS,
  UPDATE_CONVERSATION,
  UPDATE_STATUS,
}

// ------------------------------------
// Actions
// ------------------------------------
export const clearConversations = createAction(CLEAR_CONVERSATIONS)

export const createCall = createAction(CREATE_CALL, data => data)

export const createConversation = createAction(
  CREATE_CONVERSATION,
  data => data
)

export const deleteConversation = createAction(
  DELETE_CONVERSATION,
  uuid => uuid
)

export const emailConversations = createAction(
  EMAIL_CONVERSATIONS,
  data => data
)

export const getConversation = createAction(GET_CONVERSATION, uuid => uuid)

export const queryConversations = createAction(
  QUERY_CONVERSATIONS,
  params => params
)

export const preDeleteConversation = createAction(
  PRE_DELETE_CONVERSATION_CLEAR,
  uuid => uuid
)

export const preDeleteConversationClear = createAction(
  PRE_DELETE_CONVERSATION_CLEAR
)

export const socketDeleteConversation = createAction(SOCKET_DELETE_CONVERSATION)
export const socketUpdateConversation = createAction(
  SOCKET_UPDATE_CONVERSATION,
  data => data
)

export const socketCreateCall = createAction(SOCKET_CREATE_CALL, data => data)

export const socketUpdateCall = createAction(SOCKET_UPDATE_CALL, data => data)

export const toggleConversationForm = createAction(
  TOGGLE_CONVERSATION_FORM,
  open => open
)

export const toggleShareModal = createAction(TOGGLE_SHARE_MODAL, open => open)

export const updateCall = createAction(UPDATE_CALL, data => data)

export const updateConversation = createAction(
  UPDATE_CONVERSATION,
  data => data
)

export const updateStatus = createAction(UPDATE_STATUS, data => data)

export const actions = {
  clearConversations,
  createCall,
  createConversation,
  deleteConversation,
  emailConversations,
  getConversation,
  preDeleteConversation,
  preDeleteConversationClear,
  queryConversations,
  socketUpdateConversation,
  socketCreateCall,
  socketDeleteConversation,
  socketUpdateCall,
  toggleConversationForm,
  toggleShareModal,
  updateCall,
  updateConversation,
  updateStatus,
}

export const initialState = () =>
  fromJS({
    conversationFormOpen: false,
    conversations: {},
    calls: {},

    conversationUuids: [],
    conversationsContacts: {},
    conversationsError: null,
    conversationsLoading: false,
    conversationsCount: 0,

    createCallError: null,
    createCallLoading: false,
    createConversationError: null,
    createConversationLoading: false,
    deleteConversationError: null,
    deleteConversationLoading: false,
    updateCallError: null,
    updateCallLoadingList: [],
    updateConversationErrorObject: {},
    updateConversationLoadingList: [],
    getConversationError: null,
    getConversationLoadingList: [],
    preDeleteConversationId: null,
    shareModalOpen: false,
  })

export const reducers = {
  [CLEAR_CONVERSATIONS]: state =>
    state.merge({
      conversationUuids: initialState().get('conversationUuids'),
      conversationsCount: initialState().get('conversationsCount'),
    }),
  [CREATE_CALL]: state =>
    state.merge({
      createCallLoading: true,
      createCallError: initialState().get('createCallError'),
    }),
  [CREATE_CALL_SUCCESS]: state =>
    state.merge({
      createCallLoading: initialState().get('createCallLoading'),
      createCallError: initialState().get('createCallError'),
    }),
  [CREATE_CALL_FAIL]: (state, { payload }) =>
    state.merge({
      createCallLoading: initialState().get('createCallLoading'),
      createCallError: payload.get('error'),
    }),

  [CREATE_CONVERSATION]: state =>
    state.merge({
      createConversationLoading: true,
      createConversationError: initialState().get('createConversationError'),
    }),
  [CREATE_CONVERSATION_SUCCESS]: state =>
    state.merge({
      createConversationLoading: initialState().get(
        'createConversationLoading'
      ),
      createConversationError: initialState().get('createConversationError'),
    }),
  [CREATE_CONVERSATION_FAIL]: (state, { payload }) =>
    state.merge({
      createConversationLoading: initialState().get(
        'createConversationLoading'
      ),
      createConversationError: payload.get('error'),
    }),

  [DELETE_CONVERSATION]: state =>
    state.merge({
      deleteConversationLoading: true,
      deleteConversationError: initialState().get('deleteConversationError'),
    }),
  [DELETE_CONVERSATION_SUCCESS]: (
    state,
    { payload: deletedConversationUuid }
  ) =>
    state.merge({
      deleteConversationLoading: initialState().get(
        'deleteConversationLoading'
      ),
      deleteConversationError: initialState().get('deleteConversationError'),
      conversationUuids: state
        .get('conversationUuids')
        .filterNot(uuid => uuid === deletedConversationUuid),
    }),
  [DELETE_CONVERSATION_FAIL]: (state, { payload }) =>
    state.merge({
      deleteConversationLoading: initialState().get(
        'deleteConversationLoading'
      ),
      deleteConversationError: payload.get('error'),
    }),

  [GET_CONVERSATION]: (state, { payload }) =>
    state.merge({
      getConversationLoadingList: state
        .get('updateConversationLoadingList')
        .push(payload.get('uuid')),
      getConversationError: initialState().get('getConversationError'),
    }),
  [GET_CONVERSATION_SUCCESS]: (state, { payload }) =>
    state.merge({
      getConversationLoadingList: payload.get('getConversationLoadingList'),
      getConversationError: initialState().get('getConversationError'),
      conversations: state
        .get('conversations')
        .mergeDeep(payload.get('conversations')),
      calls: state.get('calls').merge(payload.get('calls')),
    }),
  [GET_CONVERSATION_FAIL]: (state, { payload }) =>
    state.merge({
      getConversationLoadingList: payload.get('getConversationLoadingList'),
      getConversationError: payload.get('error'),
    }),

  [LOAD_FILTERED_CONVERSATIONS]: state =>
    state.merge({
      conversationsLoading: true,
    }),

  [PRE_DELETE_CONVERSATION]: (state, { payload }) =>
    state.merge({
      preDeleteConversationId: payload,
    }),

  [PRE_DELETE_CONVERSATION_CLEAR]: state =>
    state.merge({
      preDeleteConversationId: initialState().get('preDeleteConversationId'),
    }),

  [QUERY_CONVERSATIONS]: state =>
    state.merge({
      conversationsLoading: true,
      conversationsError: initialState().get('conversationsError'),
    }),
  [QUERY_CONVERSATIONS_SUCCESS]: (state, { payload }) =>
    state.merge({
      conversations: state
        .get('conversations')
        .mergeDeep(payload.get('conversations')),
      conversationUuids: payload.get('replace')
        ? payload.get('conversationUuids')
        : state
            .get('conversationUuids')
            .concat(payload.get('conversationUuids')),
      conversationsLoading: initialState().get('conversationsLoading'),
      conversationsError: initialState().get('conversationsError'),
      conversationsCount: payload.get('conversationsCount'),
    }),
  [QUERY_CONVERSATIONS_FAIL]: (state, { payload }) =>
    state.merge({
      conversationsLoading: initialState().get('conversationsLoading'),
      conversationsError: payload.get('error'),
    }),

  [QUERY_CONVERSATIONS_UUIDS_SUCCESS]: (state, { payload }) =>
    state.merge({
      conversationUuids: payload.get('conversationUuids'),
      conversationsCount: payload.get('conversationsCount'),
    }),

  [SOCKET_CREATE_CALL_SUCCESS]: (state, { payload }) =>
    state.merge({
      calls: state.get('calls').merge(payload.get('calls')),
      conversations: state
        .get('conversations')
        .mergeDeep(payload.get('conversations')),
    }),

  [SOCKET_UPDATE_CALL_SUCCESS]: (state, { payload }) =>
    state.merge({
      calls: state.get('calls').merge(payload.get('calls')),
    }),

  [SOCKET_UPDATE_CONVERSATION_SUCCESS]: (state, { payload }) =>
    state.merge({
      conversations: state
        .get('conversations')
        .merge(payload.get('conversations')),
    }),

  [TOGGLE_CONVERSATION_FORM]: (state, { payload }) =>
    state.merge({
      conversationFormOpen: payload,
    }),
  [TOGGLE_SHARE_MODAL]: (state, { payload }) =>
    state.merge({
      shareModalOpen: payload,
    }),

  [UPDATE_CALL]: (state, { payload }) =>
    state.merge({
      updateCallLoadingList: state
        .get('updateCallLoadingList')
        .push(payload.get('uuid')),
      updateCallError: initialState().get('updateCallError'),
    }),
  [UPDATE_CALL_SUCCESS]: (state, { payload }) =>
    state.merge({
      updateCallLoadingList: payload.get('updateCallLoadingList'),
      updateCallError: initialState().get('updateCallError'),
    }),
  [UPDATE_CALL_FAIL]: (state, { payload }) =>
    state.merge({
      updateCallLoadingList: payload.get('updateCallLoadingList'),
      updateCallError: payload.get('error'),
    }),

  [UPDATE_CONVERSATION]: (state, { payload }) =>
    state.merge({
      updateConversationLoadingList: state
        .get('updateConversationLoadingList')
        .push(payload.get('uuid')),
      updateConversationError: initialState().get('updateConversationError'),
    }),
  [UPDATE_CONVERSATION_SUCCESS]: (state, { payload }) =>
    state.merge({
      updateConversationLoadingList: state
        .get('updateConversationLoadingList')
        .filterNot(uuid => uuid === payload.get('uuid')),
    }),
  [UPDATE_CONVERSATION_FAIL]: (state, { payload }) =>
    state.merge({
      updateConversationLoadingList: state
        .get('updateConversationLoadingList')
        .filterNot(uuid => uuid === payload.get('uuid')),
      updateConversationError: payload.get('error'),
    }),
}

export default handleActions(reducers, initialState())
