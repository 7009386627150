import styled from 'styled-components'

export default (styled.span || styled.View)`
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 4px;
  vertical-align: middle;
  color: #000;
  border-width: 4px;
  border-style: solid;
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-bottom-width: 0;
`
