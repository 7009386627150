import styled from 'styled-components'
import { media } from 'utility/styles'

import _Page from 'components/Page'
import HomeNavBar from 'components/HomeNavBar'
import Collaboration from './Collaboration'

const Page = styled(_Page)`
  padding-top: 60px;

  ${media.tablet`
    padding: 60px 30px 30px;
    display: flex;
    flex-direction: column;
  `}
`

export default () => {
  return (
    <Page navBar={HomeNavBar}>
      <Collaboration />
    </Page>
  )
}
