import styled from 'styled-components'
import { connect } from 'react-redux'
import { func, string } from 'prop-types'

import { PreDeleteModal } from 'components/Modal'

import { actions as conversationsActions } from 'modules/conversations'
import { conversationsDataSelector } from 'selectors/conversationsSelector'

const mapStateToProps = state => {
  const conversationsData = conversationsDataSelector(state)

  return {
    conversationPreDelete: conversationsData.get('conversationPreDelete'),
  }
}

const mapDispatchToProps = {
  preDeleteConversationClear: conversationsActions.preDeleteConversationClear,
  deleteConversation: conversationsActions.deleteConversation,
}

const _ConversationDeleteModal = ({
  conversationPreDelete,
  deleteConversation,
  preDeleteConversationClear,
  ...props
}) => {
  const handleCancel = preDeleteConversationClear
  const handleDelete = () => deleteConversation(conversationPreDelete)
    .then(preDeleteConversationClear)
  return (
    <PreDeleteModal
      isOpen={!!conversationPreDelete}
      handleCancel={handleCancel}
      handleDelete={handleDelete}
      title='Delete Conversation?'
      type='conversation'
      {...props}
    />
  )
}

_ConversationDeleteModal.propTypes = {
  conversationPreDelete: string,
  preDeleteConversationClear: func.isRequired,
  deleteConversation: func.isRequired,
}

_ConversationDeleteModal.defaultProps = {
  conversationPreDelete: null,
}

const ConversationDeleteModal = styled(_ConversationDeleteModal)``

export default connect(mapStateToProps, mapDispatchToProps)(ConversationDeleteModal)
