import React, { useCallback, useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'

import { currentTeamIdSelector } from 'selectors/teamsSelector'

import { actions as contactsActions } from 'modules/contacts'

import { SearchIcon as _SearchIcon } from 'components/Icons'
import ContactsSearchList from 'components/ContactsSearchList'

const ContactDropdown = styled(({ handleContactSelect, defaultContactUuid = null }) => {
  const dispatch = useDispatch()
  const [keyword, setKeyword] = useState('')

  const currentTeamUuid = useSelector(currentTeamIdSelector)

  const searchContacts = useCallback(
    _keyword => dispatch(contactsActions.searchContacts(_keyword)),
    [dispatch]
  )

  const inputRef = useRef()

  useEffect(() => {
    searchContacts('') // TODO: Replace with popularContacts
    _.result(inputRef, 'current.focus')
  }, [searchContacts])

  const typeAheadCallBack = _keyword => {
    searchContacts(_keyword)
  }

  const typeAheadDebounce = useRef(
    _.debounce(_keyword => typeAheadCallBack(_keyword), 500)
  ).current

  const onChange = ({ target: { value } = {} }) => {
    setKeyword(value)
    typeAheadDebounce(value)
  }

  const handleSelect = contact => {
    handleContactSelect(contact)
  }

  if (!currentTeamUuid) return null

  return (
    <>
      <SearchContainer>
        <Input ref={inputRef} onChange={onChange} value={keyword} />

        <SearchIcon />
      </SearchContainer>
      <ContactsSearchList 
        handleSelect={handleSelect} 
        defaultContactUuid={defaultContactUuid}
        showAddContactButton 
        hasKeyword={keyword.length > 0}
      />
    </>
  )
})``

const SearchContainer = (styled.div || styled.View)`
  position: relative;
  margin-bottom: 15px;
  margin: 0 20px 15px;
`

const SearchIcon = styled(_SearchIcon)`
  color: #ccd1d9;
  font-size: 18px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
`

const Input = (styled.input || styled.TextInput)`
  width: 100%;
  height: 40px;
  padding: 6px 35px 6px 12px;
  border: 1px solid #e0e0e0;
`

export default ContactDropdown
