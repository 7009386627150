import React, { useEffect, useRef } from 'react'
import Spinner from 'components/Spinner'
import useContactExports from 'hooks/useContactExports'

const ContactsExportToast = ({ closeToast, jobUuid }) => {
  const linkRef = useRef()
  const { exportJob } = useContactExports({ jobUuid })

  const url = (exportJob && exportJob.get('url')) || null

  useEffect(() => {
    if (url) linkRef.current.click()
  }, [url, linkRef.current])

  if (url) {
    return (
      <span>
        If download does not begin automatically click{' '}
        <a target="_blank" rel="noopener noreferrer" ref={linkRef} href={url}>
          here
        </a>
        .
      </span>
    )
  }

  return <Spinner />
}

export default ContactsExportToast
