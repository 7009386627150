import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalClose,
  ModalTitle,
  ModalFooter,
} from 'components/Modal'
import Button from 'components/Buttons'
import ButtonRow from 'components/Buttons/ButtonRow'

const PasswordConfirmModal = ({
  children,
  handleCancel,
  handleConfirm,
  invalid,
  submitting,
  loadingText,
  ...props
}) => {
  return (
    <Modal {...props}>
      <ModalHeader>
        <ModalTitle>Enter password</ModalTitle>
        <ModalClose onClick={handleCancel} />
      </ModalHeader>

      <ModalBody>
        <p>Please re-enter your password to make these changes.</p>

        {children}
      </ModalBody>

      <ModalFooter>
        <ButtonRow>
          <Button
            disabled={submitting}
            onClick={handleCancel}
            secondary
            type="button"
          >
            Cancel
          </Button>

          <Button
            disabled={submitting || invalid}
            loading={submitting}
            loadingText="Saving..."
            onClick={handleConfirm}
            type="submit"
            red
          >
            Save
          </Button>
        </ButtonRow>
      </ModalFooter>
    </Modal>
  )
}

export default PasswordConfirmModal
