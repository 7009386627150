import { Switch } from 'react-router-dom'

import Route from 'components/Route'
import NotFound from 'components/NotFound'
import MaintenanceMessage from 'components/MaintenanceMessage'

// import LazyLoading from 'components/LazyLoading'
import Account from './views/account'
import Activate from './views/activate'
import ChangePassword from './views/changePassword'
import Contacts from './views/contacts'
import Conversations from './views/conversations'
import Home from './views/home'
import Invite from './views/invite'
import Login from './views/login'
import Organizations from './views/organizations'
import Register from './views/register'
import RegisterConfirm from './views/registerConfirm'
import ResetPassword from './views/resetPassword'
import Settings from './views/settings'

import Collaboration from './views/collaboration'
import Sharing from './views/sharing'

const MAINTENANCE_MODE = !!__CONFIG__.maintenanceMode
const maintenanceOverride = !!localStorage.getItem('hamster-stapler')

export default (
  <Switch>
    <Route exact path="/" component={Home} />
    {MAINTENANCE_MODE && !maintenanceOverride ? (
      <Route path="*" component={MaintenanceMessage} status={503} />
    ) : null}
    <Route exact path="/login" component={Login} />
    <Route exact path="/password_reset" component={ChangePassword} />
    <Route exact path="/register_confirm" component={RegisterConfirm} />
    <Route exact path="/register/confirm" component={Activate} />
    <Route exact path="/reset_password" component={ResetPassword} />
    <Route exact path="/signup" component={Register} />

    <Route path="/account" component={Account} loginRequired />
    <Route path="/invite" component={Invite} loginRequired />
    <Route path="/organizations" component={Organizations} loginRequired />

    <Route
      exact
      path="/calls/:team_id?"
      component={Conversations}
      organizationRequired
      loginRequired
      activeAccountRequired
    />

    <Route
      path="/contacts/:team_id?"
      component={Contacts}
      loginRequired
      organizationRequired
      activeAccountRequired
    />

    <Route
      exact
      path={[
        '/settings/:org_id?',
        '/settings/:org_id/teams/:team_id?',
        '/settings/:org_id/users',
      ]}
      component={Settings}
      organizationRequired
      loginRequired
    />

    <Route
      exact
      path="/settings/:org_id/payment"
      component={Settings}
      organizationRequired
      loginRequired
    />

    <Route exact path="/sharing" component={Sharing} />
    <Route exact path="/collaboration" component={Collaboration} />

    <Route path="*" component={NotFound} status={404} />
  </Switch>
)
