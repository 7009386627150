import { createAction, handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import { normalize } from 'normalizr'

import {
  member as memberSchema,
  status as statusSchema,
  team as teamSchema,
} from 'schemas/teams'

const ADD_MEMBERSHIP = 'app/teams/ADD_MEMBERSHIP'
const ADD_MEMBERSHIP_FAIL = 'app/teams/ADD_MEMBERSHIP_FAIL'
const ADD_MEMBERSHIP_SUCCESS = 'app/teams/ADD_MEMBERSHIP_SUCCESS'
const ADD_STATUS = 'app/teams/ADD_STATUS'
const ADD_STATUS_FAIL = 'app/teams/ADD_STATUS_FAIL'
const ADD_STATUS_SUCCESS = 'app/teams/ADD_STATUS_SUCCESS'
const CLEAR_CURRENT_TEAM_ID = 'app/teams/CLEAR_CURRENT_TEAM_ID'
const CREATE_TEAM = 'app/teams/CREATE_TEAM'
const CREATE_TEAM_FAIL = 'app/teams/CREATE_TEAM_FAIL'
const CREATE_TEAM_SUCCESS = 'app/teams/CREATE_TEAM_SUCCESS'
const DELETE_MEMBERSHIP = 'app/teams/DELETE_MEMBERSHIP'
const DELETE_MEMBERSHIP_FAIL = 'app/teams/DELETE_MEMBERSHIP_FAIL'
const DELETE_MEMBERSHIP_SUCCESS = 'app/teams/DELETE_MEMBERSHIP_SUCCESS'
const DELETE_STATUS = 'app/teams/DELETE_STATUS'
const DELETE_STATUS_FAIL = 'app/teams/DELETE_STATUS_FAIL'
const DELETE_STATUS_SUCCESS = 'app/teams/DELETE_STATUS_SUCCESS'
const DELETE_TEAM = 'app/teams/DELETE_TEAM'
const DELETE_TEAM_FAIL = 'app/teams/DELETE_TEAM_FAIL'
const DELETE_TEAM_SUCCESS = 'app/teams/DELETE_TEAM_SUCCESS'
const GET_TEAM = 'app/teams/GET_TEAM'
const GET_TEAM_FAIL = 'app/teams/GET_TEAM_FAIL'
const GET_TEAM_SUCCESS = 'app/teams/GET_TEAM_SUCCESS'
const INVITE_MEMBERSHIP = 'app/teams/INVITE_MEMBERSHIP'
const QUERY_ADMIN_TEAMS = 'app/teams/QUERY_ADMIN_TEAMS'
const QUERY_ADMIN_TEAMS_FAIL = 'app/teams/QUERY_ADMIN_TEAMS_FAIL'
const QUERY_ADMIN_TEAMS_SUCCESS = 'app/teams/QUERY_ADMIN_TEAMS_SUCCESS'
const QUERY_TEAMS = 'app/teams/QUERY_TEAMS'
const QUERY_TEAMS_FAIL = 'app/teams/QUERY_TEAMS_FAIL'
const QUERY_TEAMS_SUCCESS = 'app/teams/QUERY_TEAMS_SUCCESS'
const REDIRECT_TEAM = 'app/teams/REDIRECT_TEAM'
const REDIRECT_TEAM_SUCCESS = 'app/teams/REDIRECT_TEAM_SUCCESS'
const SEARCH_TEAM_MEMBERS = 'app/teams/SEARCH_TEAM_MEMBERS'
const SEARCH_TEAM_MEMBERS_CLEAR = 'app/teams/SEARCH_TEAM_MEMBERS_CLEAR'
const SEARCH_TEAM_MEMBERS_FAIL = 'app/teams/SEARCH_TEAM_MEMBERS_FAIL'
const SEARCH_TEAM_MEMBERS_SUCCESS = 'app/teams/SEARCH_TEAM_MEMBERS_SUCCESS'
const SET_CURRENT_TEAM_ID = 'app/teams/SET_CURRENT_TEAM_ID'
const UPDATE_MEMBERSHIP = 'app/teams/UPDATE_MEMBERSHIP'
const UPDATE_MEMBERSHIP_FAIL = 'app/teams/UPDATE_MEMBERSHIP_FAIL'
const UPDATE_MEMBERSHIP_SUCCESS = 'app/teams/UPDATE_MEMBERSHIP_SUCCESS'
const UPDATE_STATUS = 'app/teams/UPDATE_STATUS'
const UPDATE_STATUS_FAIL = 'app/teams/UPDATE_STATUS_FAIL'
const UPDATE_STATUS_SUCCESS = 'app/teams/UPDATE_STATUS_SUCCESS'
const UPDATE_TEAM = 'app/teams/UPDATE_TEAM'
const UPDATE_TEAM_FAIL = 'app/teams/UPDATE_TEAM_FAIL'
const UPDATE_TEAM_SUCCESS = 'app/teams/UPDATE_TEAM_SUCCESS'

const SOCKET_TEAM_MEMBERSHIP = 'app/teams/SOCKET_TEAM_MEMBERSHIP'
const SOCKET_DELETE_TEAM_MEMBERSHIP = 'app/teams/SOCKET_DELETE_TEAM_MEMBERSHIP'
const SOCKET_REMOVE_TEAM_FROM_TEAM_UUIDS =
  'app/teams/SOCKET_REMOVE_TEAM_FROM_TEAM_UUIDS'

const SOCKET_TEAM_STATUS = 'app/teams/SOCKET_TEAM_STATUS'
const SOCKET_DELETE_TEAM_STATUS = 'app/teams/SOCKET_DELETE_TEAM_STATUS'

const SOCKET_TEAM = 'app/teams/SOCKET_TEAM'
const SOCKET_DELETE_TEAM = 'app/teams/SOCKET_DELETE_TEAM'

export const constants = {
  ADD_MEMBERSHIP_FAIL,
  ADD_MEMBERSHIP_SUCCESS,
  ADD_MEMBERSHIP,
  ADD_STATUS_FAIL,
  ADD_STATUS_SUCCESS,
  ADD_STATUS,
  CLEAR_CURRENT_TEAM_ID,
  CREATE_TEAM_FAIL,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM,
  DELETE_MEMBERSHIP_FAIL,
  DELETE_MEMBERSHIP_SUCCESS,
  DELETE_MEMBERSHIP,
  DELETE_STATUS_FAIL,
  DELETE_STATUS_SUCCESS,
  DELETE_STATUS,
  DELETE_TEAM_FAIL,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM,
  GET_TEAM_FAIL,
  GET_TEAM_SUCCESS,
  GET_TEAM,
  INVITE_MEMBERSHIP,
  QUERY_ADMIN_TEAMS_FAIL,
  QUERY_ADMIN_TEAMS_SUCCESS,
  QUERY_ADMIN_TEAMS,
  QUERY_TEAMS_FAIL,
  QUERY_TEAMS_SUCCESS,
  QUERY_TEAMS,
  REDIRECT_TEAM_SUCCESS,
  REDIRECT_TEAM,
  SEARCH_TEAM_MEMBERS_CLEAR,
  SEARCH_TEAM_MEMBERS_FAIL,
  SEARCH_TEAM_MEMBERS_SUCCESS,
  SEARCH_TEAM_MEMBERS,
  SET_CURRENT_TEAM_ID,
  UPDATE_MEMBERSHIP_FAIL,
  UPDATE_MEMBERSHIP_SUCCESS,
  UPDATE_MEMBERSHIP,
  UPDATE_STATUS_FAIL,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS,
  UPDATE_TEAM_FAIL,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM,
  SOCKET_TEAM_MEMBERSHIP,
  SOCKET_DELETE_TEAM_MEMBERSHIP,
  SOCKET_REMOVE_TEAM_FROM_TEAM_UUIDS,
  SOCKET_TEAM_STATUS,
  SOCKET_DELETE_TEAM_STATUS,
  SOCKET_TEAM,
  SOCKET_DELETE_TEAM,
}

// ------------------------------------
// Actions
// ------------------------------------
const addMembership = createAction(ADD_MEMBERSHIP)
const addStatus = createAction(ADD_STATUS)
const clearCurrentTeamId = createAction(CLEAR_CURRENT_TEAM_ID)
const createTeam = createAction(CREATE_TEAM)
const deleteMembership = createAction(DELETE_MEMBERSHIP)
const deleteStatus = createAction(DELETE_STATUS)
const deleteTeam = createAction(DELETE_TEAM)
const getTeam = createAction(GET_TEAM)
const queryAdminTeams = createAction(QUERY_ADMIN_TEAMS)
const queryTeams = createAction(QUERY_TEAMS)
const redirectTeam = createAction(REDIRECT_TEAM)
const searchTeamMembers = createAction(SEARCH_TEAM_MEMBERS)
const searchTeamMembersClear = createAction(SEARCH_TEAM_MEMBERS_CLEAR)
const setCurrentTeamId = createAction(SET_CURRENT_TEAM_ID)
const socketDeleteTeam = createAction(SOCKET_DELETE_TEAM)
const socketDeleteTeamMembership = createAction(SOCKET_DELETE_TEAM_MEMBERSHIP)
const socketRemoveTeamFromTeamUuids = createAction(
  SOCKET_REMOVE_TEAM_FROM_TEAM_UUIDS
)
const socketDeleteTeamStatus = createAction(SOCKET_DELETE_TEAM_STATUS)
const socketTeam = createAction(SOCKET_TEAM)
const socketTeamMembership = createAction(SOCKET_TEAM_MEMBERSHIP)
const socketTeamStatus = createAction(SOCKET_TEAM_STATUS)
const updateMembership = createAction(UPDATE_MEMBERSHIP)
const updateStatus = createAction(UPDATE_STATUS)
const updateTeam = createAction(UPDATE_TEAM)

export const actions = {
  addMembership,
  addStatus,
  clearCurrentTeamId,
  createTeam,
  deleteMembership,
  deleteStatus,
  deleteTeam,
  getTeam,
  queryAdminTeams,
  queryTeams,
  redirectTeam,
  searchTeamMembers,
  searchTeamMembersClear,
  setCurrentTeamId,
  updateMembership,
  updateStatus,
  updateTeam,
  socketTeamMembership,
  socketDeleteTeamMembership,
  socketRemoveTeamFromTeamUuids,
  socketTeamStatus,
  socketDeleteTeamStatus,
  socketTeam,
  socketDeleteTeam,
}

export const initialState = () =>
  fromJS({
    addMembershipError: null,
    addMembershipLoading: false,
    addStatusError: null,
    addStatusLoading: false,
    adminTeamUuids: [],
    createTeamLoading: null,
    createTeamError: null,
    currentTeamId: null,
    deleteMembershipError: null,
    deleteMembershipLoading: false,
    deleteStatusError: null,
    deleteStatusLoading: false,
    deleteTeamError: null,
    deleteTeamLoading: false,
    getTeamLoadingError: null,
    getTeamLoadingList: [],
    members: {},
    queryAdminTeamsError: null,
    queryAdminTeamsLoading: false,
    queryTeamsError: null,
    queryTeamsLoading: false,
    searchTeamMembersError: null,
    searchTeamMembersList: [],
    searchTeamMembersLoading: false,
    statuses: {},
    teamDeleteError: undefined,
    teamDeleteLoading: false,
    teamEditError: undefined,
    teamError: null,
    teamListError: null,
    teamListLoading: false,
    teamLoading: false,
    teamPreDelete: undefined,
    teams: {},
    teamUpdateError: null,
    teamUpdateLoading: false,
    teamUuids: [],
    updateMembershipError: null,
    updateMembershipLoading: false,
    updateStatusError: null,
    updateStatusLoading: false,
  })

export const reducers = {
  [CLEAR_CURRENT_TEAM_ID]: state =>
    state.set('currentTeamId', initialState().get('currentTeamId')),
  [CREATE_TEAM]: state =>
    state.merge({
      createTeamLoading: true,
      createTeamError: initialState().get('createTeamError'),
    }),
  [CREATE_TEAM_FAIL]: (state, { payload }) =>
    state.merge({
      createTeamLoading: initialState().get('createTeamLoading'),
      createTeamError: payload.get('error'),
    }),
  [CREATE_TEAM_SUCCESS]: (state, { payload }) =>
    state
      .update('teams', teams => teams.merge(payload.get('teams')))
      .update('members', members => members.merge(payload.get('members')))
      .update('statuses', statuses => statuses.merge(payload.get('statuses')))
      .set('createTeamError', initialState().get('createTeamError'))
      .set('createTeamLoading', initialState().get('createTeamLoading')),

  [QUERY_TEAMS]: state =>
    state.merge({
      queryTeamsLoading: true,
      queryTeamsError: initialState().get('queryTeamsError'),
    }),
  [QUERY_TEAMS_FAIL]: (state, { payload }) =>
    state.merge({
      queryTeamsLoading: initialState().get('queryTeamsLoading'),
      queryTeamsError: payload.get('error'),
    }),
  [QUERY_TEAMS_SUCCESS]: (state, { payload }) => {
    return state.merge({
      teamUuids: payload.get('teamUuids'),
      teams: state.get('teams').merge(payload.get('teams')),
      members: state.get('members').merge(payload.get('members')),
      statuses: state.get('statuses').merge(payload.get('statuses')),
      queryTeamsError: initialState().get('queryTeamsError'),
      queryTeamsLoading: initialState().get('queryTeamsLoading'),
    })
  },
  [QUERY_ADMIN_TEAMS]: state =>
    state.merge({
      adminTeamUuids: initialState().get('adminTeamUuids'),
      queryAdminTeamsLoading: true,
      queryAdminTeamsError: initialState().get('queryAdminTeamsError'),
    }),
  [QUERY_ADMIN_TEAMS_FAIL]: (state, { payload }) =>
    state.merge({
      queryAdminTeamsLoading: initialState().get('queryAdminTeamsLoading'),
      queryAdminTeamsError: payload.get('error'),
    }),
  [QUERY_ADMIN_TEAMS_SUCCESS]: (state, { payload }) => {
    return state.merge({
      adminTeamUuids: payload.get('adminTeamUuids'),
      teams: state.get('teams').merge(payload.get('teams')),
      members: state.get('members').merge(payload.get('members')),
      statuses: state.get('statuses').merge(payload.get('statuses')),
      queryAdminTeamsError: initialState().get('queryAdminTeamsError'),
      queryAdminTeamsLoading: initialState().get('queryAdminTeamsLoading'),
    })
  },
  [GET_TEAM]: (state, { payload }) => {
    return state.merge({
      getTeamLoadingList: state.get('getTeamLoadingList').push(payload),
      getTeamLoadingError: initialState().get('getTeamLoadingError'),
    })
  },
  [GET_TEAM_SUCCESS]: (state, { payload }) => {
    return state.merge({
      members: state.get('members').merge(payload.get('members')),
      teams: state.get('teams').merge(payload.get('teams')),
      statuses: state.get('statuses').merge(payload.get('statuses')),
      getTeamLoadingList: payload.get('getTeamLoadingList'),
      getTeamLoadingError: initialState().get('getTeamLoadingError'),
    })
  },
  [GET_TEAM_FAIL]: (state, { payload }) => {
    return state.merge({
      getTeamLoadingList: payload.get('getTeamLoadingList'),
      getTeamLoadingError: payload.get('error'),
    })
  },

  [DELETE_TEAM]: state => {
    return state.merge({
      deleteTeamLoading: true,
      deleteTeamError: initialState().get('deleteTeamError'),
    })
  },
  [DELETE_TEAM_SUCCESS]: (state, { payload }) => {
    return state.merge({
      teamUuids: state
        .get('teamUuids')
        .filterNot(uuid => uuid === payload.get('uuid')),
      adminTeamUuids: state
        .get('adminTeamUuids')
        .filterNot(uuid => uuid === payload.get('uuid')),
      deleteTeamLoading: initialState().get('deleteTeamLoading'),
      deleteTeamError: initialState().get('deleteTeamError'),
    })
  },
  [DELETE_TEAM_FAIL]: (state, { payload }) => {
    return state.merge({
      deleteTeamLoading: initialState().get('deleteTeamLoading'),
      deleteTeamError: payload.get('error'),
    })
  },

  [SET_CURRENT_TEAM_ID]: (state, { payload }) => {
    return state.merge({
      currentTeamId: payload,
    })
  },

  [UPDATE_TEAM]: state =>
    state.merge({
      teamUpdateError: initialState().get('teamUpdateError'),
      teamUpdateLoading: true,
    }),
  [UPDATE_TEAM_SUCCESS]: (state, { payload }) =>
    state.merge({
      teamUpdateLoading: initialState().get('teamUpdateLoading'),
      teams: state.get('teams').merge(payload.get('teams')),
    }),
  [UPDATE_TEAM_FAIL]: (state, { payload }) =>
    state.merge({
      teamUpdateError: payload.get('error'),
      teamUpdateLoading: initialState().get('teamUpdateLoading'),
    }),

  [ADD_MEMBERSHIP]: state =>
    state.merge({
      addMembershipError: initialState().get('addMembershipError'),
      addMembershipLoading: true,
    }),
  [ADD_MEMBERSHIP_SUCCESS]: (state, { payload }) =>
    state.merge({
      addMembershipLoading: initialState().get('addMembershipLoading'),
      members: state.get('members').merge(payload.get('members')),
    }),
  [ADD_MEMBERSHIP_FAIL]: (state, { payload }) =>
    state.merge({
      addMembershipError: payload.get('error'),
      addMembershipLoading: initialState().get('addMembershipLoading'),
    }),

  [UPDATE_MEMBERSHIP]: state =>
    state.merge({
      updateMembershipError: initialState().get('updateMembershipError'),
      updateMembershipLoading: true,
    }),
  [UPDATE_MEMBERSHIP_SUCCESS]: (state, { payload }) =>
    state.merge({
      updateMembershipLoading: initialState().get('updateMembershipLoading'),
      members: state.get('members').merge(payload.get('members')),
    }),
  [UPDATE_MEMBERSHIP_FAIL]: (state, { payload }) =>
    state.merge({
      updateMembershipError: payload.get('error'),
      updateMembershipLoading: initialState().get('updateMembershipLoading'),
    }),

  [DELETE_MEMBERSHIP]: state =>
    state.merge({
      deleteMembershipError: initialState().get('deleteMembershipError'),
      deleteMembershipLoading: true,
    }),
  [DELETE_MEMBERSHIP_SUCCESS]: state =>
    state.merge({
      deleteMembershipLoading: initialState().get('deleteMembershipLoading'),
    }),
  [DELETE_MEMBERSHIP_FAIL]: (state, { payload }) =>
    state.merge({
      deleteMembershipError: payload.get('error'),
      deleteMembershipLoading: initialState().get('deleteMembershipLoading'),
    }),

  [ADD_STATUS]: state =>
    state.merge({
      addStatusError: initialState().get('addStatusError'),
      addStatusLoading: true,
    }),
  [ADD_STATUS_SUCCESS]: (state, { payload }) =>
    state.merge({
      addStatusLoading: initialState().get('addStatusLoading'),
      statuses: state.get('statuses').merge(payload.get('statuses')),
    }),
  [ADD_STATUS_FAIL]: (state, { payload }) =>
    state.merge({
      addStatusError: payload.get('error'),
      addStatusLoading: initialState().get('addStatusLoading'),
    }),

  [UPDATE_STATUS]: state =>
    state.merge({
      updateStatusError: initialState().get('updateStatusError'),
      updateStatusLoading: true,
    }),
  [UPDATE_STATUS_SUCCESS]: (state, { payload }) =>
    state.merge({
      updateStatusLoading: initialState().get('updateStatusLoading'),
      statuses: state.get('statuses').merge(payload.get('statuses')),
    }),
  [UPDATE_STATUS_FAIL]: state =>
    state.merge({
      updateStatusError: initialState().get('updateStatusError'),
      updateStatusLoading: initialState().get('updateStatusLoading'),
    }),

  [DELETE_STATUS]: state =>
    state.merge({
      deleteStatusError: initialState().get('deleteStatusError'),
      deleteStatusLoading: true,
    }),
  [DELETE_STATUS_SUCCESS]: (state, { payload }) =>
    state.merge({
      deleteStatusLoading: initialState().get('deleteStatusLoading'),
      statuses: state.get('statuses').merge(payload.get('statuses')),
    }),
  [DELETE_STATUS_FAIL]: state =>
    state.merge({
      deleteStatusError: initialState().get('deleteStatusError'),
      deleteStatusLoading: initialState().get('deleteStatusLoading'),
    }),

  [SEARCH_TEAM_MEMBERS_CLEAR]: state =>
    state.merge({
      searchTeamMembersList: initialState().get('searchTeamMembersList'),
      searchTeamMembersError: initialState().get('searchTeamMembersError'),
      searchTeamMembersLoading: false,
    }),
  [SEARCH_TEAM_MEMBERS]: state =>
    state.merge({
      searchTeamMembersList: initialState().get('searchTeamMembersList'),
      searchTeamMembersError: initialState().get('searchTeamMembersError'),
      searchTeamMembersLoading: true,
    }),
  [SEARCH_TEAM_MEMBERS_SUCCESS]: (state, { payload }) =>
    state.merge({
      searchTeamMembersError: initialState().get('searchTeamMembersError'),
      searchTeamMembersLoading: initialState().get('searchTeamMembersLoading'),
      searchTeamMembersList:
        payload.get('searchTeamMembersList') ||
        initialState().get('searchTeamMembersList'),
      members: state.get('members').merge(payload.get('members')),
    }),
  [SEARCH_TEAM_MEMBERS_FAIL]: (state, { payload }) =>
    state.merge({
      searchTeamMembersError:
        payload.get('searchTeamMembersError') ||
        initialState().get('searchTeamMembersError'),
      searchTeamMembersLoading: initialState().get('searchTeamMembersLoading'),
    }),

  [SOCKET_TEAM_MEMBERSHIP]: (state, { payload }) => {
    const {
      entities: { members },
      result: uuid,
    } = normalize(payload.toJS(), memberSchema)
    const teamUuid = payload.get('team')

    return state
      .update('members', _members => _members.merge(members))
      .updateIn(['teams', teamUuid, 'members'], uuids => {
        if (!uuids) return

        if (uuids.includes(uuid)) {
          return uuids
        }

        return uuids.push(uuid)
      })
  },

  [SOCKET_DELETE_TEAM_MEMBERSHIP]: (state, { payload }) => {
    const uuid = payload.get('uuid')

    const newState = state.update('searchTeamMembersList', uuids =>
      uuids.filterNot(_uuid => _uuid === uuid)
    )

    const teamUuid = state
      .get('teams')
      .find(_team => _team.get('members').includes(uuid), undefined, fromJS({}))
      .get('uuid')

    if (teamUuid) {
      return newState.updateIn(['teams', teamUuid, 'members'], members =>
        members.filterNot(_uuid => _uuid === uuid)
      )
    }

    return newState
  },

  [SOCKET_REMOVE_TEAM_FROM_TEAM_UUIDS]: (state, { payload }) =>
    state.update('teamUuids', teamUuids =>
      teamUuids.filterNot(uuid => uuid === payload)
    ),

  [SOCKET_TEAM_STATUS]: (state, { payload }) => {
    const {
      entities: { statuses },
      result: uuid,
    } = normalize(payload.toJS(), statusSchema)
    const teamUuid = payload.get('team')

    return state
      .update('statuses', _statuses => _statuses.merge(statuses))
      .updateIn(['teams', teamUuid, 'statuses'], uuids => {
        if (!uuids) return

        if (uuids.includes(uuid)) {
          return uuids
        }

        return uuids.push(uuid)
      })
  },

  [SOCKET_DELETE_TEAM_STATUS]: (state, { payload }) => {
    const uuid = payload.get('uuid')

    const newState = state.update('searchTeamMembersList', uuids =>
      uuids.filterNot(_uuid => _uuid === uuid)
    )

    const team = state
      .get('teams')
      .find(_team => _team.get('members').includes(uuid))

    if (team) {
      return newState.updateIn(
        ['teams', team.get('uuid'), 'members'],
        members => members.filterNot(_uuid => _uuid === uuid)
      )
    }

    return newState
  },

  [SOCKET_TEAM]: (state, { payload }) => {
    const {
      entities: { teams, members, statuses },
      result: uuid,
    } = normalize(payload.toJS(), teamSchema)

    return state
      .update('teams', _teams => _teams.merge(teams))
      .update('members', _members => _members.merge(members))
      .update('statuses', _statuses => _statuses.merge(statuses))
      .update('teamUuids', uuids => {
        if (uuids.includes(uuid)) {
          return uuids
        }

        return uuids.push(uuid)
      })
  },

  [SOCKET_DELETE_TEAM]: (state, { payload }) => {
    const uuid = payload.get('uuid')

    return state
      .update('teamUuids', uuids => uuids.filterNot(_uuid => _uuid === uuid))
      .update('adminTeamUuids', uuids =>
        uuids.filterNot(_uuid => _uuid === uuid)
      )
      .update('currentTeamId', _uuid => {
        if (_uuid === uuid) {
          return null
        }

        return _uuid
      })
  },
}

export default handleActions(reducers, initialState())
