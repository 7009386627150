import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import queryString from 'query-string'

export const routerDataSelector = createSelector(
  state => state.get('router'),
  routerData => routerData
)

const routerQueriesSelector = createSelector(
  routerDataSelector,
  routerData =>
    fromJS(queryString.parse(routerData.getIn(['location', 'search'])))
)

export const routerPrintSelector = createSelector(
  routerQueriesSelector,
  routerQueriesData => routerQueriesData.has('print')
)

export const routerFiltersSelector = createSelector(
  routerQueriesSelector,
  routerQueriesData => routerQueriesData.get('filters')
)

export const routerFromSelector = createSelector(
  routerDataSelector,
  routerData => routerData.getIn(['location', 'state', 'from'])
)

export const routerPathnameSelector = createSelector(
  routerDataSelector,
  routerData => routerData.getIn(['location', 'pathname'])
)
