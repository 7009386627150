import styled from 'styled-components'

const CardMessage = styled.p`
  margin-bottom: 40px;

  font-weight: 400;
  font-size: 14px;
  line-height: 1.714285714;
  text-align: center;
`

export default CardMessage
