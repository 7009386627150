import AuthPage from 'components/Page/AuthPage'
import AuthCard from 'components/Cards/AuthCard'
import { ResetPasswordForm } from 'components/AuthForms'

const FORM_LINKS = [
  {
    path: '/login',
    label: 'Sign in',
  },
]

const ResetPassword = () => (
  <AuthPage>
    <AuthCard>
      <ResetPasswordForm links={FORM_LINKS} />
    </AuthCard>
  </AuthPage>
)

export default ResetPassword
