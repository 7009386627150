import styled from 'styled-components'

export default (styled.button || styled.Button)`
  appearance: none;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none!important;
`
