import React, { useState } from 'react'
import styled from 'styled-components'

import DeactivateModal from 'components/Modal/Deactivate'
import Button from 'components/Buttons'

import useOrganizations from 'hooks/useOrganizations'

const Deactivate = () => {
  const [openModal, setOpenModal] = useState(false)

  const {
    currentOrganizationStripeCancelAtPeriodEnd,
    currentOrganizationHasActiveSubscription,
  } = useOrganizations()

  const canDeactivate =
    currentOrganizationHasActiveSubscription &&
    !currentOrganizationStripeCancelAtPeriodEnd

  return (
    <>
      {!!canDeactivate && (
        <RedButton
          onClick={() => {
            setOpenModal(true)
          }}
        />
      )}

      <DeactivateModal
        isOpen={openModal}
        handleCancel={() => {
          setOpenModal(false)
        }}
      />
    </>
  )
}

const RedButton = styled(Button).attrs({
  red: true,
  children: 'Deactivate Organization',
})`
  margin-bottom: 54px;
`

export default Deactivate
