import styled from 'styled-components'
import Nav from '../Nav'
import OrganizationDropdown from './OrganizationDropdown'

const OrganizationNav = styled(({ ...props }) => (
  <Nav {...props}>
    <li>
      <OrganizationDropdown />
    </li>
  </Nav>
))``

export default OrganizationNav
