import devicestorage from 'utility/devicestorage'

export const getAuthToken = async (key = 'apiAccessToken') =>
  devicestorage.get(__CONFIG__.localStorageKeys[key])

export const setAccessToken = async token =>
  devicestorage.set(__CONFIG__.localStorageKeys.apiAccessToken, token)

export const setRefreshToken = async token =>
  devicestorage.set(__CONFIG__.localStorageKeys.apiRefreshToken, token)

export const deleteAuthToken = async () => {
  await Promise.all([
    devicestorage.remove(__CONFIG__.localStorageKeys.apiAccessToken),
    devicestorage.remove(__CONFIG__.localStorageKeys.apiRefreshToken),
  ])
}

export const setEnterpriseServerAddress = async enterpriseServerAddress =>
  devicestorage.set(
    __CONFIG__.localStorageKeys.enterpriseServerAddress,
    enterpriseServerAddress
  )

export const getEnterpriseServerAddress = async () =>
  devicestorage.get(__CONFIG__.localStorageKeys.enterpriseServerAddress)

export const deleteEnterpriseServerAddress = async () =>
  devicestorage.remove(__CONFIG__.localStorageKeys.enterpriseServerAddress)

export const getApiHostAddress = async () => {
  const enterpriseServerAddress = await getEnterpriseServerAddress()

  if (enterpriseServerAddress) {
    return __CONFIG__.apiHostTemplate.replace(
      '$$ENTERPRISE_SERVER_ADDRESS$$',
      enterpriseServerAddress
    )
  }

  return __CONFIG__.apiHost
}

export const getWsHostAddress = async () => {
  const enterpriseServerAddress = await getEnterpriseServerAddress()

  if (enterpriseServerAddress) {
    return __CONFIG__.socket.hostTemplate.replace(
      '$$ENTERPRISE_SERVER_ADDRESS$$',
      enterpriseServerAddress
    )
  }

  return __CONFIG__.socket.host
}
