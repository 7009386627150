import styled from 'styled-components'

export default styled.li`
  flex: 0 1 auto;
  min-width: 160px;
  position: relative;
  display: block;
  float: left;
  margin-bottom: -1px;
`
