import Button from 'components/Buttons'
import useContactExports from 'hooks/useContactExports'

const ExportContactsButton = props => {
  const { createExportJob } = useContactExports()

  return (
    <Button onClick={() => createExportJob()} {...props}>
      Export Contacts
    </Button>
  )
}

export default ExportContactsButton
