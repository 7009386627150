import React, { useEffect } from 'react'
import { fromJS } from 'immutable'

import useOrganizations from 'hooks/useOrganizations'
import useOrganizationInvoices from 'hooks/useOrganizationInvoices'

import Subhed from 'components/Subhed'
import Spinner from 'components/Spinner'

import Row from './Row'

const History = () => {
  const { currentOrganizationUuid } = useOrganizations()

  const {
    currentOrganizationInvoiceUuids,
    queryOrganizationInvoices,
  } = useOrganizationInvoices()

  useEffect(() => {
    if (currentOrganizationUuid) {
      queryOrganizationInvoices(fromJS({ uuid: currentOrganizationUuid }))
    }
  }, [currentOrganizationUuid])

  if (!currentOrganizationUuid) return <Spinner />

  return (
    <>
      <Subhed>Payment History</Subhed>

      <Container>
        <THRow>
          <THCol>Date</THCol>
          <THCol>Amount</THCol>
          <THCol />
        </THRow>
        {currentOrganizationInvoiceUuids.map(uuid => (
          <Row key={uuid} uuid={uuid} />
        ))}
      </Container>
    </>
  )
}

const Container = styled.div``
const THRow = styled.div`
  color: #b2b2b2;
  display: flex;
  border-bottom: 1px solid #e3e3e3;
  text-transform: uppercase;
  padding: 16px 18px;
`
const THCol = styled.div`
  flex: 1 1 100%;
`

export default History
