import styled from 'styled-components'
import { object, bool } from 'prop-types'
import AddIcon from './AddIcon'
import AddIconHolder from './AddIconHolder'
import ButtonElement from './ButtonElement'

const AddButton = styled(({
  open, children, onAddClick, ...props
}) => (
  <AddIconHolder {...props}>
    {open ? (
      children
    ) : (
      <ButtonElement onClick={(onAddClick)}>
        <AddIcon />
      </ButtonElement>
    )}
  </AddIconHolder>
))``

AddButton.propTypes = {
  children: object,
  open: bool,
}

AddButton.defaultProps = {
  children: null,
  open: false,
}

export default AddButton
