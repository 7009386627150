import useAuth from 'hooks/useAuth'
import DefaultNavBar from 'components/NavBar/DefaultNavBar'
import AuthNavBar from 'components/NavBar/AuthNavBar'

const NotFoundNavBar = () => {
  const { user } = useAuth()

  if (user) {
    return <DefaultNavBar />
  }

  return <AuthNavBar />
}

export default NotFoundNavBar
