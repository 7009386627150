import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form as ReactFinalForm, Field } from 'react-final-form'
import { fromJS } from 'immutable'
import styled, { css } from 'styled-components'

import { actions as conversationsActions } from 'modules/conversations'

import { addConversationFormInitialValuesSelector } from 'selectors/formSelector'
import { getFirstPhoneNumbersUuidByContactUuid } from 'selectors/contactsSelector'

import { getDateAndTime } from 'utility/helpers'

import { Form as _Form, FormError } from 'components/Form'
import Button from 'components/Buttons'
import CloseButton from 'components/CloseButton'

import validate from './AddConversationFormValidation'
import AddConversationStatus from './AddConversationStatus'
import AddConversationOwner from './AddConversationOwner'
import AddConversationContact from './AddConversationContact'
import AddConversationPhone from './AddConversationPhone'
import AddConversationDatePicker from './AddConversationDatePicker'
import AddConversationTimePicker from './AddConversationTimePicker'
import theme from './theme'

const TIME_FIELD_INTERVAL = 1000 * 60 // 1 minute

const AddConversationField = styled(({ minWidth, ...props }) => (
  <Field {...props} />
))`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: ${props => props.minWidth || 'auto'};
  border: none;
  border-bottom: ${theme.fieldBorder};
  padding: 0 ${theme.fieldHorizontalPadding};
  font-size: ${theme.fieldFontSize};
  outline: none;
  &,
  a {
    color: ${theme.fieldTextColor};
  }
  a {
    text-decoration: none;
  }
  .toggle {
    cursor: pointer;
  }
  .dropdown {
    top: 0;
    margin-top: 0;
  }
`

const growCss = css`
  width: 100%;
  ${AddConversationField} {
    width: 100%;
  }
`

const Form = styled(_Form)``

const Row = (styled.div || styled.View)`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  ${props => props.grow && growCss}
  margin-top: 22px;
  min-height: 26px;
  &:first-of-type {
    margin-top: 0;
  }
`

const ButtonRow = styled(Row)`
  width: 100%;
  justify-content: flex-end;
  margin-top: 34px;
`

const AddConversationButton = styled(Button)`
  width: 120px;
  + ${Button} {
    margin-left: 12px;
  }
`

const TextDivider = (styled.span || styled.Text)`
  font-size: ${theme.fieldFontSize};
  font-weight: 600;
  letter-spacing: 0.26px;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 1px;
  &:first-child {
    margin-left: 0;
  }
`

const InnerForm = ({
  _initialValues,
  className,
  error,
  errors,
  form,
  handleSubmit,
  pristine,
  submitError,
  submitting,
  values,
}) => {
  const dispatch = useDispatch()
  const toggleConversationForm = isOpen =>
    dispatch(conversationsActions.toggleConversationForm(isOpen))
  const firstPhoneNumberUuid = useSelector(state =>
    getFirstPhoneNumbersUuidByContactUuid(state, { uuid: values.contact })
  )

  const resetDateTime = () => {
    const dateAndTime = getDateAndTime()
    form.batch(() => {
      form.change('call.time', dateAndTime.time)
      form.resetFieldState('call.time')
      form.change('call.date', dateAndTime.date)
      form.resetFieldState('call.date')
    })
  }

  useEffect(() => {
    const timeFieldTimer = setInterval(() => {
      const { modified: dModified, active: dActive } = form.getFieldState(
        'call.date'
      )
      const { modified: tModified, active: tActive } = form.getFieldState(
        'call.time'
      )

      if (!dModified && !dActive && !tModified && !tActive) resetDateTime()
    }, TIME_FIELD_INTERVAL)
    return () => {
      clearTimeout(timeFieldTimer)
    }
  }, [])

  const handleClose = () => {
    toggleConversationForm(false)
    return false
  }

  useEffect(() => {
    form.change('selected_phone_number', firstPhoneNumberUuid || null)
  }, [values.contact])

  useEffect(() => {
    form.initialize(_initialValues)
  }, [])

  return (
    <Form
      className={className}
      onSubmit={event => {
        return handleSubmit(event).then(_errors => {
          if (_.isEmpty(_errors)) {
            resetDateTime()
          }
        })
      }}
    >
      <CloseButton type="button" handleClick={handleClose} />

      <Row>
        <AddConversationField
          name="call.status"
          component={AddConversationStatus}
        />

        <TextDivider>for</TextDivider>

        <AddConversationField
          minWidth="120px"
          name="owner"
          component={AddConversationOwner}
        />
      </Row>

      <Row grow>
        <TextDivider>from</TextDivider>

        <AddConversationField
          name="contact"
          component={AddConversationContact}
        />

        <AddConversationField
          name="selected_phone_number"
          component={AddConversationPhone}
          contactUuid={values.contact}
        />
      </Row>

      <Row>
        <AddConversationField
          name="call.date"
          component={AddConversationDatePicker}
          type="text"
        />

        <AddConversationTimePicker name="call.time" />
      </Row>

      <Row grow>
        <AddConversationField
          name="subject"
          component="input"
          type="text"
          placeholder="Regarding..."
        />
      </Row>

      <Row grow>
        <AddConversationField
          name="message"
          component="input"
          type="text"
          placeholder="Notes..."
        />
      </Row>

      {!!submitError && <FormError>{submitError}</FormError>}

      {!!error && !pristine && <FormError>{error}</FormError>}

      <ButtonRow>
        <AddConversationButton secondary onClick={handleClose} type="button">
          Cancel
        </AddConversationButton>

        <AddConversationButton
          primary
          disabled={submitting || !_.isEmpty(errors) || pristine}
          type="submit"
        >
          Save
        </AddConversationButton>
      </ButtonRow>
    </Form>
  )
}

const AddConversationForm = styled(({ className }) => {
  const initialValues = useSelector(
    addConversationFormInitialValuesSelector
  ).toJS()

  const dispatch = useDispatch()

  const onSubmit = (
    { call, ...conversation },
    { initialize, resetFieldState, batch, ...rest }
  ) => {
    return new Promise((resolve, reject) => {
      dispatch(
        conversationsActions.createConversation(
          fromJS({
            conversation,
            call,
            resolve,
            reject,
          })
        )
      )
    }).then(({ errors = {} }) => {
      if (!_.isEmpty(errors)) {
        return _.mapValues(errors, error => {
          if (!_.isArray(error)) return error

          return _.first(error)
        })
      }

      const { call: initialCall } = initialValues

      batch(() => {
        initialize({
          ...initialValues,
          owner: conversation.owner,
          call: {
            ...initialCall,
            ...getDateAndTime(),
          },
        })

        resetFieldState('call.date')
        resetFieldState('call.time')
      })

      return errors
    })
  }

  return (
    <ReactFinalForm
      onSubmit={onSubmit}
      validate={validate}
      _initialValues={initialValues}
    >
      {_props => <InnerForm className={className} {..._props} />}
    </ReactFinalForm>
  )
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  padding: 50px 24px;
  position: relative;
  width: 500px;
  min-height: 390px;
`

export default AddConversationForm
