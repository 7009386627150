import { useSelector, useDispatch } from 'react-redux'
import { actions as organizationsActions } from 'modules/organizations'
import {
  organizationInviteByUuidSelector,
  organizationInviteUuidsSelector,
  queryOrganizationInvitesErrorSelector,
  queryOrganizationInvitesLoadingSelector,
  organizationUuidsFilteredForSettingsSelector,
} from 'selectors/organizationsSelector'

export default function useOrganizationInvites({ uuid } = {}) {
  const dispatch = useDispatch()

  const inviteUuids = useSelector(organizationInviteUuidsSelector)
  const organizationInviteByUuid = useSelector(state =>
    organizationInviteByUuidSelector(state, { uuid })
  )
  const organizationUuidsFilteredForSettings = useSelector(
    organizationUuidsFilteredForSettingsSelector
  )
  const queryOrganizationInvitesError = useSelector(
    queryOrganizationInvitesErrorSelector
  )
  const queryOrganizationInvitesLoading = useSelector(
    queryOrganizationInvitesLoadingSelector
  )

  const acceptOrganizationInvite = values => {
    dispatch(organizationsActions.acceptOrganizationInvite(values))
  }

  const createOrganizationInvite = values => {
    dispatch(organizationsActions.createOrganizationInvite(values))
  }

  const getOrganizationInvite = values => {
    dispatch(organizationsActions.getOrganizationInvite(values))
  }

  const declineOrganizationInvite = values => {
    dispatch(organizationsActions.declineOrganizationInvite(values))
  }

  const queryOrganizationInvites = values => {
    if (!queryOrganizationInvitesLoading) {
      dispatch(organizationsActions.queryOrganizationInvites(values))
    }
  }

  const revokeOrganizationInvite = values => {
    dispatch(organizationsActions.revokeOrganizationInvite(values))
  }

  return {
    acceptOrganizationInvite,
    createOrganizationInvite,
    declineOrganizationInvite,
    getOrganizationInvite,
    inviteUuids,
    organizationInviteByUuid,
    organizationUuidsFilteredForSettings,
    queryOrganizationInvites,
    queryOrganizationInvitesError,
    queryOrganizationInvitesLoading,
    revokeOrganizationInvite,
  }
}
