import { useSelector } from 'react-redux'

import { onlyShow } from 'utility/styles'

import { getOrganizationOrTeamMemberByUuid } from 'selectors/organizationsSelector'

const Member = ({ className, uuid }) => {
  const member = useSelector(state =>
    getOrganizationOrTeamMemberByUuid(state, { uuid })
  )

  if (!member) return null

  const memberName = member.get('display_name')
  const memberEmail = member.get('email')

  return (
    <div className={className}>
      {memberName ? <MemberName>{memberName}</MemberName> : null}

      <MemberEmail>{memberEmail}</MemberEmail>
    </div>
  )
}

const MemberName = styled.span`
  display: block;
  font-weight: bold;
  ${onlyShow(1).line}
`

const MemberEmail = styled.span`
  display: block;
  ${onlyShow(1).line}
`

export default Member
