import { RegisterForm } from 'components/AuthForms'
import AuthPage from 'components/Page/AuthPage'
import AuthCard from 'components/Cards/AuthCard'

const FORM_TITLE = 'Sign Up'

const FORM_LINKS = [
  {
    path: '/login',
    label: 'Sign in',
  },
]

const Register = ({
  className,
  registrationToken,
  title,
  staticContext,
  ...props
}) => (
  <AuthPage {...props}>
    <AuthCard>
      <RegisterForm title={FORM_TITLE} links={FORM_LINKS} />
    </AuthCard>
  </AuthPage>
)

export default Register
