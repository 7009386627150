import React, { useState } from 'react'
import { fromJS } from 'immutable'

import useOrganizations from 'hooks/useOrganizations'

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalClose,
  ModalTitle,
  ModalFooter,
} from 'components/Modal'

import Button from 'components/Buttons'
import ButtonRow from 'components/Buttons/ButtonRow'

const DeactivateModal = ({ handleCancel, isOrganization, uuid, ...props }) => {
  const { cancelCurrentOrganizationSubscription } = useOrganizations()
  const [loading, setLoading] = useState(false)

  const handleConfirm = () => {
    setLoading(true)
    return new Promise((resolve, reject) => {
      cancelCurrentOrganizationSubscription(fromJS({ resolve, reject }))
    })
      .then(() => {
        handleCancel()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal {...props}>
      <ModalHeader>
        <ModalTitle>Deactivate Organization?</ModalTitle>
        <ModalClose onClick={handleCancel} />
      </ModalHeader>

      <ModalBody>
        <p>
          Deactivating this organization will remove any saved payment methods.
          All organization members will lose access to calls and contacts after
          the end of the current billing period.
        </p>

        <p>
          You can enter a new credit card to reactivate this organization at any
          time. Do you want to continue?
        </p>
      </ModalBody>

      <ModalFooter>
        <ButtonRow>
          <Button
            disabled={loading}
            onClick={handleCancel}
            secondary
            type="button"
          >
            No, keep my subscription
          </Button>

          <Button
            disabled={loading}
            loading={loading}
            loadingText="Deactivating..."
            onClick={handleConfirm}
            type="button"
            red
          >
            Yes, cancel subscription
          </Button>
        </ButtonRow>
      </ModalFooter>
    </Modal>
  )
}

export default DeactivateModal
