import _ from 'lodash'
import { takeEvery, race, take, put, fork, select } from 'redux-saga/effects'
import { getLocation } from 'connected-react-router/immutable'

import {
  actions as authActions,
  constants as authConstants,
} from 'modules/auth'

import { actions as organizationsActions } from 'modules/organizations'

const ignoreActionTypes = ['REFRESH_TOKEN', 'LOAD', 'LOGIN']

function monitorableAction(action) {
  return (
    !ignoreActionTypes.includes(action) &&
    _.startsWith(action.type, 'app/') &&
    !_.endsWith(action.type, '_SUCCESS') &&
    !_.endsWith(action.type, '_FAIL')
  )
}

function getSuccessType(action) {
  return `${action.type}_SUCCESS`
}

function getFailType(action) {
  return `${action.type}_FAIL`
}

function* monitor(monitoredAction) {
  const { fail } = yield race({
    success: take(getSuccessType(monitoredAction)),
    fail: take(getFailType(monitoredAction)),
  })

  if (fail) {
    const { error: { status = null } = {} } = fail.payload.toJS() || {}
    if (status === 401) {
      yield put(authActions.refreshTokenRefresh())
      const { success } = yield race({
        success: take(authConstants.REFRESH_TOKEN_SUCCESS),
        fail: take(authConstants.REFRESH_TOKEN_FAIL),
      })
      if (success) {
        yield put(monitoredAction)
      } else {
        yield put(authActions.logout())
      }
    }

    if (status === 402) {
      const { pathname } = yield select(getLocation)
      const pathRoot = pathname.split('/')[1]
      if (['calls', 'contacts'].includes(pathRoot)) {
        yield put(organizationsActions.redirectInactiveOrganization())
      }
    }
  }
}

function* watchMonitored() {
  yield takeEvery(monitorableAction, monitor)
}

export const requestMonitor = [fork(watchMonitored)]
