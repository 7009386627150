import {
  Switch,
} from 'react-router-dom'
import Route from 'components/Route'

import Sync from './Sync'
import Callback from './Callback'

const Microsoft = () => (
  <Switch>
    <Route path='/account/oauth/microsoft/callback' component={Callback} loginRequired />
    <Route path='/account/oauth/microsoft' component={Sync} loginRequired />
  </Switch>
)

export default Microsoft
