import io from 'socket.io-client'
import { toast } from 'react-toastify'
import { fromJS } from 'immutable'
import { call, cancel, delay, fork, put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'

import { actions as socketActions } from 'modules/socket'
import { constants as authConstants } from 'modules/auth'
import { actions as contactsActions } from 'modules/contacts'
import { actions as conversationsActions } from 'modules/conversations'
import { actions as organizationsActions } from 'modules/organizations'
import { actions as teamsActions } from 'modules/teams'
import { register as registerApi } from 'api/socket'

import { getAuthToken, getWsHostAddress } from 'utility/cookie'

let socket = null

const registerWebsocket = async () => {
  console.log(`connected with SID: ${socket.id}`)
  const jwt = await getAuthToken()
  socket.emit('authenticate', { token: jwt })
}

export function* socketConnect() {
  yield delay(100)

  if (socket.disconnected) {
    socket.open()
  } else {
    yield call(registerWebsocket)
  }
}

function initWebsocket() {
  let registered = false
  const socketUuids = []
  return eventChannel(emitter => {
    socket.on('connect', registerWebsocket)
    socket.on('reconnect', registerWebsocket)
    socket.on('disconnect', reason => {
      registered = false
      emitter(socketActions.socketDisconnected())
      if (reason === 'io server disconnect') {
        socket.connect()
      }
    })

    socket.on('authenticated', async () => {
      console.log('authenticated with server')
      emitter(socketActions.socketConnected())
      if (!registered) {
        await registerApi(socket.id)
      }
      registered = true
    })

    socket.on('unauthorized', msg => {
      console.log('authorization with server failed', msg)
      registered = false
    })

    socket.on('model.created', data => {
      try {
        const parsedMessage = JSON.parse(data)
        const { obj, model, event_uuid: eventUuid } = parsedMessage
        const [
          { socketContact, socketPhone, socketEmail },
          { socketCreateCall },
          {
            socketOrganization,
            socketOrganizationInvitation,
            socketOrganizationMembership,
          },
          { socketTeamMembership, socketTeam, socketTeamStatus },
        ] = [
          contactsActions,
          conversationsActions,
          organizationsActions,
          teamsActions,
        ]
        const event = {
          'accounts.entitlementtoken': socketOrganizationInvitation,
          'calls.call': socketCreateCall,
          'contacts.contact': socketContact,
          'contacts.emailaddress': socketEmail,
          'contacts.phonenumber': socketPhone,
          'organizations.organization': socketOrganization,
          'organizations.organizationmembership': socketOrganizationMembership,
          'organizations.status': socketTeamStatus,
          'organizations.team': socketTeam,
          'organizations.teammembership': socketTeamMembership,
        }[model]
        if (event && !socketUuids.includes(eventUuid)) {
          socketUuids.push(eventUuid)
          emitter(event(fromJS(obj)))
        }
        console.log('created', model, obj)
      } catch (e) {
        console.log(e)
      }
    })
    socket.on('model.updated', data => {
      try {
        const parsedMessage = JSON.parse(data)
        const { obj, model, event_uuid: eventUuid } = parsedMessage
        const [
          { socketContact, socketPhone, socketEmail },
          { socketUpdateConversation, socketUpdateCall },
          {
            socketOrganizationInvitation,
            socketOrganization,
            socketOrganizationMembership,
          },
          { socketTeamMembership, socketTeam, socketTeamStatus },
        ] = [
          contactsActions,
          conversationsActions,
          organizationsActions,
          teamsActions,
        ]
        const event = {
          'accounts.entitlementtoken': socketOrganizationInvitation,
          'calls.call': socketUpdateCall,
          'calls.conversation': socketUpdateConversation,
          'contacts.contact': socketContact,
          'contacts.emailaddress': socketEmail,
          'contacts.phonenumber': socketPhone,
          'organizations.organization': socketOrganization,
          'organizations.organizationmembership': socketOrganizationMembership,
          'organizations.status': socketTeamStatus,
          'organizations.team': socketTeam,
          'organizations.teammembership': socketTeamMembership,
        }[model]
        if (event && !socketUuids.includes(eventUuid)) {
          socketUuids.push(eventUuid)
          emitter(event(fromJS(obj)))
        }
        console.log('updated', model, obj)
      } catch (e) {
        console.log(e)
      }
    })
    socket.on('model.deleted', data => {
      try {
        const parsedMessage = JSON.parse(data)
        const { obj, model, event_uuid: eventUuid } = parsedMessage
        const [
          { socketDeleteConversation },
          { socketDeleteContact, socketDeletePhone, socketDeleteEmail },
          { socketDeleteOrganization, socketDeleteOrganizationMembership },
          { socketDeleteTeam, socketDeleteTeamMembership },
        ] = [
          conversationsActions,
          contactsActions,
          organizationsActions,
          teamsActions,
        ]
        const event = {
          'calls.conversation': socketDeleteConversation,
          'contacts.contact': socketDeleteContact,
          'contacts.emailaddress': socketDeleteEmail,
          'contacts.phonenumber': socketDeletePhone,
          'organizations.organization': socketDeleteOrganization,
          'organizations.organizationmembership': socketDeleteOrganizationMembership,
          'organizations.team': socketDeleteTeam,
          'organizations.teammembership': socketDeleteTeamMembership,
        }[model]
        if (event && !socketUuids.includes(eventUuid)) {
          socketUuids.push(eventUuid)
          emitter(event(fromJS(obj)))
        }
        console.log('deleted', model, obj)
      } catch (e) {
        console.log(e)
      }
    })

    socket.on('joined_room', (...rest) => {
      // console.log('joined_room', rest)
    })
    socket.on('left_room', (...rest) => {
      // console.log('left_room', rest)
    })

    socket.on('contacts_import_job.started', message => {
      try {
        const parsedMessage = JSON.parse(message)
        const {
          obj: { team },
        } = parsedMessage
        emitter(contactsActions.contactsLock(team))
      } catch (e) {
        console.error(`Error parsing : ${message}`)
      }
    })

    socket.on('contacts_import_job.completed', message => {
      try {
        const parsedMessage = JSON.parse(message)
        const {
          obj: { team },
        } = parsedMessage
        emitter(contactsActions.contactsUnlock(team))
        toast('Contact Import Completed')
      } catch (e) {
        console.error(`Error parsing : ${message}`)
      }
    })

    socket.on('contacts_export_job.completed', message => {
      try {
        const parsedMessage = JSON.parse(message)
        const {
          obj: { uuid },
        } = parsedMessage
        emitter(contactsActions.exportJobCompleted(uuid))
      } catch (e) {
        console.error(`Error parsing : ${message}`)
      }
    })

    socket.on('connect_error', (...rest) => {
      console.log('connect_error', rest)
    })
    socket.on('connect_timeout', (...rest) => {
      console.log('connect_timeout', rest)
    })
    socket.on('error', (...rest) => {
      console.log('error', rest)
    })
    socket.on('reconnect_attempt', (...rest) => {
      console.log('reconnect_attempt', rest)
    })
    socket.on('reconnecting', (...rest) => {
      console.log('reconnecting', rest)
    })
    socket.on('reconnect_error', (...rest) => {
      console.log('reconnect_error', rest)
    })
    socket.on('reconnect_failed', (...rest) => {
      console.log('reconnect_failed', rest)
    })
    // socket.on('ping', (...rest) => {
    //   console.log('ping', rest)
    // })
    // socket.on('pong', (...rest) => {
    //   console.log('pong', rest)
    // })

    // unsubscribe function
    return () => {
      console.log('Socket off')
    }
  })
}

function onLogout() {
  if (!socket.disconnected) {
    socket.disconnect()
  }
}

function* connectListenersToSaga() {
  const channel = yield call(initWebsocket)

  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

const createSocket = async () => {
  const host = await getWsHostAddress()
  socket = io(host, {
    autoConnect: false,
    reconnectionAttempts: 1,
    forceNew: true,
    transports: [ "websocket" ],
  })
}

function* onRefresh() {
  while (true) {
    yield take(authConstants.REFRESH_TOKEN_SUCCESS)
    yield call(socketConnect)
  }
}

function* webSocketsLoop() {
  while (true) {
    yield take(authConstants.LOAD_SUCCESS)

    yield call(createSocket)

    const listenersLoop = yield fork(connectListenersToSaga)

    yield call(socketConnect)

    const refreshLoop = yield fork(onRefresh)

    yield take(authConstants.LOGOUT)

    yield call(onLogout)
    yield cancel(listenersLoop)
    yield cancel(refreshLoop)
  }
}

export const socketSaga = [fork(webSocketsLoop)]
