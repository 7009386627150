import _ from 'lodash'
import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { generatePath } from 'react-router'
import { fromJS } from 'immutable'
import { FORM_ERROR } from 'final-form'

import {
  actions as organizationsActions,
  constants as organizationsConstants,
} from 'modules/organizations'
import { actions as teamsActions } from 'modules/teams'
import { replace, getLocation } from 'connected-react-router/immutable'
import {
  createOrganization as createOrganizationApi,
  createOrganizationPaymentMethod as createOrganizationPaymentMethodApi,
  createInvite as createInviteApi,
  deleteMembership as deleteMembershipApi,
  deleteOrganizationPaymentMethod as deleteOrganizationPaymentMethodApi,
  getOrganization as getOrganizationApi,
  getInvite as getInviteApi,
  queryInvites as queryInvitesApi,
  queryInvoices as queryInvoicesApi,
  queryMembers as queryMembersApi,
  queryOrganizationMembers as queryOrganizationMembersApi,
  queryOrganizations as queryOrganizationsApi,
  updateInvite as updateInviteApi,
  updateMembership as updateMembershipApi,
  updateOrganization as updateOrganizationApi,
} from 'api/organizations'
import {
  currentOrganizationIdSelector,
  currentOrganizationSelector,
  currentOrganizationStripeSubscriptionStatusSelector,
  firstOrganizationIdSelector,
  getCurrentOrganizationMember,
  getMemberByUuid,
} from 'selectors/organizationsSelector'
import { authUserUuidSelector } from 'selectors/authSelector'
import { currentTeamSelector } from 'selectors/teamsSelector'

function* activateCurrentOrganizationSubscription({ payload }) {
  const { resolve, reject } = payload.toJS()

  try {
    let currentlyExpired = false
    const currentOrganizationUuid = yield select(currentOrganizationIdSelector)
    const currentOrganizationStripeSubscriptionStatus = yield select(
      currentOrganizationStripeSubscriptionStatusSelector
    )

    const request = {
      subscription_cancel_at_period_end: false,
    }

    if (
      ['canceled', 'incomplete_expired'].includes(
        currentOrganizationStripeSubscriptionStatus
      )
    ) {
      currentlyExpired = true
      request.stripe_subscription_status = 'active'
      request.subscription_cancel_at_period_end = undefined
    }

    yield call(updateOrganizationApi, currentOrganizationUuid, request)

    if (currentlyExpired) {
      yield call(updateOrganizationApi, currentOrganizationUuid, {
        subscription_cancel_at_period_end: false,
      })
    }

    yield put({
      type:
        organizationsConstants.ACTIVATE_CURRENT_ORGANIZATION_SUBSCRIPTION_SUCCESS,
    })

    if (resolve) resolve({})
  } catch (error) {
    console.log({ error })
    yield put({
      type:
        organizationsConstants.ACTIVATE_CURRENT_ORGANIZATION_SUBSCRIPTION_FAIL,
      payload: fromJS({ error }),
    })

    if (reject) {
      reject({
        ..._.get(error, 'data', {}),
        [FORM_ERROR]: 'There was an error activating this subscription.',
      })
    }
  }
}

function* cancelCurrentOrganizationSubscription({ payload }) {
  const { resolve, reject } = payload.toJS()

  try {
    const currentOrganizationUuid = yield select(currentOrganizationIdSelector)

    yield call(deleteOrganizationPaymentMethodApi, currentOrganizationUuid)

    yield call(updateOrganizationApi, currentOrganizationUuid, {
      subscription_cancel_at_period_end: true,
    })

    yield put({
      type:
        organizationsConstants.CANCEL_CURRENT_ORGANIZATION_SUBSCRIPTION_SUCCESS,
    })

    if (resolve) resolve({})
  } catch (error) {
    console.log({ error })
    yield put({
      type:
        organizationsConstants.CANCEL_CURRENT_ORGANIZATION_SUBSCRIPTION_FAIL,
      payload: fromJS({ error }),
    })

    if (reject) {
      reject({
        ..._.get(error, 'data', {}),
        [FORM_ERROR]: 'There was an error cancelling this subscription.',
      })
    }
  }
}

function* createOrganization({ payload }) {
  const { organization, team, resolve, reject } = payload.toJS()
  try {
    const {
      entities: { organizations, members },
      result: organizationUuid,
    } = yield call(createOrganizationApi, organization)

    yield put({
      type: organizationsConstants.CREATE_ORGANIZATION_SUCCESS,
      payload: fromJS({
        organizations,
        members,
        organizationUuids: [organizationUuid],
      }),
    })

    if (team) {
      yield put(
        teamsActions.createTeam(
          fromJS({
            team: {
              ...team,
              organization: organizationUuid,
            },
            resolve,
            reject,
          })
        )
      )
    } else {
      if (resolve) resolve({ errors: {} })
    }
  } catch (error) {
    yield put({
      type: organizationsConstants.CREATE_ORGANIZATION_FAIL,
      payload: fromJS({
        error: _.isError(error) ? { message: error.message } : error,
      }),
    })

    if (reject) {
      reject({
        errors: {
          ..._.get(error, 'data', {}),
          [FORM_ERROR]: 'There was an error submitting this organization.',
        },
      })
    }
  }
}

function* getOrganization({ payload }) {
  const { uuid, resolve, reject } = payload.toJS()

  try {
    const {
      entities: { organizations, members },
    } = yield call(getOrganizationApi, uuid)

    organizations[uuid]._hasDetails = true

    yield put({
      type: organizationsConstants.GET_ORGANIZATION_SUCCESS,
      payload: fromJS({
        organizations,
        members,
      }),
    })

    if (resolve) resolve({})
  } catch (error) {
    yield put({
      type: organizationsConstants.GET_ORGANIZATION_FAIL,
      payload: fromJS({ error }),
    })

    if (reject) {
      reject({
        errors: {
          ..._.get(error, 'data', {}),
          [FORM_ERROR]: 'There was an error getting the organization details.',
        },
      })
    }
  }
}

function* queryOrganizations({ payload = fromJS({}) }) {
  const { resolve, reject } = payload.toJS()

  try {
    const { entities, result: organizationUuids } = yield call(
      queryOrganizationsApi
    )

    yield put({
      type: organizationsConstants.QUERY_ORGANIZATIONS_SUCCESS,
      payload: fromJS({ ...entities, organizationUuids }),
    })

    if (resolve) resolve({ errors: {} })
  } catch (error) {
    yield put({
      type: organizationsConstants.QUERY_ORGANIZATIONS_FAIL,
      payload: fromJS({ error }),
    })

    if (reject) {
      reject({
        errors: {
          ..._.get(error, 'data', {}),
          [FORM_ERROR]: 'There was an error updating this invite.',
        },
      })
    }
  }
}

function* queryOrganizationMembers() {
  try {
    const currentOrganizationUuid = yield select(currentOrganizationIdSelector)

    const {
      entities: { members = {} },
      result: queryOrganizationMembersUuids,
    } = yield call(queryOrganizationMembersApi, currentOrganizationUuid)

    yield put({
      type: organizationsConstants.QUERY_ORGANIZATION_MEMBERSHIPS_SUCCESS,
      payload: fromJS({ members, queryOrganizationMembersUuids }),
    })
  } catch (error) {
    yield put({
      type: organizationsConstants.QUERY_ORGANIZATION_MEMBERSHIPS_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* queryOrganizationInvites() {
  try {
    const currentOrganizationUuid = yield select(currentOrganizationIdSelector)

    const {
      entities: { invites = {} },
      result: inviteUuids,
    } = yield call(queryInvitesApi, currentOrganizationUuid)

    yield put({
      type: organizationsConstants.QUERY_ORGANIZATION_INVITES_SUCCESS,
      payload: fromJS({ invites, inviteUuids }),
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: organizationsConstants.QUERY_ORGANIZATION_INVITES_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* queryOrganizationInvoices() {
  try {
    const currentOrganizationUuid = yield select(currentOrganizationIdSelector)

    const {
      entities: { invoices = {} },
      result: invoiceUuids,
    } = yield call(queryInvoicesApi, currentOrganizationUuid)

    yield put({
      type: organizationsConstants.QUERY_ORGANIZATION_INVOICES_SUCCESS,
      payload: fromJS({ invoices, invoiceUuids }),
    })
  } catch (error) {
    console.log(error)
    yield put({
      type: organizationsConstants.QUERY_ORGANIZATION_INVOICES_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* redirectOrganization({ payload }) {
  const {
    match: { path },
    resolve,
    reject,
  } = payload.toJS()

  try {
    yield put(organizationsActions.queryOrganizations())

    yield take([
      organizationsConstants.QUERY_ORGANIZATIONS_SUCCESS,
      organizationsConstants.QUERY_ORGANIZATIONS_FAIL,
    ])

    const orgId = yield select(firstOrganizationIdSelector)

    if (orgId) {
      const newPath = generatePath(path, { org_id: orgId })
      if (newPath) yield put(replace(newPath))
    }

    yield put({ type: organizationsConstants.REDIRECT_ORGANIZATION_SUCCESS })

    if (resolve) resolve()
  } catch (error) {
    if (reject) reject(error)
  }
}

function* redirectInactiveOrganization({ payload }) {
  try {
    const currentOrganizationUuid = yield select(currentOrganizationIdSelector)

    if (currentOrganizationUuid) {
      yield put(replace(`/organizations/${currentOrganizationUuid}/inactive`))
      yield delay(1000)
    }
  } catch (error) {
    console.log(error)
  }
}

function* searchOrganizationMembers({ payload: keyword }) {
  try {
    const currentOrganizationId = yield select(currentOrganizationIdSelector)

    const { entities, result: searchOrganizationMembersList } = yield call(
      queryMembersApi,
      currentOrganizationId,
      keyword
    )

    yield put({
      type: organizationsConstants.SEARCH_ORGANIZATION_MEMBERS_SUCCESS,
      payload: fromJS({ ...entities, searchOrganizationMembersList }),
    })
  } catch (error) {
    yield put({
      type: organizationsConstants.SEARCH_ORGANIZATION_MEMBERS_FAIL,
      payload: fromJS({ error }),
    })
  }
}

function* socketOrganizationMembership({ payload }) {
  const { user } = payload.toJS()
  const userUuid = yield select(authUserUuidSelector)
  if (user === userUuid) {
    yield put(organizationsActions.queryOrganizations())
  }
}

function* deleteMembership({ payload }) {
  const member = payload.toJS()
  const {
    organization: organizationUuid,
    uuid: memberUuid,
    resolve,
    reject,
  } = member
  try {
    yield call(deleteMembershipApi, organizationUuid, memberUuid)

    yield put({
      type: organizationsConstants.DELETE_MEMBERSHIP_SUCCESS,
    })

    if (resolve) resolve()
  } catch (error) {
    yield put({
      type: organizationsConstants.DELETE_MEMBERSHIP_FAIL,
      payload: fromJS({ error }),
    })

    if (reject) reject()
  }
}

function* updateOrganizationInvite({ payload }) {
  const { uuid, organization, resolve, reject, ...rest } = payload.toJS()

  try {
    yield call(updateInviteApi, organization, uuid, rest)

    yield put({
      type: organizationsConstants.UPDATE_ORGANIZATION_INVITE_SUCCESS,
    })

    if (resolve) resolve({ errors: {} })
  } catch (error) {
    yield put({
      type: organizationsConstants.UPDATE_ORGANIZATION_INVITE_FAIL,
      payload: fromJS({ error }),
    })

    if (reject) {
      reject({
        errors: {
          ..._.get(error, 'data', {}),
          [FORM_ERROR]: 'There was an error updating this invite.',
        },
      })
    }
  }
}

function* createOrganizationInvite({ payload }) {
  const { organization, resolve, reject, ...rest } = payload.toJS()

  try {
    yield call(createInviteApi, organization, rest)

    yield put({
      type: organizationsConstants.CREATE_ORGANIZATION_INVITE_SUCCESS,
    })

    if (resolve) resolve({ errors: {} })
  } catch (error) {
    yield put({
      type: organizationsConstants.CREATE_ORGANIZATION_INVITE_FAIL,
      payload: fromJS({ error }),
    })

    if (reject) {
      reject({
        errors: {
          ..._.get(error, 'data', {}),
          [FORM_ERROR]: 'There was an error submitting this invite.',
        },
      })
    }
  }
}

function* getOrganizationInvite({ payload }) {
  const { uuid, organization, resolve, reject } = payload.toJS()

  try {
    const { entities, result: _uuid } = yield call(
      getInviteApi,
      organization,
      uuid
    )

    yield put({
      type: organizationsConstants.GET_ORGANIZATION_INVITE_SUCCESS,
      payload: fromJS({ ...entities, uuid: _uuid }),
    })

    if (resolve) resolve({ errors: {} })
  } catch (error) {
    yield put({
      type: organizationsConstants.GET_ORGANIZATION_INVITE_FAIL,
      payload: fromJS({ error }),
    })

    if (reject) {
      reject({
        errors: {
          ..._.get(error, 'data', {}),
          [FORM_ERROR]: 'There was an error getting this invite.',
        },
      })
    }
  }
}

function* createOrganizationPaymentMethod({ payload }) {
  const { token, organization, resolve, reject } = payload.toJS()

  try {
    yield call(createOrganizationPaymentMethodApi, organization, {
      stripeToken: token,
    })

    yield put({
      type: organizationsConstants.CREATE_ORGANIZATION_PAYMENT_METHOD_SUCCESS,
      payload: fromJS({}),
    })

    yield call(
      getOrganization,
      organizationsActions.getOrganization(
        fromJS({ uuid: organization, resolve })
      )
    )
  } catch (error) {
    yield put({
      type: organizationsConstants.CREATE_ORGANIZATION_PAYMENT_METHOD_FAIL,
      payload: fromJS({ error }),
    })

    if (reject) {
      reject({
        errors: {
          ..._.get(error, 'data', {}),
          [FORM_ERROR]: 'There was an error saving the payment information.',
        },
      })
    }
  }
}

function* setCurrentOrganizationId({ payload }) {
  const currentOrganizationData = yield select(currentOrganizationSelector)
  const currentTeam = yield select(currentTeamSelector)

  if (currentTeam && currentTeam.get('organization') !== payload) {
    yield put(teamsActions.clearCurrentTeamId())
  }

  if (!currentOrganizationData) {
    yield put(organizationsActions.getOrganization(fromJS({ uuid: payload })))

    yield all([
      put(organizationsActions.searchOrganizationMembersClear()),
      put(organizationsActions.organizationInvitesClear()),
    ])
  }
}

function* socketDeleteOrganizationMembership({ payload }) {
  const currentOrganizationMembership = yield select(
    getCurrentOrganizationMember
  )
  if (!currentOrganizationMembership) {
    const { pathname } = yield select(getLocation)
    const pathRoot = pathname.split('/')[1]
    if (['calls', 'contacts', 'settings'].includes(pathRoot)) {
      yield put(replace(`/${pathRoot}`))
    }
  }

  const currentUserUuid = yield select(authUserUuidSelector)
  const membership = yield select(state =>
    getMemberByUuid(state, { uuid: payload.get('uuid') })
  )

  if (currentUserUuid && membership) {
    if (currentUserUuid === membership.get('user')) {
      yield delay(1000)
      yield put(
        organizationsActions.socketRemoveOrganizationFromOrganizationUuids(
          membership.get('organization')
        )
      )
    }
  }
}

function* updateOrganizationMembership({ payload }) {
  const { resolve, reject, uuid, organization, ...rest } = payload.toJS()

  try {
    const {
      entities: { members },
    } = yield call(updateMembershipApi, organization, uuid, rest)

    yield put({
      type: organizationsConstants.UPDATE_ORGANIZATION_MEMBERSHIP_SUCCESS,
      payload: fromJS({ members }),
    })

    if (resolve) resolve(members[uuid])
  } catch (error) {
    yield put({
      type: organizationsConstants.UPDATE_ORGANIZATION_MEMBERSHIP_FAIL,
      payload: fromJS({ error }),
    })

    if (reject) {
      reject({
        ..._.get(error, 'data', {}),
        [FORM_ERROR]: _.get(
          error,
          'data.detail',
          'There was an error saving the membership information.'
        ),
      })
    }
  }
}

function* watchOrganizations() {
  yield takeEvery(
    organizationsConstants.CREATE_ORGANIZATION,
    createOrganization
  )
  yield takeEvery(organizationsConstants.DELETE_MEMBERSHIP, deleteMembership)
  yield takeLatest(
    organizationsConstants.QUERY_ORGANIZATIONS,
    queryOrganizations
  )
  yield takeLatest(
    organizationsConstants.QUERY_ORGANIZATION_INVITES,
    queryOrganizationInvites
  )
  yield takeLatest(
    organizationsConstants.QUERY_ORGANIZATION_INVOICES,
    queryOrganizationInvoices
  )
  yield takeLatest(
    organizationsConstants.QUERY_ORGANIZATION_MEMBERSHIPS,
    queryOrganizationMembers
  )
  yield takeLatest(
    organizationsConstants.REDIRECT_ORGANIZATION,
    redirectOrganization
  )
  yield takeEvery(organizationsConstants.GET_ORGANIZATION, getOrganization)
  yield takeLatest(
    organizationsConstants.SEARCH_ORGANIZATION_MEMBERS,
    searchOrganizationMembers
  )
  yield takeLatest(
    organizationsConstants.SET_CURRENT_ORGANIZATION_ID,
    setCurrentOrganizationId
  )
  yield takeEvery(
    organizationsConstants.SOCKET_DELETE_ORGANIZATION_MEMBERSHIP,
    socketDeleteOrganizationMembership
  )
  yield takeLatest(
    organizationsConstants.SOCKET_ORGANIZATION_MEMBERSHIP,
    socketOrganizationMembership
  )
  yield takeEvery(
    organizationsConstants.UPDATE_ORGANIZATION_INVITE,
    updateOrganizationInvite
  )
  yield takeEvery(
    organizationsConstants.CREATE_ORGANIZATION_INVITE,
    createOrganizationInvite
  )
  yield takeEvery(
    organizationsConstants.GET_ORGANIZATION_INVITE,
    getOrganizationInvite
  )
  yield takeEvery(
    organizationsConstants.CREATE_ORGANIZATION_PAYMENT_METHOD,
    createOrganizationPaymentMethod
  )
  yield takeEvery(
    organizationsConstants.ACTIVATE_CURRENT_ORGANIZATION_SUBSCRIPTION,
    activateCurrentOrganizationSubscription
  )
  yield takeEvery(
    organizationsConstants.CANCEL_CURRENT_ORGANIZATION_SUBSCRIPTION,
    cancelCurrentOrganizationSubscription
  )
  yield takeLeading(
    organizationsConstants.REDIRECT_INACTIVE_ORGANIZATION,
    redirectInactiveOrganization
  )
  yield takeLatest(
    organizationsConstants.UPDATE_ORGANIZATION_MEMBERSHIP,
    updateOrganizationMembership
  )
}

export const organizationsSaga = [fork(watchOrganizations)]
