import styled from 'styled-components'
import { connect } from 'react-redux'
import { getEmailAddressUuidsFromContactUuid } from 'selectors/contactsSelector'
import { getConversationContactUuidFromUuid } from 'selectors/conversationsSelector'

import ContactEmail from './ContactEmail'

const mapStateToProps = (state, { conversationUuid: uuid }) => {
  const contact = getConversationContactUuidFromUuid(state, { uuid })
  const emailAddressUuids = getEmailAddressUuidsFromContactUuid(state, { uuid: contact })

  return {
    emailAddressUuids,
  }
}

const ContactEmails = styled(({ conversationUuid, emailAddressUuids, ...props }) => (
  <div {...props}>
    {emailAddressUuids.map(
      emailAddressUuid => (
        <ContactEmail
          key={emailAddressUuid}
          emailAddressUuid={emailAddressUuid}
        />
      )
    )}
  </div>
))`
  &:before {
    content: '';
    display: block;
    width: 38px;
    margin: 20px 0;
    border-top: 1px solid ${props => props.theme.lightGray};
    margin-left: 0;
  }
`

export default connect(
  mapStateToProps,
  {}
)(ContactEmails)
