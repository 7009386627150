import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import Rollbar from 'rollbar'
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'

import { store, history } from 'store'
import Routes from './Routes'
import Root from './Root'

const rollbarConfig = {
  accessToken: __CONFIG__.rollbar.accessToken,
  environment: __CONFIG__.isProduction ? 'production' : 'development',
}

if (window) {
  window._rollbar = new Rollbar(rollbarConfig)
}

export const App = () => (
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary>
      <Provider store={store}>
        <Root history={history} routes={Routes} />
      </Provider>
    </ErrorBoundary>
  </RollbarProvider>
)

// Render the React application to the DOM
// Root component is to bootstrap Provider, Router and DevTools
ReactDOM.render(<App />, document.getElementById('app-container'))
