import moment from 'moment'
import Link from 'components/Link'

import useOrganizations from 'hooks/useOrganizations'

const AccountSummary = () => {
  const {
    currentOrganizationHasActiveSubscription,
    currentOrganizationSeats,
    currentOrganizationStripeCancelAtPeriodEnd,
    currentOrganizationStripeSubscriptionStatus,
    currentOrganizationSubscriptionExpirationDate,
    currentOrganizationUuid,
  } = useOrganizations()

  const endDate = moment(currentOrganizationSubscriptionExpirationDate).format(
    'MMM D, YYYY'
  )

  const activeAccountMessage = {
    trialing: `Current subscription is trialing. Trial will end ${endDate}.`,
    active: `Current subscription is active and being billed $${10 *
      currentOrganizationSeats}/month for
    ${currentOrganizationSeats} user
    ${currentOrganizationSeats > 1 ? 's' : ''}.`,
    incomplete:
      'A payment on your account has failed. Please update your payment method.',
    past_due:
      'A payment on your account has failed. Please update your payment mehtod and pay the unpaid invoice below.',
    unpaid:
      'A payment on your account has failed. Please update your payment mehtod and pay the unpaid invoice below.',
  }[currentOrganizationStripeSubscriptionStatus || 'active']

  const accountStatusMessage = currentOrganizationHasActiveSubscription
    ? activeAccountMessage
    : 'Current subscription is invactive.'

  const nextPaymentMessage = !currentOrganizationStripeCancelAtPeriodEnd
    ? `Next payment is on ${endDate}.`
    : `Subscription is set to expire on ${endDate}.`

  return (
    <Ul>
      <li>{accountStatusMessage}</li>

      {!!currentOrganizationHasActiveSubscription && (
        <li>{nextPaymentMessage}</li>
      )}

      {!!currentOrganizationHasActiveSubscription && (
        <li>
          You can remove or deactivate users in the{' '}
          <Link to={`/settings/${currentOrganizationUuid}/users`}>Users</Link>{' '}
          tab.
        </li>
      )}
    </Ul>
  )
}

const Ul = styled.ul`
  padding-left: 16px;
  margin-bottom: 54px;
`

export default AccountSummary
