import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actions as organizationsActions } from 'modules/organizations'
import { actions as teamsActions } from 'modules/teams'
import {
  organizationsLoadingSelector,
  organizationUuidsSelector,
} from 'selectors/organizationsSelector'
import {
  getQueryTeamsLoading,
  memberTeamsListSelector,
} from 'selectors/teamsSelector'

import Spinner from 'components/Spinner'
import Subhed from 'components/Subhed'
import Row from './Row'

const OrgsAndTeams = () => {
  const dispatch = useDispatch()

  const organizationsLoading = useSelector(organizationsLoadingSelector)
  const organizationUuids = useSelector(organizationUuidsSelector)

  const teams = useSelector(memberTeamsListSelector)
  const teamsLoading = useSelector(getQueryTeamsLoading)

  const [mounted, setMounted] = useState(false)

  const loading = !mounted || organizationsLoading || teamsLoading

  const organizationsAndTeams = []
  organizationUuids.forEach(uuid => {
    organizationsAndTeams.push({ uuid, isOrganization: true })
    teams
      .filter(team => team.get('organization') === uuid)
      .forEach(team => {
        organizationsAndTeams.push({
          uuid: team.get('uuid'),
          isOrganization: false,
        })
      })
  })

  useEffect(() => {
    dispatch(organizationsActions.queryOrganizations())
    dispatch(teamsActions.queryTeams())
    setMounted(true)
  }, [])
  return (
    <>
      <Subhed>My Organizations and Teams</Subhed>

      {loading && <Spinner />}

      {!loading && (
        <Col>
          {organizationsAndTeams.map(props => (
            <Row key={props.uuid} {...props} />
          ))}

          {!organizationsAndTeams.length && <NoResultsMessage />}
        </Col>
      )}
    </>
  )
}

const Col = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e3e3e3;
  margin-bottom: 50px;
`

const NoResultsMessage = styled.p.attrs({ children: 'No results found' })`
  font-style: italic;
`

export default OrgsAndTeams
