import { schema } from 'normalizr'

const idAttribute = 'uuid'

export const invite = new schema.Entity('invites', undefined, { idAttribute })

export const member = new schema.Entity('members', undefined, { idAttribute })

export const status = new schema.Entity('statuses', undefined, { idAttribute })

export const team = new schema.Entity(
  'teams',
  {
    members: [member],
    statuses: [status],
  },
  { idAttribute }
)
