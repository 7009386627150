import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { any, func, string } from 'prop-types'
import { map } from 'react-immutable-proptypes'
import cx from 'classnames'
import { fromJS } from 'immutable'

import { media } from 'utility/styles'

import { actions as conversationsActions } from 'modules/conversations'
import {
  getHasDetailsFromUuid,
  isGetConversationLoadingSelector,
} from 'selectors/conversationsSelector'

import theme from '../theme.js'
import ConversationColumn from './ConversationColumn'
import ConversationHistory from './ConversationHistory'
import ContactDropdownHolder from './ContactDropdownHolder'
import ContactEmails from './ContactEmails'
import _PhoneDropdown from './PhoneDropdown'
import _StatusDropdown from './StatusDropdown'
import _Subject from './Subject'
import _Message from './Message'
import _DatePicker from './DatePicker'
import _TimePicker from './TimePicker'
import _Owner from './Owner'
import _MoreToggle from './MoreToggle'

const ContactColumn = styled(ConversationColumn)`
  flex-basis: 340px;
  position: relative;
`

const StatusColumn = styled(ConversationColumn)`
  flex-basis: 165px;
  padding-left: 0;
`

const MessageColumn = styled(ConversationColumn)`
  flex: 1 1 auto;
  padding-left: 0;
  ${({ expanded }) =>
    !expanded &&
    css`
      textarea {
        height: auto !important;
      }
    `}
`

const DateRow = (styled.div || styled.View)`
  display: flex;
  align-items: center;
`

const OwnerColumn = styled(ConversationColumn)`
  flex-basis: 150px;
  padding-left: 0;
  justify-content: space-between;
  align-items: flex-end;
`

const PhoneDropdown = styled(_PhoneDropdown)`
  margin-top: 4px;
`

const StatusDropdown = styled(_StatusDropdown)``

const Subject = styled(_Subject)`
  margin-top: 4px;
`

const Message = styled(_Message)``
const DatePicker = styled(_DatePicker)`
  margin-right: 20px;
`
const TimePicker = styled(_TimePicker)`
  flex-basis: 132px;
`
const Owner = styled(_Owner)``
const MoreToggle = styled(_MoreToggle)``

const mapStateToProps = (state, props) => {
  const loadingDetails = isGetConversationLoadingSelector(state, props)
  const hasDetails = getHasDetailsFromUuid(state, props)

  return {
    hasDetails,
    loadingDetails,
  }
}

const mapDispatchToProps = {
  updateConversation: conversationsActions.updateConversation,
  getConversation: conversationsActions.getConversation,
  preDeleteConversation: conversationsActions.preDeleteConversation,
}

class _Conversation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      hovered: false,
    }
  }

  handleMoreCick = () => {
    const { expanded } = this.state
    const { hasDetails, uuid, getConversation } = this.props

    if (!expanded && !hasDetails) getConversation(fromJS({ uuid }))

    this.setState(prevState => ({ expanded: !prevState.expanded }))
  }

  render() {
    const {
      className,
      conversations,
      hasDetails,
      updateConversation,
      getConversation,
      loadingDetails,
      preDeleteConversation,
      printNotes,
      uuid,
      ...props
    } = this.props

    const { expanded, hovered } = this.state

    const UIexpanded = expanded && hasDetails && !loadingDetails

    return (
      <div
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
        className={cx(className, {
          conversationExpanded: UIexpanded,
        })}
        {...props}
      >
        <ContactColumn>
          <ContactDropdownHolder conversationUuid={uuid} noCaret={!hovered} />

          <PhoneDropdown conversationUuid={uuid} />
          {UIexpanded && <ContactEmails conversationUuid={uuid} />}
        </ContactColumn>

        <StatusColumn expanded={UIexpanded}>
          <StatusDropdown conversationUuid={uuid} noCaret={!hovered} />
          <Subject conversationUuid={uuid} />
        </StatusColumn>

        <MessageColumn expanded={UIexpanded}>
          <DateRow>
            <DatePicker conversationUuid={uuid} />
            <TimePicker conversationUuid={uuid} />
          </DateRow>
          <Message conversationUuid={uuid} />
          {UIexpanded && <ConversationHistory conversationUuid={uuid} />}
        </MessageColumn>

        <OwnerColumn expanded={UIexpanded}>
          <Owner conversationUuid={uuid} />
          <MoreToggle expanded={UIexpanded} onClick={this.handleMoreCick} />
        </OwnerColumn>
      </div>
    )
  }
}

_Conversation.propTypes = {
  uuid: string.isRequired,
  className: any,
  conversations: map,
  updateConversation: func.isRequired,
  preDeleteConversation: func.isRequired,
  getConversation: func.isRequired,
}

_Conversation.defaultProps = {
  className: null,
  conversations: null,
}

const Conversation = styled(_Conversation)`
  border-bottom: 1px solid ${theme.conversationBorderBottom};
  display: flex;
  height: ${theme.actionButtonHeight * 2 * theme.actionButtonBorderWidth}px;

  &:hover,
  &.dropdownOpen,
  &.conversationExpanded {
    background-color: ${theme.conversationHoverBackgroundColor};
  }

  &.conversationExpanded {
    height: auto;
  }

  &.dropdownOpen {
    pointer-events: none;
  }

  ${media.tablet`
    flex-direction: column;
    height: auto;
    padding: 15px;
  `}
`

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Conversation)
