export default {
  actionButtonHeight: 45,
  actionButtonBorderWidth: 1,

  gray: '#9d9d9d',
  lightGray: '#d7d7d7',
  conversationBorderBottom: '#e6e6e6',
  conversationHoverBackgroundColor: '#f6f6f6',
  fullNameColor: '#434a54',
  businessNameColor: '#b2b2b2',
}
