import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { fromJS } from 'immutable'
import { Field, change } from 'redux-form/immutable'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import styled, { css } from 'styled-components'

import { FloatingLabelField } from 'components/FloatingLabelInput'
import AddIconButton from 'components/Buttons/AddIconButton'
import BlankIconButtonDelete from 'components/Buttons/BlankIconButtonDelete'
import BlueActionButton from 'components/Buttons/BlueActionButton'
import { FormError } from 'components/Form'

import StatusColorDropdown from './StatusColorDropdown'
import DraggableRow from './DraggableRow'

const Container = styled.div`
  border-top: 1px solid #e3e3e3;
  flex: 1;
  margin-bottom: 72px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e3e3e3;
  padding: 15px 5px;
`

const Col = styled.div`
  flex: 1;
`

const NameCol = styled.div`
  flex: 0 0 160px;
  padding-right: 10px;
`

const InitialismField = styled(FloatingLabelField)`
  input {
    color: ${({ initialismColor }) =>
      initialismColor ? `#${initialismColor}` : 'inherit'}!important;
  }
`

const ButtonCol = styled.div`
  flex: 0 0 auto;
  padding-left: 10px;
  ${({ isHidden }) =>
    !!isHidden &&
    css`
      visibility: hidden;
    `}
`

const Title = styled.h2`
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: 18px;
`

const AddStatusButton = styled(AddIconButton).attrs(() => ({
  children: 'Add Status',
}))`
  margin: 28px 0;
`

const StatusFields = ({ fields, teamUuid, meta: { error } }) => {
  const newStatus = fromJS({
    team: teamUuid,
    color: '000000',
  })

  const dispatch = useDispatch()

  const remove = useCallback(
    (index, is_removed = true) => {
      dispatch(change('team', `statuses[${index}].is_removed`, is_removed))
    },
    [fields, dispatch]
  )

  const reactivate = useCallback(index => remove(index, false), [
    remove,
    fields,
    dispatch,
  ])

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      fields.move(dragIndex, hoverIndex)
      fields.forEach((label, index) => {
        dispatch(change('team', `statuses[${index}].order`, index))
      })
    },
    [fields, dispatch]
  )

  const hasRemovedStatuses =
    fields.getAll().findIndex(status => status.get('is_removed')) > -1

  return (
    <DndProvider backend={HTML5Backend}>
      <Title>Active Call Statuses</Title>

      <Container>
        {fields.map((status, index) => {
          const statusObject = fields.get(index)
          const isRemoved = statusObject.get('is_removed')

          if (isRemoved) return null

          const uuid = statusObject.get('uuid')
          const isSystem = statusObject.get('system')
          const initialismColor = statusObject.get('color')

          return (
            <DraggableRow
              index={index}
              id={uuid}
              key={uuid || index}
              moveRow={moveRow}
            >
              <NameCol>
                <InitialismField
                  id={`${status}-initialism`}
                  name={`${status}.initialism`}
                  initialismColor={initialismColor}
                  type="text"
                  placeholder="Abbreviation"
                  noFloat
                  isDisabled={!!isSystem}
                />
              </NameCol>

              <Col>
                <FloatingLabelField
                  id={`${status}-initialism`}
                  name={`${status}.title`}
                  type="text"
                  placeholder="Description"
                  noFloat
                  isDisabled={!!isSystem}
                />
              </Col>

              <ButtonCol>
                <Field
                  name={`${status}.color`}
                  component={StatusColorDropdown}
                />
              </ButtonCol>

              <ButtonCol isHidden={isSystem}>
                <BlankIconButtonDelete
                  type="button"
                  title="Remove Status"
                  onClick={() => remove(index)}
                />
              </ButtonCol>
            </DraggableRow>
          )
        })}

        <AddStatusButton onClick={() => fields.push(newStatus)} />

        {error ? <FormError>{error}</FormError> : null}
      </Container>

      {hasRemovedStatuses ? (
        <>
          <Title>Inactive Call Statuses</Title>

          <Container>
            {fields.map((status, index) => {
              const statusObject = fields.get(index)
              const isRemoved = statusObject.get('is_removed')

              if (!isRemoved) return null

              const uuid = statusObject.get('uuid')
              const description = statusObject.get('title')
              const initialism = statusObject.get('initialism')
              const initialismColor = statusObject.get('color')

              return (
                <Row key={uuid || index}>
                  <NameCol>
                    <span style={{ color: `#${initialismColor}` }}>
                      {initialism}
                    </span>
                  </NameCol>

                  <Col>
                    <span>{description}</span>
                  </Col>

                  <ButtonCol>
                    <BlueActionButton onClick={() => reactivate(index)}>
                      Re-activate
                    </BlueActionButton>
                  </ButtonCol>
                </Row>
              )
            })}

            <AddStatusButton onClick={() => fields.push(newStatus)} />

            {error ? <FormError>{error}</FormError> : null}
          </Container>
        </>
      ) : null}
    </DndProvider>
  )
}

export default StatusFields
