import { Form as ReactFinalForm } from 'react-final-form'
import { fromJS } from 'immutable'

import useAuth from 'hooks/useAuth'

import PersonalInfoForm from './Form'
import validate from './validate'

const PersonalInfo = props => {
  const { user, updateProfile } = useAuth()

  const onSubmit = ({
    display_name,
    password,
    new_password,
    new_password_confirm,
  }) => {
    return new Promise((resolve, reject) => {
      updateProfile(
        fromJS({
          display_name,
          password,
          new_password,
          new_password_confirm,
          resolve,
          reject,
        })
      )
    }).catch(errors => errors)
  }
  return (
    <ReactFinalForm
      onSubmit={onSubmit}
      initialValues={user}
      validate={validate}
    >
      {_props => <PersonalInfoForm {..._props} {...props} />}
    </ReactFinalForm>
  )
}

export default PersonalInfo
