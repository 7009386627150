import styled from 'styled-components'
import _ProfileNav from 'components/ProfileNav/ProfileNav'
import NavBar from 'components/NavBar/NavBar'
import _Collapse from 'components/NavBar/Collapse'
import BrandLink from 'components/NavBar/BrandLink'

const RegisterNavBar = styled(({ ...props }) => (
  <NavBar {...props}>
    <BrandLink />

    <Collapse>
      <ProfileNav />
    </Collapse>
  </NavBar>
))``

const Collapse = styled(_Collapse)`
  justify-content: flex-end;
`

const ProfileNav = styled(_ProfileNav)`
  margin-left: ${props => props.theme.nav.marginHorizontal};
`

export default RegisterNavBar
