import styled from 'styled-components'
import theme from './theme'

export default (styled.label || styled.Text)`
  color: ${theme.filterLabelColor};
  font-size: 10px;
  letter-spacing: 1px;
  line-height: 1.2;
  margin-bottom: 3px;
  text-transform: uppercase;
  white-space: nowrap;
`
