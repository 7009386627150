import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS } from 'immutable'

import {
  getOrganizationNameByUuid,
  getCurrentUserMemberByEntityUuid,
} from 'selectors/organizationsSelector'
import { getTeamNameByUuid } from 'selectors/teamsSelector'
import { actions as organizationsActions } from 'modules/organizations'
import { actions as teamsActions } from 'modules/teams'

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalClose,
  ModalTitle,
  ModalFooter,
} from 'components/Modal'
import Button from 'components/Buttons'
import ButtonRow from 'components/Buttons/ButtonRow'

const AddOrganizationMembershipModal = ({
  handleCancel,
  isOrganization,
  uuid,
  ...props
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const entityNameSelector = isOrganization
    ? getOrganizationNameByUuid
    : getTeamNameByUuid
  const entityName = useSelector(state =>
    entityNameSelector(state, { uuid, teamUuid: uuid })
  )

  const member = useSelector(state =>
    getCurrentUserMemberByEntityUuid(state, { uuid })
  )

  const handleConfirm = () => {
    setLoading(true)
    return new Promise((resolve, reject) => {
      if (isOrganization) {
        dispatch(
          organizationsActions.deleteMembership(
            fromJS({
              organization: uuid,
              uuid: member.get('uuid'),
              resolve,
              reject,
            })
          )
        )
      } else {
        dispatch(
          teamsActions.deleteMembership(
            fromJS({
              team: uuid,
              uuid: member.get('uuid'),
              resolve,
              reject,
            })
          )
        )
      }
    })
      .then(() => {
        handleCancel()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal {...props}>
      <ModalHeader>
        <ModalTitle>Invite New Member</ModalTitle>
        <ModalClose onClick={handleCancel} />
      </ModalHeader>

      <ModalBody>
        <Graf>
          Invite a new or existing PhoneSlate user to join your organization by
          email address.
        </Graf>

        <Input type="email" />
      </ModalBody>

      <ModalFooter>
        <ButtonRow>
          <Button
            disabled={loading}
            onClick={handleCancel}
            secondary
            type="button"
          >
            Cancel
          </Button>

          <Button
            disabled={loading}
            loading={loading}
            loadingText="Inviting..."
            onClick={handleConfirm}
            type="button"
            red
          >
            Invite
          </Button>
        </ButtonRow>
      </ModalFooter>
    </Modal>
  )
}

const Graf = styled.p``

const Input = styled.input``

export default AddOrganizationMembershipModal
