import InviteForm from 'components/InviteForm'
import Subhed from 'components/Subhed'

const Invites = () => (
  <>
    <Subhed>My Invites</Subhed>

    <InviteForm hideCreateButton showEmptyListMessage preventRedirect />
  </>
)

export default Invites
