import styled from 'styled-components'
import _Logo from 'components/Logo/Logo'
import InnerCard from 'components/Cards/InnerCard'

import { media } from 'utility/styles'

export default styled(({ className, children }) => (
  <Column className={className}>
    <Logo />
    <InnerCard>{children}</InnerCard>
  </Column>
))``

const Logo = styled(_Logo)`
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;

  ${media.tablet`
    display: none;
  `}
`

const Column = (styled.div || styled.View)`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 26px;

  ${media.tablet`
    padding-top: 0;
    margin: 0;
    flex: 1;
  `}
`
