import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getEmailAddressFromEmailAddressUuid } from 'selectors/contactsSelector'

const Email = styled(({ uuid, innerRef, ...props }) => {
  const email = useSelector(
    state => getEmailAddressFromEmailAddressUuid(state, { uuid }),
  )

  if (!email) return null

  return (
    <div ref={innerRef} {...props}>
      <a
        href={`mailto:${email}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        {email}
      </a>
    </div>
  )
})``

export default Email
