import styled from 'styled-components'
import { object } from 'prop-types'

import ContactDropdown, { Toggle } from 'components/ContactDropdown'

const AddConversationContact = styled(
  ({ input: { onChange, value }, meta, ...props }) => (
    <div {...props}>
      <ContactDropdown
        contactUuid={value}
        handleSelect={onChange}
        omitBusiness
        noCaret
      />
    </div>
  )
)`
  position: relative;
  flex: 1 1 100%;
  max-width: 100%;

  ${Toggle} {
    color: ${({ theme }) => theme.colors.darkGray};
    &,
    span {
      font-size: 14px;
      font-weight: normal;
    }
  }
`

AddConversationContact.propTypes = {
  input: object.isRequired,
  meta: object,
}

AddConversationContact.defaultProps = {
  meta: {},
}

export default AddConversationContact
