import styled, { css } from 'styled-components'
import { func, node, string, oneOfType } from 'prop-types'
import { _component } from 'utility/proptypes'
import Theme from 'components/Theme'
import { media } from 'utility/styles'
import PageHeading from './PageHeading'

const DEFAULT_TITLE = 'PhoneSlate'
const DEFAULT_DESCRIPTION = 'Never miss a call'

const Container = styled.div``

const Page = ({
  children,
  description,
  navBar: NavBar,
  title,
  heading,
  ...props
}) => (
  <Container {...props}>
    {NavBar && <NavBar />}
    {heading && <PageHeading>{heading}</PageHeading>}
    {children}
  </Container>
)

Page.propTypes = {
  children: node,
  description: string,
  navBar: oneOfType([func, node, _component]),
  title: string,
  heading: string,
}

Page.defaultProps = {
  children: null,
  description: DEFAULT_DESCRIPTION,
  navBar: null,
  title: DEFAULT_TITLE,
  heading: undefined,
}

export default styled(Page)`
  ${({ navBar }) =>
    !!navBar &&
    css`
      padding-top: 52px;
    `}
  padding-left: ${Theme.page.paddingHorizontal};
  padding-right: ${Theme.page.paddingHorizontal};
  padding-bottom: ${Theme.page.paddingVertical};
  flex: 1;

  ${media.tablet`
    padding: 52px 0 0 0;
    display: flex;
    flex-direction: column;
  `}
`
