import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import Subhed from 'components/Subhed'

import Form from './Form'

const stripePromise = loadStripe(__CONFIG__.stripe.api_public_key)

const CreditCardFormWrapper = () => (
  <Elements stripe={stripePromise}>
    <Form />
  </Elements>
)

const CreditCard = () => {
  return (
    <>
      <Subhed>Payment Method</Subhed>

      <CreditCardFormWrapper />
    </>
  )
}

export default CreditCard
