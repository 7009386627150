import styled from 'styled-components'

export default (styled.div || styled.View)`
  display: flex;
  align-items: stretch;
  flex: 1;

  > ul > li > a {
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: none;
      color: #fff;
    }
  }
`
