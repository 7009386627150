import { ModalConsumer } from '..'

const EmailConversationsModalToggle = ({
  component: Component,
  preSave,
  ...props
}) => (
  <ModalConsumer>
    {({ emailConversations }) => (
      <Component
        {...props}
        onClick={e => {
          emailConversations.summon(e, { preSave })
        }}
      />
    )}
  </ModalConsumer>
)

export default EmailConversationsModalToggle
